import API from '../api'
import putUrl from './paths'

export const updateFactory = (factory, user) =>
	new Promise((resolve, reject) => {
		factory.updated_by = user.user_id
		factory.updated_at = new Date().getTime()
		API.put(putUrl.updateFactory, factory)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateFabricationOrder = (orderData, user) =>
	new Promise((resolve, reject) => {
		orderData.updated_by = user?.user_id
		orderData.updated_at = new Date().getTime()

		API.put(putUrl.updateFabricationOrder, orderData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export const updateFabricationOrderRemainingInventory = (data, fabricationOrderId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateFabricationRemainingInventory, data, { fabricationOrderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateFabricationInvoice = (invoiceAmount, invoiceDate, invoiceNumber, orderId, originalInvoiceNumber) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateFabricationInvoice, {}, { invoiceAmount, invoiceDate, invoiceNumber, orderId, originalInvoiceNumber })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
