import API from '../api'
import postUrl from './paths'

export const createBannerApi = (
	type,
	page = null,
	media_url = null,
	event_id = null,
	source_id = null,
	platform,
	role,
	active,
	file = null,
	priority
) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('type', type)
		formData.append('platform', platform)
		formData.append('role', role)
		formData.append('active', active)
		formData.append('priority', priority)

		media_url && formData.append('mediaUrl', media_url)
		file && formData.append('file', file)
		page && formData.append('page', page)
		event_id && formData.append('eventId', event_id)
		source_id && formData.append('sourceId', source_id)

		API.post(postUrl.createBanner, formData, {})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
