import API from '../api'
import postUrl from './paths'

const shareEnquiry = (enquiryId, data) =>
	new Promise((resolve, reject) => {
		data.removeDefault = true;
		API.post(postUrl.shareEnquiry, data, { enquiryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { shareEnquiry }
