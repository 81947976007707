import { DrawerBodyWrapper, TOAST_TYPE } from '@base'
import React, { useEffect, useState } from 'react'
import { ActionButton, Footer } from '../../styles'
import { Commodity as CommodityModel } from '@data/model'
import { strings } from '@constants'
import CommodityForm from './CommodityForm'
import { createCommodityApi } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { editCommodityApi } from '@data/conn/put/commodity'

const Index = ({ data, update }) => {
	const edit = !!data
	const [commodity, setCommodity] = useState(new CommodityModel())
	const dispatch = useDispatch()

	useEffect(() => {
		if (data) {
			setCommodity(data)
		} else {
			const tempCommodity = Object.assign({}, commodity)
			tempCommodity['commodity_type'] = 'metal'
			setCommodity(tempCommodity)
		}
	}, [data])

	const onCommodityChange = (key, value) => {
		const tempCommodity = Object.assign({}, commodity)
		tempCommodity[key] = key === 'commodity_type' ? value?.name : value
		setCommodity(tempCommodity)
	}

	const submitHandler = () => {
		dispatch(showLoader(true))
		const apiCall = data ? editCommodityApi(commodity) : createCommodityApi(commodity)

		apiCall
			.then((_) => {
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, data ? strings('msg_commodity_editted') : strings('msg_commodity_created'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const disableButton = !commodity?.commodity_name

	const renderButtons = () => {
		if (!edit) {
			return (
				<ActionButton small type='primary' disabled={disableButton} onClick={submitHandler}>
					{strings('add')}
				</ActionButton>
			)
		} else {
			return (
				<>
					<ActionButton small disabled={disableButton} type='primary' onClick={submitHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelHandler}>
						{strings('cancel')}
					</ActionButton>
				</>
			)
		}
	}

	return (
		<>
			<DrawerBodyWrapper>
				<CommodityForm state={commodity} onCommodityChange={onCommodityChange} />
			</DrawerBodyWrapper>
			<Footer>{renderButtons()}</Footer>
		</>
	)
}

export default Index
