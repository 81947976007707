import API from "../api";
import putUrl from "./paths";

export const editGlossary = (glossary) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.editGlossary, glossary)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })