import { useState } from 'react'

const useForceUpdate = () => {
	const [forceUpdate, setForceUpdate] = useState()

	const update = () => setForceUpdate(Math.random())

	return [forceUpdate, update]
}

export default useForceUpdate
