export const SET_TOAST_MESSAGE = 'SHOW_TOAST'
export const SHOW_LOADER = 'SHOW_LOADER'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const SIDEBAR_INDEX = 'SIDEBAR_INDEX_CHANGE'
export const TAB_TITLE_CHANGE = 'TAB_TITLE_CHANGE'
export const MODAL_CHANGE = 'SHOW_MODAL'
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const SEARCH_OBJECT_CHANGE = 'SEARCH_OBJECT_CHANGE'
export const UPDATE_DRAWER_META = 'UPDATE_DRAWER_META'
export const SET_HEADER_META = 'SET_HEADER_META'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const UPDATE_MODAL_META = 'UPDATE_MODAL_META'
export const TOGGLE_FILE_VIEWER = 'TOGGLE_FILE_VIEWER'
export const PLAY_NOTIFICATION_SOUND = 'PLAY_NOTIFICATION_SOUND'
