import API from '../api'
import convertPagableData from '../convertPagableData'
import getUrl from './paths'

export const getNewsData = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getNews, { removeDefault: true })
			.then((response) => {
				resolve(response.data.articles)
			})
			.catch((err) => reject(err))
	})

export const getAllContentNews = (page = 0, newsFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllContentNews, { page, ...newsFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})
