import API from '../api'
import postUrl from './paths'

export const createCreditLimit = (creditLimit) =>
    new Promise((resolve, reject) => {
        API.post(postUrl.createCreditLimit, creditLimit)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })

export const uploadSupportedDoc = (file) =>
    new Promise((resolve, reject) => {
        const formData = new FormData()
        file && formData.append('file', file)
        API.post(postUrl.uploadCreditLimitDocument, formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })