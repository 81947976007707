class Commodity {
	constructor() {
		this.active = true
		this.commodity_id = null
		this.commodity_name = null
		this.commodity_type = null
		this.brands = [] //list
		this.category_name = []
	}
}

export default Commodity
