import { logisticDocType, logisticsStatus as logisticsStatusC } from '@constants'
import API from '@data/conn/api'
import convertPagableData from '@data/conn/convertPagableData'
import getUrl from '@data/conn/get/paths'

import postUrl from '@data/conn/post/paths'
import putUrl from '@data/conn/put/paths'

export const FETCH_USERS_PROGRESS = 'FETCH_USERS_PROGRESS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_SUCCESS_BUYER = 'FETCH_USERS_SUCCESS_BUYER'
export const FETCH_USERS_SUCCESS_SUPPLIER = 'FETCH_USERS_SUCCESS_SUPPLIER'
export const FETCH_USERS_SUCCESS_LOGISTICS = 'FETCH_USERS_SUCCESS_LOGISTICS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const CREATE_NEW_LOGISTICS_FROM_DATA = 'CREATE_NEW_LOGISTICS_FROM_DATA'
export const SET_SELECTED_BUYER_USER = 'SET_SELECTED_BUYER_USER'
export const SET_SELECTED_SUPPLIER_USER = 'SET_SELECTED_SUPPLIER_USER'
export const SET_SELECTED_LOGISTICS_USER = 'SET_SELECTED_LOGISTICS_USER'
export const SET_LOGISTICS_PARTNER = 'SET_LOGISTICS_PARTNER'
export const UPDATE_LOGISTICS = 'UPDATE_LOGISTICS'
export const ADD_NEW_ITEM_TO_LOGISTICS = 'ADD_NEW_ITEM_TO_LOGISTICS'
export const UPDATE_LOGISTICS_ITEM = 'UPDATE_LOGISTICS_ITEM'
export const DELETE_LOGISTICS_ITEM = 'DELETE_LOGISTICS_ITEM'

export const ADD_NEW_LOGISTICS_QUOTES = 'ADD_NEW_LOGISTICS_QUOTES'
export const UPDATE_LOGISTICS_QUOTES = 'UPDATE_LOGISTICS_QUOTES'
export const DELETE_LOGISTICS_QUOTES = 'DELETE_LOGISTICS_QUOTES'

export const CREATE_LOGISTICS_SUCCESS = 'CREATE_LOGISTICS_SUCCESS'
export const CREATE_LOGISTICS_FAILURE = 'CREATE_LOGISTICS_FAILURE'
export const CREATE_LOGISTICS_PROGRESS = 'CREATE_LOGISTICS_PROGRESS'

export const UPDATE_LOGISTICS_SUCCESS = 'UPDATE_LOGISTICS_SUCCESS'
export const UPDATE_LOGISTICS_FAILURE = 'UPDATE_LOGISTICS_FAILURE'
export const UPDATE_LOGISTICS_PROGRESS = 'UPDATE_LOGISTICS_PROGRESS'
export const LOGISTICS_CONVERTED_TO_ORDER = 'LOGISTICS_CONVERTED_TO_ORDER'

export const CLEAR_NEW_LOGISTICS = 'CLEAR_NEW_LOGISTICS'

export const setSelectedBuyerUser = (payload) => ({
	type: SET_SELECTED_BUYER_USER,
	payload,
})

export const setSelectedSupplierUser = (payload) => ({
	type: SET_SELECTED_SUPPLIER_USER,
	payload,
})

export const setSelectedLogisticsUser = (payload) => ({
	type: SET_SELECTED_LOGISTICS_USER,
	payload,
})

export const updateLogisticsInState = (payload) => ({
	type: UPDATE_LOGISTICS,
	payload,
})

export const addNewItemToLogistics = (payload) => ({
	type: ADD_NEW_ITEM_TO_LOGISTICS,
	payload,
})

export const updateLogisticsItem = (itemId, payload) => ({
	type: UPDATE_LOGISTICS_ITEM,
	itemId,
	payload,
})

export const deleteLogisticsItem = (itemId) => ({
	type: DELETE_LOGISTICS_ITEM,
	itemId,
})

export const addNewLogistiscQuotes = (payload) => ({
	type: ADD_NEW_LOGISTICS_QUOTES,
	payload,
})

export const updateLogistiscQuotes = (itemId, payload) => ({
	type: UPDATE_LOGISTICS_QUOTES,
	itemId,
	payload,
})

export const deleteLogistiscQuotes = (itemId) => ({
	type: DELETE_LOGISTICS_QUOTES,
	itemId,
})

export const clearNewLogisticsState = () => ({
	type: CLEAR_NEW_LOGISTICS,
})

export const createNewLogisticsFromData = (data) => ({
	type: CREATE_NEW_LOGISTICS_FROM_DATA,
	data,
})

export const LogisticsConvertedToOrder = () => ({
	type: LOGISTICS_CONVERTED_TO_ORDER,
})

export const fetchAllClients = (edit, clientData) => (dispatch) => {
	dispatch({ type: FETCH_USERS_PROGRESS })
	return API.get(getUrl.getAllClients)
		.then((response) => {
			dispatch({ type: FETCH_USERS_SUCCESS_BUYER, payload: response.data })
			if (edit) {
				const buyerUser = response.data.find((item) => item?.organisation_id === clientData?.buyer?.organisation_id)
				dispatch(setSelectedBuyerUser(buyerUser))
			}
		})
		.catch((err) => {
			dispatch({ type: FETCH_USERS_FAILURE, error: err })
		})
}

export const fetchAllSupplier = (edit, supplierData) => (dispatch) => {
	dispatch({ type: FETCH_USERS_PROGRESS })
	return API.get(getUrl.getAllOrgSuppliers)
		.then((response) => {
			dispatch({ type: FETCH_USERS_SUCCESS_SUPPLIER, payload: response.data })
			if (edit) {
				const supplierUser = response.data.find((item) => item?.organisation_id === supplierData?.supplier?.organisation_id)
				dispatch(setSelectedSupplierUser(supplierUser))
			}
		})
		.catch((err) => {
			dispatch({ type: FETCH_USERS_FAILURE, error: err })
		})
}

export const fetchAllLogisticsPartner = (edit, logisticsData) => (dispatch) => {
	dispatch({ type: FETCH_USERS_PROGRESS })
	return API.get(getUrl.getAllLogisticPartners)
		.then((response) => {
			dispatch({ type: FETCH_USERS_SUCCESS_LOGISTICS, payload: response.data })
		})
		.catch((err) => {
			dispatch({ type: FETCH_USERS_FAILURE, error: err })
		})
}

export const createLogistics = (data) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.createLogistics, data)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateLogistics = (data) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateLogistics, data)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getLogisticsById = (logisticsId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLogisticsById, { logisticsId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllLogistics = (logisticsStatus = logisticsStatusC.created, page = 0, logisticsFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLogistics, { logisticsStatus, page, ...logisticsFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllLogisticsOrders = (logisticsStatus = logisticsStatusC.created, page = 0, logisticsFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLogisticsOrders, { logisticsStatus, page, ...logisticsFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const uploadLogisticsInvoice = (file, logisticsOrderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadLogisticsInvoice, formData, { logisticsOrderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const editLogisticsOrder = (setFormData) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editLogisticsOrder, setFormData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadDocument = (file, logisticsInvoiceId, logisticsOrderId, type) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		let fileTypeURL = postUrl.uploadDispatchWeighmentSlip
		if (type === logisticDocType.drop) {
			fileTypeURL = postUrl.uploadDropWeighmentSlip
		} else if (type === logisticDocType.lorry) {
			fileTypeURL = postUrl.uploadLorryReceipt
		} else if (type === logisticDocType.material) {
			fileTypeURL = postUrl.uploadMaterialReceivingDoc
		}

		API.post(fileTypeURL, formData, { logisticsInvoiceId, logisticsOrderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

// convert to order logistic request
export const convertToOrderLogisticsRequest = ({ logisticsId, userId = '' }) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.convertToOrderLogisticsRequest, null, { logisticsId, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getLogisticsOrderById = (logisticsOrderId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLogisticsOrderById, { logisticsOrderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

// logistics partner getAllLogisticsRequestsOfUser
export const getAllLogisticsRequestsOfUser = (logisticsStatus = logisticsStatusC.created, page = 0, logisticsFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLogisticsRequestsOfUser, { logisticsStatus, page, ...logisticsFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

// get logistic details by id partner
export const getLogisticsRequestById = (logisticsId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLogisticsRequestById, { logisticsId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

// add logistic quote  partner
export const submitLogisticsQuote = (data, logisticsId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.submitLogisticsQuote, data, { logisticsId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

// update logistic quote  partner
export const editLogisticsQuote = (data, logisticsId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editLogisticsQuote, data, { logisticsId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

// /mb/logistics/getAllLogisticsOrdersOfUser

export const getAllLogisticsOrdersOfUser = (logisticsOrderStatus) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLogisticsOrdersOfUser, { logisticsOrderStatus })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})
