import API from '../api'
import deleteUrl from './paths'

const deleteUnregisteredUser = (userId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteUnregisteredUser, { userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const deleteUserWithOrg = (userId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteUserWithOrg, { userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const deleteAdmin = (newAdmin, oldAdmin) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteAdmin, { newAdmin, oldAdmin })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export { deleteUnregisteredUser, deleteUserWithOrg,deleteAdmin }
