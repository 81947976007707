import API from '../api'
import putUrl from './paths'

export const editProduct = (product, productId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editProduct, product, { productId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const editCategory = (category, categoryId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editCategory, category, { categoryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadCategoryImages = (categoryId, files) =>
	new Promise((resolve, reject) => {

		const formData = new FormData()
		files.forEach((file) => {
			formData.append('files', file)
		})
		API.put(putUrl.addCategoryImages, formData, { categoryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})