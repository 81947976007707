import { Record } from 'immutable'

const OrgRoleRecord = Record({
	approved: false,
	default_role: false,
	platform: '',
	requested: false,
	role: '',
})

class OrgRole extends OrgRoleRecord {
	constructor(data) {
		super({
			...data,
		})
	}

	update(updates) {
		return this.merge({
			...updates,
		})
	}
}

export default OrgRole
