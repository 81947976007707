import React from 'react'
import { DrawerBodyWrapper, DrawerFooterWrapper, Button, Input, Pill, Banner } from '@base'
import { strings } from '@constants'
import { FormWrapper, FormItemWrapper, InfoWrapper, Heading, CardWapper, CardInfo, StepTopWrapper } from './styles'
import { useState } from 'react'
import { displayAmount } from '@utils'
import { useDispatch } from 'react-redux'
import { showLoader } from '@data/state/action'
import { getLogisticsPricesRangeByPinCodes } from '@data'

const initialFormState = {
	fromPinCode: {
		name: 'fromPinCode',
		label: 'From',
		disabled: false,
		value: '',
		dirty: false,
	},
}

const Index = ({ totalWeight, toPinCode }) => {
	const [formState, setFormState] = useState(initialFormState)
	const [resData, setResData] = useState()
	const dispatch = useDispatch()

	const onChangeHandler = (key) => (value) => {
		setFormState({ ...formState, [key]: { ...formState[key], value } })
	}

	const renderForm = () => {
		return (
			<FormWrapper>
				<FormItemWrapper>
					<InfoWrapper>
						<Input
							type='number'
							label={formState?.fromPinCode?.label}
							value={formState?.fromPinCode?.value}
							disabled={formState?.fromPinCode?.disabled}
							onChange={onChangeHandler('fromPinCode')}
						/>
					</InfoWrapper>
				</FormItemWrapper>
				{resData?.err && (
					<StepTopWrapper>
						<Banner extendInMobile text={resData?.err} />
					</StepTopWrapper>
				)}
				{resData?.distance && (
					<FormItemWrapper>
						<CardWapper>
							<CardInfo isLeft>
								<Heading>{strings('distance')}</Heading>
								<span>:</span>
							</CardInfo>
							<CardInfo isRight>
								<Pill label={`${resData?.distance}${strings('km')}`} />
							</CardInfo>
						</CardWapper>
						<CardWapper>
							<CardInfo isLeft>
								<Heading>{strings('price')}</Heading>
								<span>:</span>
							</CardInfo>
							<CardInfo isRight>
								<Pill
									label={`${displayAmount(resData?.from_price_range * totalWeight)} - ${displayAmount(
										resData?.to_price_range * totalWeight
									)}`}
								/>
							</CardInfo>
						</CardWapper>
					</FormItemWrapper>
				)}
			</FormWrapper>
		)
	}

	const onClickHandler = () => {
		dispatch(showLoader(true))
		getLogisticsPricesRangeByPinCodes(formState?.fromPinCode?.value, toPinCode)
			.then((res) => {
				if (!res) {
						setResData({
							err: strings('freight_calculator_not_found'),
						})
				} else {
					setResData(res)
				}
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const clearSearch = () => {
		setFormState(initialFormState)
		setResData()
	}

	const disableButton = () => {
		if (!formState?.fromPinCode?.value) return true
	}

	return (
		<>
			<DrawerBodyWrapper>
				{renderForm()}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button margin={`0 0 0 auto`} small uppercase type='primary' onClick={onClickHandler} disabled={disableButton()}>
					{strings('get', 'price')}
				</Button>
				<Button small uppercase type='secondary' variant='destructive' onClick={clearSearch} disabled={disableButton()}>
					{strings('clear')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default Index
