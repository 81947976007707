class OrderSupplierQuote {
	constructor() {
		this.supplier = null
		this.dispatch_location = null
		this.order_items = []
		this.supplier_invoices = null

		this.supplier_po_amount = 0
		this.supplier_po_date = null
		this.supplier_po_document = null
		this.supplier_po_number = null
		this.supplier_po_status = null
	}
}

export default OrderSupplierQuote
