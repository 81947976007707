import { Record } from 'immutable'

const FactoryEnquiryItemRecord = Record({
	brand_ordered: '',
	category_id: '',
	category_name: '',
	ordered_weight: '',
	price_per_kg: '',
	product_id: '',
	product_name: '',
	tc_required: false,
	serviced_weight: '',
	selected: false,
	identifier: '',
})

class FactoryEnquiryItem extends FactoryEnquiryItemRecord {
	constructor(data) {
		super({
			...data,
			identifier: `${data?.category_name}_${data?.product_id}_${data?.brand_ordered}`,
		})
	}
	update(updates) {
		return this.merge(updates)
	}

	isValid() {
		return Boolean(this.serviced_weight && this.serviced_weight !== 0)
	}
}

export default FactoryEnquiryItem
