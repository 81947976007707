import { strings } from '@constants'
class EnquirySupplierQuote {
	constructor() {
		this.supplier = null
		this.dispatch_location = null
		this.order_given = false
		this.quote_type = strings('for') //x or for
		this.minimum_order_quantity = 0
		this.minimum_guaranteed_weight = 0
		this.quote_valid_till = new Date().getTime()
		this.logistics_quote = 0
		this.total_quotation_amount = 0
		this.supplier_quote = []
		this.message = null
		this.lumpsum_logistics_quote = 0
		this.by_admin = true
		this.quote_accepted_buyer = null
		this.quote_accepted_supplier = null
		this.is_requested_supplier = null
	}
}

export default EnquirySupplierQuote
