class ProductAttributes {
  constructor() {
    this.dia = null; //float
    this.height = null; //float
    this.length = null; //float
    this.width = null; //float
    this.gsm = null; //float
    this.thickness = null; //float
  }
}

export default ProductAttributes;
