import { strings } from '@constants'

class OrderBuyerQuote {
	constructor() {
		this.buyer = null
		this.shipping_address = null
		this.order_items = []
		this.buyer_invoices = null

		this.buyer_po_amount = 0
		this.buyer_po_date = null
		this.buyer_po_document = null
		this.buyer_po_number = null
		this.original_buyer_po_number = null
		this.buyer_po_status = null

		this.logistics_quote_per_mt = 0
		this.lumpsum_logistics_quote = 0
		this.minimum_guaranteed_weight = 0
		this.minimum_order_quantity = 0

		this.quote_type = strings('x')
	}
}

export default OrderBuyerQuote
