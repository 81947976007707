import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { DrawerBodyWrapper, TOAST_TYPE, TimeLine } from '@base'
import { strings } from '@constants'
import { getJourneyBySourceId } from '@data'
import { showToast } from '@data/state/action'
import { CloudUpload, DirectionsBus, Flight, Folder, InsertDriveFile, LocalShipping, Message, OpenInNew, VerifiedUser } from '@material-ui/icons'
import { displayAmount } from '@utils'

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const OpenInNewIcon = styled(OpenInNew)`
	cursor: pointer;
	&: hover {
		color: ${({ theme }) => theme.palette.background.blueLight};
	}
`

const OrderJourneyState = (data, enquiryId) => {
	let ammount = data?.amount ? `to this amount ${displayAmount(data?.amount)}` : ''
	let invoiceId = data?.message ? `Invoice id - ${data?.message}` : ''
	let gpsLink = data?.gps_link ? (
		<OpenInNewIcon
			fontSize='small'
			onClick={(e) => {
				TrackingHandler(e, data?.gps_link)
			}}
		/>
	) : (
		''
	)

	const TrackingHandler = (e, url) => {
		e.stopPropagation()
		url && window.open(url, '_blank')
	}

	switch (data?.action) {
		case 'ORDER_PLACED':
			return { text: `Order has been placed for the Enquiry id - ${enquiryId}.`, icon: <DirectionsBus /> }

		case 'BUYER_PO_UPLOADED':
			return {
				text: `Buyer PO has been uploaded for ${data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number}.`,
				icon: <InsertDriveFile />,
			}

		case 'BUYER_INVOICE_UPLOADED':
			return {
				text: `Buyer Invoice has been uploaded ${ammount} for ${
					data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number
				}. ${invoiceId} `,
				icon: <CloudUpload />,
			}

		case 'BUYER_INVOICE_UPDATED':
			return {
				text: `Buyer Invoice has been updated ${ammount} for ${
					data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number
				}.  ${invoiceId}`,
				icon: <CloudUpload />,
			}

		case 'SUPPLIER_PO_UPLOADED':
			return {
				text: `Supplier PO has been uploaded for ${data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number}.`,
				icon: <Folder />,
			}

		case 'SUPPLIER_INVOICE_UPLOADED':
			return {
				text: ` Supplier Invoice -  has been uploaded ${ammount} for ${
					data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number
				}. ${invoiceId}`,
				icon: <Folder />,
			}

		case 'SUPPLIER_INVOICE_UPDATED':
			return {
				text: `Supplier Invoice has been updated ${ammount} for ${
					data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number
				}. ${invoiceId}`,
				icon: <Folder />,
			}

		case 'BUYER_INVOICE_ENROUTE':
			return {
				text: (
					<span>
						Material has been dispatched and is Enroute for this {invoiceId}. {gpsLink}
					</span>
				),
				icon: <Flight />,
			}

		case 'BUYER_INVOICE_DELIVERED':
			return {
				text: `Material has been delivered. Total delivered - Serviceable/order qty. ${invoiceId}`,
				icon: <LocalShipping />,
			}

		case 'SUPPLIER_INVOICE_ENROUTE':
			return {
				text: (
					<span>
						Material has been dispatched and is Enroute for this {invoiceId}. {gpsLink}
					</span>
				),
				icon: <Flight />,
			}

		case 'SUPPLIER_INVOICE_DELIVERED':
			return {
				text: `Material has been delivered. Total delivered - Serviceable/order qty. ${invoiceId}`,
				icon: <LocalShipping />,
			}

		case 'ORDER_COMPLETED':
			return {
				text: `All material in respect to this order has been delivered, and this order is marked as complete now.`,
				icon: <VerifiedUser />,
			}
		case 'NOTE':
			return {
				text: `${data?.message}`,
				icon: <Message />,
			}

		default:
			break
	}
}

const Index = ({ journeySourceId }) => {
	const dispatch = useDispatch()
	const [journeyList, setJourneyList] = useState()

	useEffect(() => {
		getJourneyBySourceId(journeySourceId)
			.then((res) => {
				setJourneyList(
					res?.journey_data?.map((item) => {
						return { ...item, journeyState: OrderJourneyState(item, res?.sub_source_id) }
					})
				)
			})
			.catch((e) => {
				console.log(e)
				dispatch(showToast(true, strings('journey_not_found'), { type: TOAST_TYPE.ERROR }))
			})
	}, [])

	return (
		<DrawerBodyWrapper>
			<BodyWrapper>
				<TimeLine data={journeyList} journeySourceId={journeySourceId} />
			</BodyWrapper>
		</DrawerBodyWrapper>
	)
}

export default Index
