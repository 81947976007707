import API from '../api'
import postUrl from './paths'

const toolsEndorseInvoice = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(
			postUrl.toolsEndorseInvoice,
			formData,
			{},
			{
				responseType: 'arraybuffer',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			}
		)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const toolsEndorseTestCertificate = (file, invoiceList) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		formData.append('invoiceList', JSON.stringify(invoiceList))

		API.post(
			postUrl.toolsEndorseTestCertificate,
			formData,
			{},
			{
				responseType: 'arraybuffer',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			}
		)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const toolsDistanceCalculator = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(
			postUrl.toolsCalculateDistance,
			formData,
			{},
			{
				responseType: 'arraybuffer',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			},
			{
				timeout: 1000 * 60 * 5,
			}
		)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { toolsEndorseInvoice, toolsEndorseTestCertificate, toolsDistanceCalculator }
