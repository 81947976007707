import API from '../api'
import putUrl from './paths'

export const editContent = (content, contentType) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.editContent, { ...content }, { contentType })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })