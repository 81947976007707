import API from '../api'
import putUrl from './paths'

export const editPunchOrder = (punchOrder) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.editPunchOrder, punchOrder)
            .then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
    })