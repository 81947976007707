import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

export const getCategories = (page = 0, size = 100, commodityFilter) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getCategories, { page, size, commodityFilter })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getCategoryById = (categoryId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getCategoryById, { categoryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getProductByCategory = (categoryId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getProductOfCategory, { categoryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllProducts = (page = 0, searchFilter = { productNameFilter: '' }) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getProducts, { page, ...searchFilter })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getProductById = (productId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getProductById, { productId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllBrandApi = (page = 0, size = 100) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllBrand, { page, size })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const fetchGetTopCategories = (commodityName = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getTopCategories, { commodityName })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
