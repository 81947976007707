import React, { useState } from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { Button, ItemCard, SectionHeader, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { Autorenew } from '@material-ui/icons'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { refreshZohoPastRecordsApi } from '@data'

const MenuIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	& > svg {
		font-size: ${Text.MEDIUM};
	}
`
const MenuIconWapper = styled(Button)`
	min-width: 0rem;
`

const PastRecords = ({ financialData, update, user }) => {
	const dispatch = useDispatch()
	const [isDisabled, setDisabled] = useState(false)
	const zohoPastRecords = financialData?.zoho_past_records

	const buttonHandler = () => {
		dispatch(showLoader(true))
		refreshZohoPastRecordsApi(zohoPastRecords, user?.user_id)
			.then((res) => {
				setDisabled(true)
				dispatch(showToast(true, strings('request_submit_sucess'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('error_something_went_wrong'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	const itemData = [
		{
			label: 'GMV',
			value: zohoPastRecords?.gmv ? zohoPastRecords?.gmv.toFixed(2) : '--',
		},
		{
			label: 'GM (%)',
			value: zohoPastRecords?.gm ? zohoPastRecords?.gm.toFixed(2) : '--',
		},
		{
			label: 'Average past daily',
			value: zohoPastRecords?.average_past_delays ? zohoPastRecords?.average_past_delays.toFixed(2) : '--',
		},
		{
			label: 'Past payment terms',
			value: zohoPastRecords?.past_payments_terms ? zohoPastRecords?.past_payments_terms.toFixed(2) : '--',
		},

		{
			label: 'Access CTC',
			value: zohoPastRecords?.excess_ctc ? zohoPastRecords?.excess_ctc.toFixed(2) : '--',
		},
	]
	return (
		<>
			<DetailsWrapper>
				<SectionHeader>
					{strings(['past', 'records', 'with', 'mbook'])}
					{
						<MenuIconWapper disabled={isDisabled} xs onClick={buttonHandler}>
							<MenuIcon>
								<Autorenew fontSize='small' />
							</MenuIcon>
						</MenuIconWapper>
					}
				</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<ItemCard medium data={itemData} key={`financial-highlights-item`} />
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default PastRecords
