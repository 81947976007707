import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Calendar, H5, Input, RadioButton, Select, Textarea } from '@base'
import { ABILITY_ISSUE, INTENTION_ISSUE, ISSUE_TYPE, strings } from '@constants'
import { Devices, Spacings } from '@styles'
import { getTodayDate } from '@utils'

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	margin-top: ${Spacings.SPACING_2B};
	& > div {
		width: 100%;
	}
`
const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`
const InputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	${({ isCall }) => {
		if (isCall) {
			return css`
				& > div:nth-child(1) {
					flex: 6;
					margin-right: 0px;
					@media ${Devices.tablet} {
						margin-right: 5px;
					}
				}
				& > div:nth-child(2) {
					flex: 6;
				}
			`
		} else {
			return css`
				& > div:nth-child(1) {
					flex: 3;
				}
				& > div:nth-child(2) {
					flex: 10;
				}
			`
		}
	}}

	@media ${Devices.tablet} {
		flex-direction: row;
	}
`

const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`
const IssueWapper = styled.div`
	display: flex;
`
const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
const getDate = (date) => (+date === 0 ? new Date().getTime() : +date)
const EditCollection = ({ isEdit, isAddRemark, onChange, state }) => {
	const [issueType, setIssueType] = useState(state?.issue?.value || ISSUE_TYPE.INTENTION)

	let intentionTypes = Object.entries(INTENTION_ISSUE).map(([key, value]) => ({ key: key, label: value }))
	let abilityTypes = Object.entries(ABILITY_ISSUE).map(([key, value]) => ({ key: key, label: value }))

	const setIssueTypeFunc = (value) => {
		setIssueType(value)
		onChange('issue')(value)
		onChange('reason')({})
	}
	useEffect(() => {
		if (isAddRemark) setIssueTypeFunc(ISSUE_TYPE.INTENTION)
	}, [])

	return (
		<>
			<SectionItemWrapper>
				<InputWapper>
					<div>
						<FormHeading>{`${strings('issue')}: `}</FormHeading>
					</div>
					<IssueWapper>
						<RadioButtonWrapper>
							<RadioButton
								id='intention-radio'
								size='1.125'
								checked={issueType === ISSUE_TYPE.INTENTION}
								value={ISSUE_TYPE.INTENTION}
								onChange={() => {
									setIssueTypeFunc(ISSUE_TYPE.INTENTION)
								}}
							/>
							<Label htmlFor='intention-radio'>Intention </Label>
						</RadioButtonWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='ability-radio'
								size='1.125'
								checked={issueType === ISSUE_TYPE.ABILITY}
								value={ISSUE_TYPE.ABILITY}
								onChange={() => {
									setIssueTypeFunc(ISSUE_TYPE.ABILITY)
								}}
							/>
							<Label htmlFor='ability-radio'>Ability </Label>
						</RadioButtonWrapper>
					</IssueWapper>
				</InputWapper>
				<InputWapper>
					{issueType === ISSUE_TYPE.INTENTION && (
						<Select
							data={intentionTypes}
							onChange={onChange('reason')}
							required={state?.reason?.required}
							id={state.reason.id}
							value={state?.reason?.value}
							placeholder={strings(['select', 'intention', 'issue'])}
							displayKey={state.reason.displayKey}
							primaryKey={state.reason.primaryKey}
						/>
					)}
				</InputWapper>
				{issueType === ISSUE_TYPE.ABILITY && (
					<>
						<Select
							data={abilityTypes}
							id='reason'
							value={state?.reason?.value}
							placeholder={strings(['select', 'ability', 'issue'])}
							displayKey={state.reason.displayKey}
							primaryKey={state.reason.primaryKey}
							onChange={onChange('reason')}
							required={state.reason.required}
						/>
						<Input
							type='text'
							label={state.pdc.label}
							value={state?.pdc?.value}
							onChange={onChange('pdc')}
							required={state.pdc.required}
						/>
						<Input
							type='text'
							label={'Ask for project details & principal'}
							value={state.principal_details.value}
							onChange={onChange('principal_details')}
							required={false}
						/>
						<Select
							label={state.reason_for_delay.label}
							data={state.reason_for_delay.data}
							disabled={state.reason_for_delay.disabled}
							value={state?.reason_for_delay?.value}
							displayKey={state.reason_for_delay.displayKey}
							primaryKey={state.reason_for_delay.primaryKey}
							onChange={onChange('reason_for_delay')}
							required={false}
						/>
						{issueType === ISSUE_TYPE.ABILITY && state?.reason?.value?.key === Object.keys(ABILITY_ISSUE)[1] && (
							<Input
								type='text'
								label={state.partial_payment_agreement?.label}
								value={state.partial_payment_agreement?.value}
								onChange={onChange('partial_payment_agreement')}
								required={false}
							/>
						)}
					</>
				)}

				<FormHeading>{strings('collection_customer_detail_header')}</FormHeading>
				<InputWapper isCall>
					<Input
						type='text'
						label={state.customer_poc_name.label}
						value={state?.customer_poc_name?.value}
						onChange={onChange('customer_poc_name')}
						required={state.customer_poc_name.required}
					/>
				</InputWapper>
				<InputWapper isCall>
					<Input
						type='tel'
						label={state.customer_poc_phone.label}
						value={state?.customer_poc_phone?.value}
						pattern='^\d{7}\d+$'
						maxLength={10}
						errorMap={{
							patternMismatch: `${strings('enter_valid_num')}`,
							valueMissing: `${strings('mob_num_required')}`,
						}}
						onChange={onChange('customer_poc_phone')}
						placeholder={state.customer_poc_phone.placeholder}
						required={state.customer_poc_phone.required}
					/>
					<Input
						type='email'
						label={state.customer_poc_email.label}
						value={state?.customer_poc_email?.value}
						placeholder={state.customer_poc_email.value}
						pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
						errorMap={{
							patternMismatch: `${strings('enter_valid_email')}`,
						}}
						onChange={onChange('customer_poc_email')}
						required={state.customer_poc_email.required}
					/>
				</InputWapper>

				<FormHeading>{strings('collection_meeting_schedule_header')} </FormHeading>
				<Calendar
					label={'Date'}
					value={getDate(state?.meeting_scheduled_at?.value)}
					placeholder={state.meeting_scheduled_at.placeholder}
					minDate={getTodayDate(false).getTime() - 86400000 * 3}
					onChange={onChange('meeting_scheduled_at')}
				/>
				<FormHeading>{strings('collection_meeting_schedule_header')} </FormHeading>
				<Input
					type='text'
					label={'Remarks from the meeting'}
					value={state?.meeting_remarks?.value || ''}
					onChange={onChange('meeting_remarks')}
					required={state.meeting_remarks.required}
				/>
				<FormHeading>{strings('collection_add_remark_header')} </FormHeading>
				<Textarea
					id={state.remark_message.id}
					label={state.remark_message.label}
					type='text'
					rows={3}
					value={state?.remark_message?.value}
					onChange={onChange('remark_message')}
					required={state.reason.value === INTENTION_ISSUE.OTHER || state.reason.value === ABILITY_ISSUE.OTHER}
				></Textarea>
			</SectionItemWrapper>
		</>
	)
}

export default EditCollection
