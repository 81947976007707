import API from "../api";
import postUrl from "./paths";


export const createGlossary = (glossary) =>
    new Promise((resolve, reject) => {
        API.post(postUrl.createGlossary, glossary)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })