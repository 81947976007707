import { Record } from 'immutable'
import Commodity from './Commodity'
const CommodityItemRecord = Record({
	commodity: new Commodity(),
	created_at: new Date().getTime(), //date,
	created_by: '',
	location: '',
	price_date: 0,
	price_per_ton: 0,
	primary_or_secondary: '',
	product_name: '',
	source: '',
	updated_at: new Date().getTime(), //date
	updated_by: '',
	grade: '',
})

class CommodityPrice extends CommodityItemRecord {
	constructor(data = {}) {
		super({
			...data,
		})
	}
}

export default CommodityPrice
