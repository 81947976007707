import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getAllUsers } from '@data'
import { dataCorrection } from '@data/conn/put/ledger'
import { displayName } from '@utils'
import { Button, DrawerBodyWrapper, H5, RadioButton, Select, TOAST_TYPE } from '@base'
import { Devices, Spacings, Text } from '@styles'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`
const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`
const RadioInputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@media ${Devices.tablet} {
		flex-direction: row;
	}
	margin-bottom: ${Spacings.SPACING_2B};
`
const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`
const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
const Footer = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: initial;
	}
`
const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`
const TRADING_TYPE = {
	TRUE: true,
	FALSE: false,
}

const TradingExpenseForm = ({ data, update }) => {
	const dispatch = useDispatch()
	const [users, setUsers] = useState([])
	const [selectedUser, setSelectedUser] = useState()
	const [ledger, setLedger] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		getAllUsers()
			.then((response) => {
				const _users = response?.map((item) => {
					item['display_name'] = displayName(item)
					return item
				})
				setUsers(_users)
				const _selectedUser = _users?.find((item) => item?.organisation_name === data?.organisation_name)
				setSelectedUser(_selectedUser)
			})
			.catch((err) => {
				console.log('Error: ', err)
			})
			.finally(() => setLoading(false))
	}, [])

	const onValueChange = (value) => {
		setLedger(value)
	}
	const onClickSave = () => {
		dispatch(showLoader(true))
		dataCorrection(selectedUser?.organisation_id, ledger, data?.transaction_id)
			.then(() => {
				update()
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, strings('msg_transaction_added'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => console.log(err))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const disableButton = !selectedUser && ledger === null

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<SectionItemWrapper>
						<Select
							disabled={loading}
							id='select-user'
							placeholder={loading ? strings('loading', 'users') : strings(['select', 'user'])}
							data={users}
							displayKey='display_name'
							primaryKey='organisation_id'
							value={selectedUser ?? {}}
							onChange={(user) => {
								setSelectedUser(users.find((_u) => _u.organisation_id === user.organisation_id))
							}}
							label={strings(['select', 'user'])}
						/>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<FormHeading>{strings('trading_expense')}</FormHeading>
						<RadioInputWapper>
							<RadioButtonWrapper>
								<RadioButton
									id='debit-radio'
									size='1.125'
									checked={ledger === TRADING_TYPE.TRUE}
									value={TRADING_TYPE.TRUE}
									onChange={(e) => onValueChange(TRADING_TYPE.TRUE)}
								/>
								<Label htmlFor='debit-radio'>TRUE</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<RadioButton
									id='credit-radio'
									size='1.125'
									checked={ledger === TRADING_TYPE.FALSE}
									value={TRADING_TYPE.FALSE}
									onChange={(e) => onValueChange(TRADING_TYPE.FALSE)}
								/>
								<Label htmlFor='credit-radio'>FALSE</Label>
							</RadioButtonWrapper>
						</RadioInputWapper>
					</SectionItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<Footer>
				<ActionButton small type='primary' disabled={disableButton} onClick={onClickSave}>
					{strings('save')}
				</ActionButton>
			</Footer>
		</>
	)
}

export default TradingExpenseForm
