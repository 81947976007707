import adminPaths from './adminPaths'
// import buyerPaths from './buyerPaths'
// import supplierPaths from './supplierPaths'
import organisationPaths from './organisationPaths'
// import logisticPaths from './logisticPaths'
// import fabricatorPaths from './fabricatorPaths'
// import manufacturerPaths from './manufacturerPaths'

const paths = {
	login: '/login',
	deleteProfile: '/delete-profile',
	adminLogin: '/admin/login',
	mbDocuments: '/documents',
	notFound: '*',

	...adminPaths,
	...organisationPaths,
	// ...logisticPaths,
	// ...fabricatorPaths,
	// ...manufacturerPaths,

	routePath: (path, parentPath) => path.replace(parentPath + '/', ''),
}

export default paths
