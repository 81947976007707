import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer, toggleFileViewer, updateDrawerMeta } from '@data/state/action/root'

import { Spacings, Text } from '@styles'
import { DrawerBodyWrapper, Input, SectionHeader, Button, DocumentDownload, H6, UploadButton, Calendar, TOAST_TYPE } from '@base'
import { logisticDocType, strings } from '@constants'
import { editLogisticsOrder, uploadDocument } from '../CreateLogistics/state/actions'
import { isClient } from '@utils/adminFilter'

const CustomSectionHeader = styled(SectionHeader)`
	margin-top: ${Spacings.SPACING_6B};
`

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}
`

const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`

const DocUploadButton = styled(UploadButton)`
	margin-left: auto;
`

const DocumentsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const DownloadIconWrapper = styled.div`
	padding: ${Spacings.SPACING_3B};
	margin-right: ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	align-items: center;
	justify-content: center;
	width: fit-content;
	border-radius: ${Spacings.SPACING_2B};
`

const Message = styled(H6)`
	flex-grow: 1;
	flex-basis: 100%;
	margin-top: ${Spacings.SPACING_1B};
	margin-bottom: ${Spacings.SPACING_3B};
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
`

const SubSectionHeading = styled(H6)`
	margin-top: ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_2B};
`

const deriveStateFromData = (data, order, supplierId) => {
	const _state = {
		invoice_amount: {
			label: strings('invoice_amount'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('placeholder_enter_invoice_amount'),
		},
		logistics_order_id: {
			label: strings('logistics_order'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings('enter_logistics_order'),
		},
		gps_link: {
			label: strings('gps_link'),
			value: '',
			disabled: false,
			required: false,
			placeholder: 'https://example.com',
		},
		invoice_date: {
			label: strings('invoice', 'date'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('enter_drop_date'),
		},
	}

	if (!data) return _state

	_state.invoice_amount.value = data?.invoice_amount
	_state.logistics_order_id.value = order?.logistics_order_id
	_state.invoice_date.value = data?.invoice_date
	_state.gps_link.value = data?.gps_link
	return _state
}

const AdminEditLogisticsInvoice = memo(({ order, update, callLogisticOrderDetails }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const invoiceData = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'invoiceData']))

	useEffect(() => {
		setFormState((_) => deriveStateFromData(invoiceData, order))
	}, [invoiceData, order])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: (
					<>
						{strings('invoice', 'details')}
						<ActionButton
							small
							uppercase
							type='secondary'
							onClick={() => {
								if (invoiceData?.logistics_invoice_doc) {
									dispatch(toggleFileViewer(true, { files: invoiceData.logistics_invoice_doc }))
								}
							}}
						>
							{strings('view', 'invoice')}
						</ActionButton>
					</>
				),
			})
		)
	}, [dispatch, invoiceData])

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onChange = (key) => (value) => updateState(key, { value })

	const disabledUpdateButton = useMemo(() => {
		if (!invoiceData) return true
		const nonDisabledEntries = Object.entries(formState).filter(([key, val]) => !val.disabled)
		return (
			nonDisabledEntries.some(([key, val]) => val.required && !val.value) ||
			nonDisabledEntries.every(([key, val]) => val.value === invoiceData[key])
		)
	}, [formState, invoiceData])

	const getvalue = () => {
		return {
			gps_link: formState.gps_link.value,
			invoice_amount: formState.invoice_amount.value,
			invoice_date: formState.invoice_date.value ?? new Date().getTime(),
			logistics_order_id: formState.logistics_order_id.value,
		}
	}

	const updateInvoiceInfo = () => {
		if (disabledUpdateButton) return
		const setLogisticsInvoiceDetails = order.logistics_invoice_details.map((item, index) => {
			if (item.logistics_invoice_id === invoiceData.logistics_invoice_id) return { ...item, ...getvalue() }
			return item
		})

		dispatch(showLoader(true))

		editLogisticsOrder({ ...order, logistics_invoice_details: setLogisticsInvoiceDetails })
			.then((response) => {
				let updatedInvoiceData
				updatedInvoiceData = response?.logistics_invoice_details.find((_i) => _i.logistics_invoice_id === invoiceData.logistics_invoice_id)

				dispatch(showToast(true, strings('msg_invoice_details_updated'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						order: response,
						invoiceData: updatedInvoiceData,
					})
				)
				callLogisticOrderDetails()
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				dispatch(showToast(true, strings('msg_failed_to_update_invoice_details'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const uploadDoc = (file, type, key) => {
		dispatch(showLoader(true))
		uploadDocument(file, invoiceData.logistics_invoice_id, order.logistics_order_id, type, key)
			.then((response) => {
				dispatch(showToast(true, response.response_message, { type: TOAST_TYPE.SUCCESS }))
				const updates = {}
				if (key == 'dispatch_weighment_slip_doc' || key == 'drop_weighment_slip_doc') {
					updates[key] = [...(invoiceData[key] ?? []), response.file_path]
				} else {
					updates[key] = response.file_path
				}

				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...invoiceData,
							...updates,
						},
					})
				)
				callLogisticOrderDetails()
				update()
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const updateDocument = (key, link) => {
		if (key === logisticDocType.dispatch) {
			invoiceData.dispatch_weighment_slip_doc = invoiceData.dispatch_weighment_slip_doc.filter((item) => item !== link)
		} else if (key === logisticDocType.lorry) {
			invoiceData.lorry_receipt_doc = null
		} else if (key === logisticDocType.material) {
			invoiceData.material_receiving_doc = null
		} else if (key === logisticDocType.drop) {
			invoiceData.drop_weighment_slip_doc = invoiceData.drop_weighment_slip_doc.filter((item) => item !== link)
		}
	}

	const renderDocuments = (data, key) => {
		data = (data ?? []).filter(Boolean)
		if (Array.isArray(data) && data.length) {
			return (
				<DocumentsWrapper>
					<Message>{strings('click_icon_to_download')}</Message>
					{data.map((item, index) => (
						<DownloadIconWrapper key={`${key}_doc#_${index}`}>
							<DocumentDownload link={item} type={key} updateDocument={updateDocument} />
						</DownloadIconWrapper>
					))}
				</DocumentsWrapper>
			)
		}
		return (
			<DocumentsWrapper>
				<Message>{strings('no_documents')}</Message>
			</DocumentsWrapper>
		)
	}

	return (
		<DrawerBodyWrapper>
			<FormWrapper>
				<FormItemWrapper>
					<Input
						type='text'
						label={formState.logistics_order_id?.label}
						disabled={formState.logistics_order_id?.disabled}
						value={formState.logistics_order_id?.value}
						placeholder={formState.logistics_order_id?.placeholder}
						onChange={onChange('logistics_order_id')}
						required={formState.logistics_order_id?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Calendar
						label={formState.invoice_date?.label}
						disabled={formState.invoice_date?.disabled}
						value={formState.invoice_date?.value}
						placeholder={formState.invoice_date?.placeholder}
						onChange={onChange('invoice_date')}
						required={formState.invoice_date?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						type='number'
						label={formState.invoice_amount?.label}
						disabled={formState.invoice_amount?.disabled || isClient(user.role)}
						value={formState.invoice_amount?.value}
						placeholder={formState.invoice_amount?.placeholder}
						onChange={onChange('invoice_amount')}
						required={formState.invoice_amount?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						type='url'
						label={formState.gps_link?.label}
						disabled={formState.gps_link?.disabled}
						value={formState.gps_link?.value}
						placeholder={formState.gps_link?.placeholder}
						onChange={onChange('gps_link')}
						required={formState.gps_link?.required}
						pattern='[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?'
					/>
				</FormItemWrapper>
				<SubSectionHeading>{strings('enter_serviced_weight_sku')}</SubSectionHeading>
				<FormItemWrapper>
					<ActionButton type='primary' small uppercase disabled={disabledUpdateButton} onClick={updateInvoiceInfo}>
						{strings('update')}
					</ActionButton>
				</FormItemWrapper>
			</FormWrapper>

			<CustomSectionHeader>{strings('bilti')}</CustomSectionHeader>
			<FormItemWrapper>{renderDocuments([invoiceData?.lorry_receipt_doc], logisticDocType.lorry)}</FormItemWrapper>
			<FormItemWrapper>
				<DocUploadButton
					label={strings('upload', 'bilti')}
					small
					uppercase
					onChange={(file) => uploadDoc(file, logisticDocType.lorry, 'lorry_receipt_doc')}
				/>
			</FormItemWrapper>

			<CustomSectionHeader>{strings('dispatch_weighment_slip')} </CustomSectionHeader>
			<FormItemWrapper>{renderDocuments(invoiceData?.dispatch_weighment_slip_doc, logisticDocType.dispatch)}</FormItemWrapper>
			<FormItemWrapper>
				<DocUploadButton
					label={strings('upload_dispatch_weighment')}
					small
					uppercase
					onChange={(file) => uploadDoc(file, logisticDocType.dispatch, 'dispatch_weighment_slip_doc')}
				/>
			</FormItemWrapper>

			<CustomSectionHeader>{strings('drop_weighment_slip')} </CustomSectionHeader>
			<FormItemWrapper>{renderDocuments(invoiceData?.drop_weighment_slip_doc, logisticDocType.drop)}</FormItemWrapper>
			<FormItemWrapper>
				<DocUploadButton
					label={strings('upload_drop_weighment')}
					small
					uppercase
					onChange={(file) => uploadDoc(file, logisticDocType.drop, 'drop_weighment_slip_doc')}
				/>
			</FormItemWrapper>

			<CustomSectionHeader>{strings('receiving', 'document')} </CustomSectionHeader>
			<FormItemWrapper>{renderDocuments([invoiceData?.material_receiving_doc], logisticDocType.material)}</FormItemWrapper>
			<FormItemWrapper>
				<DocUploadButton
					label={strings(['upload', 'receiving', 'document'])}
					small
					uppercase
					onChange={(file) => uploadDoc(file, logisticDocType.material, 'material_receiving_doc')}
				/>
			</FormItemWrapper>
		</DrawerBodyWrapper>
	)
})

export default AdminEditLogisticsInvoice
