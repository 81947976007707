import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Checkbox, Select, Input, MultiSelect } from '@base'
import { Spacings } from '@styles'
import { enquiryInputType, roles, strings } from '@constants'
import { isSuperAdmin } from 'src/utils/adminFilter'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: flex-end;

	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin: 0px ${Spacings.SPACING_2B};
		}
	}
`

const BiInputWrapper = styled.div`
	display: grid;
	grid-template-columns: 4fr 2fr;
	column-gap: ${Spacings.SPACING_2B};
`

const EnquiryItemForm = React.memo(({ enquiryItems, itemId, state, updateState, defaultBrand, defualtCategoryId, checkTmtItem, activeCommodity }) => {
	const { category, product, requiredWeight, targetPrice, brands, message, tcRequired, requiredPieces, requiredLength, inputType } = state
	const [weightInMT, setWeight] = useState(requiredWeight?.value / 1000 || '')
	const [tmtProduct, setTmtProduct] = useState()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [preferredUnits, setPrefferedUnits] = useState([])

	useEffect(() => {
		if (!product?.tmtProduct) {
			let updateTmtValue = product?.data.map((item) => {
				const _product = enquiryItems.find((item2) => item.product_id === item2.product_id)
				if (_product !== undefined) {
					item.disabled = true
					item.required_weight = _product.required_weight / 1000
				} else {
					item.required_weight = ''
				}
				return item
			})
			setTmtProduct(updateTmtValue)
			if (product?.value?.product_id?.includes('scrap')) {
				updateState('brands', { value: [defaultBrand], disabled: true, dirty: true })
			}
		}
		const filteredUnits = Object.keys(enquiryInputType).filter((key) => state.category.value.preferred_units?.includes(key))
		setPrefferedUnits(filteredUnits.map((key) => ({ key: key, label: enquiryInputType[key] })))
	}, [product])

	useEffect(() => {
		if (preferredUnits === null || preferredUnits.length === 0) {
			updateState('inputType', { value: inputType?.value ? inputType?.value : enquiryInputType.WEIGHT })
		} else {
			updateState('inputType', { value: inputType?.value ? inputType?.value : preferredUnits?.[0]?.key })
		}
	}, [preferredUnits])

	useEffect(() => {
		if (user?.role === roles.manufacturer) {
			updateState('category', { value: category.data.find((_c) => _c.category_id === 'scrap'), dirty: true, disabled: true })
			updateState('brands', { value: [defaultBrand], disabled: true, dirty: true })
		} else if (defualtCategoryId?.includes('scrap')) {
			updateState('brands', { value: [defaultBrand], disabled: true, dirty: true })
		}
	}, [user, category.data, defualtCategoryId])

	const onChange = (key) => (value) => {
		if (key === 'requiredWeight') {
			setWeight(value)
			value = value * 1000
		}

		if (key === 'category') {
			if (value?.category_id.includes('scrap')) {
				updateState('brands', { value: [defaultBrand], disabled: true, dirty: true })
			} else {
				updateState('brands', { value: [], disabled: false, dirty: true })
			}
		}

		if (key === 'inputType') {
			value = value?.key
			onChange('requiredWeight')('')
			onChange('requiredPieces')('')
			onChange('requiredLength')('')
			setWeight('')
		}

		updateState(key, { value, dirty: true })
	}
	const onCheckBoxChange = (e) => onChange('tcRequired')(e.target.checked)

	const tmtProductHandler = (tmtProd, index) => {
		tmtProduct[index] = tmtProd
		setTmtProduct(tmtProduct)
		updateState('product', { tmtProduct, dirty: true })
	}

	useEffect(() => {
		setWeight(requiredWeight.value / 1000 || '')
	}, [requiredWeight.value])

	const selectedBrandUi = () => {
		return (
			<SectionItemWrapper>
				<MultiSelect
					id='enquiry-brands-select'
					disabled={brands?.disabled}
					label={brands?.label}
					displayKey={brands?.displayKey}
					primaryKey={brands?.primaryKey}
					placeholder={brands?.placeholder}
					data={brands?.data}
					value={brands?.value}
					onChange={onChange('brands')}
					multiple
					createNewOption={isSuperAdmin(user?.role)}
				/>
			</SectionItemWrapper>
		)
	}
	const simpleProductUi = () => {
		return (
			<>
				<SectionItemWrapper>
					{category?.value?.category_id?.includes('scrap') && !itemId ? (
						<MultiSelect
							id='enquiry-product-select'
							disabled={product?.disabled}
							label={product?.label}
							displayKey={product?.displayKey}
							primaryKey={product?.primaryKey}
							placeholder={product?.placeholder}
							data={product?.data}
							value={product?.value?.length > 0 ? product?.value : []}
							onChange={onChange('product')}
							createNewOption={true}
						/>
					) : (
						<Select
							id='enquiry-product-select'
							disabled={product?.disabled}
							label={product?.label}
							displayKey={product?.displayKey}
							primaryKey={product?.primaryKey}
							placeholder={product?.placeholder}
							data={product?.data}
							value={product?.value}
							onChange={onChange('product')}
							createNewOption={true}
						/>
					)}
				</SectionItemWrapper>
				{selectedBrandUi()}
				<SectionItemWrapper>
					{activeCommodity?.commodity_name === 'scrap' ? (
						<Input
							type='number'
							disabled={requiredWeight?.disabled}
							label={requiredWeight?.label}
							value={weightInMT}
							onChange={onChange('requiredWeight')}
						/>
					) : (
						<BiInputWrapper>
							<Input
								type='number'
								disabled={
									inputType?.value === enquiryInputType.LENGTH
										? requiredLength?.disabled
										: inputType?.value === enquiryInputType.PIECE
										? requiredPieces?.disabled
										: requiredWeight?.disabled
								}
								label={
									inputType?.value === enquiryInputType.LENGTH
										? requiredLength?.label
										: inputType?.value === enquiryInputType.PIECE
										? requiredPieces?.label
										: requiredWeight?.label
								}
								value={
									inputType?.value === enquiryInputType.LENGTH
										? requiredLength?.value
										: inputType?.value === enquiryInputType.PIECE
										? requiredPieces?.value
										: weightInMT
								}
								onChange={
									inputType?.value === enquiryInputType.LENGTH
										? onChange('requiredLength')
										: inputType?.value === enquiryInputType.PIECE
										? onChange('requiredPieces')
										: onChange('requiredWeight')
								}
							/>
							<Select
								id='selected-unit'
								label={inputType?.label}
								placeholder={inputType?.placeholder}
								data={preferredUnits}
								displayKey='label'
								primaryKey='key'
								value={preferredUnits.find((item) => item.key === inputType.value)}
								onChange={onChange('inputType')}
							/>
						</BiInputWrapper>
					)}
				</SectionItemWrapper>
				<SectionItemWrapper>
					<Input
						type='number'
						disabled={targetPrice?.disabled}
						label={
							inputType.value === 'LENGTH'
								? 'Target Price per Meter'
								: inputType.value === 'PIECE'
								? 'Target Price per Piece'
								: 'Target Price per MT'
						}
						value={targetPrice?.value}
						onChange={onChange('targetPrice')}
					/>
				</SectionItemWrapper>
			</>
		)
	}

	const tmtProductUi = () => {
		return (
			<>
				{selectedBrandUi()}
				{tmtProduct &&
					tmtProduct.map((item, index) => {
						return (
							<SectionItemWrapper key={index}>
								<SectionItemWrapperWithTwoItems>
									<Input type='text' disabled={true} label={strings('product', 'name')} value={item?.product_name} />
									<Input
										type='number'
										disabled={item?.disabled}
										label={requiredWeight?.label}
										value={item?.required_weight}
										onChange={(e) => {
											tmtProductHandler({ ...item, required_weight: e }, index)
										}}
									/>
									<Input
										type='number'
										disabled={item?.disabled}
										label={targetPrice?.label}
										value={item?.target_price}
										onChange={(e) => {
											tmtProductHandler({ ...item, target_price: e }, index)
										}}
									/>
								</SectionItemWrapperWithTwoItems>
							</SectionItemWrapper>
						)
					})}
			</>
		)
	}

	const productNameUi = () => {
		if (!checkTmtItem.find((item) => item === category?.value?.category_id) || itemId) {
			return simpleProductUi()
		}
		return tmtProductUi()
	}

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Select
					id='enquiry-category-select'
					disabled={category?.disabled}
					label={category?.label}
					displayKey={category?.displayKey}
					primaryKey={category?.primaryKey}
					placeholder={category?.placeholder}
					data={category?.data}
					value={category?.value}
					onChange={onChange('category')}
				/>
			</SectionItemWrapper>
			{productNameUi()}

			<SectionItemWrapper>
				<Input type='text' label={message?.label} disabled={message?.disabled} value={message?.value} onChange={onChange('message')} />
			</SectionItemWrapper>
			<SectionItemWrapperWithTwoItems>
				<Checkbox
					id='Checkbox'
					size={1.125}
					disabled={tcRequired?.disabled}
					checked={tcRequired?.value}
					value={tcRequired?.value}
					onChange={onCheckBoxChange}
				/>
				<FormElementLabel disabled={tcRequired?.disabled} htmlFor='Checkbox'>
					{tcRequired?.label}
				</FormElementLabel>
			</SectionItemWrapperWithTwoItems>
		</FormWrapper>
	)
})

export default EnquiryItemForm
