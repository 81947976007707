import API from '../api'
import deleteUrl from './paths'

const deleteEnquiryPhoto = (enquiryPhotoId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteEnquiryPhoto, { enquiryPhotoId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { deleteEnquiryPhoto }
