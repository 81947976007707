import { Map } from 'immutable'
import {
	SET_USER,
	USER_UPDATED_FAILURE,
	USER_UPDATED_PROGRESS,
	USER_UPDATED_SUCCESS,
	USER_PROFILE_UPDATED_SUCCESS,
	SET_ORGANISATION,
	UPDATE_ORGANISATION,
} from '../action/user'

const initialUserState = Map({
	user: null,
	organisation: null,
	userUpdateInProgress: false,
	userUpdateFailed: false,
})

const userReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case SET_USER:
			return state.merge({ user: action.payload })
		case USER_UPDATED_PROGRESS:
			return state.merge({
				userUpdateInProgress: true,
				userUpdateFailed: false,
			})
		case USER_UPDATED_SUCCESS:
			return state.merge({
				user: action.data,
				userUpdateInProgress: false,
			})
		case USER_UPDATED_FAILURE:
			return state.merge({
				userUpdateInProgress: false,
				userUpdateFailed: true,
			})
		case USER_PROFILE_UPDATED_SUCCESS:
			return state.merge({
				userUpdateInProgress: false,
			})
		case SET_ORGANISATION:
			return state.merge({ organisation: action.payload })
		case UPDATE_ORGANISATION:
			const organisation = { ...state.toJS().organisation, commodity: action.payload }
			return state.merge({ organisation })
		// return state.merge({ organisation: action.payload })
		default:
			return state
	}
}

export default userReducer
