import React, { useEffect, useMemo } from 'react'
import { ButtonNavigationWrapper, Wrapper } from '../styles'
import { FloatingButton, PageLevelTableWrapper, Table, TableActionButton, TableColumns, TOAST_TYPE, useTableReducer } from '@base'
import { Spacings } from '@styles'
import { Add, Delete } from '@material-ui/icons'
import { MODAL_TYPES, strings } from '@constants'
import { useDispatch } from 'react-redux'
import { useForceUpdate, useHeaderMeta } from '@hooks'
import { useNavigate } from 'react-router'
import { paths } from '@routes'
import { getAllGlossary } from '@data/conn/get/glossary'
import { deleteGlossary } from '@data/conn/delete/glossary'
import { showLoader, showToast } from '@data/state/action'
import { toggleModal } from '@data/state/action/root'
import { adminGlossary } from '@utils'

const Glossary = () => {
	useHeaderMeta(adminGlossary)
	const globalDispatch = useDispatch()
	const [state, dispatch, actions] = useTableReducer(/*glossaryFilter*/)
	const [forceUpdate, update] = useForceUpdate()
	const navigate = useNavigate()

	useEffect(() => {
		if (state.loading) return
		dispatch(actions.fetchTableDataProgress())
		getAllGlossary(state.query.page)
			.then((response) => {
				dispatch(actions.fetchTableDataSuccess(response.content, response.total_elements))
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {})
	}, [state.query, forceUpdate])

	const onDeleteConfirm = (item) => {
		globalDispatch(showLoader(true))
		deleteGlossary(item.glossary_id)
			.then(() => {
				globalDispatch(showToast(true, strings('msg_glossary_delete_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let msg = error?.message || strings('msg_glossary_delete_fail')
				globalDispatch(showToast(true, msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				globalDispatch(showLoader(false))
			})
	}

	const deleteHandler = (e, item) => {
		e.stopPropagation()
		globalDispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings('msg_sure_want_to_delete_glossary'),
				confirmAction: (actionType) => {
					actionType && onDeleteConfirm(item)
					globalDispatch(toggleModal(false))
				},
			})
		)
	}

	const columns = useMemo(
		() => [
			{ label: strings('glossary', 'id'), key: 'glossary_id', mobile: true },
			{ label: strings('keyword'), key: 'keyword', mobile: true },
			{ label: strings('slug'), key: 'slug', mobile: true },
			TableColumns.actionsColumn({
				label: strings('action'),
				align: 'left',
				mobile: true,
				renderer: (item) => {
					return (
						<TableActionButton type='secondary' variant='destructive' small uppercase onClick={(e) => deleteHandler(e, item)}>
							<Delete fontSize='small' />
						</TableActionButton>
					)
				},
			}),
		],
		[state.data]
	)

	const addGlossaryHandler = () => {
		navigate(paths.admin_content_glossary_create)
	}
	const rowClickHandler = (row) => {
		navigate(paths.admin_content_glossary_edit(row.slug))
	}

	const changePageHandler = (next = true) => {
		dispatch(actions.changePage(next))
	}

	return (
		<Wrapper>
			<ButtonNavigationWrapper>
				<FloatingButton
					desktopLabel={strings('add')}
					mobileLabel={<Add fontSize='small' />}
					margin={`0 ${Spacings.SPACING_1B} 0 0`}
					type='primary'
					small
					notFixed
					onClick={addGlossaryHandler}
				/>
			</ButtonNavigationWrapper>
			<PageLevelTableWrapper>
				<Table
					fullHeight
					data={state.data}
					columns={columns}
					page={state.query.page}
					rowsToDisplay={10}
					loading={state.loading}
					totalItemCount={state.total_count}
					onPageChange={changePageHandler}
					onRowClick={rowClickHandler}
				/>
			</PageLevelTableWrapper>
		</Wrapper>
	)
}

export default Glossary
