import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { Spacings } from '@styles'
import { getMonthlyTarget, updateTarget } from '@data'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { showToast } from '@data/state/action'

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	& > div {
		width: 100%;
	}
	margin: ${Spacings.SPACING_3B} 0;
`

const MonthlyTarget = ({update}) => {
	const [target, setTarget] = useState([])
	const dispatch = useDispatch()

	useEffect(() => {
		getMonthlyTarget()
			.then((response) => {
				setTarget(response.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const onChange = (index) => (value) => {
		const updatedTarget = [...target]
		updatedTarget[index] = { ...updatedTarget[index], monthly_target: value }
		setTarget(updatedTarget)
	}
	const updateTargetHandler = () => {
		updateTarget(target)
			.then((response) => {
				dispatch(showToast(true, strings('target', 'updated'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings(['target', 'not', 'updated']), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(toggleDrawer(false))
				update()
			})
	}
	return (
		<>
			<DrawerBodyWrapper>
				{target?.map((item, index) => {
					return (
						<SectionWrapper>
							<div>{item?.full_name}</div>
							<Input type='number' inline small value={item?.monthly_target} onChange={onChange(index)} />
						</SectionWrapper>
					)
				})}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small onClick={updateTargetHandler}>
					{strings('set', 'target')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default MonthlyTarget
