import API from '../api'
import putUrl from './paths'

export const editOrder = (
	order,
	purchaseOrderGenerated = false,
	supplyOrderGenerated = false,
	purchaseInvoiceGenerated = false,
	supplyInvoiceGenerated = false
) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editOrder, order, {
			purchaseInvoiceGenerated,
			purchaseOrderGenerated,
			supplyInvoiceGenerated,
			supplyOrderGenerated,
		})
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const updateInvoice = (
	invoiceAmount,
	invoiceDate,
	invoiceNumber,
	orderId,
	orderItems,
	originalInvoiceNumber,
	gpsLink,
	deliveryStatus,
	driverNumber,
	vehicleNumber,
	deliveryDate = null,
	rejectedWeight = null
) =>
	new Promise((resolve, reject) => {
		const payload = {
			invoiceAmount,
			invoiceDate,
			invoiceNumber,
			orderId,
			gpsLink,
			deliveryStatus,
			driverNumber,
			vehicleNumber,
			deliveryDate,
			rejectedWeight,
		}
		if (originalInvoiceNumber) {
			payload['originalInvoiceNumber'] = originalInvoiceNumber
		}
		API.put(putUrl.updateInvoice, orderItems, payload)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})


export const updateInvoiceV2 = (invoice, orderId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateInvoiceV2, invoice, { orderId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err)
			})
	})

export const deleteInvoice = (invoiceNumber, orderId, buyerInvoice = 'null', supplierInvoice = 'null') =>
	new Promise((resolve, reject) => {
		const queryParams = {
			invoiceNumber,
			orderId,
			buyerInvoice,
			supplierInvoice,
		}
		API.put(putUrl.deleteInvoice, {}, queryParams)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const updateInvoiceTracker = (driverNumber, invoiceId, lrid, orderId, vehicleNumber) =>
	new Promise((resolve, reject) => {
		API.put(
			putUrl.updateInvoiceTracker,
			{},
			{
				driverNumber,
				invoiceId,
				lrid,
				orderId,
				vehicleNumber,
			}
		)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadExportDocumentsApi = (documentType, files, invoiceNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		files.forEach((file) => {
			formData.append('files', file)
		})
		const fileTypeURL = putUrl.uploadBuyerExportDocuments
		API.put(fileTypeURL, formData, { documentType, invoiceNumber, orderId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})
