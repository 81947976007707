import React, { lazy } from 'react'
import paths from '../paths'
import { Navigate } from 'react-router'
import { withSuspense } from './routeUtils'

const Login = lazy(() => import(/* webpackChunkName: "Login" */ '@pages/Login'))
const LoginForm = lazy(() => import(/* webpackChunkName: "LoginForm" */ '@pages/Login/LoginForm'))

const LoginRoutes = {
	path: '/',
	element: withSuspense(<Login />),
	children: [
		{ index: true, element: <Navigate to='/login' /> },
		{
			path: paths.login,
			element: withSuspense(<LoginForm />),
		},
		{ path: '*', element: <Navigate to='/login' /> },
	],
}

export default LoginRoutes
