import { useEffect, useRef } from 'react'

const MOVE_THRESHOLD = 10

const useSwipe = ({ elem, xMoveHandler, threshold = MOVE_THRESHOLD }) => {
	const xData = useRef({ initialX: 0, initialY: 0, moveX: 0, moveY: 0 })

	useEffect(() => {
		const startHandler = (e) => {
			xData.current = {
				...xData.current,
				initialX: e.touches[0].pageX,
			}
		}
		const moveHandler = (e) => {
			const currentX = e.touches[0].pageX
			xData.current = {
				...xData.current,
				moveX: currentX - xData.current.initialX,
			}
		}
		const endHandler = (e) => {
			const { moveX } = xData.current

			if (Math.abs(moveX) > threshold) {
				if (moveX < 0) {
					xMoveHandler()
				} else {
					xMoveHandler(true)
				}
			}

			xData.current = {}
		}

		if (elem) {
			elem.addEventListener('touchstart', startHandler)
			elem.addEventListener('touchmove', moveHandler)
			elem.addEventListener('touchend', endHandler)
		}

		return () => {
			if (elem) {
				elem.removeEventListener('touchstart', startHandler)
				elem.removeEventListener('touchmove', moveHandler)
				elem.removeEventListener('touchend', endHandler)
			}
		}
	}, [elem, xMoveHandler, threshold])
}

export default useSwipe
