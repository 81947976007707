import styled, { css } from 'styled-components'
import { Button, H6, SectionHeader, UploadButton } from '@base'
import { Spacings } from '@styles'

export const CustomSectionHeader = styled(SectionHeader)`
	margin-top: ${Spacings.SPACING_6B};
`

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}

	${({ isStock }) => {
		if (isStock) {
			return css`
				justify-content: space-between;
			`
		}
	}};
`

export const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`

export const DocUploadButton = styled(UploadButton)`
	margin-left: auto;
`

export const DocumentsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const DownloadIconWrapper = styled.div`
	padding: ${Spacings.SPACING_3B};
	margin-right: ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	align-items: center;
	justify-content: center;
	width: fit-content;
	border-radius: ${Spacings.SPACING_2B};
`

export const Message = styled(H6)`
	flex-grow: 1;
	flex-basis: 100%;
	margin-top: ${Spacings.SPACING_1B};
	margin-bottom: ${Spacings.SPACING_3B};
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
`

export const SubSectionHeading = styled(H6)`
	margin-top: ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_2B};
`
export const TopSectionHeader = styled(SectionHeader)`
	justify-content: space-between;
`