import axios from 'axios'
import store from '../state/store'
import { apiSizeCount } from '@constants'

const AXIOS_OBJECT = (headers) => {
	let url = process.env.REACT_APP_URL

	if (
		window.location.href.includes('lazy.metalbook.app') ||
		window.location.href.includes('dev.metalbook.app') ||
		process.env.REACT_APP_SERVER === 'develop'
	) {
		url = process.env.REACT_APP_DEV_URL
	}

	if (process.env.REACT_APP_SERVER === 'local') {
		url = process.env.REACT_APP_LOCAL_URL
	}

	return axios.create({
		baseURL: url,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': 'POST,GET,OPTIONS,PUT,DELETE',
			'Access-Control-Allow-Methods': 'Content-Type,*',
			'Access-Control-Allow-Credentials': true,
			'mb-app-api': true,
			authorization: headers.authToken && 'Bearer ' + headers.authToken,
			'mb-auth-uid': headers.uid,
			'mb-id': headers.user_id,
			'mb-org-id': headers.organisation_id,
			'mb-url': window.location.href,
		},
	})
}

const callMethod = (method, api, data, params = {}, customHeaders = {}) =>
	new Promise((resolve, reject) => {
		const headers = store.getState().getIn(['user', 'user']) || {}

		// console.group('Remove these callMethod check api values');
		// console.log( 'api', api, method)
		// console.log('data', data)
		// console.log('headers', headers)
		// console.groupEnd();

		let methodToCall = null

		if (method === 'post') {
			if (data) {
				data['userId'] = data.userId || headers.user_id
				data['organisationId'] = data.organisationId || headers.organisation_id
				data.created_by = data['userId']
				if (data.removeDefault) {
					data['userId'] = null
				}
			}
			methodToCall = AXIOS_OBJECT(headers).post(api, data, {
				params,
				...customHeaders,
			})
		} else if (method === 'get') {
			if (!data.removeDefault) {
				data['userId'] = data.userId || headers.user_id
				data['size'] = data.size || apiSizeCount
				data['organisationId'] = data.organisationId || headers.organisation_id
			}
			data.removeDefault = null
			methodToCall = AXIOS_OBJECT(headers).get(api, { params: data, ...params })
		} else if (method === 'put') {
			// data['size'] = data.size || apiSizeCount;
			data.updated_by = data.userId || headers.user_id
			methodToCall = AXIOS_OBJECT(headers).put(api, data, { params })
		} else if (method === 'delete') {
			data.updated_by = data.userId || headers.user_id
			methodToCall = AXIOS_OBJECT(headers).delete(api, { params: data })
		}

		if (methodToCall === null) reject('contact support')

		methodToCall
			.then((response) => {
				if (response.error) {
					if (response.authenticated === false) {
						reject('authenticated failed')
					} else {
						reject(response.error)
					}
				} else {
					resolve(response)
				}
			})
			.catch((err) => {
				reject(err)
			})
	})

const API = {
	get: (api, data = {}, options) => callMethod('get', api, data, options),
	post: (api, data, params, customHeaders) => callMethod('post', api, data, params, customHeaders),
	put: (api, data, params = {}) => callMethod('put', api, data, params),
	delete: (api, data = {}, params = {}) => callMethod('delete', api, data, params),
}

export default API
