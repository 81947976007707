import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { DeleteOutline } from '@material-ui/icons'

import { deleteEnquiryPhoto, downloadUrl } from '@data'
import { getAllEnquiryPhoto } from '@data/conn/get/enquiryPhotos'
import { toggleDrawer, toggleFileViewer, toggleModal } from '@data/state/action/root'
import { Spacings } from '@styles'
import defaultImage from '@assets/defaultImage.png'
import { Button, DrawerBodyWrapper, TOAST_TYPE } from '@base'
import { MODAL_TYPES, strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { useForceUpdate } from '@hooks'
import { TextValue, Label } from '@pages/ProfileV2/Profile/styles'
import { displayName } from '@utils'

const EnquiryImage = styled.img`
	width: ${Spacings.SPACING_32B};
	height: ${Spacings.SPACING_32B};
	object-fit: cover;
	border-radius: 8%;
	cursor: cell;
	&:hover {
		opacity: 0.4;
	}
`

const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const EditIconWrapper = styled(Button)`
	padding: 0 ${Spacings.SPACING_2B};
	height: ${Spacings.SPACING_9B};
	width: ${Spacings.SPACING_9B};
	min-width: 0;
	margin: 0 ${Spacings.SPACING_1B};
	min-height: 0;
`

const MenuIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const ButtonWappers = styled.div`
	display: flex;
`

const EnquiryDetails = styled.div`
	display: flex;
	margin-left: ${Spacings.SPACING_6B};
	flex-direction: column;
	justify-content: space-evenly;
`

const EnquiryPhotoDetail = styled.div`
	display: flex;
`

function Index() {
	const dispatch = useDispatch()
	const [enquiryPhotos, setEnquiryPhotos] = useState()
	const [forceUpdate, update] = useForceUpdate()

	useEffect(() => {
		getAllEnquiryPhoto()
			.then((res) => {
				setEnquiryPhotos(res)
			})
			.catch((err) => console.log(err))
	}, [forceUpdate])

	const viewPhotoHandler = (file) => {
		dispatch(toggleFileViewer(true, { files: file }))
	}

	const selectEnquiryItemhandler = (item) => {
		dispatch(toggleDrawer(false, null, { chooseEnquiryPhoto: item }))
	}

	const deleteHandler = (enquiryPhotoId) => {
		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings('want_to_delete'),
				confirmAction: (actionType) => {
					actionType && deletedEnquiry(enquiryPhotoId)
					dispatch(toggleModal(false))
				},
			})
		)
	}

	const deletedEnquiry = (enquiryPhotoId) => {
		dispatch(showLoader(true))
		deleteEnquiryPhoto(enquiryPhotoId)
			.then((response) => {
				dispatch(showToast(true, strings(['delete', 'photo', 'successfully']), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<DrawerBodyWrapper>
			{enquiryPhotos?.map((item, index) => (
				<FormItemWrapper isMargin key={index}>
					<EnquiryPhotoDetail>
						<EnquiryImage
							src={downloadUrl + item?.image_paths}
							onClick={(e) => {
								viewPhotoHandler(item?.image_paths)
							}}
							onError={(e) => {
								e.target.src = `${defaultImage}`
							}}
						/>
						<EnquiryDetails>
							<div>
								<Label>{strings('buyer')}</Label>
								<TextValue>{displayName(item?.buyer)}</TextValue>
							</div>
							{item?.relationship_manager && (
								<div>
									<Label>{strings('relationship', 'manager')}</Label>
									<TextValue>{item?.relationship_manager?.slice(1)?.[0]?.full_name}</TextValue>
								</div>
							)}
						</EnquiryDetails>
					</EnquiryPhotoDetail>
					<ButtonWappers>
						<Button
							small
							xs
							onClick={() => {
								selectEnquiryItemhandler(item)
							}}
							margin='0 0 0 auto'
						>
							{strings(['select'])}
						</Button>
						<EditIconWrapper type='secondary' variant='destructive' uppercase>
							<MenuIcon>
								<DeleteOutline
									fontSize='medium'
									onClick={() => {
										deleteHandler(item?.enquiry_photo_id)
									}}
								/>
							</MenuIcon>
						</EditIconWrapper>
					</ButtonWappers>
				</FormItemWrapper>
			))}
		</DrawerBodyWrapper>
	)
}

export default Index
