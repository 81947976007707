import API from '../api'
import postUrl from './paths'

export const createMachine = (machine, user) =>
	new Promise((resolve, reject) => {
		machine.created_by = user.user_id
		machine.created_at = new Date().getTime()
		API.post(postUrl.createMachine, machine)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadMachineTemplatePhoto = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadMachineTemplatePhoto, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
