import { InventoryTypes } from '@constants'
import { Record } from 'immutable'

const FactoryOrderInputItemRecord = Record({
	alloted_weight: '',
	alloted_units: '',
	process_type: '',
	batch_number: '',
	brand: '',
	cast_number: '',
	category_name: '',
	incoming_order_id: [],
	inventory_id: '',
	inventory_status: '',
	inventory_type: InventoryTypes.STANDARD,
	mb_reference_number_from_inventory: [],
	mb_reference_number: [],
	mb_reference_photos: [],
	price_per_ton: '',
	product_id: '',
	product_name: '',
	product_received_photos: [],
	quantity: '',
	test_certificate: '',
	total_weight: '',
	identifier: '',
	selected: false,
})

class FactoryOrderInputItem extends FactoryOrderInputItemRecord {
	constructor(data) {
		super({
			...data,
			identifier: `${data?.batch_number}_${data?.brand}`,
		})
	}
	update(updates) {
		if (!this.updated) {
			return this.merge({ ...updates, updated: true })
		}
		return this.merge(updates)
	}

	isValid() {
		const areValuesValid =
			this.alloted_units &&
			this.alloted_weight &&
			this.alloted_weight <= (this.total_weight || Number.MAX_SAFE_INTEGER) &&
			this.alloted_units <= (this.quantity || Number.MAX_SAFE_INTEGER)
		return Boolean(areValuesValid && this.process_type && this.mb_reference_number?.length > 0)
	}
}

export default FactoryOrderInputItem
