import styled from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { Button, H5, Pill } from '@base'

export const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

export const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

export const FormElementLabel = styled.label`
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.label};
	margin-left: ${Spacings.SPACING_2B};
	cursor: pointer;
	user-select: none;
`

export const Footer = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: initial;
	}
`

export const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`
export const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_1B};
`
export const SearchWrapper = styled.div`
	margin-left: ${Spacings.SPACING_1B};
`
export const ActionDiv = styled.div`
	display: flex;
	align-items: center;
`

export const PillsWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-left: auto;
align-items: end;
margin:${Spacings.SPACING_3B} auto;
`
export const CategoryPill = styled(Pill)`
	
	background: ${({ theme }) => (theme.palette.background.greenLight)};
	border: 1px solid ${({ theme }) => (theme.palette.background.borderColor)};

	& > span:nth-child(1) {
		margin-right: 0;
	}
	min-height: 1.6rem;
	padding: 0 ${Spacings.SPACING_2B};
`

export const OtherDetailWrapper = styled.div`
	margin-top:${Spacings.SPACING_2B};
	&>h5{
		margin-left:${Spacings.SPACING_1B};
	}

`