import API from '../api'
import postUrl from './paths'

const createLogisticsPrice = (logisticItem) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.logisticsPrices, logisticItem)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { createLogisticsPrice }
