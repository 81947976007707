import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Button, H4, H5, Loader, RadioButton, TOAST_TYPE, UploadButton } from '@base'
import { Spacings, Text } from '@styles'
import { strings } from '@constants'
import { updateLocationApi, uploadKYCDocument } from '@data'
import { toggleFileViewer } from '@data/state/action/root'
import { showLoader, showToast } from '@data/state/action'
import {
	ActionButton,
	ActionButtonWapper,
	ButtonIcon,
	ButtonIconWapper,
	FooterWrapper,
	Label,
	PermissionStatusWrapper,
	RadioButtonWrapper,
	RadioInputWapper,
	StepsToAllow,
} from './styles'
import RejectReason from './RejectReason'
import Webcam from './WebCam'
import { ChevronLeftOutlined, ChevronRightOutlined, Lock } from '@material-ui/icons'
import { isClient } from '@utils/adminFilter'

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`

const SectionItemWrapper = styled.div`
	display: flex;
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: ${({ marginTop }) => marginTop || `${Spacings.SPACING_2B}`};

	& > div {
		width: 100%;
	}
`

const HeaderWrapper = styled(H5)`
	align-self: center;
	flex: 1;
`

const HeaderSpanWrapper = styled.span`
	color: ${({ theme, color }) => (color ? theme.palette.background[color] : theme.palette.text.greydark)};
	font-size: ${Text.SMALL};
`

const TitleWrapper = styled(H4)`
	margin-bottom: ${Spacings.SPACING_1B};
	margin-top: ${({ marginTop }) => (marginTop ? Spacings.SPACING_6B : 0)};
	font-weight: bold;
`

const FormHeading = styled(H5)`
	margin: ${Spacings.SPACING_2B} 0;
	padding-right: ${Spacings.SPACING_32B};
	min-width: 200px;
`

const DocumentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`
const ExtraDiv = styled.div`
	margin-right: ${Spacings.SPACING_4B};
`

const Documents = ({ org, nextStep, preStep, adminView, kycStatushandler, selectedStep, setOrgData, update }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(!adminView)
	const [permission, setPermission] = useState(adminView)
	const [isActiveReason, setIsActiveReason] = useState(false)
	const [activeImageWebcam, setActiveImageWebcam] = useState(false)
	const [geoLocation, setGeoLocation] = useState({ lat: 0, lng: 0 })
	const _user = useSelector((state) => state.getIn(['user', 'user']))

	useEffect(() => {
		if (!adminView) {
			checkGeoLocationPermission()
		}
	}, [])

	const getCurrentGeoPosition = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				setGeoLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
			},
			(error) => {
				if (error.code === error.PERMISSION_DENIED) {
					console.log('Geolocation is not supported by this device')
				}
			}
		)
	}

	const checkGeoLocationPermission = () => {
		setLoading(true)
		navigator.permissions
			.query({ name: 'geolocation' })
			.then((permissionObject) => {
				setPermission(permissionObject.state === 'granted')

				permissionObject.onchange = () => {
					setPermission(permissionObject.state === 'granted')
					if (permissionObject.state === 'granted') {
						getCurrentGeoPosition()
					}
				}

				if (permissionObject.state !== 'granted') {
					getCurrentGeoPosition()
				}
			})
			.catch((err) => {
				console.log(`Error in permission API: ${err}`)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const updateUserLocation = () => {
		dispatch(showLoader(true))
		updateLocationApi(geoLocation.lat, geoLocation.lng, 'system', org?.user?.organisation_id)
			.then((res) => {
				setOrgData({ ...org, ...res })
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const uploadFileHandler =
		(key, objectDocType = null, typeOfProperty = null) =>
		(file) => {
			dispatch(showLoader(true))
			uploadKYCDocument(file, key, org?.user?.organisation_id, typeOfProperty)
				.then((res) => {
					if (isClient(_user.role) && geoLocation.lat !== 0) {
						updateUserLocation()
					}
					if (objectDocType !== 'typeOfProperty') dispatch(showToast(true, strings('upload_success'), { type: TOAST_TYPE.SUCCESS }))
					setOrgData({ ...org, ...res })
				})
				.catch((err) => console.log(err))
				.finally(() => {
					dispatch(showLoader(false))
				})
		}

	const downloadDocument = (filePath) => {
		dispatch(toggleFileViewer(true, { files: filePath }))
	}

	const capturePhotoHandler = (img) => {
		urltoFile(img, 'photos.jpeg').then(function (file) {
			uploadFileHandler('photos', null)(file)
		})
	}

	const urltoFile = (url, filename, mimeType) => {
		mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
		return fetch(url)
			.then(function (res) {
				return res.arrayBuffer()
			})
			.then(function (buf) {
				return new File([buf], filename, { type: mimeType })
			})
	}

	const DocumentViewer = useCallback(({ title, _key, _keyType, filePath, selfAttested = false, optional = false, optionalTitle = null }) => {
		const btnTitle = filePath ? strings('change') : strings('select')
		return (
			<DocumentWrapper>
				<HeaderWrapper>
					{title}
					{selfAttested && <HeaderSpanWrapper> ({strings('selfattested')})</HeaderSpanWrapper>}
					{optional && <HeaderSpanWrapper color='orangeLight'> ({strings('optional')})</HeaderSpanWrapper>}
					{optionalTitle && <HeaderSpanWrapper> ({optionalTitle})</HeaderSpanWrapper>}
				</HeaderWrapper>
				<Button xs type='secondary' disabled={!filePath} onClick={() => downloadDocument(filePath)}>
					{strings('view')}
				</Button>
				<ExtraDiv />
				{_keyType === 'photos' ? (
					<Button
						xs
						onClick={() => setActiveImageWebcam(true)}
						variant={btnTitle === strings('select') ? 'destructive' : null}
						disabled={adminView}
					>
						{btnTitle}
					</Button>
				) : (
					<UploadButton
						xs
						onChange={uploadFileHandler(_key, _keyType)}
						label={btnTitle}
						variant={btnTitle === strings('select') ? 'destructive' : null}
					/>
				)}
			</DocumentWrapper>
		)
	}, [])

	const footerHandler = () => {
		if (adminView) {
			return (
				<FooterWrapper>
					<SectionItemWrapper isFooter>
						<ButtonIconWapper>
							<ButtonIcon
								type='secondary'
								small
								uppercase
								onClick={() => {
									preStep()
								}}
							>
								<ChevronLeftOutlined />
							</ButtonIcon>
							<ButtonIcon
								type='secondary'
								small
								margin={'0px 0px 0px 5px'}
								uppercase
								onClick={() => {
									nextStep()
								}}
							>
								<ChevronRightOutlined />
							</ButtonIcon>
						</ButtonIconWapper>
						<ActionButtonWapper>
							<Button
								xs
								type='secondary'
								variant='destructive'
								onClick={() => {
									setIsActiveReason(true)
								}}
							>
								{strings('reject')}
							</Button>
							<Button
								xs
								type='primary'
								margin='0px 0px 0px 3px'
								onClick={() => {
									kycStatushandler('', selectedStep, true, 'Documents request accept')
								}}
							>
								{strings('accept')}
							</Button>
						</ActionButtonWapper>
					</SectionItemWrapper>
					{isActiveReason && (
						<RejectReason
							kycStatushandler={kycStatushandler}
							setIsActiveReason={setIsActiveReason}
							setpNumber={selectedStep}
							stepName='Documents'
							rejectReasonMsg={org?.kyc_reject_reason?.[2] || ''}
						/>
					)}
				</FooterWrapper>
			)
		}

		return (
			<ActionButton disabled={!checkDocument()} small type='primary' onClick={() => nextStep()}>
				{strings('next')}
			</ActionButton>
		)
	}

	function checkDocument() {
		let docValues =
			org?.documents?.pan &&
			org?.documents?.aadhaar &&
			org?.documents?.passport_photo &&
			org?.documents?.gst_certificate &&
			org?.documents?.gst_r2_a &&
			org?.documents?.gst_r3_b &&
			org?.documents?.office_documents?.photos &&
			org?.documents?.office_documents?.utility_bill

		if (org?.documents?.office_documents?.type_of_property === 'owned') {
			return docValues
		} else {
			return docValues && org?.documents?.office_documents?.rent_agreement && org?.documents?.office_documents?.landlord_noc
		}
	}

	const officeType = (rentedOfc) => {
		uploadFileHandler('type_of_property', 'typeOfProperty', rentedOfc ? 'rental' : 'owned')(null)
	}

	const renderDocuments = () => (
		<SectionWrapper>
			<TitleWrapper>{strings('msg_upload_document', 'director')}</TitleWrapper>
			<SectionItemWrapper>
				<DocumentViewer _key='pan' filePath={org?.documents?.pan} title={strings('pan')} selfAttested />
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer _key='aadhaar' filePath={org?.documents?.aadhaar} title={strings('aadhaar')} selfAttested />
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer _key='passport_photo' filePath={org?.documents?.passport_photo} title={strings('passport', 'photo')} />
			</SectionItemWrapper>

			<TitleWrapper marginTop>{strings('msg_upload_document', 'financials')}</TitleWrapper>
			<SectionItemWrapper>
				<DocumentViewer
					_key='gst_certificate'
					filePath={org?.documents?.gst_certificate}
					title={strings('gst', 'certificate')}
					selfAttested
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer _key='gst_r2a' filePath={org?.documents?.gst_r2_a} title={strings('gstr2a')} optionalTitle='Last 2months' />
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer _key='gst_r3b' filePath={org?.documents?.gst_r3_b} title={strings('gstr3b')} optionalTitle='Past 6months' />
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer _key='gst_r1' filePath={org?.documents?.gst_r1} title={strings('gstr1')} optional />
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer
					optional
					key='bank_statement'
					_key='bank_statement'
					filePath={org?.documents?.bank_statement}
					title={strings('bank', 'statements')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<DocumentViewer
					optional
					key='cancelledCheque'
					_key='cancelled_cheque'
					filePath={org?.documents?.cancelled_cheque}
					title={strings('cancelled', 'cheque')}
				/>
			</SectionItemWrapper>

			<TitleWrapper marginTop>{strings('msg_upload_document', 'office')}</TitleWrapper>
			<SectionItemWrapper>
				<FormHeading>{strings('office', 'type')}</FormHeading>
				<RadioInputWapper>
					<RadioButtonWrapper>
						<RadioButton
							id='ofc-permanent-radio'
							size='1.125'
							checked={org?.documents?.office_documents?.type_of_property === 'owned'}
							value='owned'
							onChange={(e) => officeType(false)}
						/>
						<Label htmlFor='ofc-permanent-radio'>{strings('owned')}</Label>
					</RadioButtonWrapper>
					<RadioButtonWrapper>
						<RadioButton
							id='ofc-rented-radio'
							size='1.125'
							checked={org?.documents?.office_documents?.type_of_property === 'rental'}
							value='rental'
							onChange={(e) => officeType(true)}
						/>
						<Label htmlFor='ofc-rented-radio'>{strings('rental')}</Label>
					</RadioButtonWrapper>
				</RadioInputWapper>
			</SectionItemWrapper>
			{!activeImageWebcam && (
				<SectionItemWrapper>
					<DocumentViewer
						_key='officeDocuments'
						_keyType='photos'
						filePath={org?.documents?.office_documents?.photos}
						title={strings('office', 'photos')}
					/>
				</SectionItemWrapper>
			)}
			{activeImageWebcam && (
				<Webcam
					org={org}
					adminView={adminView}
					setActiveImageWebcam={setActiveImageWebcam}
					requesType='photos'
					capturePhotoHandler={capturePhotoHandler}
				/>
			)}
			<SectionItemWrapper>
				<DocumentViewer
					_key='utility_bill'
					_keyType='utility_bill'
					filePath={org?.documents?.office_documents?.utility_bill}
					title={strings('utility', 'bill')}
					optionalTitle={strings('all_utility')}
				/>
			</SectionItemWrapper>
			{org?.documents?.office_documents?.type_of_property === 'rental' && (
				<>
					<SectionItemWrapper>
						<DocumentViewer
							_key='rent_agreement'
							_keyType='rent_agreement'
							filePath={org?.documents?.office_documents?.rent_agreement}
							title={strings('rent_agreement')}
							selfAttested
						/>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<DocumentViewer
							_key='landlord_noc'
							_keyType='landlord_noc'
							filePath={org?.documents?.office_documents?.landlord_noc}
							optionalTitle={strings('noc')}
							title={strings('owner_noc')}
						/>
					</SectionItemWrapper>
				</>
			)}
		</SectionWrapper>
	)

	const renderContent = () => {
		if (loading) {
			return (
				<PermissionStatusWrapper>
					<H4>Checking location permission</H4>
					<br />
					<Loader size={2} />
				</PermissionStatusWrapper>
			)
		}

		if (!permission) {
			return (
				<PermissionStatusWrapper>
					<H4>Please allow location permission from browser settings</H4>
					<StepsToAllow>
						<H5>
							1. Click the <Lock /> icon in the browser's address bar
						</H5>
						<H5>2. Turn on location</H5>
					</StepsToAllow>
				</PermissionStatusWrapper>
			)
		}

		return (
			<>
				{renderDocuments()}
				{footerHandler()}
			</>
		)
	}

	return renderContent()
}

export default Documents
