import API from '../api'
import postUrl from './paths'

export const createReport = (pan, user_details) =>
	new Promise((resolve, reject) => {
		pan = pan.length > 10 ? pan.substring(2, 12) : pan
		API.post(postUrl.createCreditReport, user_details, {
			pan,
			timeout: 300000,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadCreditReport = (pan, reportId) =>
	new Promise((resolve, reject) => {
		API.post(
			postUrl.downloadCreditReport,
			{},
			{ pan, reportId },
			{
				responseType: 'arraybuffer',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			}
		)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateSalesTeamFeedback = (
	salesTeamFeedback,
	pan,
	reportId,
	type,
	userId,
	email = null,
	accountsEmail = null,
	contact = null,
	accountsContact = null
) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.updateSalesTeamFeedback, salesTeamFeedback, {
			pan,
			reportId,
			type,
			userId,
			email,
			accountsEmail,
			contact,
			accountsContact,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateTenorAmount = (pan, reportId, amount, tenor) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.updateTenorAmount, {}, { pan, reportId, amount, tenor })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const fetchPastRecordsFromZoho = (customerId, pan, reportId, userId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.fetchPastRecords, { removeDefault: true }, { customerId, pan, reportId, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const markAsCompleted = (pan, reportId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.markAsCompleted, {}, { pan, reportId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadProcessFile = (file, pan, reportId, institutionId, fromDate, toDate, isEStatement) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)

		API.post(postUrl.uploadProcessFile, formData, {
			institutionId,
			pan,
			reportId,
			fromDate,
			toDate,
			isEStatement,
			timeout: 300000,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const initiateGenerateReport = (pan, reportId) =>
	new Promise((resolve, reject) => {
		API.post(
			postUrl.initiateGenerateReport,
			{},
			{
				pan,
				reportId,
			}
		)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const UploadFsaReportApi = (file, pan, reportId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)

		API.post(postUrl.uploadFsaReport, formData, { pan, reportId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateTeamFeedback = (pan, reportId, type, score, comments, team_comment, feedback_by, score_by = null, isReportAvailable = false) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.updateTeamFeedback, { score, comments, team_comment, feedback_by, score_by }, { pan, reportId, type, isReportAvailable })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})
