const unit = 'rem'

export const ULTRA_SMALL = '.375' + unit // 6px
export const MICRO_SMALL = '.5' + unit // 6px
export const EXTRA_SMALL = '.625' + unit // 10px
export const SMALL = '.75' + unit // 12px
export const MEDIUM = '.875' + unit // 14px
export const LARGE = '1' + unit // 16px
export const M_LARGE = '1.125' + unit // 18px
export const X_LARGE = '1.25' + unit // 20px
export const XX_LARGE = '1.5' + unit // 24px
export const XXX_LARGE = '2.25' + unit // 36px

export const LINE_HEIGHT = '100%'
export const LINE_HEIGHT_MIN = '120%'
export const LINE_HEIGHT_MAX = '150%'
export const LINE_HEIGHT_MEDIUM = '130%'

export const REGULAR = 'normal'
export const BOLD_500 = '500'
export const BOLD_600 = '600'
export const BOLD_700 = '700'
export const BOLD_800 = '800'
export const BOLD_900 = '900'
