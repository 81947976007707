import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Select, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { getAllUsers, mergeOrganisation } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const initialState = () => {
	const _state = {
		fromOrg: {
			value: '',
			id: 'organisation_id',
			required: true,
			disabled: false,
			label: strings(['from', 'organisation']),
			placeholder: strings(['select', 'from', 'organisation']),
		},
		toOrg: {
			value: '',
			id: 'organisation_id',
			required: true,
			disabled: false,
			label: strings(['to', 'organisation']),
			placeholder: strings(['select', 'to', 'organisation']),
		},
	}
	return _state
}

const MergeOrganisation = ({ label, update }) => {
	const [formState, setFormState] = useState(initialState())
	const [orgData, setOrgData] = useState()
	const dispatch = useDispatch()
	useEffect(() => {
		getAllUsers()
			.then((response) => {
				setOrgData(response)
			})
			.catch((err) => console.log(err))
	}, [])

	const _orgData = orgData?.map((item) => {
		item['display_name'] = item.organisation_name ? `${item.organisation_name + ' (' + item.organisation_id + ')'}` : `(${item.organisation_id})`
		return item
	})

	const onChange = (key) => (value) => {
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...{ value },
			},
		}))
	}
	const disabled =
		formState.fromOrg.value === '' ||
		formState.toOrg.value === '' ||
		formState.fromOrg.value?.organisation_id === formState.toOrg.value?.organisation_id

	const mergeOrganisationHandler = () => {
		dispatch(showLoader(true))
		mergeOrganisation(formState.fromOrg.value.organisation_id, formState.toOrg.value.organisation_id)
			.then((response) => {
				dispatch(showToast(true, strings('organisation_merged'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings('organisation_merged_failure'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
				dispatch(toggleDrawer(false))
				update()
			})
	}
	return (
		<>
			<DrawerBodyWrapper>
				<Select
					label={formState.fromOrg.label}
					id={formState.fromOrg.id}
					placeholder={formState.fromOrg.placeholder}
					value={formState.fromOrg.value}
					onChange={onChange('fromOrg')}
					data={_orgData}
					displayKey='display_name'
					primaryKey='organisation_id'
				/>
				<Select
					label={formState.toOrg.label}
					id={formState.toOrg.id}
					placeholder={formState.toOrg.placeholder}
					value={formState.toOrg.value}
					onChange={onChange('toOrg')}
					data={_orgData}
					displayKey='display_name'
					primaryKey='organisation_id'
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small disabled={disabled} onClick={mergeOrganisationHandler}>
					{strings('merge')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default MergeOrganisation
