import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, SectionHeader, Select, TOAST_TYPE } from '@base'
import { InventoryTypes, InventoryTypesList, strings } from '@constants'
import { useEffect } from 'react'
import FactoryWaste from '@data/model/NewModals/CloudFactory/FactoryWaste'
import { getBrands, getCategories, getProductByCategory, updateFabricationOrderRemainingInventory } from '@data'
import { decimalSeparatedNumber } from '@utils'
import { Spacings } from '@styles'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { showLoader, showToast } from '@data/state/action'
import { FormFieldWrapper, FormSection, GridWrapper } from '../../../styles'

const ManageOrderWasteItem = ({ data, order, update, view }) => {
	const edit = !!data
	const dispatch = useDispatch()

	const [wasteItem, setWasteItem] = useState()
	const [categories, setCategories] = useState([])
	const [products, setProducts] = useState([])
	const [brands, setBrands] = useState([])

	const inventoryType = wasteItem?.get('inventory_type')
	const isStandard = inventoryType === InventoryTypes.STANDARD
	const isNonStandard = inventoryType === InventoryTypes.NON_STANDARD
	const isScrap = inventoryType === InventoryTypes.SCRAP

	const selectedInputOrderItem = useMemo(() => {
		if (!wasteItem || !order) return {}

		return order?.input_order_items.find((_iot) => _iot.batch_number === wasteItem.get('batch_number')) ?? {}
	}, [order, wasteItem])

	const refNumbers = (selectedInputOrderItem.mb_reference_number ?? []).map((_rn) => ({ value: _rn, id: _rn }))

	const categoryName = wasteItem?.get('category_name')

	useEffect(() => {
		setWasteItem(new FactoryWaste(data))
	}, [data])

	useEffect(() => {
		if (view) return

		getCategories(0, 100).then((response) => {
			setCategories(response.data)
		})
		getBrands().then((response) => {
			setBrands(
				response.map((_b) => ({
					name: _b,
					id: _b.split(' ').join('_').toLowerCase(),
				}))
			)
		})
	}, [])

	useEffect(() => {
		if (view || !categories || categories.length === 0) return

		if (categoryName) {
			const categoryId = categories.find((_c) => _c.category_name === categoryName)?.category_id
			getProductByCategory(categoryId).then((response) => {
				setProducts(response)
			})
		} else {
			setProducts([])
		}
	}, [categoryName, categories])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: view ? strings(['view', 'order', 'waste']) : edit ? strings(['Edit', 'order', 'waste']) : strings(['Add', 'order', 'waste']),
			})
		)
	}, [dispatch, view, edit])

	const updateItemBulk = (updates) => setWasteItem((_i) => _i.update({ ...updates }))

	const updateItem = (key) => (value) => {
		setWasteItem((_wi) => _wi.update({ [key]: value }))
	}

	const inventoryTypeHandler = (val) => {
		const _newItem = new FactoryWaste(data).update({ inventory_type: val.id })
		setWasteItem(_newItem)
	}

	const updateRefNumber = (value) => {
		updateItem('mb_reference_number')([value.id])
	}

	const updateCategory = (val) => {
		updateItemBulk({
			category_name: val.category_name,
			product_id: '',
			product_name: '',
		})
	}

	const updateProduct = (val) => {
		updateItemBulk({
			product_id: val.product_id,
			product_name: val.product_name,
		})
	}

	const batchSelectHandler = (value) => {
		const { batch_number, cast_number, category_name, product_id, product_name, brand } =
			order?.input_order_items.find((_iot) => _iot.batch_number === value.batch_number) ?? {}
		updateItemBulk({
			batch_number,
			cast_number,
			category_name,
			product_id,
			product_name,
			brand,
			quantity: '',
			total_weight: '',
			mb_reference_number: [],
		})
	}

	const closeHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const updateHander = (updatedRemainingInventory) => {
		dispatch(showLoader(true))
		updateFabricationOrderRemainingInventory(updatedRemainingInventory, order.order_id)
			.then(() => {
				dispatch(
					showToast(true, edit ? strings('updated_waste_item') : strings('added_waste_item'), {
						type: TOAST_TYPE.SUCCESS,
					})
				)
				update()
				closeHandler()
			})
			.catch((err) => {
				dispatch(
					showToast(true, edit ? strings('msg_failed_update_waste_item') : strings('msg_failed_add_waste_item'), {
						type: TOAST_TYPE.ERROR,
					})
				)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const submitHandler = () => {
		if (!wasteItem.isValid(selectedInputOrderItem)) return

		const _item = wasteItem.toJS()

		let updatedRemainingInventory

		if (edit) {
			const itemIndex = order?.remaining_inventory.find((_i) => _i.inventory_id === _item.inventory_id)
			if (itemIndex !== -1) {
				order.remaining_inventory[itemIndex] = _item
				updatedRemainingInventory = [...order.remaining_inventory]
			}
		} else {
			updatedRemainingInventory = [...(order?.remaining_inventory ?? []), _item]
		}

		updateHander(updatedRemainingInventory)
	}

	const deleteHandler = () => {
		const updatedRemainingInventory = order.remaining_inventory.filter((_i) => _i.inventory_id !== wasteItem.get('inventory_id'))
		updateHander(updatedRemainingInventory)
	}

	const renderTypeSelection = () => (
		<FormFieldWrapper>
			<Select
				disabled={view}
				placeholder={strings('select_type')}
				label={strings('inventory', 'type')}
				displayKey='value'
				primaryKey='id'
				data={InventoryTypesList}
				value={InventoryTypesList.find((_t) => _t.id === wasteItem.get('inventory_type')) ?? {}}
				onChange={inventoryTypeHandler}
			/>
		</FormFieldWrapper>
	)

	const renderBatchSelection = () => {
		if (isStandard || isNonStandard) {
			return (
				<FormFieldWrapper>
					<Select
						disabled={view}
						placeholder={strings(['select', 'batch', 'number'])}
						label={strings('batch', 'number')}
						displayKey='batch_number'
						primaryKey='inventory_id'
						data={order?.input_order_items}
						value={selectedInputOrderItem}
						onChange={batchSelectHandler}
					/>
				</FormFieldWrapper>
			)
		}
	}

	const renderProductDetails = () => {
		if ((isStandard || isNonStandard) && wasteItem.get('batch_number')) {
			return (
				<>
					<GridWrapper>
						<FormFieldWrapper>
							<Select
								disabled={isStandard || view}
								placeholder={strings('select_category')}
								label={strings('category')}
								displayKey='category_name'
								primaryKey='category_id'
								data={categories}
								value={categories.find((_c) => _c.category_name === wasteItem.get('category_name')) ?? {}}
								onChange={updateCategory}
							/>
						</FormFieldWrapper>
						<FormFieldWrapper>
							<Select
								disabled={isStandard || view}
								placeholder={strings('select_product')}
								label={strings('product')}
								displayKey='product_name'
								primaryKey='product_id'
								data={products}
								value={(products ?? []).find((_p) => _p.product_id === wasteItem.get('product_id')) ?? {}}
								onChange={updateProduct}
							/>
						</FormFieldWrapper>
					</GridWrapper>
					<GridWrapper>
						<FormFieldWrapper>
							<Select
								disabled
								placeholder={strings('select_brand')}
								label={strings('brand')}
								displayKey='name'
								primaryKey='id'
								data={brands}
								value={brands.find((_b) => _b.name === wasteItem.get('brand')) ?? {}}
							/>
						</FormFieldWrapper>
						<FormFieldWrapper>
							<Input
								disabled
								type='text'
								placeholder={strings('placeholder_enter_cast_no')}
								label={strings('cast_number')}
								value={selectedInputOrderItem.cast_number}
							/>
						</FormFieldWrapper>
					</GridWrapper>
				</>
			)
		}
	}

	const renderReferenceSelection = () => {
		if (isStandard && wasteItem.get('batch_number')) {
			return (
				<FormFieldWrapper>
					<Select
						disabled={view}
						placeholder={strings('select_mb_ref_num')}
						label={strings('mb_ref_number')}
						displayKey='value'
						primaryKey='id'
						data={refNumbers}
						value={refNumbers.find((_rn) => _rn.id === wasteItem.get('mb_reference_number')[0]) ?? {}}
						onChange={updateRefNumber}
					/>
				</FormFieldWrapper>
			)
		}
	}

	const renderWeightAndUnits = () => {
		if (isScrap || wasteItem.get('batch_number')) {
			if (isScrap) {
				return (
					<FormFieldWrapper halfWidth>
						<Input
							disabled={view}
							type='number'
							placeholder={strings('placeholder_weight_metric_tonne')}
							label={strings('total', 'label_weight_mt')}
							value={wasteItem.get('total_weight')}
							onChange={updateItem('total_weight')}
							max={selectedInputOrderItem?.total_weight}
							errorMap={{
								rangeOverflow: strings('max_canbe_mt', [decimalSeparatedNumber(selectedInputOrderItem?.total_weight)]),
							}}
						/>
					</FormFieldWrapper>
				)
			}
			return (
				<GridWrapper>
					<FormFieldWrapper halfWidth>
						<Input
							disabled={view}
							type='number'
							placeholder={strings('placeholder_weight_metric_tonne')}
							label={strings('total', 'label_weight_mt')}
							value={wasteItem.get('total_weight')}
							onChange={updateItem('total_weight')}
							max={selectedInputOrderItem?.total_weight}
							errorMap={{
								rangeOverflow: strings('max_canbe_mt', [decimalSeparatedNumber(selectedInputOrderItem?.total_weight)]),
							}}
						/>
					</FormFieldWrapper>
					<FormFieldWrapper halfWidth secondHalf>
						<Input
							disabled={view}
							type='number'
							placeholder={strings('placeholder_enter_quantity')}
							label={strings('qty')}
							value={wasteItem.get('quantity')}
							onChange={updateItem('quantity')}
							step='1'
							max={selectedInputOrderItem?.quantity}
							errorMap={{
								rangeOverflow: strings('max_canbe', [decimalSeparatedNumber(selectedInputOrderItem?.quantity)])
							}}
						/>
					</FormFieldWrapper>
				</GridWrapper>
			)
		}
	}

	const renderButtons = () => {
		if (view) {
			return (
				<Button type='secondary' small onClick={closeHandler}>
					{strings('close')}
				</Button>
			)
		}

		return (
			<>
				<Button small disabled={!wasteItem.isValid(selectedInputOrderItem)} onClick={submitHandler}>
					{edit ? strings('update', 'item') : strings('add', 'item')}
				</Button>
				<Button type='secondary' small margin={`0 ${Spacings.SPACING_3B} 0 0`} onClick={closeHandler}>
					{strings('cancel')}
				</Button>
				{edit && (
					<Button margin='0 auto 0 0' variant='destructive' type='secondary' small onClick={deleteHandler}>
						{strings('delete')}
					</Button>
				)}
			</>
		)
	}

	if (!wasteItem) return null

	return (
		<>
			<DrawerBodyWrapper>
				<FormSection>
					{renderTypeSelection()}
					{renderBatchSelection()}
					{renderReferenceSelection()}
					{renderProductDetails()}
					{renderWeightAndUnits()}
				</FormSection>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default ManageOrderWasteItem
