import { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Spacings, Text } from '@styles'
import { DrawerBodyWrapper, DrawerFooterWrapper, Button, Pill, ItemCard, AddressCard, H4, H5, Chip } from '@base'
import { decimalSeparatedNumber, displayAmount, getManager, showDateFormat } from '@utils'
import { paths } from '@routes'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { enquiryStatus, getRejectionReasonDetail, strings } from '@constants'

const PillsWrapper = styled.div`
	margin-top: ${Spacings.SPACING_4B};
`

const DateSection = styled.div`
	display: flex;
	justify-content: space-between;
`

const SectionLabel = styled(H4)`
	margin-top: ${({ extraTopMargin }) => (extraTopMargin ? Spacings.SPACING_2B : 0)};
	font-weight: ${Text.BOLD_500};
	color: ${({ theme }) => theme.palette.text.tableHeader};
	display: flex;
	align-items: center;
`

const SectionValue = styled(H5)`
	color: ${({ theme }) => theme.palette.text.tableHeader};
`

const FooterWrapper = styled(DrawerFooterWrapper)`
	flex-direction: row;
`

const ActionButton = styled(Button)`
	margin-left: auto;
`

const SectionContent = styled.div`
	padding: ${Spacings.SPACING_4B} 0;
`
const ViewOpenEnquiry = ({ enquiry }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const user = useSelector((state) => state.getIn(['user', 'user']))

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: (
					<>
						{strings('open', 'enquiry')}
						<Pill label='Enquiry Id:' value={enquiry?.enquiry_id} margin={`0 0 0 auto`} />
					</>
				),
			})
		)
	}, [dispatch])

	const enquiryItems = enquiry?.enquiry_items ?? []
	const totalWeight = enquiryItems?.reduce((acc, item) => acc + item.required_weight, 0) ?? 0

	const _renderData = useMemo(() => {
		const myQuote = (enquiry?.all_supplier_quotes ?? []).find((_quote) => _quote.supplier?.user_id === user?.user_id)
		const quoteType = myQuote?.quote_type
		return {
			manager: getManager(enquiry?.relationship_manager, 'supply'),
			myQuote,
			enquiryStatus: enquiry?.enquiry_status,
			quoteAmount: myQuote
				? myQuote.supplier_quote.reduce((acc, item) => {
						if (quoteType.toLowerCase() === 'ex') return acc + item.ex_price * item.serviceable_weight
						return acc + item.for_price * item.serviceable_weight
				  }, 0)
				: 0,
		}
	}, [enquiry, user])

	const renderEnquiryItemsList = () => {
		return enquiryItems.map((item, index) => {
			const itemData = [
				{
					label: strings('category'),
					value: item?.category_name,
				},
				{
					label: strings('product'),
					value: item?.product_name ?? '--',
					wrap: true,
				},
				{
					label: strings('weight_mt'),
					value: decimalSeparatedNumber(item?.required_weight / 1000),
				},
				{
					label: strings('brands'),
					value: item?.brands?.join(', ') || item?.brand,
				},
				{
					label: strings('tc_reqd'),
					value: item?.tc_required ? strings('yes') : strings('No'),
				},
				{
					label: strings('message'),
					value: item?.message ?? '--',
					isMessage: true,
				},
			]
			return <ItemCard small data={itemData} id={item?.id} key={`enquiry-item-${index}`} />
		})
	}

	const buttonClick = () => {
		dispatch(toggleDrawer(false))
		navigate(_renderData?.myQuote ? paths.edit_enquiry_quote(enquiry?.enquiry_id) : paths.submit_enquiry_quote(enquiry?.enquiry_id))
	}

	const buttonText = () => {
		return _renderData?.enquiryStatus === enquiryStatus.orderPlaced ? strings('view', 'quote') : strings('edit', 'quote')
	}
	return (
		<>
			<DrawerBodyWrapper>
				<SectionLabel>{strings('items')}</SectionLabel>
				<PillsWrapper>
					<Pill label={`${strings('total_items')}:`} value={enquiryItems?.length} />
					<Pill
						label={`${strings('total', 'weight_mt')}:`}
						value={decimalSeparatedNumber(totalWeight / 1000, { default: 0 })}
						margin={`0 0 0 ${Spacings.SPACING_2B}`}
					/>
				</PillsWrapper>
				<SectionContent>{renderEnquiryItemsList()}</SectionContent>
				<SectionLabel>{strings('delivery', 'location')}</SectionLabel>
				<SectionContent>
					<AddressCard data={enquiry?.shipping_address} showAddress={false} />
				</SectionContent>
				<DateSection>
					{/* <div>
						<SectionLabel extraTopMargin>Enquiry Date</SectionLabel>
						<SectionContent>
							<SectionValue>{showDateFormat(new Date(enquiry?.enquiry_date))}</SectionValue>
						</SectionContent>
					</div> */}
					<div>
						<SectionLabel extraTopMargin>{strings('expiry', 'date')}</SectionLabel>
						<SectionContent>
							<SectionValue>{showDateFormat(new Date(enquiry?.expiry_date))}</SectionValue>
						</SectionContent>
					</div>
				</DateSection>
				<SectionLabel extraTopMargin>{strings('quotations', 'info')}</SectionLabel>
				<SectionContent>
					{
						_renderData?.myQuote ? (
							<Pill
								label={`${strings('quotation_amount')}:`}
								value={displayAmount(_renderData?.quoteAmount)}
								message={strings(['(', 'excluding', 'gst', ')'])}
							/>
						) : (
							'-'
						)
						// (
						// 	<Pill label='Quotations submitted: ' value={enquiry?.all_supplier_quotes?.length ?? 0} />
						// )
					}
				</SectionContent>
				<SectionLabel extraTopMargin>{strings('concerned_manager')}</SectionLabel>
				<SectionContent>
					<SectionValue>{_renderData?.manager?.full_name}</SectionValue>
					<SectionValue>
						<small>{_renderData?.manager?.mobile_number}</small>
					</SectionValue>
				</SectionContent>
			</DrawerBodyWrapper>
			<FooterWrapper>
				{_renderData?.myQuote?.rejection_reason && (
					<Chip status='expired' label={getRejectionReasonDetail(_renderData?.myQuote?.rejection_reason)} />
				)}
				<ActionButton small uppercase onClick={buttonClick}>
					{_renderData?.myQuote ? buttonText() : strings('submit', 'quote')}
				</ActionButton>
			</FooterWrapper>
		</>
	)
}

export default ViewOpenEnquiry
