import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Spacings } from '@styles'
import { Address } from '@data/model'
import { createLogisticsPrice, getAddressFromPincode, getlogisticsPrices, updateLogisticsPrice } from '@data'
import { H6, DrawerBodyWrapper, DrawerFooterWrapper, Button, SectionHeader, Input, TOAST_TYPE } from '@base'
import { showLoader, showToast } from '@data/state/action'
import { strings } from '@constants'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'

const Error = styled(H6)`
	color: ${({ theme }) => theme.palette.text.messageRed};
`

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const FormItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	${({ isOrigin }) => {
		if (isOrigin) {
			return css`
				display: flex;
				div:nth-child(1) {
					flex: 1;
				}
				div:nth-child(2) {
					flex: 3;
					margin-left: 5px;
				}
			`
		}
	}}

	& > div {
		width: 100%;
	}
`

const initialFormState = {
	originPinCode: {
		label: strings('origin'),
		placeholder: strings('enter_origin_pincode'),
		value: '',
		dirty: false,
		disabled: false,
		required: false,
	},
	origin_location: '',
	origin_error: {
		value: '',
		dirty: false,
	},
	destinationPinCode: {
		label: strings('destination'),
		placeholder: strings('enter_destination_pincode'),
		value: '',
		dirty: false,
		disabled: false,
		required: false,
	},
	destination_location: '',
	destination_error: {
		value: '',
		dirty: false,
	},
	rate_range_from: {
		label: strings('min', 'price'),
		placeholder: strings('enter_min_price'),
		value: '',
		dirty: false,
		disabled: false,
		required: false,
	},
	rate_range_to: {
		label: strings('max', 'price'),
		placeholder: strings('enter_max_price'),
		value: '',
		dirty: false,
		disabled: false,
		required: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.originPinCode.value = data?.origin?.pincode ?? ''
	derivedState.originPinCode.disabled = editable && data?.origin?.city

	derivedState.destinationPinCode.value = data?.destination?.pincode ?? ''
	derivedState.destinationPinCode.disabled = editable && data?.origin?.city

	derivedState.origin_location = data?.origin ?? ''
	derivedState.destination_location = data?.destination ?? ''

	derivedState.rate_range_from.value = data?.rate_range_from ?? ''

	derivedState.rate_range_to.value = data?.rate_range_to ?? ''

	return derivedState
}

const _getAddress = (_location) => {
	if (_location) return `${_location?.city}, ${_location?.district}, ${_location?.state}`
	return ''
}

const AddLogisticsPrice = ({ update }) => {
	const globalDispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const timeoutId = useRef()

	const [selectedRow, setSelectedRow] = useState(useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'selectedRow'])))
	const editable = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'editable'])) ?? false
	const [formState, setFormState] = useState(initialFormState)
	
	useEffect(() => {
		if (formState?.originPinCode.value && formState?.destinationPinCode.value) {
			getlogisticsPrices()
				.then((res) => {
					const _findPrice = res?.data.find(
						(item) =>
							item?.destination?.pincode === formState.destinationPinCode.value &&
							item?.origin?.pincode === formState.originPinCode.value
					)
					if (formState.rate_range_from.value !== 0) {
						setSelectedRow(_findPrice)
						_findPrice && setFormState((_oldState) => deriveFormStateFromItemData(_oldState, _findPrice, editable))
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [formState?.originPinCode, formState?.destinationPinCode])

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, selectedRow, editable))
	}, [editable])

	useEffect(() => {
		globalDispatch(
			updateDrawerMeta({
				label:
					selectedRow !== undefined ? (
						strings('update')
					) : (
						<>
							{strings('add')} {strings('logistics_price')}
						</>
					),
			})
		)
	}, [globalDispatch, selectedRow])

	const updateState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
	}

	const addButtonClick = () => {
		globalDispatch(showLoader(true))
		let apiCall = ''
		let successMsg = ''
		if (selectedRow) {
			selectedRow.destination = formState.destination_location
			selectedRow.origin = formState.origin_location
			selectedRow.rate_range_from = formState.rate_range_from.value
			selectedRow.rate_range_to = formState.rate_range_to.value
			apiCall = updateLogisticsPrice(selectedRow)
			successMsg = strings('msg_logistics_price_update')
		} else {
			const _formData = {
				destination: formState.destination_location,
				origin: formState.origin_location,
				rate_range_from: formState.rate_range_from.value,
				rate_range_to: formState.rate_range_to.value,
			}
			apiCall = createLogisticsPrice(_formData)
			successMsg = strings('msg_logistics_price_add')
		}
		apiCall
			.then((res) => {
				update()
				globalDispatch(showToast(true, successMsg, { type: TOAST_TYPE.SUCCESS }))
				globalDispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				globalDispatch(showLoader(false))
			})
	}

	const pincodeChangeHandler = (key) => (value) => {
		onChange(key)(value)
		if ((key === 'originPinCode' || key === 'destinationPinCode') && value.toString().length >= 6) {
			let _key = ''
			if (key === 'originPinCode') {
				_key = 'origin_location'
				onChange('origin_error')('')
			} else {
				_key = 'destination_location'
				onChange('destination_error')('')
			}
			getAddress(_key, value)
		}
	}

	const getAddress = (_key, _pinCode) => {
		clearTimeout(timeoutId.current)
		setLoading(true)
		getAddressFromPincode(_pinCode)
			.then((address) => {
				if (!address.city || !address.state) {
					return Promise.reject()
				}
				const _address = new Address(address)
				const newAddress = {
					city: _address?.city,
					complete_address: _address.getCityState(),
					country: _address?.country,
					district: _address?.district,
					pincode: _address?.pincode,
					state: _address?.state,
				}
				updateState(_key, { ...newAddress })
			})
			.catch(() => {
				_key === 'origin_location'
					? onChange('origin_error')(strings('err_failed_origin_pincode'))
					: onChange('destination_error')(strings('err_failed_destination_pincode'))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const enableButton = () => {
		if (formState.originPinCode.value.toString().length < 6) {
			return true
		} else if (formState.destinationPinCode.value.toString().length < 6) {
			return true
		} else if (formState.rate_range_from.value === '') {
			return true
		} else if (formState.rate_range_to.value === '') {
			return true
		} else if (Number(formState.rate_range_from.value) > Number(formState.rate_range_to.value)) {
			return true
		} else if (formState.origin_error.value !== '') {
			return true
		} else if (formState.destination_error.value !== '') {
			return true
		}
		return false
	}

	const renderForm = () => {
		return (
			<FormWrapper>
				<FormItemWrapper isOrigin>
					<Input
						type='text'
						label={formState.originPinCode?.label}
						value={formState.originPinCode?.value}
						placeholder={formState.originPinCode?.placeholder}
						min={1000}
						max={99999999}
						pattern='^[0-9-]*'
						disabled={loading || formState.originPinCode?.disabled}
						onChange={pincodeChangeHandler('originPinCode')}
						setValidity={pincodeChangeHandler('valid')}
						errorMap={{
							rangeOverflow: strings('invalid_pincode'),
							rangeUnderflow: strings('invalid_pincode'),
						}}
					/>
					<Input
						type='text'
						disabled={true}
						label={strings('origin', 'location')}
						value={_getAddress(formState?.origin_location)}
						placeholder={strings('origin', 'location')}
					/>
				</FormItemWrapper>
				{formState.origin_error?.value && <Error>{formState.origin_error?.value}</Error>}
				<FormItemWrapper isOrigin>
					<Input
						type='text'
						disabled={loading || formState.destinationPinCode?.disabled}
						label={formState.destinationPinCode?.label}
						value={formState.destinationPinCode?.value}
						placeholder={formState.destinationPinCode?.placeholder}
						min={1000}
						max={99999999}
						pattern='^[0-9-]*'
						onChange={pincodeChangeHandler('destinationPinCode')}
						setValidity={pincodeChangeHandler('valid')}
						errorMap={{
							rangeOverflow: strings('invalid_pincode'),
							rangeUnderflow: strings('invalid_pincode'),
						}}
					/>
					<Input
						type='text'
						disabled={true}
						label={strings('destination', 'location')}
						value={_getAddress(formState?.destination_location)}
						placeholder={strings('destination', 'location')}
					/>
				</FormItemWrapper>
				{formState?.destination_error?.value && <Error>{formState?.destination_error?.value}</Error>}
				<FormItemWrapper>
					<Input
						type='text'
						disabled={loading || formState.rate_range_from?.disabled}
						label={formState.rate_range_from?.label}
						value={formState.rate_range_from?.value}
						placeholder={formState.rate_range_from?.placeholder}
						onChange={onChange('rate_range_from')}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						type='text'
						disabled={loading || formState.rate_range_to?.disabled}
						label={formState.rate_range_to?.label}
						value={formState.rate_range_to?.value}
						placeholder={formState.rate_range_to?.placeholder}
						onChange={onChange('rate_range_to')}
					/>
				</FormItemWrapper>
			</FormWrapper>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>{renderForm()}</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase onClick={addButtonClick} disabled={loading || enableButton()}>
					{selectedRow !== undefined ? strings('update') : strings('add')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddLogisticsPrice
