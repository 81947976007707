import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers'
import { Map } from 'immutable'
const middlewares = [thunkMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares)

const composedEnhancers = composeWithDevTools(middlewareEnhancer)

// passing initial state for now, need to replace it will {} once we segment the reducers.
const store = createStore(rootReducer, Map(), composedEnhancers)

export default store
