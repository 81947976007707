import React from 'react'
import { Input } from '@base'
import { FormWrapper, CustomSectionHeader, FormChildItemWrapper } from '../styles'
import { FormItemWrapper } from '../../FinalReport/styles'

const Form = ({ state, updateState }) => {
	const { heading } = state
	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
	}
	return (
		<FormWrapper>
			<FormChildItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='email'
						label={state?.email?.label}
						value={state?.email?.value}
						onChange={onChange('email')}
						pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
						errorMap={{
							patternMismatch: 'Please enter a valid email ID',
						}}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='email'
						label={state?.accounts_email?.label}
						value={state?.accounts_email?.value}
						onChange={onChange('accounts_email')}
						pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
						errorMap={{
							patternMismatch: 'Please enter a valid email ID',
						}}
					/>
				</FormItemWrapper>
			</FormChildItemWrapper>
			<FormChildItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.contact?.label}
						value={state?.contact?.value}
						pattern='^\d{7}\d+$'
						errorMap={{
							patternMismatch: 'Please enter a valid mobile number',
						}}
						onChange={onChange('contact')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.accounts_contact?.label}
						value={state?.accounts_contact?.value}
						pattern='^\d{7}\d+$'
						errorMap={{
							patternMismatch: 'Please enter a valid mobile number',
						}}
						onChange={onChange('accounts_contact')}
					/>
				</FormItemWrapper>
			</FormChildItemWrapper>
			<CustomSectionHeader>{heading?.[0]}</CustomSectionHeader>
			<FormItemWrapper>
				<Input
					type='text'
					label={state?.corporate_office?.label}
					value={state?.corporate_office?.value}
					onChange={onChange('corporate_office')}
				/>
			</FormItemWrapper>
			<CustomSectionHeader>{heading?.[1]}</CustomSectionHeader>
			<FormItemWrapper>
				<Input type='text' label={state?.site?.label} value={state?.site?.value} onChange={onChange('site')} />
			</FormItemWrapper>
			<CustomSectionHeader>{heading?.[2]}</CustomSectionHeader>
			<FormItemWrapper>
				<Input
					type='text'
					label={state?.basis_industry_classification?.label}
					value={state?.basis_industry_classification?.value}
					onChange={onChange('basis_industry_classification')}
				/>
			</FormItemWrapper>
			<CustomSectionHeader>{heading?.[3]}</CustomSectionHeader>
			<FormItemWrapper>
				<Input
					type='text'
					label={state?.claimed_turnover?.label}
					value={state?.claimed_turnover?.value}
					onChange={onChange('claimed_turnover')}
				/>
			</FormItemWrapper>
			<CustomSectionHeader>{heading?.[4]}</CustomSectionHeader>
			<FormChildItemWrapper isJustifyContent>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.supplier_payment_terms?.label}
						value={state?.supplier_payment_terms?.value}
						onChange={onChange('supplier_payment_terms')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.supplier_monthly_procurement?.label}
						value={state?.supplier_monthly_procurement?.value}
						onChange={onChange('supplier_monthly_procurement')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.supplier_past_issues?.label}
						value={state?.supplier_past_issues?.value}
						onChange={onChange('supplier_past_issues')}
					/>
				</FormItemWrapper>
			</FormChildItemWrapper>
			<CustomSectionHeader>{heading?.[5]}</CustomSectionHeader>
			<FormChildItemWrapper isJustifyContent>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.supplier_2_payment_terms?.label}
						value={state?.supplier_2_payment_terms?.value}
						onChange={onChange('supplier_2_payment_terms')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.supplier_2_monthly_procurement?.label}
						value={state?.supplier_2_monthly_procurement?.value}
						onChange={onChange('supplier_2_monthly_procurement')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.supplier_2_past_issues?.label}
						value={state?.supplier_2_past_issues?.value}
						onChange={onChange('supplier_2_past_issues')}
					/>
				</FormItemWrapper>
			</FormChildItemWrapper>
			<CustomSectionHeader>{heading?.[6]}</CustomSectionHeader>
			<FormChildItemWrapper isJustifyContent>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.business_vintage?.label}
						value={state?.business_vintage?.value}
						onChange={onChange('business_vintage')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input type='text' uppercase label={state?.pep?.label} value={state?.pep?.value} onChange={onChange('pep')} />
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input type='number' label={state?.age?.label} value={state?.age?.value} onChange={onChange('age')} />
				</FormItemWrapper>
			</FormChildItemWrapper>
			<CustomSectionHeader>{heading?.[7]}</CustomSectionHeader>
			<FormChildItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.sales_team_experience_custome?.label}
						value={state?.sales_team_experience_custome?.value}
						onChange={onChange('sales_team_experience_custome')}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={state?.background_aggregators?.label}
						value={state?.background_aggregators?.value}
						onChange={onChange('background_aggregators')}
					/>
				</FormItemWrapper>
			</FormChildItemWrapper>
		</FormWrapper>
	)
}

export default Form
