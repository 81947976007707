import API from '../api'
import postUrl from './paths'

export const uploadBuyerPO = (file, buyerPoNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadBuyerPO, formData, { buyerPoNumber, orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadBuyerPoListApi = (file, buyerPoNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		file.forEach((file) => {
			formData.append('files', file)
		})
		API.post(postUrl.uploadBuyerPoList, formData, { buyerPoNumber, orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadBuyerInvoice = (file, buyerPoNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadBuyerInvoice, formData, { buyerPoNumber, orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadDocument = (file, buyerInvoiceNumber, orderId, type = 'tc', invoiceType = 'buyer') =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)

		let fileTypeURL = postUrl.uploadBuyerReceivedTc

		if (invoiceType === 'buyer') {
			if (type === 'bilti') {
				fileTypeURL = postUrl.uploadBuyerBilty
			} else if (type === 'dkt') {
				fileTypeURL = postUrl.uploadBuyerDKT
			} else if (type === 'ewaybill') {
				fileTypeURL = postUrl.uploadBuyerEwayBill
			} else if (type === 'stockPhotos') {
				fileTypeURL = postUrl.uploadSupplierProductPhotos
			} else if (type === 'delivery_receipt') {
				fileTypeURL = postUrl.uploadBuyerDeliveryReceipt
			}
		} else {
			fileTypeURL = postUrl.uploadSupplierReceivedTc
			if (type === 'bilti') {
				fileTypeURL = postUrl.uploadSupplierBilty
			} else if (type === 'dkt') {
				fileTypeURL = postUrl.uploadSupplierDKT
			} else if (type === 'ewaybill') {
				fileTypeURL = postUrl.uploadSupplierEwayBill
			} else if (type === 'stockPhotos') {
				fileTypeURL = postUrl.uploadSupplierProductPhotos
			}
		}

		let data = { orderId }
		data[invoiceType === 'buyer' ? 'buyerInvoiceNumber' : 'supplierInvoiceNumber'] = buyerInvoiceNumber
		API.post(fileTypeURL, formData, data)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadSupplierPO = (file, supplierPoNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadSupplierPO, formData, { supplierPoNumber, orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadSupplierPoListApi = (file, supplierPoNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		file.forEach((file) => {
			formData.append('files', file)
		})
		API.post(postUrl.uploadSupplierPoList, formData, { supplierPoNumber, orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadSupplierInvoice = (file, supplierPoNumber, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadSupplierInvoice, formData, { supplierPoNumber, orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadSupplierAuctionDoc = (files, type = 'tc') =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		files.forEach((file) => {
			formData.append(type === 'tc' ? 'file' : 'files', file)
		})
		let url = ''
		if (type === 'tc') {
			url = postUrl.uploadAuctionReceivedTc
		} else if (type === 'photo') {
			url = postUrl.uploadAuctionStockPhoto
		} else {
			return
		}
		API.post(url, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadFabricationInvoice = (file, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadFabricationInvoice, formData, { orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadInventoryTestCertificate = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadTestCertificate, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

	export const uploadBuyerInvoiceDocumentsApi = (invoiceNumber, files, orderId, type, invoiceType) =>
		new Promise((resolve, reject) => {
			const formData = new FormData()
			files.forEach((file) => {
				formData.append('files', file)
			})
			let fileTypeURL = invoiceType === 'buyer' ? postUrl.uploadBuyerInvoiceDocuments : postUrl.uploadSupplierInvoiceDocuments
			let data = { orderId, type }
			data[invoiceType === 'buyer' ? 'buyerInvoiceNumber' : 'supplierInvoiceNumber'] = invoiceNumber

			API.post(fileTypeURL, formData, data)
				.then((response) => {
					resolve(response.data)
				})
				.catch((err) => reject(err))
		})
