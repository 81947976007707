import API from '../api'
import getUrl from './paths'

export const getUserMessages = (user_id, source_id, organisationId, source = 'ENQUIRY') =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getUserMessages, { user_id, source_id, source, removeDefault: true, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAdminMessages = (source_id, source = 'ENQUIRY') =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAdminMessages, { source_id, source, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getNotification = (user_id) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getNotifications, { user_id, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})