class Invoice {
  constructor() {
    this.invoice_number = '';
    this.invoice_date = null; // long
    this.invoice_amount = null; //int

    this.invoice_document = null; // string
    this.bilty_document = null; //string

    this.dkt_documents = null; //list string
    this.tc_received_document = null; //list string
    this.tc_updated_document = null; //list string
  }
}

export default Invoice;
