import { randomIdGenerator } from '@utils'
import { SET_TOAST_MESSAGE } from '../actions'

const defaultOptions = {
	autoHide: true,
	closable: false,
	actions: null,
	autoHideTime: 3000,
	callActionAfterHide: false,
	type: '',
}

export const showToast =
	(open, title = '', options = {}) =>
	(dispatch, getState) => {
		const isOpen = getState().getIn(['root', 'toast', 'open'])

		if (isOpen && open) {
			options['resetTimer'] = randomIdGenerator()
		}

		dispatch({
			type: SET_TOAST_MESSAGE,
			payload: {
				title,
				open,
				options: {
					...defaultOptions,
					...options,
				},
			},
		})
	}
