import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { useDispatch } from 'react-redux'
import { strings } from '@constants'
import { getProductByCategory } from '@data'
import { showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import LogisticsItemForm from './LogisticsItemForm'
import { Devices, Spacings } from '@styles'

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

const initialFormState = {
	category: {
		label: strings('category'),
		disabled: false,
		readOnly: false,
		displayKey: 'category_name',
		primaryKey: 'category_id',
		placeholder: strings('placeholder_select_category'),
		data: [],
		value: '',
		dirty: false,
		shouldFetchProducts: { flag: true },
	},
	product: {
		label: strings('product'),
		disabled: false,
		readOnly: false,
		displayKey: 'product_name',
		primaryKey: 'product_id',
		placeholder: strings('search_product'),
		data: [],
		value: '',
		dirty: false,
	},
	requiredWeight: {
		label: strings(['weight', '(', 'mt', ')']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	brand: {
		label: strings('brand'),
		disabled: false,
		readOnly: false,
		displayKey: 'name',
		primaryKey: 'id',
		placeholder: strings('select_a_brand'),
		data: [],
		value: '',
		dirty: false,
	},
	message: {
		label: strings('message'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	tcRequired: {
		label: strings('tc_reqd'),
		disabled: false,
		value: '',
		dirty: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.category.value = { category_id: data.category_id, category_name: data.category_name }
	derivedState.category.disabled = !editable
	derivedState.category.readOnly = !editable

	derivedState.product.value = { product_id: data.product_id, product_name: data.product_name }
	derivedState.product.disabled = !editable
	derivedState.product.readOnly = !editable

	derivedState.requiredWeight.value = data.required_weight ?? ''
	derivedState.requiredWeight.disabled = !editable
	derivedState.requiredWeight.readOnly = !editable

	derivedState.brand.value = { name: data.brand, id: data.brand?.toLowerCase() }
	derivedState.brand.disabled = !editable
	derivedState.brand.readOnly = !editable
	derivedState.message.value = data.message ?? ''
	derivedState.message.disabled = !editable
	derivedState.message.readOnly = !editable
	derivedState.tcRequired.value = data.tc_required ?? ''
	derivedState.tcRequired.disabled = !editable

	return derivedState
}

const CreateLogisticsItem = ({
	itemId,
	editable = true,
	getState,
	addNewItemToEnquiryCallback,
	deleteEnquiryItemCallback,
	updateEnquiryItemCallback,
}) => {
	const dispatch = useDispatch()
	const categories = getState().categories
	const brands = getState().brands
	const logisticitems = getState()?.newLogistic.get(['logistic_items'])

	const lastCategoryProductsFetched = useRef()

	const item = logisticitems?.find((_item) => _item.get('id') === itemId)?.toJS() ?? {}

	const [formState, setFormState] = useState(initialFormState)
	const disableButton =
		!formState.category.value?.category_id ||
		!formState.product?.data?.length ||
		!formState.product.value?.product_id ||
		!formState.requiredWeight.value ||
		!formState.brand.value?.name

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	useEffect(() => {
		lastCategoryProductsFetched.current = undefined
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, item, !!editable))
	}, [editable])

	const updateCategoryState = () => {
		if (categories?.data?.data) {
			const data = categories?.data?.data
			const updates = { data }
			if (item?.category_id) {
				updates.value = data.find((_item) => _item.category_id === item.category_id)
				updates.shouldFetchProducts = { flag: true }
			}
			updateFormState('category', updates)
		}
	}

	useEffect(() => {
		updateCategoryState()
	}, [categories, editable])

	useEffect(() => {
		if (brands?.data) {
			if (!formState.brand.value?.id) {
				updateFormState('brand', { data: brands?.data, value: brands?.data[0] })
			} else {
				updateFormState('brand', { data: brands?.data })
			}
		}
	}, [brands])

	useEffect(() => {
		if (formState.category.dirty) {
			updateFormState('product', { value: '', data: [], dirty: false })
		}
		const categoryId = formState.category.value?.category_id
		if (categoryId && categoryId !== lastCategoryProductsFetched.current) {
			lastCategoryProductsFetched.current = categoryId
			getProductByCategory(categoryId).then((data) => {
				const updates = { data, value: '' }
				if (item?.product_id && categoryId === item?.category_id) {
					updates.value = data?.find((_item) => item.product_id === _item.product_id) || formState.product.value
				}
				updateFormState('product', updates)
			})
		}
	}, [formState.category.value, formState.category.shouldFetchProducts])

	useEffect(() => {
		if (formState.product.dirty) {
			const selectedProduct = formState.product.value

			if (selectedProduct?.product_id === item?.product_id) {
				return
			}

			const sameProductItemsLength = logisticitems?.filter((item) => item.get('product_id') === selectedProduct?.product_id).size
			if (sameProductItemsLength > 0) {
				dispatch(showToast(true, strings('msg_item_already_added'), { type: TOAST_TYPE.ERROR }))
				updateFormState('product', { value: '' })
			}
		}
	}, [formState.product.value])

	const formStateToEnquiryItem = () => {
		return {
			category_id: formState.category.value.category_id,
			category_name: formState.category.value.category_name,
			product_id: formState.product.value.product_id,
			product_name: formState.product.value.product_name,
			required_weight: formState.requiredWeight.value === '' ? '' : parseFloat(formState.requiredWeight.value),
			brand: formState.brand.value?.name,
			message: formState.message.value,
			tc_required: formState.tcRequired.value,
			is_unknown_product: formState.product.value.is_new_item,
		}
	}

	const addNewItemHandler = () => {
		const newEnquiryItemData = formStateToEnquiryItem()
		if (newEnquiryItemData.required_weight <= 0) {
			dispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (newEnquiryItemData) {
			addNewItemToEnquiryCallback(newEnquiryItemData)
			dispatch(toggleDrawer(false))
		}
	}

	const cancelEditHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const updateHandler = () => {
		const updatedEnquiryItemData = formStateToEnquiryItem()
		if (updatedEnquiryItemData.required_weight <= 0) {
			dispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (updatedEnquiryItemData) {
			updateEnquiryItemCallback(itemId, updatedEnquiryItemData)
			dispatch(toggleDrawer(false))
		}
	}

	const deleteButtonHandler = () => {
		deleteEnquiryItemCallback(itemId)
		dispatch(toggleDrawer(false))
	}

	const renderButtons = () => {
		if (!itemId && editable) {
			return (
				<ActionButton small type='primary' disabled={disableButton} onClick={addNewItemHandler}>
					{strings('add_item')}
				</ActionButton>
			)
		} else if (itemId && editable) {
			return (
				<>
					<ActionButton small disabled={disableButton} type='primary' onClick={updateHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
					<ActionButton margin={`0 auto 0 0`} small type='primary' variant='destructive' onClick={deleteButtonHandler}>
						{strings('delete')}
					</ActionButton>
				</>
			)
		}
	}

	return (
		<>
			<DrawerBodyWrapper>
				<LogisticsItemForm state={formState} updateState={updateFormState} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default CreateLogisticsItem
