import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DrawerBodyWrapper, DrawerFooterWrapper, TimeLine } from '@base'
import { strings } from '@constants'
import { Call, CheckCircle, DoubleArrow, Duo, EditOutlined, EventNote, Face, Videocam } from '@material-ui/icons'
import { showDateFormat } from '@utils'
import { getJourneyBySourceId } from '@data'

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const JourneyDiv = styled.div`
	& :nth-child(1) {
		font-weight: bold;
	}
	& :nth-child(2) {
	}
`
const LeadJourney = ({ orgId, leadId }) => {
	const [journeyList, setJourneyList] = useState()
	useEffect(() => {
		getJourneyBySourceId(leadId)
			.then((response) => {
				setJourneyList(
					response?.journey_data?.map((item) => {
						return {
							...item,
							action: item.action.replace(/_/g, ' ').includes('STATUS') ? 'STATUS CHANGED' : item.action.replace(/_/g, ' '),
							date: item.date,
							journeyState: LeadJourneyState(item),
						}
					})
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const LeadJourneyState = (data) => {
		switch (data?.action) {
			case 'LEAD_CREATED':
				return {
					text: `Lead has been created${data?.by ? ' by ' + data?.by?.full_name || data?.by?.user_id : ''}.`,
					icon: <CheckCircle />,
				}
			case 'MANAGER_UPDATED':
				return {
					text: `Manager updated `,
					icon: <Face />,
				}
			case 'COLD_CALL_SCHEDULED':
				return {
					text: (
						<>
							<JourneyDiv>
								<span>{strings('title', ': ')}</span>
								<span>{data?.task?.title}</span>
							</JourneyDiv>
							{data?.task?.description && (
								<JourneyDiv>
									<span>{strings('description', ': ')}</span>
									<span>{data?.task?.description}</span>
								</JourneyDiv>
							)}
							<JourneyDiv>
								<span>{strings(['scheduled', 'at', ': '])}</span>
								<span>{showDateFormat(new Date(data?.task?.date))}</span>
							</JourneyDiv>
							<JourneyDiv>
								<span>{strings(['meeting', 'mode', ': '])}</span>
								<span>{data?.task?.meeting_mode}</span>
							</JourneyDiv>
						</>
					),
					icon: <Call />,
				}
			case 'MEETING_SCHEDULED':
				return {
					text: (
						<>
							<JourneyDiv>
								<span>{strings('title', ': ')}</span>
								<span>{data?.task?.title}</span>
							</JourneyDiv>
							{data?.task?.description && (
								<JourneyDiv>
									<span>{strings('description', ': ')}</span>
									<span>{data?.task?.description}</span>
								</JourneyDiv>
							)}
							<JourneyDiv>
								<span>{strings(['scheduled', 'at', ': '])}</span>
								<span>{showDateFormat(new Date(data?.task?.date))}</span>
							</JourneyDiv>
							<JourneyDiv>
								<span>{strings(['meeting', 'mode', ': '])}</span>
								<span>{data?.task?.meeting_mode}</span>
							</JourneyDiv>
						</>
					),
					icon: <Videocam />,
				}
			case 'FOLLOW_UP_SCHEDULED':
				return {
					text: (
						<>
							<JourneyDiv>
								<span>{strings('title', ': ')}</span>
								<span>{data?.task?.title}</span>
							</JourneyDiv>
							{data?.task?.description && (
								<JourneyDiv>
									<span>{strings('description', ': ')}</span>
									<span>{data?.task?.description}</span>
								</JourneyDiv>
							)}
							<JourneyDiv>
								<span>{strings(['scheduled', 'at', ': '])}</span>
								<span>{showDateFormat(new Date(data?.task?.date))}</span>
							</JourneyDiv>
							<JourneyDiv>
								<span>{strings(['meeting', 'mode', ': '])}</span>
								<span>{data?.task?.meeting_mode}</span>
							</JourneyDiv>
						</>
					),
					icon: <EventNote />,
				}

			case 'NOTE_ADDED':
				return {
					text: (
						<>
							<JourneyDiv>
								<span>{strings('note', ': ')}</span>
								<span>{data?.note?.text}</span>
							</JourneyDiv>
							<JourneyDiv>
								<span>{strings(['added', 'by', ': '])}</span>
								{/* by or editor? */}
								<span>{data?.note?.editor?.full_name || '-'}</span>
							</JourneyDiv>
						</>
					),
					icon: <EditOutlined />,
				}

			case 'CLOSURE_DATE_UPDATED':
				return {
					text: `Closure date updated`,
					icon: <EditOutlined />,
				}
			default:
				return {
					text: data?.action.replace(/_/g, ' '),
					icon: <DoubleArrow />,
				}
		}
	}
	return (
		<>
			<DrawerBodyWrapper>
				<BodyWrapper>
					<TimeLine data={journeyList} />
				</BodyWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper></DrawerFooterWrapper>
		</>
	)
}

export default LeadJourney
