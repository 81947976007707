import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, H5, Select, Textarea, TOAST_TYPE } from '@base'
import { Devices, Spacings, Text } from '@styles'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { addFinancialsRemark } from '@data'

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const Footer = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: initial;
	}
`
const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`
const REMARK_TYPE = {
	CREDIT_COMMITTEE: 'CREDIT COMMITTEE',
	CREDIT_RISK_HEAD: 'CREDIT RISK HEAD',
	CREDIT_ANALYST: 'CREDIT ANALYST',
}

const remarkTypes = Object.entries(REMARK_TYPE).map(([key, value]) => ({ key, label: value }))
const remarkState = (userDetails) => {
	const _state = {
		remark: {
			id: 'remark',
			label: strings('remark'),
			placeholder: strings('enter', 'remark'),
			value: '',
			required: true,
		},
		type: {
			id: 'remark-type',
			label: strings('remark', 'type'),
			data: remarkTypes,
			placeholder: strings(['select', 'remark', 'type']),
			value: remarkTypes[0],
			primaryKey: 'key',
			displayKey: 'label',
			required: true,
		},
		remark_by: userDetails,
		created_at: new Date().getTime(),
	}

	return _state
}
const Remark = ({ organisation_id, update }) => {
	const dispatch = useDispatch()
	const user = useSelector((_state) => _state.getIn(['user', 'user']))
	const [formState, setFormState] = useState(remarkState(user.getUserDetails()))

	const updateState = (key) => (val) => {
		setFormState((_s) => ({
			..._s,
			[key]: {
				..._s[key],
				value: val,
			},
		}))
	}
	const onClickSave = () => {
		dispatch(showLoader(true))
		addFinancialsRemark(organisation_id, formState?.remark_by, formState.type?.value?.key, formState?.remark?.value, formState?.created_at)
			.then(() => {
				update()
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, strings('msg_financials_remark_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => console.log(err))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const disableButton = !formState?.remark?.value || !Object.entries(formState.type?.value).keys()

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<SectionItemWrapper></SectionItemWrapper>
					<SectionItemWrapper>
						<Select
							id={formState.type?.id}
							label={formState.type?.label}
							placeholder={formState.type?.placeholder}
							data={formState.type?.data}
							displayKey={formState.type?.displayKey}
							primaryKey={formState.type?.primaryKey}
							value={formState.type?.value}
							onChange={updateState('type')}
						/>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<Textarea
							id={formState.remark.id}
							type='text'
							rows={4}
							label={formState.remark.label}
							placeholder={formState.remark.placeholder}
							value={formState.remark.value}
							onChange={updateState('remark')}
						/>
					</SectionItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<Footer>
				<ActionButton small type='primary' disabled={disableButton} onClick={onClickSave}>
					{strings('save')}
				</ActionButton>
			</Footer>
		</>
	)
}

export default Remark
