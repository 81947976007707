import API from '../api'
import postUrl from './paths'

export const generateOTP = (aadhaarNo, consent) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.generateAadhaarOTP, null, { aadhaarNo, consent })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const verifyOTP = (aadhaarNo, consent, otp, organisationId, requestId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.verifyAadhaarOTP, null, { aadhaarNo, consent, otp, organisationId, requestId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadKYCDocument = (file, docType, organisationId, typeOfProperty = null) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		file && formData.append('files', file)
		API.post(postUrl.uploadKYCDocument, formData, { docType, organisationId, typeOfProperty, timeout: 300000 })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const panVerification = (consent, pan, organisationId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.verifyPan, null, { consent, pan, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
