import API from '../api'
import convertPagableData from '../convertPagableData'
import getUrl from './paths'

export const getAllLeadOrganisations = (page = 0, empId = "", leadFilters) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getAllLeadOrganisations, { page, empId, ...leadFilters })
            .then((response) => {
                resolve(convertPagableData(response.data))
            }).catch((err) => {
                reject(err)
            })
    })

export const getLeadById = (leadId) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getLeadById, { leadId })
            .then((response) => {
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })


export const getLeadJourneyByOrgId = (organisationId) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getLeadJourneyByOrgId, { organisationId })
            .then((response) => {
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })

export const getLeadsByOrgId = (orgId, page, size) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getLeadsByOrgId, { orgId, page, size })
            .then((response) => {
                resolve(convertPagableData(response.data))
            })
            .catch((err) => {
                reject(err)
            })
    })


export const downloadLeads = (leadFilters) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.downloadLeads, { ...leadFilters })
            .then((response) => {
                resolve(response.data)
            })
            .catch((err) => reject(err))
    })

