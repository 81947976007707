import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, TOAST_TYPE } from '@base'
import AddAddress from './AddAddress'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { addGstToOrganisation } from '@data'
import OrgGstDetail from '@data/model/NewModals/OrgGstDetail'

const AddGST = ({ orgId, update }) => {
	const dispatch = useDispatch()
	const [address, setAddress] = useState({})
	const [gst, setGst] = useState({})
	const [enableButton, setEnableButton] = useState({})

	const onGSTChange = (key) => (value) => {
		setGst((gst) => ({
			...gst,
			[key]: value,
		}))
	}

	const addressCb = useCallback(({ address, enableButton }) => {
		if (address) setAddress(address)
		if (enableButton !== undefined) setEnableButton(enableButton)
	}, [])

	const addGSTHandler = () => {
		if (!gst?.valid || !enableButton) return

		dispatch(showLoader(true))

		const gstDetail = new OrgGstDetail().update({
			address,
			gst: gst.value,
		})

		addGstToOrganisation(gstDetail.toJS(), orgId)
			.then(() => {
				dispatch(showToast(true, 'Successfully added new GST details', { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while adding new gst details', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<Input
					label={strings('gstin')}
					id='org-gstin'
					type='text'
					pattern='\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}'
					errorMap={{
						patternMismatch: strings('enter_digit_gstin'),
						valueMissing: strings('gstin_required'),
					}}
					value={gst?.value ?? ''}
					onChange={onGSTChange('value')}
					placeholder={strings('digit_gstin')}
					setValidity={onGSTChange('valid')}
					uppercase
				/>
				<AddAddress addressCb={addressCb} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={!gst?.valid || !enableButton} onClick={addGSTHandler}>
					{strings('add', 'details')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddGST
