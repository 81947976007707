import HourGlass from '@assets/hourglass.gif'
import { Text } from '@styles'
import {logout} from '@utils'
import {useEffect} from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: #d14855;
    font-size: ${Text.X_LARGE};
    font-style: ${Text.BOLD_500};
`

const UnderMaintenance = () => { 
    const navigate = useNavigate()
    
    useEffect(() => { 
        logout(navigate, false)
    }, [])

    return (
        <MainWrapper>
            <img src={HourGlass} width='100%' />
        </MainWrapper>
    )
}

export default UnderMaintenance