const unit = 'rem'

export const BASE = 0.25
export const SPACING_0B = `0${unit}`
export const SPACING_1 = `${BASE / 4}${unit}`
export const SPACING_2 = `${BASE / 2}${unit}`
export const SPACING_6 = `${(BASE * 3) / 2}${unit}`
export const SPACING_10 = `${(BASE * 5) / 2}${unit}`
export const SPACING_1B = `${BASE}${unit}`
export const SPACING_2B = `${2 * BASE}${unit}`
export const SPACING_3B = `${3 * BASE}${unit}`
export const SPACING_4B = `${4 * BASE}${unit}`
export const SPACING_5B = `${5 * BASE}${unit}`
export const SPACING_6B = `${6 * BASE}${unit}`
export const SPACING_7B = `${7 * BASE}${unit}`
export const SPACING_8B = `${8 * BASE}${unit}`
export const SPACING_9B = `${9 * BASE}${unit}`
export const SPACING_10B = `${10 * BASE}${unit}`
export const SPACING_11B = `${11 * BASE}${unit}`
export const SPACING_12B = `${12 * BASE}${unit}`
export const SPACING_13B = `${13 * BASE}${unit}`
export const SPACING_14B = `${14 * BASE}${unit}`
export const SPACING_15B = `${15 * BASE}${unit}`
export const SPACING_16B = `${16 * BASE}${unit}`
export const SPACING_17B = `${17 * BASE}${unit}`
export const SPACING_18B = `${18 * BASE}${unit}`
export const SPACING_20B = `${20 * BASE}${unit}`
export const SPACING_21B = `${21 * BASE}${unit}`
export const SPACING_22B = `${22 * BASE}${unit}`
export const SPACING_24B = `${24 * BASE}${unit}`
export const SPACING_32B = `${32 * BASE}${unit}`
