import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Input } from '@base'
import { strings } from '@constants'
import { Checkbox, TOAST_TYPE } from '@base'
import { panVerification } from '@data'
import { showLoader, showToast } from '@data/state/action'
import {
	ActionButton,
	ActionButtonWapper,
	BodySectionWrapper,
	ButtonIcon,
	ButtonIconWapper,
	FooterWrapper,
	FormElementLabel,
	SectionItemWrapper,
	SectionItemWrapperWithTwoItems,
} from '../styles'
import ActiveGst from './ActiveGst'
import RejectReason from '../RejectReason'
import styled from 'styled-components'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons'

const BodyWrapper = styled.div``

const initialFormState = {
	pan: {
		label: strings('pan', 'number'),
		disabled: false,
		readOnly: false,
		placeholder: strings(['enter', 'pan', 'number']),
		value: '',
		dirty: false,
	},
	consent: {
		label: strings('provide_consent_pan'),
		disabled: false,
		value: true,
		dirty: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }
	derivedState.pan.value = data?.pan
	derivedState.pan.disabled = !editable

	derivedState.consent.value = data?.consent ?? true
	derivedState.consent.disabled = !editable

	return derivedState
}

const PanCard = ({ editable = true, nextStep, org, adminView, kycStatushandler, selectedStep }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(initialFormState)
	const [activeGstList, setActiveGstList] = useState({ active_gsts: [] })
	const [isActiveReason, setIsActiveReason] = useState(false)

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, org, !!editable))
		org?.pan_verification_details && setActiveGstList({ active_gsts: org?.pan_verification_details?.active_gsts })
	}, [org])

	const updateState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const onChange = (key) => (value) => {
		if (key === 'pan') {
			updateState(key, { value: value.toUpperCase(), dirty: true })
		} else {
			updateState(key, { value, dirty: true })
		}
	}

	const onCheckBoxChange = (e) => onChange('consent')(e.target.checked)

	const addPanCard = () => {
		dispatch(showLoader(true))
		panVerification('Y', formState.pan.value, org?.user?.organisation_id)
			.then((response) => {
				setActiveGstList(response)
				dispatch(showToast(true, strings('pancard_upload_success'), { type: TOAST_TYPE.SUCCESS }))
				setTimeout(() => {
					nextStep()
				}, 2000)
			})
			.catch((err) => {
				dispatch(showToast(true, strings('error_something_went_wrong'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const footerHandler = () => {
		return (
			<>
				<BodySectionWrapper isSetHeight={adminView}>
					<SectionItemWrapper isFullHeight>{adminView && <ActiveGst data={activeGstList?.active_gsts} />}</SectionItemWrapper>
				</BodySectionWrapper>
				<FooterWrapper>
					<SectionItemWrapper isStatement>
						<ButtonIconWapper>
							<ButtonIcon type='secondary' small uppercase disabled={true}>
								<ChevronLeftOutlined />
							</ButtonIcon>
							<ButtonIcon
								type='secondary'
								small
								margin={'0px 0px 0px 5px'}
								uppercase
								onClick={() => {
									nextStep()
								}}
							>
								<ChevronRightOutlined />
							</ButtonIcon>
						</ButtonIconWapper>
						<ActionButtonWapper>
							<Button
								xs
								type='secondary'
								variant='destructive'
								onClick={() => {
									setIsActiveReason(true)
								}}
							>
								{strings('reject')}
							</Button>
							<Button
								xs
								type='primary'
								margin='0px 0px 0px 3px'
								onClick={() => {
									kycStatushandler('', selectedStep, true, 'Pan Card request accepted')
								}}
							>
								{strings('accept')}
							</Button>
						</ActionButtonWapper>
					</SectionItemWrapper>
					{isActiveReason && (
						<RejectReason
							kycStatushandler={kycStatushandler}
							setIsActiveReason={setIsActiveReason}
							setpNumber={selectedStep}
							stepName='Pan Card'
							rejectReasonMsg={org?.kyc_reject_reason?.[0] || ''}
						/>
					)}
				</FooterWrapper>
			</>
		)
	}

	return (
		<>
			<BodyWrapper>
				<SectionItemWrapper>
					<Input
						type='text'
						disabled={formState.pan?.disabled}
						label={formState.pan?.label}
						value={formState.pan.value}
						onChange={onChange('pan')}
						maxLength='10'
						placeholder={formState.pan.placeholder}
						pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}'
						errorMap={{
							patternMismatch: strings('enter_valid_pan'),
							valueMissing: strings('pan_required'),
						}}
					/>
					<SectionItemWrapperWithTwoItems>
						<Checkbox
							id='checkbox_pan'
							size={1.125}
							disabled={formState.consent?.disabled}
							checked={formState.consent?.value}
							value={formState.consent?.value}
							onChange={onCheckBoxChange}
						/>
						<FormElementLabel disabled={formState.consent?.disabled} htmlFor='checkbox_pan'>
							{formState.consent?.label}
						</FormElementLabel>
					</SectionItemWrapperWithTwoItems>
				</SectionItemWrapper>
				<ActionButton small type='primary' disabled={!formState.consent?.value || formState.pan.value?.length !== 10} onClick={addPanCard}>
					{strings('confirm')}
				</ActionButton>
			</BodyWrapper>
			{adminView && footerHandler()}
		</>
	)
}

export default PanCard
