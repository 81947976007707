import API from '../api'
import putUrl from '../put/paths'

export const editAuction = (auction, user) =>
	new Promise((resolve, reject) => {
		auction.updated_by = user?.user_id
		auction.updated_At = new Date().getTime()
		API.put(putUrl.editAuction, auction, {})
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const submitBuyerBid = (auctionId, bidQuote, user) =>
	new Promise((resolve, reject) => {
		bidQuote.buyer = user.getUserDetails()
		bidQuote.buyer.is_primary = true
		API.put(putUrl.submitBuyerBid, bidQuote, { auctionId, newAddressAdded: bidQuote.is_new_address })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const submitSupplierBid = (auctionId, quote, finalQuoteSubmitted) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.submitSupplierBid, quote, { auctionId, finalQuoteSubmitted })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const editBuyerBid = (auctionId, bidQuote, user) =>
	new Promise((resolve, reject) => {
		if (user) {
			bidQuote.buyer = user.getUserDetails()
			bidQuote.buyer.is_primary = true
		}
		API.put(putUrl.editBuyerBid, bidQuote, { auctionId, newAddressAdded: bidQuote.is_new_address })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})
