import API from "../api";
import deleteUrl from "./paths";

export const deleteGlossary = (glossaryId) =>
    new Promise((resolve, reject) => {
        API.delete(deleteUrl.deleteGlossary, {glossaryId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })