import API from '../api'
import deleteUrl from './paths'

const deleteBillInvoice = (invoiceNumber) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteBillInvoice, { invoiceNumber })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export { deleteBillInvoice }
