import { Add, Close, DragHandle } from '@material-ui/icons'
import { useMemo } from 'react'
import styled from 'styled-components'
import { strings } from '@constants'
import { Devices, Spacings, Text } from '@styles'
import { DrawerBodyWrapper, DrawerFooterWrapper, H5, H6 } from '@base'
import { displayAmount, displayWeight } from '@utils'

const Body = styled.div`
	display: flex;
	flex-direction: column;
`
const Heading = styled(H5)`
	color: ${({ theme }) => theme.palette.text.label};
	margin-top: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_3B};

	&:first-of-type {
		margin-top: ${Spacings.SPACING_1B};
	}
`

const Item = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.main};
	border-radius: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`

const ProductName = styled(H6)`
	margin-bottom: ${Spacings.SPACING_2B};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.black};
`
const CalculationWrapper = styled.div`
	display: flex;
`
const Calculation = styled.div`
	flex-grow: 1;
	&:last-of-type {
		flex-basis: 35%;
		flex-grow: 0;
	}
`
const Info = styled.div`
	color: ${({ theme }) => theme.palette.text.label};
	font-size: ${Text.EXTRA_SMALL};
	line-height: ${Text.LINE_HEIGHT_MEDIUM};
`
const Value = styled.div`
	font-size: ${Text.MEDIUM};
	color: ${({ theme }) => theme.palette.text.darkblack};
`
const MathIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${Spacings.SPACING_2B};
	flex-grow: ${({ noGrow }) => (noGrow ? 0 : 1)};

	@media ${Devices.tablet} {
		flex-grow: 1;
	}

	& > svg {
		fill: ${({ theme }) => theme.palette.text.darkblack};
		font-size: ${Text.LARGE};
	}
`

const SummaryItem = styled.div`
	display: flex;
	padding: ${Spacings.SPACING_3B};
	border: 1px solid ${({ theme }) => theme.palette.background.blue};
	background: ${({ theme }) => theme.palette.background.white};
	border-radius: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
	align-items: center;
`

const TotalAmount = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;

	@media ${Devices.tablet} {
		padding: ${Spacings.SPACING_1B} 0;
	}
`

const SummaryItemCount = styled.div`
	display: flex;
	align-items: center;
	& > span {
		background: ${({ theme }) => theme.palette.background.blue};
		color: ${({ theme }) => theme.palette.text.white};
		font-size: ${Text.SMALL};
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const SummaryItemInfo = styled.div`
	font-size: ${Text.SMALL};
	flex-grow: 1;
	padding-left: ${Spacings.SPACING_2B};
`

const TotalAmountInfo = styled.div`
	font-size: ${Text.LARGE};
	display: flex;
	flex-grow: 1;
	justify-content: center;
	flex-direction: column;

	& > span {
		margin-bottom: ${Spacings.SPACING_1B};
	}

	@media ${Devices.tablet} {
		flex-direction: row;
		align-items: center;
		justify-content: initial;
		& > span {
			margin-bottom: 0;
		}
	}
`

const SummaryItemValue = styled.div`
	font-size: ${({ big }) => (big ? Text.LARGE : Text.MEDIUM)};
	flex-basis: 35%;
	font-weight: ${({ big }) => (big ? Text.BOLD_600 : 'initial')};
	color: ${({ theme }) => theme.palette.text.darkblack};
`

const SummaryCalculation = styled.div`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.palette.text.darkblack};
	& > span {
		margin: 0 ${Spacings.SPACING_1B};
	}
`

const TaxHeading = styled(Heading)`
	display: flex;

	& > span {
		margin-right: ${Spacings.SPACING_1B};
	}
`

const OrderSummary = ({ data, isPurchaseOrder, user }) => {
	const getServicedWeight = (invoices, item) => {
		return invoices.reduce(
			(total, _i) => {
				let _weight = total.weight
				if (_i.order_items) {
					_weight += _i.order_items.find((_ix) => _ix.product_id === item.product_id).serviced_weight
				}
				return { weight: _weight }
			},
			{ weight: 0 }
		).weight
	}
	const getTotalItemAmount = (dataInvoices, productId) => {
		let avg = 0
		dataInvoices?.map((item) => {
			item?.order_items?.map((_i) => {
				if (productId == _i.product_id) {
					avg += _i?.price_per_kg * _i.serviced_weight ?? 0
				}
			})
		})
		return avg
	}

	const { userData, totalItemsAmount, totalLogistics, totalGST, weightKey, dataInvoices } = useMemo(() => {
		let userData
		if (isPurchaseOrder) {
			userData = data.buyer_list.filter((item) => item.buyer.user_id === user.user_id)[0]
		} else {
			userData = data.supplier_list.filter((item) => item.supplier.user_id === user.user_id)[0]
		}

		const dataInvoices = userData[isPurchaseOrder ? 'buyer_invoices' : 'supplier_invoices'] || []
		let totalItemsAmount = 0
		let totalLogistics = 0
		let totalGST = 0

		const weightKey = isPurchaseOrder ? 'ordered_weight' : 'allotted_weight'

		if (!data) {
			return {
				userData: {},
				totalItemsAmount,
				totalLogistics,
				totalGST,
				dataInvoices,
			}
		}

		totalItemsAmount = userData?.order_items.reduce((amount, item) => amount + getTotalItemAmount(dataInvoices, item?.product_id), 0)
		if (totalItemsAmount !== 0) {
			totalLogistics =
				userData.lumpsum_logistics_quote ||
				(userData?.order_items.reduce((_weight, item) => _weight + getServicedWeight(dataInvoices, item), 0) *
					userData.logistics_quote_per_mt) /
					1000
		}

		return { userData, totalItemsAmount, totalLogistics, totalGST: (totalItemsAmount + totalLogistics) * 0.18, weightKey, dataInvoices }
	}, [data, isPurchaseOrder, user])

	const renderItemBreakup = () => {
		return (
			<>
				<Heading>{strings('items_cost')}</Heading>
				{(userData?.order_items ?? []).map((_item, index) => {
					const _ordered_weight = _item[weightKey]
					const _servicedWeight = getServicedWeight(dataInvoices, _item)
					let _total_amt = getTotalItemAmount(dataInvoices, _item?.product_id)

					return (
						<Item key={`item-breakup-${index}`}>
							<ProductName>{_item?.product_name}</ProductName>
							<CalculationWrapper>
								<Calculation>
									<Info>{strings('serviced_weight')}</Info>
									<Value>{displayWeight(_servicedWeight / 1000) + ' (' + displayWeight(_ordered_weight / 1000) + ')'}</Value>
								</Calculation>
								<MathIcon>
									<Close />
								</MathIcon>
								<Calculation>
									<Info>{strings('price_per_kg')}</Info>
									<Value>{displayAmount(_total_amt / _servicedWeight)}</Value>
								</Calculation>
								<MathIcon>
									<DragHandle />
								</MathIcon>
								<Calculation>
									<Info>{strings('amount')}</Info>
									<Value>{displayAmount(_total_amt)}</Value>
								</Calculation>
							</CalculationWrapper>
						</Item>
					)
				})}
				<SummaryItem>
					<SummaryItemCount>
						<span>1</span>
					</SummaryItemCount>
					<SummaryItemInfo>{strings('total_items_amount')}</SummaryItemInfo>
					<SummaryItemValue>{displayAmount(totalItemsAmount)}</SummaryItemValue>
				</SummaryItem>
			</>
		)
	}

	const renderLogistics = () => {
		return (
			<>
				<Heading>{strings('logistics')}</Heading>
				<SummaryItem>
					<SummaryItemCount>
						<span>2</span>
					</SummaryItemCount>
					<SummaryItemInfo>{strings('total_logistics_amount')}</SummaryItemInfo>
					<SummaryItemValue>{displayAmount(totalLogistics)}</SummaryItemValue>
				</SummaryItem>
			</>
		)
	}

	const renderTotalWithTax = () => {
		return (
			<>
				<TaxHeading>
					<span>{strings('gst', 'calculation')}</span>
					<SummaryCalculation>
						<span>[</span>
						<span>(</span>
						<SummaryItemCount>
							<span>1</span>
						</SummaryItemCount>
						<MathIcon>
							<Add />
						</MathIcon>
						<SummaryItemCount>
							<span>2</span>
						</SummaryItemCount>
						<span>)</span>
						<MathIcon>
							<Close />
						</MathIcon>
						<Value>18%</Value>
						<span>]</span>
						{/* <span>(</span>
						<SummaryItemCount>
							<span>1</span>
						</SummaryItemCount>

						<MathIcon>
							<Close />
						</MathIcon>
						<Value>18%</Value>
						<span>)</span> */}
					</SummaryCalculation>
				</TaxHeading>
				<SummaryItem>
					<SummaryItemCount>
						<span>3</span>
					</SummaryItemCount>
					<SummaryItemInfo>{strings('total_gst')}</SummaryItemInfo>
					<SummaryItemValue>{displayAmount(totalGST)}</SummaryItemValue>
				</SummaryItem>
			</>
		)
	}

	const renderTotalAmount = () => {
		return (
			<>
				<TotalAmount>
					<TotalAmountInfo>
						<span>{strings('total_amount')}</span>
						<SummaryCalculation>
							<span>(</span>
							<SummaryItemCount>
								<span>1</span>
							</SummaryItemCount>
							<MathIcon noGrow>
								<Add />
							</MathIcon>
							<SummaryItemCount>
								<span>2</span>
							</SummaryItemCount>
							<MathIcon noGrow>
								<Add />
							</MathIcon>
							<SummaryItemCount>
								<span>3</span>
							</SummaryItemCount>
							<span>)</span>
						</SummaryCalculation>
					</TotalAmountInfo>
					<SummaryItemValue big>{displayAmount(totalItemsAmount + totalLogistics + totalGST)}</SummaryItemValue>
				</TotalAmount>
			</>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<Body>
					{renderItemBreakup()}
					{renderLogistics()}
					{renderTotalWithTax()}
				</Body>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderTotalAmount()}</DrawerFooterWrapper>
		</>
	)
}

export default OrderSummary
