import { InventoryTypes } from '@constants'
import { Record } from 'immutable'

const FactoryWasteRecord = Record({
	category_name: '',
	product_id: '',
	product_name: '',
	brand: '',
	batch_number: '',
	cast_number: '',
	inventory_id: null,
	quantity: '',
	total_weight: '',
	incoming_order_id: [],
	inventory_type: '',
	mb_reference_number: [],
	mb_reference_photos: [],
	product_received_photos: [],
	test_certificate: '',
})

class FactoryWaste extends FactoryWasteRecord {
	update(updates) {
		return this.merge(updates)
	}

	isValid(orderItemData) {
		const weightAndQuantityValid = Boolean(
			typeof this.quantity === 'number' &&
				typeof this.total_weight === 'number' &&
				this.quantity <= (orderItemData?.quantity ?? Number.MAX_SAFE_INTEGER) &&
				this.total_weight <= (orderItemData?.total_weight ?? Number.MAX_SAFE_INTEGER)
		)

		const basicDetailsValid = Boolean(
			this.inventory_type && this.batch_number && this.brand && this.cast_number && this.product_id && this.product_name
		)

		if (this.inventory_type === InventoryTypes.SCRAP) {
			return Boolean(this.inventory_type && typeof this.total_weight === 'number')
		}

		if (this.inventory_type === InventoryTypes.STANDARD) {
			return Boolean(basicDetailsValid && weightAndQuantityValid && this.mb_reference_number?.length === 1)
		}

		return Boolean(basicDetailsValid && weightAndQuantityValid)
	}
}

export default FactoryWaste
