import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
	Button,
	Calendar,
	DrawerBodyWrapper,
	DrawerFooterWrapper,
	H5,
	Input,
	MultiSelect,
	Pill,
	Select,
	TOAST_TYPE,
	Table,
	Textarea,
	useTableReducer,
} from '@base'
import { ABILITY_ISSUE, ABILITY_ISSUE_REASON_OF_DELAY, INTENTION_ISSUE, modeOfPayment, strings } from '@constants'
import API from '@data/conn/api'
import getUrl from '@data/conn/get/paths'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { Spacings, Text } from '@styles'
import EditCollection from './EditCollection'
import { createPaymentReceivable, getDueLedgerReport } from '@data'
import { addRemarkToPaymentReceivables, editPaymentReceivable } from '@data/conn/put/ledger'
import { showLoader, showToast } from '@data/state/action'
import { displayAmount, getTodayDate, numberToWords, showDateFormat } from '@utils'

export const FormSection = styled.div`
	display: flex;
	flex-direction: column;
`

export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}
`

const CustomBodyWrapper = styled(DrawerBodyWrapper)`
	padding-top: 0;
	padding-bottom: 0;
`
const InvoiceListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`

const HeadingWrapper = styled.div`
	margin-top: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_2B};
	font-size: ${Text.SMALL};
`

const AmountWrapper = styled.div`
	font-size: ${Text.SMALL};
`

const BalanceWrapper = styled.div`
	padding-left: ${Spacings.SPACING_3B};
`

export const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`

const InfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	&:nth-child(1) {
		margin-right: ${Spacings.SPACING_3B};
	}
`

const TableWrapper = styled.div`
	flex: 1;
	background: ${({ theme }) => theme.palette.background.tablegrey};
	border: 1px solid rgba(234, 241, 250, 1);
	margin: 0 -${Spacings.SPACING_4B};
	padding: 0 ${Spacings.SPACING_4B};
	margin-top: ${Spacings.SPACING_4B};
	padding-bottom: ${Spacings.SPACING_3B};
`

const TableHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_3B};
	margin-bottom: 0;
	padding-bottom: ${Spacings.SPACING_2B};
`

const transformedModeOfPayment = Object.entries(modeOfPayment).map(([key, value]) => ({ key, label: value }))
let intentionTypes = Object.entries(INTENTION_ISSUE).map(([key, value]) => ({ key: key, label: value }))
let abilityTypes = Object.entries(ABILITY_ISSUE).map(([key, value]) => ({ key: key, label: value }))
let ability_issue_reason_of_delay = Object.entries(ABILITY_ISSUE_REASON_OF_DELAY).map(([key, value]) => ({ key: key, label: value }))
const getDate = (date) => (+date === 0 ? new Date().getTime() : +date)

const setFormStateData = (data, paymentReceivables) => {
	const _state = {
		expected_payment_date: {
			label: strings('expected', 'date'),
			value: new Date().getTime(),
			disabled: false,
			required: true,
			placeholder: strings(['select', 'expected', 'date']),
		},
		user_details: {
			label: strings('client'),
			value: '',
			disabled: false,
			required: true,
			display_key: 'display_name',
			primary_key: 'organisation_id',
			placeholder: strings(['select', 'client']),
		},
		payment_amount: {
			label: strings('amount'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings('enter', 'amount'),
		},
		payment_mode: {
			label: strings('mode_of_payment'),
			data: transformedModeOfPayment,
			value: transformedModeOfPayment?.find((item) => item?.label === modeOfPayment.DIRECT_BANK_TRANSFER),
			primary_key: 'key',
			display_key: 'label',
			disabled: false,
			required: true,
			placeholder: strings(['select', 'mode_of_payment']),
		},
		message: {
			label: strings('message'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('add', 'message'),
		},
		issue: {
			label: strings('issue'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('issue'),
		},
		meeting_remarks: {
			label: strings('meeting', 'remarks'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('meeting', 'remarks'),
		},
		customer_poc_email: {
			label: strings('customer', 'email'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings(['customer', 'poc', 'email']),
		},
		customer_poc_name: {
			label: strings('customer', 'name'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('customer', 'name'),
		},
		customer_poc_phone: {
			label: strings('customer', 'phone'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('customer', 'phone'),
		},
		meeting_scheduled_at: {
			label: strings('meeting', 'scheduled'),
			value: new Date().getTime(),
			disabled: false,
			required: false,
			placeholder: strings(['meeting', 'scheduled', 'at']),
		},
		remark_message: {
			label: strings('message'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('message'),
		},
		pdc: {
			label: 'Can customer give PDCS?',
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('pdc'),
		},
		principal_details: {
			label: strings('ask_principal_details'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('details_principal'),
		},
		reason_for_delay: {
			label: strings('reason_for_delay_placeholder'),
			value: '',
			data: ability_issue_reason_of_delay,
			disabled: false,
			required: false,
			displayKey: 'label',
			primaryKey: 'key',
			placeholder: strings('reason_for_delay'),
		},
		reason: {
			id: 'reason',
			label: strings('reason'),
			value: '',
			disabled: false,
			required: false,
			primaryKey: 'key',
			displayKey: 'label',
			placeholder: strings('reason'),
		},
		partial_payment_agreement: {
			id: 'partial_payment_agreement',
			label: strings('partial_payment_agreement'),
			value: '',
			disabled: false,
			required: false,
		},
		invoice_list: {
			id: 'invoice_list',
			label: strings(['invoice', 'list']),
			placeholder: strings('Select invoices'),
			value: [],
			disabled: false,
			required: false,
		},
	}
	if (paymentReceivables != null) {
		_state.user_details.value = paymentReceivables?.user_details
		_state.user_details.value['display_name'] =
			paymentReceivables?.user_details.organisation_name || paymentReceivables?.user_details?.organisation_id
		_state.payment_mode.value = transformedModeOfPayment?.find((item) => item?.key == paymentReceivables?.payment_mode)
		_state.expected_payment_date.value = paymentReceivables?.expected_payment_date
		_state.payment_amount.value = paymentReceivables?.payment_amount
		_state.customer_poc_name.value = paymentReceivables?.user_details?.full_name
		_state.customer_poc_phone.value = paymentReceivables?.user_details?.mobile_number
		_state.invoice_list.value = paymentReceivables?.invoice_list
	}
	if (!data) {
		return _state
	} else {
		_state.issue.value = data?.issue
		_state.reason.value = intentionTypes.find((item) => item?.key === data?.reason) || abilityTypes.find((item) => item?.key === data?.reason)
		_state.customer_poc_email.value = data?.customer_poc_email
		_state.customer_poc_name.value = data?.customer_poc_name
		_state.customer_poc_phone.value = data?.customer_poc_phone
		_state.payment_amount.value = data?.amount

		_state.meeting_scheduled_at.value = data?.meeting_scheduled_at
		_state.meeting_remarks.value = data?.meeting_remarks
		_state.remark_message.value = data?.message

		_state.pdc.value = data?.qna?.pdc
		_state.principal_details.value = data?.qna?.principal_details
		_state.reason_for_delay.value = ability_issue_reason_of_delay?.find((item) => item?.key == data?.qna?.reason_for_delay) || {}
		_state.partial_payment_agreement.value = data?.qna?.partial_payment_agreement
	}
	return _state
}

const CreateCollection = ({ paymentReceivables = null, data = null, isAddRemark = false, isEdit = false, update, isEditRemark = false }) => {
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [clientData, setClientData] = useState([])
	const [formState, setFormState] = useState(setFormStateData(data, paymentReceivables))
	const [collection, setCollection] = useState({})
	const [invoiceList, setInvoiceList] = useState({})
	const [balance, setBalance] = useState(0)
	const [state, dispatch, actions] = useTableReducer()
	const globalDispatch = useDispatch()

	useEffect(() => {
		globalDispatch(
			updateDrawerMeta({
				label: <>{isEdit ? strings('edit', 'remark') : isAddRemark ? strings('add', 'remark') : strings('create', 'collection')}</>,
			})
		)
		API.get(getUrl.getAllClients)
			.then((response) => {
				response.data?.map((item) => {
					item['display_name'] =
						item.organisation_name === '' || item.organisation_name == null ? item.organisation_id : item.organisation_name
					return item
				})
				setClientData(response.data)
			})
			.catch((err) => console.log(err))
	}, [])

	useEffect(() => {
		if (formState.user_details?.value?.organisation_id) {
			dispatch(actions.fetchTableDataProgress())

			getDueLedgerReport(formState?.user_details?.value?.organisation_id)
				.then((res) => {
					setBalance(res?.balance)
					dispatch(actions.fetchTableDataSuccess(res?.payment_receivables, res?.payment_receivables.length))
					res?.ledger_report?.map((item) => {
						item['display_name'] = `${item.invoice_number} \xa0\xa0\xa0\xa0\xa0\xa0\xa0 (${showDateFormat(
							new Date(item.transaction_date)
						)}) \xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${displayAmount(item.invoice_amount)}`
						return item
					})
					setInvoiceList(res?.ledger_report)
				})
				.catch((err) => {
					console.log(err)
					dispatch(actions.fetchTableDataFailure(err))
				})
		}
	}, [formState.user_details])

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onChange = (key) => (value) => {
		const tempCollection = Object.assign({}, collection)
		if (key === 'invoice_list') {
			let tempRegionSet = new Set()
			let tempInvoiceList = new Array()

			value.forEach((e) => {
				tempInvoiceList.push(`${e.invoice_number}`)
				if (e?.region != null) tempRegionSet.add(e?.region)
			})
			tempCollection[key] = tempInvoiceList
			tempCollection['region'] = Array.from(tempRegionSet).join(', ')
		} else if (key === 'payment_mode' || key === 'reason') {
			tempCollection[key] = value.key
		} else {
			tempCollection[key] = value
		}
		setCollection(tempCollection)
		updateState(key, { value })
	}

	const isCreatebuttonDisabled =
		formState.payment_amount.value == null ||
		formState.payment_amount.value === '' ||
		formState.user_details.value == null ||
		formState.user_details.value === '' ||
		formState.payment_mode.value == null ||
		formState.payment_mode.value === '' ||
		formState.expected_payment_date == null

	const formStateToRemark = () => {
		const remark = {
			created_at: data?.created_at ? data?.created_at : Date.now(),
			amount: formState.payment_amount?.value,
			expected_payment_date: formState?.expected_payment_date?.value,
			customer_poc_email: formState.customer_poc_email?.value,
			customer_poc_name: formState.customer_poc_name?.value,
			customer_poc_phone: formState.customer_poc_phone?.value,
			issue: formState.issue?.value,
			meeting_remarks: formState.meeting_remarks?.value,
			meeting_scheduled_at: formState.meeting_scheduled_at?.value,
			message: formState.remark_message?.value,
			user_details: formState.user_details?.value,
			qna: {
				pdc: formState.pdc?.value,
				principal_details: formState.principal_details?.value,
				reason_for_delay: formState.reason_for_delay?.value?.key,
				partial_payment_agreement: formState.partial_payment_agreement?.value,
			},
			reason: formState.reason?.value?.key,
			payment_mode: formState.payment_mode?.value?.key,
		}
		return remark
	}

	const submitHandler = () => {
		globalDispatch(showLoader(true))
		collection['manager'] = [user]
		collection['expected_payment_date'] = formState.expected_payment_date?.value

		if (!('payment_mode' in collection)) {
			collection['payment_mode'] = formState.payment_mode?.value?.key
		}

		createPaymentReceivable(collection)
			.then((response) => {
				globalDispatch(showToast(true, strings('msg_collection_created_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				console.log(err)
				let err_msg = strings('msg_collection_created_failure')
				if (err?.client_error_code === 'E_002' && err?.message) {
					err_msg = err.message
				}
				globalDispatch(showToast(true, err_msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				globalDispatch(toggleDrawer(false))
				globalDispatch(showLoader(false))
				update()
			})
	}
	const addRemarkHandler = () => {
		if (paymentReceivables != null) {
			globalDispatch(showLoader(true))
			let remark = formStateToRemark()
			if (paymentReceivables.remarks == null) {
				paymentReceivables = { ...paymentReceivables, remarks: [remark] }
			} else {
				paymentReceivables.remarks.push(remark)
			}
			const paymentReceivablesId = paymentReceivables?.receivable_id
			addRemarkToPaymentReceivables({ ...remark, type: 'message' }, paymentReceivablesId)
				.then((response) => {
					globalDispatch(showToast(true, strings('msg_collection_remark_added_success'), { type: TOAST_TYPE.SUCCESS }))
				})
				.catch((err) => {
					console.log(err)
					globalDispatch(showToast(true, strings('msg_collection_remark_added_failure'), { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					globalDispatch(showLoader(false))
					globalDispatch(toggleDrawer(false))
					update()
				})
		}
	}
	const editCollectionHandler = () => {
		globalDispatch(showLoader(true))
		const remark = formStateToRemark()
		const paymentReceivablesId = paymentReceivables?.receivable_id
		editPaymentReceivable(paymentReceivablesId, remark)
			.then(() => {
				globalDispatch(showToast(true, strings('msg_receivable_edited_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				console.log(err)
				globalDispatch(showToast(true, strings('msg_receivable_edited_failure'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				globalDispatch(toggleDrawer(false))
				update()
				globalDispatch(showLoader(false))
			})
	}

	const tableColumns = useMemo(
		() => [
			{
				label: strings('expected', 'date'),
				key: (data) => showDateFormat(new Date(data.expected_payment_date)),
				mobile: true,
			},

			{
				label: strings(['amount']),
				key: (data) => {
					if (!data) {
						return '-'
					}
					return (
						<>
							<div>
								<AmountWrapper>{data?.payment_amount ? displayAmount(data?.payment_amount) : '-'}</AmountWrapper>
							</div>
						</>
					)
				},
				mobile: true,
			},
			{
				label: strings('message'),
				key: (data) => {
					return <div>{data?.message ? data?.message : '-'}</div>
				},
				mobile: true,
			},
		],
		[state?.data]
	)
	const isAddRemarkDisabled =
		formState.issue.value == null ||
		formState.issue.value === '' ||
		formState.reason.value == null ||
		formState.reason.value === '' ||
		(formState.reason.value?.key?.toLowerCase() === ABILITY_ISSUE.OTHER.toLowerCase() && formState.remark_message?.value === '') ||
		Object.keys(formState.reason.value).length === 0

	return (
		<>
			<CustomBodyWrapper>
				<FormSection>
					<FormItemWrapper>
						<Select
							primaryKey={formState.user_details?.primary_key}
							displayKey={formState.user_details?.display_key}
							data={clientData}
							label={formState.user_details?.label}
							disabled={isEdit || isAddRemark}
							value={formState.user_details?.value}
							placeholder={formState.user_details?.placeholder}
							onChange={onChange('user_details')}
							required={formState.user_details?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<InfoWrapper>
							<Calendar
								label={formState.expected_payment_date?.label}
								disabled={formState.expected_payment_date?.disabled}
								value={getDate(formState.expected_payment_date?.value)}
								placeholder={formState.expected_payment_date?.placeholder}
								onChange={onChange('expected_payment_date')}
								required={formState.expected_payment_date?.required}
								minDate={getTodayDate(false).getTime() - 86400000 * 3}
								maxDate={getTodayDate(false).getTime() + 86400000 * 7}
							/>
						</InfoWrapper>
						<InfoWrapper>
							<Select
								primaryKey={formState.payment_mode?.primary_key}
								displayKey={formState.payment_mode?.display_key}
								data={formState.payment_mode?.data}
								label={formState.payment_mode?.label}
								disabled={formState.payment_mode?.disabled}
								value={formState.payment_mode?.value}
								placeholder={formState.payment_mode?.placeholder}
								onChange={onChange('payment_mode')}
								required={formState.payment_mode?.required}
							/>
						</InfoWrapper>
					</FormItemWrapper>

					<FormItemWrapper>
						<Input
							type='number'
							label={formState.payment_amount?.label}
							disabled={formState.payment_amount?.disabled}
							value={formState.payment_amount?.value}
							placeholder={formState.payment_amount?.placeholder}
							onChange={onChange('payment_amount')}
							required={formState.payment_amount?.required}
						/>
						<BalanceWrapper>
							<Pill label={`${strings('total', 'due')}: `} value={displayAmount(balance)} />
						</BalanceWrapper>
					</FormItemWrapper>
					{formState.payment_amount?.value != 0 && formState.payment_amount?.value != null && (
						<FormItemWrapper>
							<AmountWrapper>{numberToWords(formState.payment_amount?.value)}</AmountWrapper>
						</FormItemWrapper>
					)}
					{!isEdit && !isAddRemark && (
						<FormItemWrapper>
							<Textarea
								id={formState.message.id}
								label={strings('message')}
								type='text'
								rows={3}
								placeholder={formState.message.placeholder}
								value={formState.message?.value}
								onChange={onChange('message')}
							></Textarea>
						</FormItemWrapper>
					)}
					{!isEdit && !isAddRemark && (
						<FormItemWrapper>
							<MultiSelect
								id={formState.invoice_list.id}
								label={formState.invoice_list.label}
								displayKey={'display_name'}
								primaryKey={'display_name'}
								placeholder={formState.invoice_list.placeholder}
								data={invoiceList}
								value={formState.invoice_list.value}
								onChange={onChange('invoice_list')}
							/>
						</FormItemWrapper>
					)}
					{(isEdit || isAddRemark) && (
						<>
							<HeadingWrapper>{strings(['invoice', 'numbers', ' : '])}</HeadingWrapper>
							<InvoiceListWrapper>
								{formState.invoice_list?.value?.map((item, index) => {
									return (
										<Pill
											// label={`${strings('invoice', 'id')}: `}
											value={item}
											margin={`0 ${Spacings.SPACING_2B} ${Spacings.SPACING_2B} 0`}
										/>
									)
								})}
							</InvoiceListWrapper>
						</>
					)}
					{!isEdit && !isAddRemark && balance !== null && state?.data?.length > 0 && (
						<>
							<TableWrapper>
								<TableHeading>{strings('previous', 'collections')}</TableHeading>
								<Table
									fullHeight
									data={state.data}
									columns={tableColumns}
									rowsToDisplay={10}
									loading={state.loading}
									totalItemCount={state?.data?.length}
									rowClickable={false}
									hideFooter
								/>
							</TableWrapper>
						</>
					)}

					{(isEdit || isAddRemark) && <EditCollection isEdit={isEdit} isAddRemark={isAddRemark} onChange={onChange} state={formState} />}
				</FormSection>
			</CustomBodyWrapper>
			<DrawerFooterWrapper>
				{isEdit ? (
					<ActionButton small uppercase isHover disabled={!isEditRemark || isAddRemarkDisabled} onClick={editCollectionHandler}>
						{strings('edit', 'remark')}
					</ActionButton>
				) : isAddRemark ? (
					<ActionButton small uppercase isHover disabled={isAddRemarkDisabled} onClick={addRemarkHandler}>
						{strings('add', 'remark')}
					</ActionButton>
				) : (
					<ActionButton small uppercase isHover disabled={isCreatebuttonDisabled} onClick={submitHandler}>
						{strings('create', 'collection')}
					</ActionButton>
				)}
			</DrawerFooterWrapper>
		</>
	)
}

export default CreateCollection
