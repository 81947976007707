import styled from 'styled-components'
import { H4, Button, AddressCard, Pill, DrawerBodyWrapper, DrawerFooterWrapper, ItemCard } from '@base'
import { Spacings, Text } from '@styles'
import { showDateFormat } from '@utils'
import { enquiryStatus, strings } from '@constants'
import { paths } from '@routes'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { decimalSeparatedNumber } from '@utils'
import { useEffect } from 'react'

const SectionLabel = styled(H4)`
	font-weight: ${Text.BOLD_500};
	color: ${({ theme }) => theme.palette.text.tableHeader};
	display: flex;
	align-items: center;
`

const AddedItemsList = styled.div`
	padding: ${Spacings.SPACING_4B} 0;
`
const AddressCardWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_4B};
	border-radius: ${Spacings.SPACING_2B};
	padding: ${Spacings.SPACING_4B} 0;
`

const DateCardWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`
const ExpiryDate = styled.span`
	display: inline-block;
	font-size: ${Text.MEDIUM};
	padding: ${Spacings.SPACING_4B} 0;
	font-weight: ${Text.BOLD_600};
`
const AddedItemsInfoWrapper = styled.div`
	display: flex;
	margin-left: auto;
`

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
`

const ViewEnquiry = ({ enquiry, role }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const enquiryItems = enquiry?.enquiry_items ?? []
	const totalWeight = enquiryItems?.reduce((acc, item) => acc + item.required_weight, 0) ?? 0

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: (
					<>
						{strings('view_enquiry')} <Pill label={`${strings('enquiry_id')}:`} value={enquiry?.enquiry_id} margin={`0 0 0 auto`} />
					</>
				),
			})
		)
	}, [dispatch])

	const scrapPlatform = () => {
		const { final_quote_buyer } = enquiry
		const quotePerProduct = final_quote_buyer?.quote_per_product.find((item) => item?.for_price || item?.ex_price)
		return enquiry?.platform === 'SCRAP' && !!quotePerProduct
	}
	const editButtonHandler = () => {
		dispatch(toggleDrawer(false))
		if ([enquiryStatus.active, enquiryStatus.orderPlaced, enquiryStatus.expired].indexOf(enquiry.enquiry_status) > -1) {
			if (scrapPlatform()) {
				navigate(`${paths.manage_purchase_order_status('active')}?enquiry_id=${enquiry?.enquiry_id}`)
				return
			}
			if (enquiry.enquiry_status === enquiryStatus.active && !enquiry.final_quote_buyer) {
				navigate(paths.edit_enquiry(enquiry.enquiry_id), {
					state: enquiry,
				})
				return
			}
			navigate(paths.show_buyer_quotes(enquiry.enquiry_id), {
				state: enquiry,
			})
		} else {
			navigate(paths.edit_enquiry(enquiry.enquiry_id), {
				state: enquiry,
			})
		}
	}

	const closeClickHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const createPoClickHandler = () => {
		navigate(paths.create_po(enquiry.enquiry_id), {
			state: enquiry,
		})
	}
	const getActionButtonText = () => {
		if (enquiry.enquiry_status === enquiryStatus.expired) return strings(['view', 'quotes'])
		if (enquiry.enquiry_status === enquiryStatus.orderPlaced || enquiry.final_quote_buyer) {
			if (scrapPlatform()) return strings(['previous', 'orders'])
			return strings(['see', 'quotes'])
		}
		return strings('edit')
	}

	const renderEnquiryItemsList = () => {
		return enquiryItems.map((item, index) => {
			const itemData = [
				{
					label: strings('category'),
					value: item?.category_name,
				},
				{
					label: strings('product'),
					value: item?.product_name ?? '--',
					wrap: true,
				},
				{
					label: strings('weight_mt'),
					value: decimalSeparatedNumber((item?.required_weight / 1000).toFixed(3)),
				},
				{
					label: strings('brands'),
					value: item.brands?.join(', ') || item?.brand,
				},
				{
					label: strings('tc_reqd'),
					value: item?.tc_required ? strings('yes') : strings('no'),
				},
				{
					label: strings('message'),
					value: item?.message ?? '--',
					isMessage: true,
				},
			]
			return <ItemCard small data={itemData} id={item?.id} key={`enquiry-item-${index}`} />
		})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<SectionLabel>
					{strings('items')}
					<AddedItemsInfoWrapper>
						<Pill label={`${strings('total_items')}:`} value={enquiryItems?.length} />
						<Pill
							label={`${strings('total', 'weight_mt')}:`}
							value={decimalSeparatedNumber(totalWeight / 1000, { default: 0 })}
							margin={`0 0 0 ${Spacings.SPACING_2B}`}
						/>
					</AddedItemsInfoWrapper>
				</SectionLabel>
				<AddedItemsList>{renderEnquiryItemsList()}</AddedItemsList>
				<SectionLabel>{strings('shipping_address')}</SectionLabel>
				<AddressCardWrapper>
					<AddressCard data={enquiry?.shipping_address} />
				</AddressCardWrapper>
				<DateCardWrapper>
					<div>
						<SectionLabel>{strings('enquiry_date')}</SectionLabel>
						<ExpiryDate>{showDateFormat(new Date(enquiry?.enquiry_date))}</ExpiryDate>
					</div>
					<div>
						<SectionLabel>{strings('expiry_date')}</SectionLabel>
						<ExpiryDate>{showDateFormat(new Date(enquiry?.expiry_date))}</ExpiryDate>
					</div>
				</DateCardWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<ActionButton small uppercase type='primary' onClick={editButtonHandler}>
					{getActionButtonText()}
				</ActionButton>
				{enquiry?.platform === 'SCRAP' && enquiry?.enquiry_status!==enquiryStatus.created && (
					<ActionButton small uppercase type='secondary' onClick={createPoClickHandler}>
						{strings(['create', 'po'])}
					</ActionButton>
				)}
				<ActionButton small uppercase type='tertiary' onClick={closeClickHandler}>
					{strings('close')}
				</ActionButton>
			</DrawerFooterWrapper>
		</>
	)
}

export default ViewEnquiry
