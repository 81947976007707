import React, { useEffect, useState } from 'react'
import { TOAST_TYPE, Input, Textarea, Button } from '@base'
import { FINANCE_FEEDBACK_TYPES, strings } from '@constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateTeamFeedback } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { BaseWrapper, ContainerWrapper, FooterWapper, FormItemWrapper, PsuedoWrapper } from '../styles'
import { useQuery } from '@hooks'

const deriveStateFromData = (data) => {
	const _state = {
		team_comment: [
			{
				question: 'Past Experience',
				answer: '',
			},
			{
				question: 'Remarks from meeting if any',
				answer: '',
			},
			{
				question: 'Governance insight',
				answer: '',
			},
			{
				question: 'Tenor with the company',
				answer: '',
			},
			{
				question: 'Resignations and other rejigs in management',
				answer: '',
			},
		],
		comments: {
			id: 'comments',
			label: 'Comments',
			value: '',
			disabled: false,
			required: true,
			dirty: false,
			placeholder: 'Enter comments',
		},
		score: {
			id: 'score',
			label: 'Score',
			value: '',
			disabled: false,
			required: true,
			dirty: false,
			placeholder: 'Enter score',
		},
	}

	if (!data?.financial_report?.finance_feedback) return _state

	_state.team_comment = data?.financial_report?.finance_feedback?.team_comment || _state.team_comment
	_state.comments.value = data?.financial_report?.finance_feedback?.comments
	_state.score.value = data?.financial_report?.finance_feedback?.score

	return _state
}

const ManagementFeedback = ({ data, update }) => {
	const query = useQuery()
	const dispatch = useDispatch()
	const panNumber = query?.get('pan') || data?.pan
	const reportId = query?.get('report_id') || data?.report_id
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [formState, setFormState] = useState(deriveStateFromData())

	useEffect(() => {
		setFormState((_) => deriveStateFromData(data))
	}, [data])

	const onChange = (key) => (value) => updateState(key, { value, dirty: true })

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const submitButtonHandler = () => {
		updateTeamFeedback(
			panNumber,
			reportId,
			FINANCE_FEEDBACK_TYPES.FINANCE,
			formState?.score?.value,
			formState?.comments?.value,
			formState?.team_comment,
			user?.getUserDetails(),
			formState?.score?.value ? user?.getUserDetails() : null,
			!!panNumber && !!reportId
		)
			.then(() => {
				dispatch(showToast(true, strings('msg_financials_remark_added_success'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, err.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const onChangeQuestion = (index) => (value) => {
		const teamComment = formState?.team_comment
		teamComment?.map((item, i) => {
			if (i === index) {
				item['answer'] = value
			}
			return item
		})
		setFormState((state) => ({
			...state,
			team_comment: teamComment,
		}))
	}
	const disabled = formState?.team_comment?.find((item) => item?.answer !== '')
	return (
		<>
			<BaseWrapper>
				<ContainerWrapper>
					{formState?.team_comment?.map((item, index) => {
						return (
							<FormItemWrapper key={`${index}-sales`}>
								<Textarea
									id={`${index}-sales`}
									label={item?.question}
									type='text'
									rows={3}
									placeholder={`Enter ${item?.question}`}
									value={item?.answer}
									onChange={(e) => {
										onChangeQuestion(index)(e)
									}}
								/>
							</FormItemWrapper>
						)
					})}
				</ContainerWrapper>
				<ContainerWrapper>
					<FormItemWrapper>
						<Input
							id={formState?.score.id}
							type='number'
							label={formState?.score.label}
							value={formState?.score.value}
							placeholder={formState?.score.placeholder}
							onChange={onChange('score')}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Textarea
							id={formState?.comments.id}
							label={formState?.comments.label}
							type='text'
							rows={3}
							placeholder={formState?.comments.placeholder}
							value={formState?.comments.value}
							onChange={onChange('comments')}
						/>
					</FormItemWrapper>
				</ContainerWrapper>
			</BaseWrapper>
			<PsuedoWrapper>
				<FooterWapper>
					<Button xs margin='0 0 0 auto' onClick={submitButtonHandler} disabled={!disabled || !data?.pan}>
						{strings('save')}
					</Button>
				</FooterWapper>
			</PsuedoWrapper>
		</>
	)
}

export default ManagementFeedback
