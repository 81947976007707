import API from '../api'
import getUrl from './paths'

export const getDashboardData = (userId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getUserDashboard, {
			userId,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})

export const getCompanyData = (employeeId = null, accessType, numMonths=6) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getCompanyDashboard, { employeeId, accessType, numMonths })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})

export const getManagerDashboardData = (userId, employeeId = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getManagerDashboardData, { userId, employeeId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
