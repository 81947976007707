import { ORG_USER_ROLES } from '@constants'
import { Record } from 'immutable'
const UserRecord = Record({
	id: '',
	user_id: '',
	organisation_id: '',
	uid: null,
	authToken: null,
	organisation_name: '',
	mapping: null,
	mobile_number: '',
	email_id: '',
	full_name: '',
	vernac_language: 'ENGLISH',
	profile_url: '',
	remarks: '',
	remarks_by: null, // get user details
	notification: null,
	role: ORG_USER_ROLES.ORG_ADMIN, // ORG_MANAGER, ORG_ADMIN
	reporting_managers: [],
	sub_role: null,
	manager_name: '',
	active: true,
	org_active: false,
	org_created: true,
	fcm_tokens: [],
	created_at: new Date().getTime(), //date
	created_by: '',
	updated_at: new Date().getTime(), //date
	updated_by: '',
	access_type: 'web', //string
	is_default_admin: false,
	commodity: [], // only for admin
	country_code: '',
})

class User extends UserRecord {
	constructor(data) {
		super({
			...data,
		})
	}

	update(updates) {
		return this.merge(updates)
	}

	getUserDetails() {
		return {
			user_id: this.user_id,
			full_name: this.full_name,
			organisation_name: this.organisation_name,
			organisation_id: this.organisation_id,
			mobile_number: this.mobile_number,
			role: this.role,
			is_primary: this.is_primary || false,
			is_primary_supply: this.is_primary_supply || false,
		}
	}

	getAdminDetails() {
		return {
			user_id: this.user_id,
			full_name: this.full_name,
			mobile_number: this.mobile_number,
			role: this.role,
			is_primary: this.is_primary || false,
			is_primary_supply: this.is_primary_supply || false,
		}
	}

	getTableName() {
		return (this.organisation_name || this.full_name || '') + ' (' + this.mobile_number + ')'
	}

	getAdminTableName() {
		return (this.full_name || '') + ' (' + this.mobile_number + ')'
	}
}

export default User
