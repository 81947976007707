import React, { useEffect, useState } from 'react'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, Select, TOAST_TYPE } from '@base'
import styled, { css } from 'styled-components'
import { strings } from '@constants'
import { Spacings } from '@styles'
import { fetchLogisticsBill, getAllUsers } from '@data'
import { useDispatch, useSelector } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { displayName } from '@utils'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 0;
	${({ isMarginTop }) => {
		if (isMarginTop) {
			return css`
				margin-top: ${Spacings.SPACING_2B};
			`
		}
	}}
`
const FormItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

export const BannerText = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`

const deriveStateFromData = () => {
	const _state = {
		bill_number: {
			label: strings(['bill', 'number']),
			value: '',
			disabled: false,
			required: true,
			dirty: false,
			placeholder: strings(['enter', 'bill', 'number']),
		},
		client: {
			label: strings(['client']),
			value: {},
			disabled: false,
			required: true,
			dirty: false,
			placeholder: strings(['select', 'client']),
			primaryKey: 'organisation_id',
			displayKey: 'display_name',
		},
	}

	return _state
}

const AddInvoice = ({ update }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())
	const [clients, setClients] = useState([])
	const user = useSelector((state) => state.getIn(['user', 'user']))

	useEffect(() => {
		getAllUsers()
			.then((response) => {
				setClients(
					response.map((item) => {
						item['display_name'] = displayName(item)
						return item
					})
				)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onChange = (key) => (value) => updateState(key, { value, dirty: true })

	const addButtonClick = () => {
		dispatch(showLoader(true))
		fetchLogisticsBill(formState.bill_number.value, formState.client?.value, user.user_id)
			.then(() => {
				dispatch(showToast(true, strings('invoices_bills_added_successfully'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, err.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<FormItemWrapper>
						<Input
							type='text'
							label={formState?.bill_number.label}
							value={formState?.bill_number.value}
							placeholder={formState?.bill_number.placeholder}
							onChange={onChange('bill_number')}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Select
							id={formState.client.id}
							placeholder={formState.client.placeholder}
							label={formState.client.label}
							onChange={onChange('client')}
							value={formState.client?.value}
							data={clients}
							primaryKey={formState.client.primaryKey}
							displayKey={formState.client.displayKey}
						/>
					</FormItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase onClick={addButtonClick} disabled={!formState?.bill_number?.dirty || !formState?.client?.dirty}>
					{strings('fetch')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddInvoice
