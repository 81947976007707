import API from "../api";
import putUrl from "./paths";


export const updateCreditLimit = (creditLimit) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.updateCredditLimit, { ...creditLimit })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })