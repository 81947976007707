import styled, { css } from 'styled-components'
import { Devices, Spacings } from '@styles'
import { NavigationWrapper, SectionHeader } from '@base'

export const CatalogNavigationWrapper = styled(NavigationWrapper)`
	display: flex;
	align-items: center;
`

export const SearchWrapper = styled.div`
	display: flex;
	padding-left: ${Spacings.SPACING_4B};
	margin-left: auto;
`

export const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	margin-top: ${Spacings.SPACING_1B};
	display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
	${({ isLeftMargin }) => {
		if (isLeftMargin) {
			return css`
				& > div {
					&:nth-child(1) {
						flex: 2;
						padding: 0px 2px;
						margin-left: 0px;
					}
					&:nth-child(2) {
						flex: 2;
						padding: 0px 2px;
						margin-left: 0px;
					}
					&:nth-child(3) {
						flex: 1;
						padding: 0px 2px;
						margin-left: 0px;
					}
					&:nth-child(4) {
						flex: 1;
						padding: 0px 2px;
						margin-left: 0px;
					}
					&:nth-child(5) {
						flex: 1;
						padding: 0px 2px;
						margin-left: 0px;
					}
					&:nth-child(6) {
						flex: 1;
						padding: 0px 2px;
						margin-left: 0px;
					}
				}
				@media ${Devices.mobile} {
					display: flex;
				}
			`
		}
	}}
`
export const InputWapper = styled.div`
	width: auto;
	flex: auto;

	@media ${Devices.laptop} {
		width: 100%;
		flex: 1;
	}
`

export const DateWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	max-width: 100%;
	@media ${Devices.mobile} {
		max-width: 15rem;
	}
`


export const HeadingWapper = styled.div`
	padding: ${Spacings.SPACING_2B} 0px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	& > div {
		&:nth-child(1) {
			flex: 2;
		}
		&:nth-child(2) {
			flex: 2;
		}
	}
`
export const Heading = styled.div`
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	display: flex;
	justify-content: center;
	flex: 1;
`
export const SectionHeaderWapper = styled(SectionHeader)`
	justify-content: space-between;
`
export const StepTopWrapper = styled.div`
	padding: ${Spacings.SPACING_3B} 0px;
`