export const sizes = {
	mobile: 425,
	tablet: 768,
	laptop: 1024,
	desktop: 1440,
}

export const devices = {
	mobile: `(min-width: ${sizes.mobile}px)`,
	tablet: `(min-width: ${sizes.tablet}px)`,
	laptop: `(min-width: ${sizes.laptop}px)`,
	desktop: `(min-width: ${sizes.desktop}px)`,
}
