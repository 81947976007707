import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Spacings } from '@styles'
import { decimalSeparatedNumber } from '@utils'
import { PROCESS_TYPES, strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import FactoryOrderInputItem from '@data/model/NewModals/CloudFactory/FactoryOrderInputItem'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, MultiSelect, SectionHeader, Select, CloudInventoryCard } from '@base'

import {
	ExntededPanelItemsWrapper,
	ExntendedItemWrapper,
	WeightUnitExntendedItemWrapper,
	CardButtonsWrapper,
	ListLabel,
	FlexRowWrapper,
} from '../../../../styles'

const AddInventoryItems = ({ inventory, selectedItems, update, clickedCardId }) => {
	const dispatch = useDispatch()

	const [inventoryItems, setInventoryItems] = useState([])
	const [highlight, setHighlight] = useState()

	const isAnyItemInvalid = useMemo(() => {
		const selectedItems = inventoryItems.filter((_i) => _i.selected)
		if (selectedItems?.length > 0) {
			return selectedItems.some((_i) => !_i.isValid())
		}
		return false
	}, [inventoryItems])

	useEffect(() => {
		if (Array.isArray(inventory)) {
			setInventoryItems(() =>
				inventory
					.map(
						(_i) =>
							new FactoryOrderInputItem({
								..._i,
								mb_reference_number_from_inventory: _i.mb_reference_number,
								mb_reference_number: [],
							})
					)
					.map((_i) => {
						const _item = selectedItems.find((_si) => _si.identifier === _i.identifier)
						if (_item) {
							return _i.update({
								..._item.toJS(),
								mb_reference_number_from_inventory: _i.mb_reference_number_from_inventory,
								selected: true,
							})
						}
						return _i.update({ selected: false })
					})
			)
		}

		if (clickedCardId) {
			setHighlight(clickedCardId)
			const timerId = setTimeout(() => {
				setHighlight(undefined)
			}, 1000)

			return () => {
				clearTimeout(timerId)
				setHighlight(undefined)
			}
		}
	}, [])

	const updateItem = (identifier, key) => (val) => {
		setInventoryItems((_items) => {
			const _itemIndex = inventoryItems.findIndex((_i) => _i.identifier === identifier)
			if (_itemIndex !== -1) {
				_items[_itemIndex] = _items[_itemIndex].update({ [key]: val })
				return [..._items]
			}
			return _items
		})
	}

	const addItem = (item) => () => {
		if (!item.isValid()) return

		setInventoryItems((items) => {
			const itemIndex = items.findIndex((_i) => _i.identifier === item.identifier)
			if (itemIndex !== -1) {
				items[itemIndex] = item.update({ selected: true })
			}
			return [...items]
		})
	}

	const removeSelectedItem = (item) => () => {
		if (highlight) {
			setHighlight(undefined)
		}
		setInventoryItems((items) => {
			const itemIndex = items.findIndex((_i) => _i.identifier === item.identifier)
			if (itemIndex !== -1) {
				items[itemIndex] = item.update({ selected: false })
			}
			return [...items]
		})
	}

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const doneHandler = () => {
		const selectedItems = inventoryItems.filter((_i) => _i.selected)
		update(selectedItems)
		dispatch(toggleDrawer(false))
	}

	const updateRefNumbers = (_item) => (val) => {
		const values = val.map((_v) => _v.value)
		updateItem(_item.identifier, 'mb_reference_number')(values)
	}

	const renderOrderInputItemCard = (item, isSelected) => {
		const _item = item.toJS()
		const data = [
			{
				label: strings(['batch', '/', 'cast']),
				value: `${_item.batch_number} / ${_item.cast_number}`,
				wrap: true,
			},
			{
				label: strings('product'),
				value: _item.product_name,
				wrap: true,
			},
			{
				label: strings('brand'),
				value: _item.brand,
				wrap: true,
			},
			{
				label: strings(['label_weight_mt', '/', 'units']),
				value: `${decimalSeparatedNumber(_item.total_weight)} / ${decimalSeparatedNumber(_item.quantity)}`,
			},
		]

		const referenceNumbers = (_item.mb_reference_number_from_inventory ?? []).map((_i, index) => ({
			id: 'mb_reference_' + _i,
			value: _i,
		}))

		const selectedReferenceNumbers = _item.mb_reference_number.map((_i, index) => ({
			id: 'mb_reference_' + _i,
			value: _i,
		}))

		const selectedProcess = PROCESS_TYPES.find((_p) => _p.key === _item.process_type) ?? {}

		const bottomPanel = (
			<>
				<ExntededPanelItemsWrapper>
					<WeightUnitExntendedItemWrapper>
						<div>
							<Input
								viewOnly
								type='number'
								placeholder='Weight'
								label={strings('used_weight')}
								value={_item.alloted_weight}
								max={_item.total_weight}
								errorMap={{
									rangeOverflow: strings('max_available_mt', [decimalSeparatedNumber(_item.total_weight)]),
								}}
								onChange={updateItem(_item.identifier, 'alloted_weight')}
							/>
						</div>
						<div>
							<Input
								viewOnly
								type='number'
								placeholder='Units'
								label={strings('used', 'units')}
								value={_item.alloted_units}
								max={_item.quantity}
								errorMap={{
									rangeOverflow: strings('max_available', [decimalSeparatedNumber(_item.quantity)]),
								}}
								step='1'
								onChange={updateItem(_item.identifier, 'alloted_units')}
							/>
						</div>
					</WeightUnitExntendedItemWrapper>
					<ExntendedItemWrapper width={30}>
						<Input
							type='number'
							placeholder={strings('placeholder_cost_per_mt')}
							step='1'
							label={strings('cost_per_mt')}
							value={_item.price_per_ton}
							onChange={updateItem(_item.identifier, 'price_per_ton')}
						/>
					</ExntendedItemWrapper>
					<FlexRowWrapper>
						<ExntendedItemWrapper width={60}>
							<MultiSelect
								id='mb-ref-numbers'
								label={strings('mb_ref_no')}
								placeholder=''
								value={selectedReferenceNumbers}
								displayKey='value'
								primaryKey='id'
								data={referenceNumbers}
								onChange={updateRefNumbers(_item)}
							/>
						</ExntendedItemWrapper>
						<ExntendedItemWrapper width={40} margin={`0 0 0 ${Spacings.SPACING_3B}`}>
							<Select
								label={strings('process_type')}
								placeholder={strings('select_process')}
								value={selectedProcess}
								displayKey='label'
								primaryKey='key'
								data={PROCESS_TYPES}
								onChange={(val) => updateItem(_item.identifier, 'process_type')(val.key)}
							/>
						</ExntendedItemWrapper>
					</FlexRowWrapper>
				</ExntededPanelItemsWrapper>
				<CardButtonsWrapper>
					{isSelected && (
						<Button xs type='secondary' variant='destructive' margin='0 0 0 auto' onClick={removeSelectedItem(item)}>
							{strings('remove')}
						</Button>
					)}
					{!isSelected && (
						<Button xs margin='0 0 0 auto' disabled={!item.isValid()} onClick={addItem(item)}>
							{strings('add')}
						</Button>
					)}
				</CardButtonsWrapper>
			</>
		)

		return (
			<CloudInventoryCard
				showBorder={highlight === _item.identifier}
				key={`input-order-item-${_item.identifier}`}
				data={data}
				renderBottomPanel={bottomPanel}
			/>
		)
	}

	const renderCards = () => {
		const selectedCardsItems = inventoryItems.filter((_i) => _i.selected)
		const nonSelectedCardsItems = inventoryItems.filter((_i) => !_i.selected)

		return (
			<>
				{!!selectedCardsItems.length && <ListLabel>{strings('selected_items')}</ListLabel>}
				{selectedCardsItems.map((item) => renderOrderInputItemCard(item, true))}
				{!!selectedCardsItems.length && <br />}
				{!!nonSelectedCardsItems.length && <ListLabel>{strings('available_items')}</ListLabel>}
				{nonSelectedCardsItems.map((item) => renderOrderInputItemCard(item, false))}
			</>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				{renderCards()}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small onClick={doneHandler} disabled={isAnyItemInvalid}>
					{strings('done')}
				</Button>
				<Button small type='secondary' margin={`0 ${Spacings.SPACING_3B} 0 auto`} onClick={cancelHandler}>
					{strings('cancel')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddInventoryItems
