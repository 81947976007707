import ProductAttributes from './ProductAttributes'

class Product {
	constructor() {
		this.product_id = ''
		this.category_id = null
		this.category_name = null
		this.brands = [] //string
		this.description = null //string
		this.product_name = null //string
		this.thumbnail_url = null //string
		this.rating = null //float
		this.usual_price_per_kg = null //float
		this.weight_per_piece = null //float
		this.weight_per_meter = null

		this.product_attributes = new ProductAttributes()

		this.access_type = 'web' //string
		this.updated_at = new Date().getTime() //date
		this.updated_by = ''
		this.created_at = new Date().getTime() //date
		this.created_by = ''
	}
}

export default Product
