import getUrl from '@data/conn/get/paths'
import API from '../../conn/api'
import putUrl from '../../conn/put/paths'
import { User } from '../../model'
import postUrl from '@data/conn/post/paths'

export const SET_USER = 'SET_USER'
export const SET_ORGANISATION = 'SET_ORGANISATION'

export const USER_UPDATED_PROGRESS = 'USER_UPDATED_PROGRESS'
export const USER_UPDATED_SUCCESS = 'USER_UPDATED_SUCCESS'
export const USER_UPDATED_FAILURE = 'USER_UPDATED_FAILURE'
export const USER_PROFILE_UPDATED_SUCCESS = 'USER_PROFILE_UPDATED_SUCCESS'
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION'

export const setUser = (payload) => ({
	type: SET_USER,
	payload,
})

export const setOrganisation = (payload) => ({
	type: SET_ORGANISATION,
	payload,
})

export const updateUser =
	(updatedUserData, isAdmin = false, onSuccess, onError, onFinal) =>
	(dispatch) => {
		dispatch({
			type: USER_UPDATED_PROGRESS,
		})

		let promise

		if (typeof updatedUserData === 'string') {
			promise = API.get(getUrl.getUserById, { user_id: updatedUserData })
		} else {
			promise = API.put(putUrl.editUser, updatedUserData)
		}

		return promise
			.then((response) => {
				if (!isAdmin) {
					dispatch({
						type: USER_UPDATED_SUCCESS,
						data: new User(response.data),
					})
				} else {
					dispatch({
						type: USER_PROFILE_UPDATED_SUCCESS,
						// data: new User(response.data),
					})
				}
				if (typeof onSuccess === 'function') {
					onSuccess(response)
				}
			})
			.catch((error) => {
				dispatch({
					type: USER_UPDATED_FAILURE,
					error,
				})
				if (typeof onError === 'function') {
					onError(error)
				}
			})
			.finally(() => {
				if (typeof onFinal === 'function') {
					onFinal()
				}
			})
	}

export const editUser = (updatedUserData) => (dispatch) => {
	return API.put(putUrl.editUser, updatedUserData)
		.then()
		.catch((err) => console.log(err))
}

export const updateOrganisation =
	(orgId, commodityId, isAdmin = false, onSuccess, onError, onFinal) =>
	(dispatch) => {
		return API.post(postUrl.activateCommodity, {}, { orgId, commodityId })
			.then((response) => {
				dispatch({
					type: UPDATE_ORGANISATION,
					payload: response?.data,
				})
				if (typeof onSuccess === 'function') {
					onSuccess(response)
				}
			})
			.catch((error) => {
				dispatch({
					type: USER_UPDATED_FAILURE,
					error,
				})
				if (typeof onError === 'function') {
					onError(error)
				}
			})
			.finally(() => {
				if (typeof onFinal === 'function') {
					onFinal()
				}
			})
	}
