import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import NewReportForm from './NewReportForm'
import { ActionButton } from './styles'
import { createReport } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'

const initialFormState = {
	pan: {
		label: strings(['pan', '/', 'gst']),
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	userDetails: {
		label: strings('select', 'user'),
		disabled: false,
		value: '',
		dirty: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.pan.value = data?.pan ?? ''
	derivedState.pan.disabled = !editable

	derivedState.userDetails.value = data?.user ?? ''
	derivedState.userDetails.disabled = !editable

	return derivedState
}

const NewReport = ({ item, editable = true, bankDetails, update }) => {
	const [formState, setFormState] = useState(initialFormState)
	const dispatch = useDispatch()

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, item, !!editable))
	}, [editable])

	const addNewItemHandler = () => {
		dispatch(showLoader(true))
		createReport(formState.pan?.value, formState.userDetails?.value || {})
			.then((res) => {
				dispatch(
					toggleDrawer(true, DRAWER_TYPES.UNDERWRITING_STATUS, {
						label: strings('update', 'underwriting'),
						bankDetails,
						reportDetails: { ...res, pan: formState.pan?.value },
					})
				)
				typeof update === 'function' && update()
				dispatch(showToast(true, strings('create_underwriting_successfully'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const disableButton = () => {
		let panRegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/
		let gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
		if (panRegex.test(formState.pan.value) || gstRegex.test(formState.pan.value)) {
			return false
		}
		return true
	}

	const renderButtons = () => {
		return (
			<ActionButton small type='primary' disabled={disableButton()} onClick={addNewItemHandler}>
				{strings('generate', 'report')}
			</ActionButton>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<NewReportForm state={formState} updateState={updateFormState} bankDetails={bankDetails} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default NewReport
