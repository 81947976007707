import React, {  useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, Input, Select, TOAST_TYPE, Textarea } from '@base'
import { LEADS_TASK_TYPE, MEETING_MODE, TASK_PRIORITY, strings } from '@constants'
import { showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { Spacings, Text } from '@styles'
import { Cancel } from '@material-ui/icons'
import { addLeadTask } from '@data/conn/put/leads'

const InputWrapper = styled.div`
	padding: ${Spacings.SPACING_1B} 0;
	& > div {
		width: 100%;
	}
`

const BiInputWrapper = styled.div`
	display: grid;
	grid-template-columns: ${({ withButton }) => (withButton ? `5fr 1fr` : `3fr 3fr`)};
	column-gap: ${Spacings.SPACING_2B};
	align-items: center;
`

const ActionButton = styled(Button)`
	height: 70%;
`

const OptionPill = styled.div`
	display: flex;
	font-size: ${Text.EXTRA_SMALL};
	min-height: ${Spacings.SPACING_6B};
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.palette.background.pill};
	margin-right: ${Spacings.SPACING_2B};
	border-radius: ${Spacings.SPACING_2B};
	padding: 0 ${Spacings.SPACING_6B} 0 ${Spacings.SPACING_2B};
	position: relative;
	flex-shrink: 0;
`
const PillsWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: ${Spacings.SPACING_1B};
	max-height: fit-content;
	transition: all 0.15s ease-in-out;
`
const CrossIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: ${Spacings.SPACING_1B};
	cursor: pointer;

	& > svg {
		font-size: ${Text.MEDIUM};
		fill: ${({ theme }) => theme.palette.text.darkblack};
	}
`

const priorityType = Object.entries(TASK_PRIORITY).map(([key, value]) => ({ key, label: value }))
const taskTypes = Object.entries(LEADS_TASK_TYPE).map(([key, value]) => ({ key, label: value }))
const meetingMode = Object.entries(MEETING_MODE).map(([key, value]) => ({ key, label: value }))
const taskState = (data, taskType) => {
	const _state = {
		title: {
			id: 'title',
			label: strings('title'),
			placeholder: strings('enter', 'title'),
			value: '',
			required: true,
		},
		description: {
			id: 'description',
			label: strings('description'),
			placeholder: strings('enter', 'description'),
			value: '',
			required: false,
		},
		date: {
			id: 'task-date',
			label: strings('task', 'date'),
			placeholder: strings(['select', 'date']),
			value: new Date().getTime(),
			required: true,
		},
		priority: {
			id: 'priority',
			label: strings('priority'),
			placeholder: strings('select', 'priority'),
			data: priorityType,
			value: {},
			displayKey: 'label',
			primaryKey: 'key',
			required: true,
		},
		type: {
			id: 'type',
			label: strings('type'),
			placeholder: strings('select', 'type'),
			data: taskTypes,
			value: null,
			displayKey: 'label',
			primaryKey: 'key',
			required: true,
		},
		meeting_mode: {
			id: 'meeting-mode',
			label: strings('meeting', 'mode'),
			placeholder: strings(['select', 'meeting', 'mode']),
			data: meetingMode,
			value: null,
			displayKey: 'label',
			primaryKey: 'label',
			required: true,
		},
		invitee: {
			id: 'invitee',
			label: strings('invitee', 'email'),
			placeholder: strings(['enter', 'email']),
			value: null,
			required: false,
		},
	}
	if (data) {
		_state.date.value = data?.date
		_state.title.value = data?.title
		_state.description.value = data?.description
		_state.meeting_mode.value = meetingMode.find((item) => item.key === data?.meeting_mode)
		_state.priority.value = priorityType.find((item) => item.key === data?.priority)
		_state.type.value = taskTypes.find((item) => item.key === data?.type)
	} else {
		_state.type.value = taskTypes.find((item) => item.key === taskType)
		_state.meeting_mode.value = meetingMode.find((item) => item.key === 'OFFLINE')
		_state.priority.value = priorityType.find((item) => item.key === 'MEDIUM')
	}
	return _state
}

const AddTasks = ({ leadId, data = null, taskType, update, editable = true }) => {
	const [formState, setFormState] = useState(taskState(data, taskType))
	const [taskData, setTaskData] = useState({})
	const [invitees, setInvitees] = useState(data?.invitees ? data?.invitees : [])
	const dispatch = useDispatch()
	const updateState = (key) => (val) => {
		setFormState((_s) => ({
			..._s,
			[key]: {
				..._s[key],
				value: val,
			},
		}))
	}
	const onChange = (key) => (val) => {
		updateState(key)(val)

		if (['priority', 'type', 'meeting_mode'].includes(key)) {
			setTaskData({ ...taskData, [key]: val?.key })
		} else {
			setTaskData({ ...taskData, [key]: val })
		}
	}
	const addHandler = () => {
		let _task = { ...taskData }

		if (!taskData?.meeting_mode) {
			_task = { ..._task, meeting_mode: formState.meeting_mode?.value?.key }
			_task = { ..._task, priority: formState.priority?.value?.key }
		}

		addLeadTask(leadId, { ..._task, type: taskType, date: formState.date?.value, invitees: invitees })
			.then((response) => {
				dispatch(showToast(true, strings('task', 'added'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, strings(['task', 'not', 'added']), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
			})
		dispatch(toggleDrawer(false))
	}

	const disabled = useMemo(() => {
		return Object.values(formState).some((d) => d.required && !d.value)
	}, [formState])

	const deleteInvitee = (invitee) => {
		setInvitees(invitees.filter((item) => item !== invitee))
		setTaskData((_s) => ({ ..._s, invitees: invitees }))
	}

	const renderInvitees = () => {
		return (
			<PillsWrapper>
				{invitees.map((item, index) => {
					return (
						<OptionPill key={`${index}`}>
							{item}
							{editable && (
								<CrossIconWrapper onClick={() => deleteInvitee(item, index)}>
									<Cancel />
								</CrossIconWrapper>
							)}
						</OptionPill>
					)
				})}
			</PillsWrapper>
		)
	}
	const addInvitee = () => {
		setInvitees((_s) => [..._s, formState.invitee?.value])
		setTaskData((_s) => ({ ..._s, invitees: invitees }))
		updateState('invitee')('')
	}

	return (
		<>
			<DrawerBodyWrapper>
				<InputWrapper>
					<Input
						label={formState.title.label}
						id={formState.title.id}
						type='text'
						value={formState.title.value}
						onChange={onChange('title')}
						placeholder={formState.title.placeholder}
						disabled={!editable}
					/>
				</InputWrapper>
				<InputWrapper>
					<Textarea
						id={formState.description.id}
						type='text'
						rows={4}
						label={formState.description.label}
						placeholder={strings(['enter', 'description', '...'])}
						value={formState.description.value}
						onChange={onChange('description')}
						disabled={!editable}
					/>
				</InputWrapper>
				<InputWrapper>
					<BiInputWrapper>
						<Calendar
							label={formState.date.label}
							value={formState.date.value}
							placeholder={formState.date.placeholder}
							onChange={onChange('date')}
							disabled={!editable}
						/>
						<Select
							id={formState.type.id}
							label={formState.type.label}
							placeholder={formState.type.placeholder}
							data={formState.type.data}
							primaryKey={formState.type.primaryKey}
							displayKey={formState.type.displayKey}
							value={formState.type.value || {}}
							onChange={onChange('type')}
							disabled={true}
						/>
					</BiInputWrapper>
				</InputWrapper>
				<InputWrapper>
					<BiInputWrapper>
						<Select
							id={formState.priority.id}
							label={formState.priority.label}
							placeholder={formState.priority.placeholder}
							data={priorityType}
							displayKey={formState.priority.displayKey}
							primaryKey={formState.priority.primaryKey}
							value={formState.priority.value || {}}
							onChange={onChange('priority')}
							disabled={!editable}
						/>
						<Select
							id={formState.meeting_mode.id}
							label={formState.meeting_mode.label}
							placeholder={formState.meeting_mode.placeholder}
							data={formState.meeting_mode.data}
							displayKey={formState.meeting_mode.displayKey}
							primaryKey={formState.meeting_mode.primaryKey}
							value={formState.meeting_mode.value || {}}
							onChange={onChange('meeting_mode')}
							disabled={!editable}
						/>
					</BiInputWrapper>
				</InputWrapper>
				<InputWrapper>
					{invitees.length > 0 && renderInvitees()}
					<BiInputWrapper withButton>
						<Input
							id={formState.invitee.id}
							type='email'
							label={formState.invitee.label}
							placeholder={formState.invitee.placeholder}
							value={formState.invitee.value}
							onChange={updateState('invitee')}
							pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
							errorMap={{
								patternMismatch: `${strings('enter_valid_email')}`,
							}}
							disabled={!editable}
						/>
						<ActionButton small disabled={!formState.invitee.value} onClick={addInvitee}>
							{strings('add')}
						</ActionButton>
					</BiInputWrapper>
				</InputWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				{/* disable when any of condition is true */}
				<Button small onClick={addHandler} disabled={disabled || !editable}>
					{strings('add', 'task')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddTasks
