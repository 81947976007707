import React, { useEffect } from 'react'
import { DrawerBodyWrapper, DrawerFooterWrapper, Button, Input, Pill, Banner } from '@base'
import { strings } from '@constants'
import { FormWrapper, FormItemWrapper, InfoWrapper, Heading, CardWapper, CardInfo, StepTopWrapper } from './styles'
import { useState } from 'react'
import { displayAmount } from '@utils'
import { useDispatch } from 'react-redux'
import { showLoader } from '@data/state/action'
import { getLogisticsPricesRangeByPinCodes } from '@data'
import styled, { css } from 'styled-components'

const FooterWrapper = styled(DrawerFooterWrapper)`
	${({ reduceBorder }) => {
		if (reduceBorder) {
			return css`
				box-shadow: none;
			`
		}
	}}
`

const initialFormState = {
	fromPinCode: {
		name: 'fromPinCode',
		label: strings('from'),
		disabled: false,
		value: '',
		dirty: false,
	},
	toPinCode: {
		name: 'toPinCode',
		label: strings('to'),
		disabled: false,
		value: '',
		dirty: false,
	},
}

const deriveFormStateQuotesItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.toPinCode.value = data?.pincode ?? ''
	derivedState.toPinCode.disabled = !editable

	return derivedState
}

const Index = ({ totalWeight, toAddress, reduceBorder = false, editable = true }) => {
	const [formState, setFormState] = useState(initialFormState)
	const [resData, setResData] = useState()
	const dispatch = useDispatch()

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateQuotesItemData(_oldState, toAddress, !!editable))
	}, [editable])

	const onChangeHandler = (key) => (value) => {
		setFormState({ ...formState, [key]: { ...formState[key], value } })
	}

	const priceLabel = () => {
		if (totalWeight) return `${displayAmount(resData?.from_price_range * totalWeight)} - ${displayAmount(resData?.to_price_range * totalWeight)}`
		return `${displayAmount(resData?.from_price_range * 1)}/MT - ${displayAmount(resData?.to_price_range * 1)}/MT`
	}

	const renderForm = () => {
		return (
			<FormWrapper>
				<FormItemWrapper>
					<InfoWrapper>
						<Input
							type='number'
							label={formState?.fromPinCode?.label}
							value={formState?.fromPinCode?.value}
							disabled={formState?.fromPinCode?.disabled}
							onChange={onChangeHandler('fromPinCode')}
						/>
					</InfoWrapper>
					<InfoWrapper>
						<Input
							type='number'
							label={formState?.toPinCode?.label}
							value={formState?.toPinCode?.value}
							disabled={formState?.toPinCode?.disabled}
							onChange={onChangeHandler('toPinCode')}
						/>
					</InfoWrapper>
				</FormItemWrapper>
				{resData?.err && (
					<StepTopWrapper>
						<Banner extendInMobile text={resData?.err} />
					</StepTopWrapper>
				)}
				{resData?.distance && (
					<FormItemWrapper>
						<CardWapper>
							<CardInfo isLeft>
								<Heading>{strings('distance')}</Heading>
								<span>:</span>
							</CardInfo>
							<CardInfo isRight>
								<Pill label={`${resData?.distance}${strings('km')}`} />
							</CardInfo>
						</CardWapper>
						<CardWapper>
							<CardInfo isLeft>
								<Heading>{strings('price')}</Heading>
								<span>:</span>
							</CardInfo>
							<CardInfo isRight>
								<Pill
									label={priceLabel()}
								/>
							</CardInfo>
						</CardWapper>
					</FormItemWrapper>
				)}
			</FormWrapper>
		)
	}

	const onClickHandler = () => {
		dispatch(showLoader(true))
		getLogisticsPricesRangeByPinCodes(formState?.fromPinCode?.value, formState?.toPinCode?.value)
			.then((res) => {
				if (!res) {
					setResData({
						err: strings('freight_calculator_not_found'),
					})
				} else {
					setResData(res)
				}
			})
			.catch((err) => {
				setResData({
					err: strings('freight_calculator_not_found'),
				})
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const clearSearch = () => {
		setFormState(initialFormState)
		setResData()
	}

	const disableButton = () => {
		if (!formState?.fromPinCode?.value || !formState?.toPinCode?.value) return true
	}

	return (
		<>
			<DrawerBodyWrapper>{renderForm()}</DrawerBodyWrapper>
			<FooterWrapper reduceBorder={reduceBorder}>
				<Button margin={`0 0 0 auto`} small uppercase type='primary' onClick={onClickHandler} disabled={disableButton()}>
					{strings('get', 'price')}
				</Button>
				<Button small uppercase type='secondary' variant='destructive' onClick={clearSearch} disabled={disableButton()}>
					{strings('clear')}
				</Button>
			</FooterWrapper>
		</>
	)
}

export default Index
