import { Button, H5, NavigationWrapper } from '@base'
import { Description, OpenInNew } from '@material-ui/icons'
import { Devices, Spacings, Text } from '@styles'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const ButtonNavigationWrapper = styled(NavigationWrapper)`
	display: flex;
	justify-content: flex-end;
`
export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
		flex: 1;
	}
	${({ isTwo }) => {
		if (isTwo) {
			return css`
				& > div:first-child {
					margin-right: 10px;
				}
			`
		}
	}}
`
export const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`
export const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_2B};
`
export const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	padding: ${Spacings.SPACING_2B} 0;
`
export const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
export const MenuIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	& > svg {
		font-size: ${Text.LARGE};
	}
`

export const OpenInNewIcon = styled(OpenInNew)`
	cursor: pointer;
	&: hover {
		color: ${({ theme }) => theme.palette.background.blueLight};
	}
`
export const FileName = styled.div`
	display: flex;
	justify-content: flex-end;
	font-size: ${Text.SMALL};
	line-height: ${Text.LINE_HEIGHT_MEDIUM};
	flex-grow: 1;
`