import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { BaseWrapper, Pill, SectionHeader, Table, Tabs } from '@base'

export const ResponsiveStepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	@media ${Devices.tablet} {
		flex-direction: row;
	}
`

export const ResponsiveLeft = styled.div`
	flex: 1;
	flex-grow: 0;
	width: 100%;
	margin-right: ${Spacings.SPACING_3B};
`
export const ResponsiveRight = styled.div`
	display: flex;
	flex: 11;
	width: 100%;
	justify-content: space-between;
	flex-direction: column;
	margin-top: ${Spacings.SPACING_2B};
	@media ${Devices.mobile} {
		margin-top: 0;
	}
`
export const Wrapper = styled(BaseWrapper)`
	display: flex;
	flex-direction: row;
	padding: 0;
	width: 100%;
	justify-content: space-evenly;
	flex-wrap: wrap;
	@media ${Devices.tablet} {
		flex-direction: column;
		width: 50%;
	}
	@media ${Devices.laptop} {
		flex-direction: column;
		width: 50%;
		// margin-left: ${Spacings.SPACING_5B};
	}

	@media ${Devices.mobile} {
		padding-bottom: ${Spacings.SPACING_4B};
		padding-left: 0;
	}
	${({ isMenu }) => {
		if (isMenu) {
			return css`
				background: none;
				box-shadow: none;
			`
		}
	}}
`
export const FormItemWrapper = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.palette.background.white};
	${({ inIsolation }) => {
		if (!inIsolation) {
			return css`
				padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};
				margin-bottom: ${Spacings.SPACING_4B};
			`
		}
	}}

	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex: 1 1 0;
		border-radius: ${Spacings.SPACING_2B};
	}
`
export const BoxWapper = styled.div`
	font-size: ${Text.EXTRA_SMALL};
	display: flex;
	flex-direction: row;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_1B};
	cursor: pointer;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	& > p {
		width: 3rem;
		text-align: center;
	}
	@media ${Devices.mobile} {
		& > p {
			width: 4rem;
		}
		margin: 0 0 ${Spacings.SPACING_2B} 0;
		flex-direction: row;
	}
	@media ${Devices.tablet} {
		// justify-content: flex-start;
		flex-direction: column;
	}
	border-radius: ${Spacings.SPACING_2B};
	box-shadow: 1px 1px ${({ theme }) => theme.palette.background.disabled};
	border: 1px solid ${({ theme }) => theme.palette.background.border};
	fill: ${({ theme }) => theme.palette.text.grey};
	color: ${({ theme }) => theme.palette.background.blueLight};
	background: ${({ theme }) => theme.palette.background.white};
	&: hover {
		background: ${({ theme }) => theme.palette.background.blueLight};
		& > svg {
			fill: ${({ theme }) => theme.palette.background.white};
		}
		& > p {
			color: ${({ theme }) => theme.palette.background.white};
		}
	}

	${({ isSelected }) => {
		if (isSelected) {
			return css`
				border: 1px solid ${({ theme }) => theme.palette.background.blueLight};
				background: ${({ theme }) => theme.palette.background.blueLight};
				& > p {
					color: ${({ theme }) => theme.palette.background.white};
				}
				& > svg {
					fill: ${({ theme }) => theme.palette.background.white};
				}
			`
		}
	}}
`

export const ItemWapper = styled.div`
	display: flex;
	margin-bottom: ${Spacings.SPACING_4B};
	& > div {
		width: 100%;
	}
	div:nth-child(1) {
		flex: 4;
		display: flex;
		justify-content: space-between;
	}
	div:nth-child(2) {
		flex: 8;
		margin-left: 10px;
	}
`
export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const SectionHeaderTop = styled(SectionHeader)`
	display: flex;
	min-height: 2.25rem;
	background: #ffffff;
	width: fit-content;
	border-radius: 0.5rem 0.5rem 0 0;
	padding: 10px;
`

export const AccordionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 0 ${Spacings.SPACING_3B} ${Spacings.SPACING_3B};
	background: linear-gradient(180deg, rgba(255, 254, 254, 1) 0%, rgba(255, 254, 254, 1) 68%);
	border: 1px solid rgba(234, 241, 250, 1);
	border-top: none;
	margin-bottom: ${Spacings.SPACING_3B};
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	padding: 10px;
	// box-shadow: 0px ${Spacings.SPACING_1B} ${Spacings.SPACING_3B} 0.125rem ${({ theme }) => theme.palette.background.blue + '17'};
	${({ ishigth }) => {
		if (ishigth) {
			return css`
				height: ${ishigth};
			`
		}
	}}
`

export const TabsWrapper = styled(Tabs)`
	border-radius: ${Spacings.SPACING_2B} ${Spacings.SPACING_2B} 0 0;
	div:nth-child(1) {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	div:nth-child(3) {
		border-bottom-right-radius: 0;
	}
`
export const MoreDirector = styled.div`
	background: ${({ theme }) => theme.palette.background.tablegrey};
	color: ${({ theme }) => theme.palette.background.blackGrey};
	font-size: ${Text.EXTRA_SMALL};
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	line-height: 1;
	border-radius: ${Spacings.SPACING_3B};
	margin: 0px;
	display: inline-block;
	height: fit-content;
	width: fit-content;
	cursor: pointer;
`

export const PillWapper = styled(Pill)`
	${({ type }) => {
		if (type === 'low') {
			return css`
				background: ${({ theme }) => theme.palette.background.yellowLightest};
				border: 1px solid ${({ theme }) => theme.palette.background.yellowLight};
			`
		}
		if (type === 'medium') {
			return css`
				background: ${({ theme }) => theme.palette.background.orangeLightest};
				border: 1px solid ${({ theme }) => theme.palette.background.orangeLight};
			`
		}
	}}
`
export const TableWrapper = styled(Table)`
	& div:last-child {
		height: auto;
	}
`

export const TypeWapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	${({ isTop }) => {
		if (isTop) {
			return css`
				padding-top: ${Spacings.SPACING_1B};
			`
		}
	}}
`
export const LabelWapper = styled.div`
	flex: 3;
	display: flex;
`
export const ValueWapper = styled.div`
	flex: 9;
`
export const BannerText = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`
