class CategoryAttributes {
	constructor() {
		this.custom_details = false
		this.diameter = false
		this.height = false
		this.length = true
		this.thickness = false
		this.width = false
		this.gsm = false
	}
}

export default CategoryAttributes
