class Commodity {
  constructor() {
    this.commodity_id = 1;
    this.commodity_name = 'steel';
    this.commodity_type = 'metal';
    this.brands = null; //list
  }
}

export default Commodity;
