import React, { useMemo } from 'react'
import { strings } from '@constants'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { PageLevelTableWrapper, SectionHeader, Table } from '@base'

const ManagementContactDetails = ({ financialData }) => {
	const POC = financialData?.poc || []
	const columns = useMemo(
		() => [
			{
				label: strings('name'),
				key: (item) => {
					return (
						<>
							{item?.organisation_name || item?.full_name} <b>{item?.mobile_number}</b>
						</>
					)
				},
				mobile: true,
			},
			{
				label: strings('type'),
				key: (item) => {
					return item?.role || '--'
				},
				mobile: true,
			},
		],
		[]
	)

	return (
		<DetailsWrapper>
			<SectionHeader>{strings(['contact', 'details'])}</SectionHeader>
			<GridListWrapper itemPerRow={[1, 1, 1]}>
				<PageLevelTableWrapper>
					<Table fullHeight data={POC} columns={columns} page={0} hideFooter rowClickable={false} />
				</PageLevelTableWrapper>
			</GridListWrapper>
		</DetailsWrapper>
	)
}

export default ManagementContactDetails
