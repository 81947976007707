import React, { useEffect, useMemo, useState } from 'react'
import { DRAWER_TYPES, strings } from '@constants'
import { PageLevelTableWrapper, Table, TableColumns } from '@base'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { getDataCorrectionList } from '@data'
import { showLoader } from '@data/state/action'
import { displayAmount } from '@utils'

const Ledger = ({ update, forceUpdate }) => {
	const dispatch = useDispatch()
	const [data, setData] = useState([])

	useEffect(() => {
		dispatch(showLoader(true))
		getDataCorrectionList()
			.then((res) => {
				setData(res)
			})
			.catch((err) => console.log(err))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}, [forceUpdate])

	const columns = useMemo(
		() => [
			{
				label: strings('transaction', 'id'),
				key: 'transaction_id',
				mobile: true,
			},
			{
				label: strings('document', 'number'),
				key: 'original_document_number',
				mobile: true,
			},
			{
				label: strings('organisation'),
				key: 'organisation_name',
			},
			TableColumns.dateColumn({
				align: 'left',
				key: 'transaction_date',
				label: strings('date'),
			}),

			{
				label: strings('amount'),
				key: (item) => {
					return <>{displayAmount(item?.transaction_amount, { default: 0 })}</>
				},
			},
			{
				label: strings('trading_expense'),
				key: 'trading_expense',
			},
			{
				label: strings('zoho', 'link'),
				key: 'zoho_link',
			},
			{
				label: strings('source'),
				key: 'source',
			},
		],
		[]
	)
	const rowClickHandler = (row) => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.DATA_CORRECTION_TRADING_EXPENSE, {
				label: strings('edit', 'trading_expense'),
				data: row,
				update: update,
			})
		)
	}

	return (
		<PageLevelTableWrapper>
			<Table
				page={0}
				fullHeight
				data={data}
				columns={columns}
				rowsToDisplay={data?.length}
				totalItemCount={data?.length}
				rowClickable={true}
				onRowClick={rowClickHandler}
			/>
		</PageLevelTableWrapper>
	)
}

export default Ledger
