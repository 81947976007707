import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, DrawerBodyWrapper, DrawerFooterWrapper, ImageHandler, SectionHeader, Select, TOAST_TYPE } from '@base'
import { Spacings } from '@styles'
import { strings } from '@constants'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { showLoader, showToast } from '@data/state/action'
import OrderItemJourney from '@data/model/NewModals/CloudFactory/OrderItemJourney'
import { uploadFabricationJourneyPhotos, uploadFactoryReceivingPhoto } from '@data'

import { FieldWrapper, PhotosWrapper, PhotosSectionHeading } from '../../../styles'

const ManageItemJourney = ({ orderId, data, machines, doneHandler, edit, view }) => {
	const dispatch = useDispatch()
	const [journeyData, setJourneyData] = useState()

	useEffect(() => {
		setJourneyData(new OrderItemJourney(data.toJS()))
	}, [data])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: view ? strings('view', 'journey') : edit ? strings('edit', 'journey') : strings('start', 'journey'),
			})
		)
	}, [dispatch, view, edit])

	const selectedMachine = useMemo(() => {
		if (journeyData) {
			return machines.find((_m) => _m.machine_id === journeyData.machine?.machine_id) ?? {}
		}
		return {}
	}, [machines, journeyData])

	const close = () => {
		dispatch(toggleDrawer(false))
	}

	const done = () => {
		doneHandler(journeyData)
		close()
	}

	const onMachineChange = (machine) => {
		setJourneyData((_j) => _j.update({ machine }))
	}

	const uploadPhotoHandler = (files, key, isReceivingPhotos) => {
		dispatch(showLoader(true))
		const promises = Array.from(files).map((item) => {
			const promise = isReceivingPhotos ? uploadFactoryReceivingPhoto(item) : uploadFabricationJourneyPhotos(item, orderId)

			return promise
				.then((response) => {
					if (response?.file_path) {
						return response?.file_path
					} else {
						return Promise.reject()
					}
				})
				.catch((err) => {
					dispatch(showToast(true, `${strings('msg_img_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
				})
		})

		Promise.all(promises)
			.then((values) => {
				if (values.includes(undefined)) {
					dispatch(showToast(true, strings('msg_img_upload_failed'), { type: TOAST_TYPE.ERROR }))
				} else {
					dispatch(showToast(true, strings('msg_success_upload_img'), { type: TOAST_TYPE.SUCCESS }))
					setJourneyData((_j) => _j.update({ [key]: [..._j[key], ...values] }))
				}
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const renderPhotosSection = (key, heading, isReceivingPhotos) => (
		<PhotosWrapper>
			<PhotosSectionHeading>{heading}</PhotosSectionHeading>
			<ImageHandler viewOnly={view} images={journeyData[key] ?? []} handler={(files) => uploadPhotoHandler(files, key, isReceivingPhotos)} />
		</PhotosWrapper>
	)

	if (!journeyData) return null

	return (
		<>
			<DrawerBodyWrapper>
				<FieldWrapper>
					<Select
						disabled={view}
						id='admin-journey-machine-select'
						placeholder={strings('select', 'machine')}
						data={machines}
						displayKey='machine_name'
						primaryKey='machine_id'
						value={selectedMachine}
						onChange={onMachineChange}
						label={strings('select', 'machine')}
					/>
				</FieldWrapper>
				<br />
				{renderPhotosSection('received_at_warehouse', strings('received_warehouse_photos'), true)}
				<br />
				{renderPhotosSection('before_process', strings('before_process_photos'))}
				<br />
				{renderPhotosSection('during_process', strings('during_process_photos'))}
				<br />
				{renderPhotosSection('after_process', strings('after_process_photos'))}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				{!view && (
					<Button small onClick={done}>
						{strings('done')}
					</Button>
				)}
				<Button small type='secondary' margin={`0 ${view ? 0 : Spacings.SPACING_3B} 0  auto`} onClick={close}>
					{view ? strings('close') : strings('cancel')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default ManageItemJourney
