import { combineReducers } from 'redux-immutable'
import {
	SET_TOAST_MESSAGE,
	SHOW_LOADER,
	SIDEBAR_INDEX,
	TAB_TITLE_CHANGE,
	UPDATE_DRAWER_META,
	MODAL_CHANGE,
	TOGGLE_DRAWER,
	SEARCH_OBJECT_CHANGE,
	SET_HEADER_META,
	TOGGLE_MODAL,
	UPDATE_MODAL_META,
	TOGGLE_FILE_VIEWER,
	PLAY_NOTIFICATION_SOUND,
} from '../actions'
import initialState from '../state'
import userReducer from './user'

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case SET_TOAST_MESSAGE: {
			return state.merge({ toast: action.payload })
		}
		case SHOW_LOADER:
			return state.merge({ showLoader: action.payload })
		case SIDEBAR_INDEX:
			return state.merge({ sidebarTitle: action.payload })
		case TAB_TITLE_CHANGE:
			return state.merge({ tabTitle: action.payload })
		case MODAL_CHANGE:
			return state.merge({ modal: action.payload })
		case TOGGLE_DRAWER:
			return state.merge({
				showDrawer: action.showDrawer,
				drawerType: action?.drawerType,
				drawerTypeMeta: action?.drawerTypeMeta ?? {},
			})
		case UPDATE_DRAWER_META:
			const drawerTypeMeta = state.getIn(['drawerTypeMeta'])
			return state.merge({
				drawerTypeMeta: {
					...drawerTypeMeta,
					...action?.drawerTypeMeta,
				},
			})
		case TOGGLE_MODAL:
			return state.merge({
				showModal: action.showModal,
				modalType: action?.modalType,
				modalMeta: action?.modalMeta ?? {},
			})
		case UPDATE_MODAL_META:
			const modalMeta = state.getIn(['modalMeta'])
			return state.merge({
				modalMeta: {
					...modalMeta,
					...action?.modalMeta,
				},
			})
		case SEARCH_OBJECT_CHANGE:
			return state.merge({
				search: action.payload,
			})
		case SET_HEADER_META:
			return state.merge({
				headerMeta: action.payload,
			})

		case TOGGLE_FILE_VIEWER:
			return state.merge({
				showFileViewer: action.showFileViewer,
				fileViewerData: action?.fileViewerData,
			})
		case PLAY_NOTIFICATION_SOUND:
			return state.merge({
				notification: { play: action.payload },
			})
		default:
			return state
	}
}

export default combineReducers({
	root: rootReducer,
	user: userReducer,
})
