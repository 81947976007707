import styled, { css } from 'styled-components'
import { Button, DrawerBodyWrapper, H5, AddressCard } from '@base'
import { Devices, Spacings } from '@styles'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { DRAWER_TYPES, strings } from '@constants'

const BodyWrapper = styled.div`
	padding-top: ${Spacings.SPACING_1B};
`

const LabelHeader = styled(H5)`
	color: ${({ theme }) => theme.palette.text.black};
	margin: ${Spacings.SPACING_4B} 0;
`

const AddressListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const AddressCardWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_2B};
	flex-grow: 1;
	flex-basis: 100%;

	border: 1px solid transparent;
	border-radius: ${Spacings.SPACING_2B};
	overflow: hidden;
	transition: border-color 0.15s ease-in-out;

	${({ selected }) => {
		if (selected) {
			return css`
				cursor: initial;
				border-color: ${({ theme }) => theme.palette.background.blue};
			`
		}
	}}

	${({ hoverable = true }) => {
		if (hoverable) {
			return css`
				cursor: pointer;
				&:hover {
					border-color: ${({ theme }) => theme.palette.background.blue};
				}
			`
		}
	}}
`

const SelectAddress = ({ user, updateCallback, addToOrg = false, addToOrgCallback }) => {
	const dispatch = useDispatch()

	const addAddressHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.ADD_NEW_ADDRESS, {
				addToNewAuction: true,
				updateCallback,
				label: strings('add_new_address'),
				addToNewEnquiry: true,
				addToOrgCallback,
				addToOrg,
			})
		)
	}

	const selectAddress = (address) => () => {
		updateCallback(address)
		dispatch(toggleDrawer(false))
	}

	return (
		<>
			<DrawerBodyWrapper>
				<BodyWrapper>
					<div>
						<Button small uppercase type='primary' onClick={addAddressHandler}>
							{strings('add_new_address')}
						</Button>
					</div>
					<LabelHeader>{strings('select_from_existing_addresses')}</LabelHeader>
					<AddressListWrapper>
						{(user?.addresses ?? []).map((address, index) => {
							return (
								<AddressCardWrapper onClick={selectAddress(address)} key={`address-card-from-addressbook-${index}`}>
									<AddressCard data={address} />
								</AddressCardWrapper>
							)
						})}
					</AddressListWrapper>
				</BodyWrapper>
			</DrawerBodyWrapper>
		</>
	)
}

export default SelectAddress
