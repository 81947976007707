import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { BasicButton, Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, TOAST_TYPE, TimeLine } from '@base'
import { strings } from '@constants'
import { getJourneyBySourceId } from '@data'
import { showToast } from '@data/state/action'
import { Done, EditOutlined, ForumOutlined, GolfCourse, GroupAdd,Message, Money, PersonOutline, Send } from '@material-ui/icons'
import { Spacings } from '@styles'
import { addMessageToEnquiryJourney } from '@data/conn/put/journey'
import { useForceUpdate } from '@hooks'

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const InputWrapper = styled.div`
	flex-grow: 1;
	margin-right: ${Spacings.SPACING_1B};
`

const SendButton = styled(BasicButton)`
	justify-content: center;
	border-radius: 50%;
	height: 2.35rem;
	width: 2.35rem;
	margin-left: ${Spacings.SPACING_2B};

	& > svg {
		fill: ${({ theme }) => theme.palette.text.white};
		font-size: ${Text.X_LARGE};
	}
`
const EnquiryJourneyState = (data, journeySourceId) => {
	switch (data?.action) {
		case 'ENQUIRY_CREATED':
			return {
				text: `Enquiry has been created by ${data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number}.`,
				icon: <EditOutlined />,
			}

		case 'ASSIGN_ENQUIRY':
			return {
				text: `Enquiry has been assigned to ${data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number}.`,
				icon: <PersonOutline />,
			}

		case 'ENQUIRY_VERIFIED':
			return {
				text: `Enquiry has been verified by ${data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number}.`,
				icon: <Done />,
			}

		case 'ENQUIRY_SHARED':
			return {
				text: (
					<span>
						{journeySourceId} has been shared to <b>21</b> suppliers.
					</span>
				),
				icon: <GroupAdd />,
			}

		case 'SUPPLIER_QUOTE_UPDATED':
			return {
				text: `Supply quote has been updated by ${
					data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number
				} for the Supplier - ${data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number}.`,
				icon: <ForumOutlined />,
			}

		case 'SUPPLIER_QUOTE_SUBMITTED':
			return {
				text: `Supply quote has been submitted by ${
					data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number
				} for the Supplier - ${data?.to?.full_name || data?.to?.organisation_name || data?.to?.mobile_number}.`,
				icon: <ForumOutlined />,
			}

		case 'BUYER_QUOTE_SUBMITTED':
			return {
				text: `Buyer quote has been submitted to the buyer by ${
					data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number
				}.`,
				icon: <Money />,
			}

		case 'BUYER_QUOTE_UPDATED':
			return {
				text: `Buyer quote has been updated to the buyer by ${
					data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number
				}.`,
				icon: <Money />,
			}

		case 'FINAL_BUYER_QUOTE_SUBMITTED':
			return {
				text: `Final buyer quote has been submitted by ${data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number}.`,
				icon: <Money />,
			}

		case 'CONVERTED_TO_ORDER':
			return {
				text: `Order has been converted for the buyer - ${data?.by?.full_name || data?.by?.organisation_name || data?.by?.mobile_number}.`,
				icon: <GolfCourse />,
			}

		case 'NOTE':
			return {
				text: `${data?.message}`,
				icon: <Message />,
			}
      
		default:
			break
	}
}

const Index = ({ journeySourceId }) => {
	const dispatch = useDispatch()
	const [forceUpdate, update] = useForceUpdate()
	const [message, setMessage] = useState('')
	const [journeyList, setJourneyList] = useState()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	useEffect(() => {
		getJourneyBySourceId(journeySourceId)
			.then((res) => {
				setJourneyList(
					res?.journey_data?.map((item) => {
						return { ...item, journeyState: EnquiryJourneyState(item, journeySourceId) }
					})
				)
			})
			.catch((e) => dispatch(showToast(true, strings('journey_not_found'), { type: TOAST_TYPE.ERROR })))
	}, [forceUpdate])

	const disableSendButton = message === '' ? true : false

	const onMessageChangeHandler = (value) => {
		setMessage(value)
	}
	const sendMessage = () => {
		addMessageToEnquiryJourney(journeySourceId, message, user)
			.then()
			.catch((err) => console.log(err))
			.finally(() => {
				update()
				setMessage('')
			})
	}
	return (
		<>
			<DrawerBodyWrapper>
				<BodyWrapper>
					<TimeLine data={journeyList} journeySourceId={journeySourceId} />
				</BodyWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				{/* <Button margin={`0 0 0 auto`} small uppercase type='primary' disabled={false} onClick={sendMessage}> */}
				<SendButton small disabled={disableSendButton} onClick={sendMessage}>
					<Send />
				</SendButton>
				{/* </Button> */}
				<InputWrapper>
					<Input
						inline
						type='text'
						placeholder='Enter your message here'
						value={message}
						onChange={(value) => onMessageChangeHandler(value)}
					></Input>
				</InputWrapper>
			</DrawerFooterWrapper>
		</>
	)
}

export default Index
