import API from '../api'
import postUrl from './paths'

export const createLead = (userId, lead) =>
    new Promise((resolve, reject) => {
        API.post(postUrl.createLead, { ...lead }, { userId })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })