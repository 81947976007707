import API from '../api'
import putUrl from './paths'

export const editUser = (user) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editUser, user)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const requestKycUpdate = (kycRequested, userId) =>
	new Promise((resolve, reject) => {
		API.put(
			putUrl.requestKycUpdate,
			{},
			{
				kycRequested,
				userId,
			}
		)
			.then((res) => resolve(res))
			.catch((err) => reject(err))
	})

export const mergeUsersApi = (fromUserId, toUserId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.mergeUsers, {}, { fromUserId, toUserId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const editReportingManagers = (managerId, userId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateReportingManager, {}, { managerId, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const changeUserRole = (userId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.changeUserRole, {}, { userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateTarget = (users_monthly_targets) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateTarget, { users_monthly_targets })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err)
			})
	})