class ProductQuote {
	constructor(product_id, required_weight, product_name, category_name, category_id, serviceable_weight) {
		if (typeof product_id === 'object') {
			Object.assign(this, product_id)
			return
		}

		this.product_id = product_id ? product_id : null
		this.product_name = product_name ? product_name : null
		this.category_name = category_name ? category_name : null
		this.category_id = category_id ? category_id : null

		this.required_weight = required_weight ? required_weight : null
		this.brand_available = 'ANY'
		this.serviceable_weight = serviceable_weight ? serviceable_weight : null
		this.ex_price = null
		this.for_price = null
		this.tc_available = true
		this.brand_ordered = 'ANY'
	}
}

export default ProductQuote
