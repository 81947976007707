import { useContext, useEffect, useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { MODAL_TYPES } from '@constants'
import { toggleModal } from '@data/state/action/root'

const useBlocker = (blocker, when = true) => {
	const { navigator } = useContext(NavigationContext)

	useEffect(() => {
		if (!when) return

		const unblock = navigator.block((tx) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock()
					tx.retry()
				},
			}

			blocker(autoUnblockingTx)
		})

		return unblock
	}, [navigator, blocker, when])
}

const usePrompt = (message, when = true) => {
	const dispatch = useDispatch()
	const txRef = useRef()
	const [confirmed, setConfirmed] = useState(false)

	const clickHandler = useCallback((confirmed) => {
		setConfirmed(confirmed)
		dispatch(toggleModal(false))
		if (txRef.current && confirmed) {
			txRef.current.retry()
		}
	}, [])

	const blocker = useCallback(
		(tx) => {
			txRef.current = tx

			if (!confirmed) {
				dispatch(
					toggleModal(true, MODAL_TYPES.CONFIRMATION, {
						title: message,
						overlayClickable: true,
						confirmAction: clickHandler,
					})
				)
			} else {
				tx.retry()
			}
		},
		[message, confirmed, clickHandler]
	)

	useBlocker(blocker, when)
}

export default usePrompt
