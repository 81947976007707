import API from '../api'
import putUrl from './paths'

const kycStatusApi = (kycRejectReason, kycStatus, step, organisationId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.kycStatus, {}, { kycRejectReason, kycStatus, step, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const updateLocationApi = (latitude, longitude, type, orgId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateLocation, {}, { latitude, longitude, type, orgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { kycStatusApi, updateLocationApi }
