import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import AddAddress from './AddAddress'
import { useCallback, useState } from 'react'
import { strings } from '@constants'
import { useDispatch } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { addAddressToOrganisation } from '@data/conn/put/organisation'
import { toggleDrawer } from '@data/state/action/root'

const AddOrgAddress = ({ update, orgId }) => {
	const dispatch = useDispatch()
	const [address, setAddress] = useState({})
	const [enableButton, setEnableButton] = useState({})

	const addressCb = useCallback(({ address, enableButton }) => {
		if (address) setAddress(address)
		if (enableButton !== undefined) setEnableButton(enableButton)
	}, [])

	const addAddressHandler = () => {
		if (!enableButton) return

		dispatch(showLoader(true))
		addAddressToOrganisation(address, orgId)
			.then(() => {
				dispatch(showToast(true, 'Successfully added a new address', { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while adding a new address', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<AddAddress addressCb={addressCb} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={!enableButton} onClick={addAddressHandler}>
					{strings('add_address')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddOrgAddress
