import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

export const getAllBannersApi = (page = 0, bannerFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllBanners, { page, ...bannerFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})
