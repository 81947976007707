const convertPagableData = (data) => {
  if(!data.total_elements) {
    data.total_elements = data.content.length;
  }
  return {
    data: data.content,
    count: data.total_elements,
  }
}

export default convertPagableData;
