import { useHeaderMeta } from '@hooks'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Devices, Spacings } from '@styles'
import { BaseWrapper, Input, Logo } from '@base'
import { downloadFile } from '@data'
import { strings } from '@constants'

const Wrapper = styled(BaseWrapper)`
	flex-grow: 1;
	background: transparent;
	padding: ${Spacings.SPACING_4B};
	box-shadow: none;
`
const ToolWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 48.7%);
	gap: ${Spacings.SPACING_4B};

	@media ${Devices.tablet} {
		grid-template-columns: repeat(3, 32%);
	}

	@media ${Devices.laptop} {
		grid-template-columns: repeat(4, 24%);
	}
`
const ToolButton = styled.div`
	display: flex;
	flex-direction: column;
	height: 60px;
	padding: 0 ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.white};
	margin: ${Spacings.SPACING_3B} 0 0 0;
	border: 1px solid transparent;
	&: hover {
		border: 1px solid #1967d2;
	}

	cursor: pointer;
	& > div {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-direction: column;
		color: ${({ theme }) => theme.palette.text.blueLight};
		border-color: ${({ theme }) => theme.palette.text.blueLight};
		border-radius: ${Spacings.SPACING_2B};

		& > svg {
			font-size: 2.5rem;
			fill: ${({ theme }) => theme.palette.background.blueLight};
		}
		user-select: none;
		transition: all 0.15s ease-in;
	}
	span {
		text-align: center;
	}
`

const documents = [
    { title: 'PAN', search: 'pan card aaocm1740q', path: 'mb_documents/pan.pdf' },
    { title: 'Certificate of Incorporation', search: 'certificate of incorporation coi u51900dl2020ptc375107', path: 'mb_documents/coi.pdf' },
	{ title: 'Udhyam Certificate', search: 'udhyam udhyam-dl-10-0025638', path: 'mb_documents/udhyam.pdf' },
    // { title: 'ICICI Bank - 0281', search: 'icici bank 724005000281 cancelled cheque', path: 'mb_documents/icici_0281.pdf' },
    // { title: 'IDFC Bank - 9696', search: 'idfc bank 10101209696 cancelled cheque', path: 'mb_documents/idfc_9696.pdf' },
	// { title: 'SBM Bank - 0734', search: 'sbm bank 20062200480734 cancelled cheque', path: 'mb_documents/sbm_0734.pdf' },
    { title: 'GST - Haryana', search: 'gst haryana 06AAOCM1740Q1ZY', path: 'mb_documents/gst_haryana.pdf' },
    { title: 'GST - Delhi', search: 'gst delhi 07AAOCM1740Q1ZW', path: 'mb_documents/gst_delhi.pdf' },
    { title: 'GST - West Bengal', search: 'gst west bengal 19AAOCM1740Q1ZR', path: 'mb_documents/gst_west_bengal.pdf' },
	{ title: 'GST - Odisha', search: 'gst odisha 21AAOCM1740Q1Z6', path: 'mb_documents/gst_odisha.pdf' },
	{ title: 'GST - Uttar Pradesh', search: 'gst up uttar pradesh 09AAOCM1740Q1ZS', path: 'mb_documents/gst_up.pdf' },
	{ title: 'GST - Chattisgarh', search: 'gst chattisgarh raipur 22AAOCM1740Q1Z4', path: 'mb_documents/gst_chattisgarh.pdf' },
	{ title: 'GST - Mumbai', search: 'gst mumbai bombay maharashtra 27AAOCM1740Q1ZU', path: 'mb_documents/gst_mumbai.pdf' },
]

const MBDocuments = () => { 
    useHeaderMeta({ title: 'Metalbook Documents' })
    const [search, setSearch] = useState()
 
    return (
		<Wrapper>
			<Logo size='4.5rem' isLogin />
			<Input type='text' label={strings('search')} value={search} onChange={setSearch} placeholder='Search by document type, unique id, location, bank, account no' />
			<ToolWrapper>
                {
                    documents
                        .filter(item => search ? item?.search.includes(search.toLowerCase()) : true)
                        .map((_item) => {
						return (
                            <ToolButton key={`template_mb_document_${Math.random()}`} hoverable onClick={() => { downloadFile(_item.path) }}>
								<div>
									<span>{_item.title}</span>
								</div>
							</ToolButton>
						)
                    })
                }
			</ToolWrapper>
		</Wrapper>
    )
}

export default MBDocuments