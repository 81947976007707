import API from '../api'
import deleteUrl from './paths'

const deleteLedgerByDocumentId = (documentId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteLedgerByDocumentId, { documentId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export { deleteLedgerByDocumentId }