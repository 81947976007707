import { BaseWrapper, Button, Checkbox, H2, H3, H5, ImageHandler, Input, MultiSelect, TOAST_TYPE, Textarea } from '@base'
import { categoryCustomisations, strings } from '@constants'
import { Devices, Spacings } from '@styles'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { useParams } from 'react-router'
import styled, { css } from 'styled-components'
import 'react-quill/dist/quill.snow.css'
import { editCategory, getAllBrandApi, getCategoryById, uploadCategoryImages } from '@data'
import { useDispatch } from 'react-redux'
import { useForceUpdate, useHeaderMeta } from '@hooks'
import { showLoader, showToast } from '@data/state/action'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

const PseudoWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B};
	}
`
const Footer = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
`
const PsuedoFullHeightWrapper = styled(BaseWrapper)`
	flex-grow: 1;
`
const InfoCreateWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;

	margin-bottom: ${Spacings.SPACING_3B};

	@media ${Devices.tablet} {
		flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
		flex-grow: ${({ fullWidth }) => (fullWidth ? '1' : '0')};

		${({ fullWidth }) => {
			if (!fullWidth) {
				return css`
					&:nth-child(2n + 1) {
						margin-right: ${Spacings.SPACING_4B};
						flex-basis: calc(50% - ${Spacings.SPACING_4B});
						padding-right: ${Spacings.SPACING_2B};
					}
				`
			}
		}}
	}
`

const FormBody = styled.div`
	display: flex;
	flex-direction: column;
`

const FormElementWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	// max-width: 25rem;

	margin-top: ${Spacings.SPACING_1B};

	& > div {
		width: 100%;
	}
`

const Heading = styled(H3)`
	padding: ${Spacings.SPACING_3B} 0 ${Spacings.SPACING_3B};
	color: ${({ theme }) => theme.palette.text.darkblack};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	margin-bottom: ${Spacings.SPACING_2B};
	margin-top: ${({ noTopMargin }) => (noTopMargin ? 0 : Spacings.SPACING_2B)};

	@media ${Devices.tablet} {
		margin-top: 0;
	}
`

const Subheading = styled(H5)`
	margin-bottom: ${Spacings.SPACING_2B};
	margin-top: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.darkblack};
	@media ${Devices.tablet} {
		// margin-top: 0;
	}
`

const BiFieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	column-gap: ${Spacings.SPACING_3B};
	& > div {
		width: 100%;
	}
`
const QuillWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_4B};
`

const ReactQuillWrapper = styled(ReactQuill)`
	// height: 100%;
	// max-height: 100%;
	height: auto;
	@media ${Devices.mobile} {
		height: 90%;
		max-height: 90%;
	}
	& > div:nth-child(2) {
		height: auto;
	}
`

const CheckBoxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: ${Spacings.SPACING_3B};
	&: nth-child(1) {
		height: 100%;
		width: 100%;
	}
	& > div {
		width: 100%;
	}
`

const ImageWrapper = styled.div`
	width: 50%;
	height: 50%;
`

const customisationsType = Object.entries(categoryCustomisations).map(([key, value]) => ({ key, label: value }))

const deriveState = (data) => {
	const _state = {
		advantage: {
			id: 'advantage',
			label: strings('advantage'),
			disabled: false,
			placeholder: strings(['enter', 'advantage', '...']),
			value: '',
			required: false,
		},
		application: {
			id: 'application',
			label: strings('application'),
			disabled: false,
			placeholder: strings(['enter', 'applications', '...']),
			value: '',
			required: false,
		},
		description1: {
			id: 'first-description',
			label: strings(['description', 'box', '1']),
			disabled: false,
			placeholder: strings(['enter', 'description', '...']),
			value: '',
			required: false,
		},
		description2: {
			id: 'second-description',
			label: strings(['description', 'box', '2']),
			disabled: false,
			placeholder: strings(['enter', 'description', '...']),
			value: '',
			required: false,
		},
		thickness: {
			id: 'thickness',
			label: strings('thickness'),
			disabled: false,
			placeholder: strings(['enter', 'thickness']),
			value: '',
			required: false,
		},
		width: {
			id: 'width',
			label: strings('width'),
			disabled: false,
			placeholder: strings(['enter', 'width']),
			value: '',
			required: false,
		},
		height: {
			id: 'height',
			label: strings('height'),
			disabled: false,
			placeholder: strings(['enter', 'height']),
			value: '',
			required: false,
		},
		diameter: {
			id: 'diameter',
			label: strings('diameter'),
			placeholder: strings(['enter', 'diameter']),
			value: '',
			disabled: false,
			required: false,
		},
		length: {
			id: 'length',
			label: strings('length'),
			placeholder: strings(['enter', 'length']),
			value: '',
			disabled: false,
			required: false,
		},
		grade: {
			id: 'grade',
			label: strings('grade'),
			placeholder: strings(['enter', 'grade']),
			value: '',
			disabled: false,
			required: false,
		},
		brands: {
			id: 'brands',
			label: strings('brands'),
			placeholder: strings('enter', 'brands'),
			value: null,
			primaryKey: 'brand_id',
			displayKey: 'brand_name',
			disabled: false,
			required: false,
		},
		customisation_available: {
			id: 'customisation-available',
			value: false,
			disabled: false,
			required: false,
		},
		customizations: {
			id: 'customisations',
			label: strings('customisations'),
			placeholder: strings(['select', 'customisations']),
			value: null,
			data: customisationsType,
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			required: false,
		},
	}

	if (data) {
		_state.advantage.value = data?.advantage
		_state.application.value = data?.application
		_state.description1.value = data?.description1
		_state.description2.value = data?.description2
		_state.thickness.value = data?.specifications?.thickness
		_state.width.value = data?.specifications?.width
		_state.height.value = data?.specifications?.height
		_state.diameter.value = data?.specifications?.diameter
		_state.length.value = data?.specifications?.length
		_state.grade.value = data?.specifications?.grade
		// _state.brands.value = data?.specifications?.brands
		_state.customisation_available.value = data?.specifications?.customisation_available
		_state.customizations.value = customisationsType.filter((item) => data?.specifications?.customizations.includes(item.key))
	}

	return _state
}

let toolbarOptions = [
	[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	['bold', 'italic', 'underline', 'strike'], // toggled buttons
	['link', 'image'],
	[
		([({ header: 1 }, { header: 2 })], // custom button values
		{ list: 'bullet' }),
	],
	[
		([({ header: 1 }, { header: 2 })], // custom button values
		{ list: 'ordered' }),
	],
	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	[{ align: [] }],
]

const CategoryInfo = () => {
	const [formState, setFormState] = useState(deriveState())
	const [brandList, setBrandList] = useState([])
	const [categoryImages, setCategoryImages] = useState([])
	const { categoryId } = useParams()
	const [categoryData, setCategoryData] = useState({})
	const dispatch = useDispatch()
	const [forceUpdate, update] = useForceUpdate()

	useHeaderMeta({
		title: strings('edit', 'category'),
	})
	useEffect(() => {
		dispatch(showLoader(true))
		getCategoryById(categoryId)
			.then((res) => {
				setFormState(deriveState(res))
				setCategoryData(res)
				setCategoryImages(res?.images)
			})
			.catch((e) => {
				console.log(e)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}, [forceUpdate])

	useEffect(() => {
		categoryData &&
			getAllBrandApi()
				.then((res) => {
					setBrandList(res.data)
					setFormState((_s) => ({
						..._s,
						['brands']: {
							..._s['brands'],
							value: res.data.filter((item) => categoryData?.specifications?.brands?.includes(item.brand_name)),
						},
					}))
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {})
	}, [categoryData])

	const onChangeHandler = (key) => (value) => {
		setFormState((_state) => ({
			..._state,
			[key]: {
				..._state[key],
				value,
			},
		}))
	}

	const formStateToCategory = () => {
		let _state = {
			advantage: formState.advantage?.value,
			application: formState.application?.value,
			description1: formState.description1?.value,
			description2: formState.description2?.value,
			specifications: {
				brands: formState.brands?.value?.map((item) => item.brand_name),
				customisation_available: formState.customisation_available?.value,

				customizations: formState.customizations?.value?.map((item) => {
					return item.key
				}),
				diameter: formState.diameter?.value,
				grade: formState.grade?.value,
				height: formState.height?.value,
				length: formState.length?.value,
				thickness: formState.thickness?.value,
				width: formState.width?.value,
			},
		}
		return _state
	}

	const addInfoHandler = () => {
		const _categoryData = formStateToCategory()
		editCategory({ ...categoryData, ..._categoryData }, categoryId)
			.then(() => {
				dispatch(showToast(true, strings('category', 'updated'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch(() => {
				dispatch(showToast(true, strings(['category', 'not', 'updated']), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
			})
	}
	const uploadPhotoHandler = (files) => {
		const getPath = Array.from(files).map((f) => f)
		dispatch(showLoader(true))
		uploadCategoryImages(categoryId, getPath)
			.then((response) => {
				dispatch(showToast(true, strings('uploaded_the_file_successfully'), { type: TOAST_TYPE.SUCCESS }))
				setCategoryImages(response)
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	return (
		<Wrapper>
			<PsuedoFullHeightWrapper>
				<Heading>{strings(['enter', 'additional', 'details'])}</Heading>
				<InfoCreateWrapper>
					<Section>
						<FormBody>
							<FormElementWrapper>
								<BiFieldWrapper>
									<Input
										id={formState.thickness.id}
										type='text'
										label={formState.thickness.label}
										placeholder={formState.thickness.placeholder}
										disabled={formState.thickness.disabled}
										value={formState.thickness.value}
										required={formState.thickness.required}
										onChange={onChangeHandler('thickness')}
									/>
									<Input
										id={formState.width.id}
										type='text'
										label={formState.width.label}
										placeholder={formState.width.placeholder}
										disabled={formState.width.disabled}
										value={formState.width.value}
										required={formState.width.required}
										onChange={onChangeHandler('width')}
									/>
								</BiFieldWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<BiFieldWrapper>
									<Input
										id={formState.height.id}
										type='text'
										label={formState.height.label}
										placeholder={formState.height.placeholder}
										disabled={formState.height.disabled}
										value={formState.height.value}
										required={formState.height.required}
										onChange={onChangeHandler('height')}
									/>
									<Input
										id={formState.diameter.id}
										type='text'
										label={formState.diameter.label}
										placeholder={formState.diameter.placeholder}
										disabled={formState.diameter.disabled}
										value={formState.diameter.value}
										required={formState.diameter.required}
										onChange={onChangeHandler('diameter')}
									/>
								</BiFieldWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<BiFieldWrapper>
									<Input
										id={formState.length.id}
										type='text'
										label={formState.length.label}
										placeholder={formState.length.placeholder}
										disabled={formState.length.disabled}
										value={formState.length.value}
										required={formState.length.required}
										onChange={onChangeHandler('length')}
									/>
									<Input
										id={formState.grade.id}
										type='text'
										label={formState.grade.label}
										placeholder={formState.grade.placeholder}
										disabled={formState.grade.disabled}
										value={formState.grade.value}
										required={formState.grade.required}
										onChange={onChangeHandler('grade')}
									/>
								</BiFieldWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<MultiSelect
									id={formState.brands.id}
									label={formState.brands.label}
									placeholder={formState.brands?.placeholder}
									disabled={formState.brands.disabled}
									value={formState.brands?.value || []}
									required={formState.brands.required}
									primaryKey={formState.brands.primaryKey}
									displayKey={formState.brands.displayKey}
									data={brandList}
									onChange={onChangeHandler('brands')}
								/>
							</FormElementWrapper>

							<FormElementWrapper>
								<Subheading>{strings('customisations')}</Subheading>
								<CheckBoxWrapper>
									<Checkbox
										id='logistics-required-checkbox'
										// size={1.125}
										checked={formState.customisation_available.value}
										value={formState.customisation_available.value}
										onChange={(e) => {
											onChangeHandler('customizations')([])
											onChangeHandler('customisation_available')(e.target.checked)
										}}
									/>
									<MultiSelect
										id={formState.customizations.id}
										data={formState.customizations.data}
										placeholder={formState.customizations.placeholder}
										primaryKey={formState.customizations.primaryKey}
										displayKey={formState.customizations.displayKey}
										value={formState.customizations?.value || []}
										disabled={!formState.customisation_available.value}
										required={formState.customizations.required}
										onChange={onChangeHandler('customizations')}
									/>
								</CheckBoxWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<QuillWrapper>
									<Subheading>{strings('applications')}</Subheading>
									<ReactQuillWrapper
										theme='snow'
										placeholder={formState.application.placeholder}
										onChange={onChangeHandler('application')}
										modules={{ toolbar: toolbarOptions }}
										value={formState.application?.value}
									/>
								</QuillWrapper>
							</FormElementWrapper>
						</FormBody>
					</Section>
					<Section>
						<FormBody>
							<FormElementWrapper>
								<QuillWrapper>
									<Subheading>{strings(['description', '1'])}</Subheading>
									<ReactQuillWrapper
										theme='snow'
										placeholder={formState.description1.placeholder}
										onChange={onChangeHandler('description1')}
										modules={{ toolbar: toolbarOptions }}
										value={formState.description1?.value}
									/>
								</QuillWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<QuillWrapper>
									<Subheading>{strings(['description', '2'])}</Subheading>
									<ReactQuillWrapper
										theme='snow'
										placeholder={formState.description2.placeholder}
										onChange={onChangeHandler('description2')}
										modules={{ toolbar: toolbarOptions }}
										value={formState.description2?.value}
									/>
								</QuillWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<QuillWrapper>
									<Subheading>{strings('advantage')}</Subheading>
									<ReactQuillWrapper
										theme='snow'
										placeholder={formState.advantage.placeholder}
										label={formState.advantage.label}
										onChange={onChangeHandler('advantage')}
										modules={{ toolbar: toolbarOptions }}
										value={formState.advantage?.value}
									/>
								</QuillWrapper>
							</FormElementWrapper>
						</FormBody>
					</Section>
				</InfoCreateWrapper>
				<Section>
					<FormBody>
						<ImageWrapper>
							<ImageHandler images={categoryImages} handler={uploadPhotoHandler} />
						</ImageWrapper>
					</FormBody>
				</Section>
			</PsuedoFullHeightWrapper>
			<PseudoWrapper>
				<Footer>
					<Button small margin='0 0 0 auto' onClick={addInfoHandler}>
						{strings('submit')}
					</Button>
				</Footer>
			</PseudoWrapper>
		</Wrapper>
	)
}

export default CategoryInfo
