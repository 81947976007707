import { Record } from 'immutable'
import Commodity from './Commodity'

const BrandItemRecord = Record({
	// id: '',
	access_type: 'web',
	brand_id: '',
	brand_name: '',
	commodity: new Commodity(),
	created_at: '',
	created_by: '',
	prefix: '',
	rating: '',
	type: '',
	usual_price_per_kg: '',
	weight_per_piece: '',
	updated_at: '',
	updated_by: '',
})

class Brand extends BrandItemRecord {
	constructor(data = {}) {
		super({
			...data,
		})
	}
}
export default Brand
