import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

export const getAllInvoice = (page = 0, enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllInvoice, { page, ...enquiryFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const downloadBills = (enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadBills, { ...enquiryFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

