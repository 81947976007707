import React, { useEffect, useState } from 'react'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, ItemCard, Pill } from '@base'
import { strings } from '@constants'
import { getScrapSupplierQuotesV2 } from '@data'
import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { useDispatch } from 'react-redux'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { displayName } from '@utils'

const SupplierWrapper = styled.div`
	border: 1px solid rgba(234, 241, 250, 1);
	border-radius: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_3B};
	&:hover {
		cursor: pointer;
		border-color: ${({ theme }) => theme.palette.background.blue};
	}
	${({ selected }) => {
		if (selected) {
			return css`
				border-color: ${({ theme }) => theme.palette.background.blue};
			`
		}
	}}
`

// const Wrapper = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	flex-grow: 1;
// `

// const ItemInfoWrapper = styled.div`
// 	width: 50%;

// 	${({ small, extraSmall, medium }) => {
// 		if (small) {
// 			return css`
// 				width: 50%;
// 			`
// 		}

// 		if (extraSmall) {
// 			return css`
// 				width: 40%;
// 				@media ${Devices.mobile} {
// 					width: 25%;
// 				}
// 			`
// 		}

// 		if (medium) {
// 			return css`
// 				width: 50%;
// 				@media ${Devices.mobile} {
// 					width: 33%;
// 				}
// 			`
// 		}
// 		return css`
// 			@media ${Devices.tablet} {
// 				width: 33%;
// 			}
// 			@media ${Devices.laptop} {
// 				width: 25%;
// 			}
// 			@media ${Devices.desktop} {
// 				width: 16.6%;
// 			}
// 		`
// 	}}
// `

// const InnerWrapper = styled.div`
// 	position: relative;
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
// 	margin-bottom: ${Spacings.SPACING_2B};
// 	margin-right: ${Spacings.SPACING_2B};
// `

// const ItemInfoLabel = styled.div`
// 	font-size: ${Text.EXTRA_SMALL};
// 	color: ${({ theme }) => theme.palette.text.label};
// 	text-transform: uppercase;
// 	line-height: 1.6;
// 	display: flex;
// 	align-items: center;
// `

// const ItemInfoValue = styled.div`
// 	font-size: ${Text.MEDIUM};
// 	color: ${({ theme }) => theme.palette.text.primary};

// 	${({ overflow, wrap }) => {
// 		if (!overflow) {
// 			return css`
// 				white-space: ${wrap ? 'unset' : 'nowrap'};
// 				overflow: hidden;
// 				text-overflow: ellipsis;
// 			`
// 		}
// 	}}
// `
const ProductListWrapper = styled.div`
	padding: ${Spacings.SPACING_10};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`
const PillWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_2B};
	display: flex;
	flex-wrap: wrap;
	padding: 2px;
`

const SelectScrapSupplier = ({ order, setShowSlidableComponent }) => {
	const [supplierList, setSupplierList] = useState([])
	const [selectedSupplier, setSelectedSupplier] = useState(null)
	const globalDispatch = useDispatch()

	useEffect(() => {
		const city = order.buyer_list[0].shipping_address.city
		getScrapSupplierQuotesV2(city)
			.then((response) => {
				const _data = response.data
				_data.map((item) => {
					item['display_name'] = displayName(item)
					return item
				})
				setSupplierList(_data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const selectSupplier = (item) => {
		setSelectedSupplier(item)
	}

	const submitSupplierHandler = () => {
		setShowSlidableComponent({ show: true, id: supplierList?.organisation_id })
		globalDispatch(toggleDrawer(false))
		globalDispatch(
			updateDrawerMeta({
				selectedSupplier: selectedSupplier,
			})
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				{supplierList?.map((item, value) => {
					const scrapItemData = [
						{
							label: strings('organisation'),
							value: item?.display_name,
						},
						{
							label: strings('category', 'id'),
							value: item?.category_id,
						},
						{
							label: strings(['capacity', 'per', 'day']),
							value: `${item?.weight / 1000} MT`,
						},
					]
					const index = item?.product_id.findIndex((key) => key === order?.buyer_list[0].order_items[0].product_id)

					const renderBottomPanel = () => {
						return (
							<ProductListWrapper>
								{item?.product_name?.map((k, idx) => {
									return (
										<PillWrapper>
											<Pill label={`${k} :`} value={item.prices[idx]} />
										</PillWrapper>
									)
								})}
							</ProductListWrapper>
						)
					}

					return (
						<>
							<SupplierWrapper
								small
								onClick={() => selectSupplier(item)}
								selected={item?.organisation_id === selectedSupplier?.organisation_id}
							>
								<ItemCard
									small
									data={scrapItemData}
									id={item?.id}
									key={`enquiry-item-${index}`}
									renderBottomPanel={renderBottomPanel()}
								/>
							</SupplierWrapper>
						</>
					)
				})}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small onClick={submitSupplierHandler} disabled={selectedSupplier === null}>
					{strings('select', 'supplier')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default SelectScrapSupplier
