import { lazy } from 'react'
import { Navigate } from 'react-router'
import DashboardLayout from '@global/DashboardLayout'
import { auctionStatus, strings } from '@constants'
import paths from '../paths'

import { withSuspense } from './routeUtils'
import { OrganisationDetails, OrganisationEditUser } from './AdminRoutes'
import Peers from '@pages/ProfileV2/Peers'

export const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '@pages/Dashboard'))

const ProfileV2 = lazy(() => import(/* webpackChunkName: "ProfileV2" */ '@pages/ProfileV2'))
const MyProfile = lazy(() => import(/* webpackChunkName: "MyProfile" */ '@pages/ProfileV2/Profile'))

const Fulfilment = lazy(() => import(/* webpackChunkName: "Fulfilment" */ '@pages/Fulfilment'))
const OpenEnquiries = lazy(() => import(/* webpackChunkName: "OpenEnquiries" */ '@pages/Fulfilment/OpenEnquiries'))
const MyQuotes = lazy(() => import(/* webpackChunkName: "MyQuotes" */ '@pages/Fulfilment/MyQuotes'))
const QuotationFlow = lazy(() => import(/* webpackChunkName: "QuotationFlow" */ '@pages/Fulfilment/QuotationFlow'))

const Orders = lazy(() => import(/* webpackChunkName: "Orders" */ '@pages/Orders'))
const ViewOrder = lazy(() => import(/* webpackChunkName: "ViewOrder" */ '@pages/Orders/ViewOrder'))
const OrdersByStatus = lazy(() => import(/* webpackChunkName: "OrdersByStatus" */ '@pages/Orders/OrdersByStatus'))

const Auction = lazy(() => import(/* webpackChunkName: "Auction" */ '@pages/Auction'))
const CreateAuction = lazy(() => import(/* webpackChunkName: "CreateAuction" */ '@pages/Auction/CreateAuction'))
const MyAuctions = lazy(() => import(/* webpackChunkName: "MyAuctions" */ '@pages/Auction/MyAuctions'))
const EditAuction = lazy(() => import(/* webpackChunkName: "EditAuction" */ '@pages/Auction/EditAuction'))
const ViewAuction = lazy(() => import(/* webpackChunkName: "ViewAuction" */ '@pages/Auction/ViewAuction'))

const AuctionBid = lazy(() => import(/* webpackChunkName: "AuctionBid" */ '@pages/AuctionBid'))
const BidListAuctions = lazy(() => import(/* webpackChunkName: "BidListAuctions" */ '@pages/AuctionBid/ListAuctions'))
const AuctionBidMyQuotes = lazy(() => import(/* webpackChunkName: "AuctionBidMyQuotes" */ '@pages/AuctionBid/MyQuotes'))
const BidOnAuction = lazy(() => import(/* webpackChunkName: "BidOnAuction" */ '@pages/AuctionBid/BidOnAuction'))

const Enquiry = lazy(() => import(/* webpackChunkName: "Enquiry" */ '@pages/Enquiry'))
const CreateEnquiry = lazy(() => import(/* webpackChunkName: "CreateEnquiry" */ '@pages/Enquiry/CreateEnquiry'))
const MyEnquiries = lazy(() => import(/* webpackChunkName: "MyEnquiries" */ '@pages/Enquiry/MyEnquiries'))
const EditEnquiry = lazy(() => import(/* webpackChunkName: "EditEnquiry" */ '@pages/Enquiry/EditEnquiry'))
const CreatePO = lazy(() => import(/* webpackChunkName: "CreatePO" */ '@pages/Enquiry/CreatePO'))

const BuyerQuotes = lazy(() => import(/* webpackChunkName: "BuyerQuotes" */ '@pages/Enquiry/BuyerQuotes'))

const Payments = lazy(() => import(/* webpackChunkName: "Payments" */ '@pages/Payments'))
const Ledgers = lazy(() => import(/* webpackChunkName: "Ledgers" */ '@pages/Payments/Ledgers'))
const Invoices = lazy(() => import(/* webpackChunkName: "Invoices" */ '@pages/Payments/Invoices'))

const Inventory = lazy(() => import(/* webpackChunkName: "Inventory" */ '@pages/Inventory'))

const Scrap = lazy(() => import(/* webpackChunkName: "Scrap" */ '@pages/Scrap'))

const LogisticsDetails = lazy(() => import(/* webpackChunkName: "LogisticsDetails" */ '@pages/Logistics/LogisticsDetails'))
const ManageLogisticsRequest = lazy(() => import(/* webpackChunkName: "ManageLogisticsRequest" */ '@pages/Logistics/ManageLogisticsRequest'))
const LogisticsAll = lazy(() => import(/* webpackChunkName: "LogisticsAll" */ '@pages/Logistics/ManageLogisticsRequest/LogisticsAll'))
const LogisticsMyQuotes = lazy(() => import(/* webpackChunkName: "LogisticsMyQuotes" */ '@pages/Logistics/ManageLogisticsRequest/MyQuotes'))
const LogisticsConvertedToOrder = lazy(() =>
	import(/* webpackChunkName: "LogisticsConvertedToOrder" */ '@pages/Logistics/ManageLogisticsRequest/ConvertedToOrder')
)
const LogistisOrders = lazy(() => import(/* webpackChunkName: "LogistisOrders" */ '@pages/Logistics/Order'))
const ManageLogistics = lazy(() => import(/* webpackChunkName: "ManageLogistics" */ '@pages/Logistics/Order/ManageLogistics'))
const LogisticsOrdersByStatus = lazy(() => import(/* webpackChunkName: "LogisticsOrdersByStatus" */ '@pages/Logistics/Order/OrdersByStatus'))

const LogisticsCalculator = lazy(() => import(/* webpackChunkName: "LogisticsCalculator" */ '@pages/LogisticsCalculator'))

const CloudFactory = lazy(() => import(/* webpackChunkName: "CloudFactory" */ '@pages/CloudFactory'))
const ManageCloudOrders = lazy(() => import(/* webpackChunkName: "ManageCloudOrders" */ '@pages/CloudFactory/ManageOrders'))
const ListFactoryOrders = lazy(() => import(/* webpackChunkName: "ListFactoryOrders" */ '@pages/CloudFactory/ManageOrders/ListOrders'))
const Factories = lazy(() => import(/* webpackChunkName: "Factories" */ '@pages/CloudFactory/ListFactories'))
const CreateFactory = lazy(() => import(/* webpackChunkName: "CreateFactory" */ '@pages/CloudFactory/CreateFactory'))
const EditFactory = lazy(() => import(/* webpackChunkName: "EditFactory" */ '@pages/CloudFactory/EditFactory'))
const ViewFactoryInventory = lazy(() => import(/* webpackChunkName: "ViewFactoryInventory" */ '@pages/CloudFactory/ViewFactoryInventory'))
const ManageCloudOrder = lazy(() => import(/* webpackChunkName: "ManageCloudOrder" */ '@pages/CloudFactory/ManageOrders/ManageOrder'))
const WeightCalculator = lazy(() => import(/* webpackChunkName: "WeightCalculator" */ '@pages/Admin/Tools/WeightCalculator'))

const OrganisationRoutes = {
	path: paths.home,
	element: <DashboardLayout />,
	protected: true,
	children: [
		{ index: true, element: <Navigate to={paths.dashboard} /> },
		{ path: paths.routePath(paths.dashboard, paths.home), element: withSuspense(<Dashboard />), title: strings('dashboard') },
		{
			path: paths.routePath(paths.profile, paths.home),
			element: withSuspense(<ProfileV2 />),
			title: strings('my_profile'),
			children: [
				{ index: true, element: <Navigate to={paths.my_profile} /> },
				{
					path: paths.my_profile,
					element: withSuspense(<MyProfile />),
				},
				{
					path: paths.my_organisation,
					element: withSuspense(<OrganisationDetails />),
				},
				{
					path: paths.my_peers,
					element: withSuspense(<Peers />),
				},
				{
					path: paths.my_peer(),
					element: withSuspense(<OrganisationEditUser />),
				},
				{
					path: '*',
					element: <Navigate to={paths.my_profile} />,
				},
			],
		},
		{
			path: paths.routePath(paths.fullfill_enquiry, paths.home),
			element: withSuspense(<Fulfilment />),
			title: strings('fullfill_enquiry'),
			children: [
				{ index: true, element: <Navigate to={paths.fullfill_enquiry_open} /> },
				{
					path: paths.fullfill_enquiry_open,
					element: withSuspense(<OpenEnquiries />),
				},
				{
					path: paths.fullfill_enquiry_my_quotes,
					element: withSuspense(<MyQuotes />),
				},
				{
					path: paths.fullfill_enquiry_converted_order,
					element: withSuspense(<OrdersByStatus inEnquiryRoute isEnquiry type='supply' />),
				},
				{
					path: paths.submit_enquiry_quote(),
					element: withSuspense(<QuotationFlow submit />),
				},
				{ path: paths.edit_enquiry_quote(), element: withSuspense(<QuotationFlow edit />) },
			],
		},
		{
			path: paths.routePath(paths.manage_supply_orders, paths.home),
			element: withSuspense(<Orders type='supply' />),
			title: strings('manage_supply_orders'),
			children: [
				{ index: true, element: <Navigate to={paths.manage_supply_order_status('active')} /> },
				{
					path: paths.manage_supply_order_status(),
					element: withSuspense(<OrdersByStatus type='supply' />),
				},
				{
					path: paths.manage_supply_order(),
					element: withSuspense(<ViewOrder type='supply' />),
				},
				{
					path: '*',
					element: <Navigate to={paths.manage_supply_order_status('active')} />,
				},
			],
		},
		{
			path: paths.routePath(paths.auction, paths.home),
			element: withSuspense(<Auction />),
			title: strings('sell', 'inventory'),
			children: [
				{ index: true, element: <Navigate to={paths.create_auction} /> },
				{ path: paths.create_auction, element: withSuspense(<CreateAuction />) },
				{ path: paths.my_auctions, element: withSuspense(<MyAuctions />) },
				{ path: paths.convert_auction_to_order, element: withSuspense(<OrdersByStatus isAuction type='supply' />) },
				{ path: paths.edit_auction(), element: withSuspense(<EditAuction />) },
				{ path: paths.view_auction(), element: withSuspense(<ViewAuction />) },
				{
					path: '*',
					element: <Navigate to={paths.create_auction} />,
				},
			],
		},
		{
			path: paths.routePath(paths.bid_auction, paths.home),
			element: withSuspense(<AuctionBid />),
			title: strings('bid_auction'),
			children: [
				{ index: true, element: <Navigate to={paths.bid_auction_active} /> },
				{
					path: paths.bid_auction_active,
					element: withSuspense(<BidListAuctions status={auctionStatus.closing} />),
				},
				{
					path: paths.bid_auction_upcoming,
					element: withSuspense(<BidListAuctions status={auctionStatus.opening} />),
				},
				{
					path: paths.bid_auction_your_bids,
					element: withSuspense(<AuctionBidMyQuotes />),
				},
				{
					path: paths.bid_auction_converted_order,
					element: withSuspense(<OrdersByStatus inEnquiryRoute isAuction />),
				},
				{
					path: paths.bid_on_auction(),
					element: withSuspense(<BidOnAuction />),
				},
				{
					path: '*',
					element: <Navigate to={paths.bid_auction_active} />,
				},
			],
		},
		{
			path: paths.routePath(paths.enquiry, paths.home),
			element: withSuspense(<Enquiry />),
			title: strings(['enquiry', '(', 'rfq', ')']),
			children: [
				{ index: true, element: <Navigate to={paths.create_enquiry} /> },
				{ path: paths.create_enquiry, element: withSuspense(<CreateEnquiry />) },
				{ path: paths.my_enquiries, element: withSuspense(<MyEnquiries />) },
				{ path: paths.convert_enquiry_to_order, element: withSuspense(<OrdersByStatus inEnquiryRoute isEnquiry />) },
				{ path: paths.edit_enquiry(), element: withSuspense(<EditEnquiry />) },
				{ path: paths.create_po(), element: withSuspense(<CreatePO />) },
				{ path: paths.show_buyer_quotes(), element: withSuspense(<BuyerQuotes />) },
				{
					path: '*',
					element: <Navigate to={paths.create_enquiry} />,
				},
			],
		},
		{
			path: paths.routePath(paths.manage_purchase_orders, paths.home),
			element: withSuspense(<Orders type='purchase' />),
			title: strings('manage_purchase_orders'),
			children: [
				{ index: true, element: <Navigate to={paths.manage_purchase_order_status('active')} /> },
				{
					path: paths.manage_purchase_order_status(),
					element: withSuspense(<OrdersByStatus />),
				},
				{
					path: paths.manage_purchase_order(),
					element: withSuspense(<ViewOrder />),
				},
				{
					path: '*',
					element: <Navigate to={paths.manage_purchase_order_status('active')} />,
				},
			],
		},
		{
			path: paths.routePath(paths.payment_summary, paths.home),
			element: withSuspense(<Payments />),
			title: strings('payment_summary'),
			children: [
				{ index: true, element: <Navigate to={paths.ledger} /> },
				{
					path: paths.ledger,
					element: withSuspense(<Ledgers />),
				},
				{ path: paths.sale_invoices, element: withSuspense(<Invoices type='sale' />) },
				{
					path: paths.purchase_invoices,
					element: withSuspense(<Invoices type='purchase' />),
				},
				{
					path: '*',
					element: <Navigate to={paths.ledger} />,
				},
			],
		},
		{
			path: paths.routePath(paths.trader_list_inventory, paths.home),
			element: withSuspense(<Inventory />),
			title: strings('list_inventory'),
		},
		{
			path: paths.routePath(paths.tools_weight_calculator, paths.home),
			element: withSuspense(<WeightCalculator />),
			title: strings('weight', 'calculator'),
		},
		{
			path: paths.routePath(paths.supply_scrap_quote, paths.home),
			element: withSuspense(<Scrap />),
			title: strings('supply_scrap'),
		},
		// logistics
		{
			path: paths.routePath(paths.logistics_requests, paths.home),
			element: withSuspense(<ManageLogisticsRequest />),
			title: strings('requests'),
			children: [
				{ index: true, element: <Navigate to={paths.logistics_requests_all} /> },
				{
					path: paths.logistics_requests_all,
					element: withSuspense(<LogisticsAll />),
				},
				{
					path: paths.logistics_my_quotes,
					element: withSuspense(<LogisticsMyQuotes />),
				},
				{
					path: paths.logistics_converted_to_order,
					element: withSuspense(<LogisticsConvertedToOrder />),
				},
				{
					path: paths.logistics_quote(),
					element: withSuspense(<LogisticsDetails />),
					title: strings('requests'),
				},
				{
					path: '*',
					element: <Navigate to={paths.logistics_requests_all} />,
				},
			],
		},

		{
			path: paths.routePath(paths.manage_logistics_orders, paths.home),
			element: withSuspense(<LogistisOrders />),
			title: strings(['manage', 'logistic', 'orders']),
			children: [
				{ index: true, element: <Navigate to={paths.manage_logistics_order_status('active')} /> },
				{
					path: paths.manage_logistics_order_status(),
					element: withSuspense(<LogisticsOrdersByStatus />),
				},
				{
					path: paths.manage_logistics_order_details(),
					element: withSuspense(<ManageLogistics />),
				},
				{
					path: '*',
					element: <Navigate to={paths.manage_logistics_order_status('active')} />,
				},
			],
		},
		{
			path: paths.routePath(paths.logistics_calculator, paths.home),
			element: withSuspense(<LogisticsCalculator />),
			title: strings('calculate', 'logistics'),
		},

		// fabricator
		{
			path: paths.routePath(paths.fabricator_factories, paths.home),
			element: withSuspense(<CloudFactory />),
			title: '',
			children: [
				{ index: true, element: <Navigate to={paths.fabricator_factories_all} /> },
				{ path: paths.fabricator_factories_all, element: withSuspense(<Factories />), title: strings('factories') },
				{ path: paths.fabricator_factories_add, element: withSuspense(<CreateFactory />), title: strings('create_factory') },
				{ path: paths.fabricator_factories_edit(), element: withSuspense(<EditFactory />), title: strings('edit_factory') },
				{
					path: paths.fabricator_view_factory_inventory(),
					element: withSuspense(<ViewFactoryInventory />),
					title: strings('view_factory_inventory'),
				},
				{
					path: paths.fabricator_orders,
					element: withSuspense(<ManageCloudOrders />),
					title: strings(['manage', 'cloud', 'orders']),
					children: [
						{ index: true, element: <Navigate to={paths.fabricator_orders_order_status('active')} /> },
						{
							path: paths.fabricator_orders_order_status(),
							element: withSuspense(<ListFactoryOrders />),
						},
						{
							path: paths.fabricator_manage_factory_order(),
							element: withSuspense(<ManageCloudOrder />),
						},
						{
							path: '*',
							element: <Navigate to={paths.fabricator_orders_order_status('active')} />,
						},
					],
				},
				{
					path: '*',
					element: <Navigate to={paths.fabricator_factories_all} />,
				},
			],
		},

		{
			path: '*',
			element: <Navigate to={paths.dashboard} />,
		},
	],
}

export default OrganisationRoutes
