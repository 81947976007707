import React from 'react'

import { Outlet } from 'react-router-dom'
import { OutletWrapper } from '@base'

const AdminReports = () => {
	return (
		<OutletWrapper>
			<Outlet />
		</OutletWrapper>
	)
}

export default AdminReports
