import { Record } from 'immutable'

const OrderItemJourneyRecord = Record({
	after_process: [],
	before_process: [],
	during_process: [],
	inventory_id: null,
	machine: null,
	mb_reference_number: null,
	received_at_warehouse: [],
	product_name: null,
	identifier: null,
	updated: false,
})

class OrderItemJourney extends OrderItemJourneyRecord {
	constructor(data) {
		super({
			...data,
			identifier: `${data?.inventory_id}_${data?.mb_reference_number}`,
		})
	}

	update(updates) {
		if (!this.updated) {
			return this.merge({ ...updates, updated: true })
		}
		return this.merge(updates)
	}

	isValid() {
		return true
	}

	objectForAPI() {
		const _jsObject = super.toJS()

		delete _jsObject.updated
		delete _jsObject.identifier

		return _jsObject
	}
}

export default OrderItemJourney
