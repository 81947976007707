import React from 'react'
import styled from 'styled-components'
import { Table, FullHeightPageLevelTableWrapper, Pill } from '@base'
import { strings } from '@constants'

const PillWapper = styled(Pill)`
	cursor: pointer;
`
const ActiveGst = ({ data }) => {
	const columns = [
		{
			label: strings('gstin'),
			key: 'gstin',
			mobile: true,
		},
		{
			label: strings('trade', 'name'),
			key: 'trade_nam',
			mobile: true,
		},
		{
			label: strings('hsn'),
			key: 'hsn',
			mobile: true,
		},
		{
			label: strings('address'),
			key: 'adr',
			mobile: true,
		},
		{
			label: strings('financial', 'year'),
			key: (dataItem) => (
				<>
					{dataItem?.result?.map((item) => {
						return <PillWapper label={item?.financial_year} margin='2px' onClick={()=>{moduleHandler(item?.e_filedlist)}} />
					})}
				</>
			),
			mobile: true,
		},
    ]
    
    const moduleHandler = (efiledlist) => {
        
        // dispatch(
		// 	toggleModal(true, MODAL_TYPES.CONFIRMATION, {
		// 		overlayClickable: true,
		// 		title: confirmTitle(),
		// 		confirmAction: (actionType) => {
		// 			dispatch(toggleModal(false))
		// 		},
		// 	})
		// )
    }

	if(data == null) return null
	
	return (
		<FullHeightPageLevelTableWrapper>
			<Table fullHeight data={data} columns={columns} loading={false} rowClickable={false} hideFooter />
		</FullHeightPageLevelTableWrapper>
	)
}

export default ActiveGst
