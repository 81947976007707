import styled, { css } from 'styled-components'
import { Button, DrawerFooterWrapper, NavigationWrapper } from '@base'
import { Devices, Spacings, Text } from '@styles'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`
export const ButtonNavigationWrapper = styled(NavigationWrapper)`
	display: flex;
	justify-content: flex-end;
`
export const MenuIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	& > svg {
		font-size: ${Text.LARGE};
	}
	color: ${({ theme, isDelete }) => isDelete && theme.palette.background.red};
`

// export const OpenInNewIcon = styled(OpenInNew)`
// 	cursor: pointer;
// 	&: hover {
// 		color: ${({ theme }) => theme.palette.background.blueLight};
// 	}
// `

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const PsuedoFullHeightWrapper = styled.div`
	padding: ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);

	flex-grow: 1;

	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
		margin-bottom: ${Spacings.SPACING_4B};
	}
`
export const Section = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;

	margin-bottom: ${Spacings.SPACING_3B};

	@media ${Devices.tablet} {
		flex-basis: ${({ fullWidth }) => (fullWidth ? '60%' : '40%')};
		flex-grow: ${({ fullWidth }) => (fullWidth ? '1' : '0')};

		${({ fullWidth }) => {
		if (!fullWidth) {
			return css`
					&:nth-child(2n + 1) {
						margin-right: ${Spacings.SPACING_4B};
						// flex-basis: calc(40% - ${Spacings.SPACING_4B});
						padding-right: ${Spacings.SPACING_2B};
					}
				`
		}
	}}
	}
`
export const ImageSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.5rem 0;
`

export const DuoWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	@media ${Devices.tablet} {
		flex-wrap: nowrap;
	}
`

export const FormItemWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	flex: 6;
	width: 100%;
`

export const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

export const SwitchCard = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	align-items: center;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};

	& > h6 {
		font-weight: ${Text.BOLD_600};
		margin-right: ${Spacings.SPACING_3B};
		line-height: ${Spacings.SPACING_7B};
	}
`
export const CreateAuctionFooter = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
`
export const FooterWrapper = styled(DrawerFooterWrapper)`
	background: ${({ theme }) => theme.palette.background.white};
`
export const ErrorMessage = styled.span`
	color: ${({ theme }) => theme.palette.text.messageRed};
	font-size: ${Text.SMALL};
	bottom: ${({ small }) => (small ? '-' + Spacings.SPACING_2 : 0)};
	left: 0.5rem;
	line-height: 1;
	padding: 0 ${Spacings.SPACING_1B};
	background: ${({ theme }) => theme.palette.background.white};
	font-weight: ${Text.BOLD_600};
`

export const RadioButtonInputWrapper = styled.div`
	display:flex;
	&>div{
	width:100%;
	}
	padding:${Spacings.SPACING_3B} 0;
`

export const RadioButtonWrapper = styled.div`
		display:flex;		
`

export const RadioOptionsWrapper = styled.div`
	display:flex;
	justify-content:space-around;
`

export const Label = styled.label`
font-size: ${Text.MEDIUM};
margin-left: ${Spacings.SPACING_2B};
color: ${({ theme }) => theme.palette.text.black};
font-weight: ${Text.BOLD_600};
cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
user-select: none;
`

export const FaqHeader = styled.div`
	display:flex;
	flex:1;
	justify-content:space-between;
	align-items:center;
`

export const FaqItemWrapper = styled.div`
	padding: ${Spacings.SPACING_2B} 0 ${Spacings.SPACING_2B} 0;
`

export const QuilWrapper = styled.div`
	max-height:800px;
`

export const FaqWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${Spacings.SPACING_3B};
`

export const AccordionWrapper = styled.div`
	display:flex;
	flex-direction:row;
`

export const AccordionItem = styled.div`
	width:100%;
`

export const DeleteWrapper = styled.div`
	display:flex;
	justify-content:center;
	align-items:center;
	width:fit-content;
	
	&>svg{
		color:${({ theme }) => theme.palette.background.redLight};
		&:hover{
		color:${({ theme }) => theme.palette.background.red};
		cursor:pointer;
		}
	}
`