import styled, { css } from 'styled-components'
import { PageHeader } from '@base'
import { Devices, Spacings } from '@styles'

export const CustomSectionHeader = styled(PageHeader)``

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
export const FormChildItemWrapper = styled.div`
	display: flex;
	${({ isJustifyContent }) => {
		if (isJustifyContent) {
			return css`
				justify-content: space-around;
			`
		}
	}}
	flex-direction: column;
	@media ${Devices.mobile} {
		flex-direction: row;
	}
`
