import styled, { css } from 'styled-components'
import { Button, H5, UploadButton } from '@base'
import { Devices, Spacings, Text } from '@styles'

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: ${Spacings.SPACING_4B};
	margin-top: auto;
`
export const ProfileActionButton = styled(Button)`
	&:last-of-type {
		margin-left: ${Spacings.SPACING_3B};
	}
	${({ isZoho }) => {
		if (isZoho) {
			return css`
				align-self: self-start;
			`
		}
	}}
`

export const RequestSupplierButton = styled(Button)`
	margin-left: auto;
	margin-bottom: 8px;
`

export const ProfileWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex-direction: row;
		border-radius: ${Spacings.SPACING_2B};
		flex-wrap: wrap;
	}
`

export const ProfileSection = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	${({ inIsolation }) => {
		if (!inIsolation) {
			return css`
				padding: ${Spacings.SPACING_4B};
				box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
				margin-bottom: ${Spacings.SPACING_4B};
			`
		}
	}}

	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex: 1 1 0;
		border-radius: ${Spacings.SPACING_2B};
		flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - .5rem)')};
		max-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - .5rem)')};

		&:first-of-type {
			margin-right: ${Spacings.SPACING_4B};
		}
	}
`

export const UserBadgeSection = styled.div`
	display: flex;
	flex-direction: row;
	margin: ${Spacings.SPACING_6B} 0 ${Spacings.SPACING_3B};
	user-select: none;
`

export const BadgeLabel = styled.span`
	color: ${({ theme }) => theme.palette.text.label};
	position: absolute;
	top: -${Spacings.SPACING_5B};
`

export const BadgeWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	position: relative;
`
export const Badge = styled.span`
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.yellow};
	color: ${({ theme }) => theme.palette.text.darkblack};
	border-radius: ${Spacings.SPACING_2B};
`

export const InfoWrapper = styled.div`
	padding-top: ${Spacings.SPACING_2B};
`

export const ChoiceText = styled(H5)`
	color: ${({ theme }) => theme.palette.text.darkblack};
	margin: ${Spacings.SPACING_4B} 0 ${Spacings.SPACING_2B};
`

export const ButtonMainWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	margin-left: ${Spacings.SPACING_4B};
`

export const DashboardButtonWrapper = styled.div`
	justify-content: flex-end;
	display: flex;
	flex: 1;
`

export const RoleRequestSection = styled.div`
	margin-bottom: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.white};
	border-radius: ${Spacings.SPACING_2B};

	@media ${Devices.tablet} {
	}
`
export const BannerContent = styled.div`
	display: flex;
	align-items: center;

	& > button {
		min-width: fit-content;
	}
`
export const ButtonZohoWapper = styled(Button)`
	align-self: self-start;
`

export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;

	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}

	${({ isStock }) => {
		if (isStock) {
			return css`
				justify-content: space-between;
			`
		}
	}};
`
export const DocUploadButton = styled(UploadButton)`
	margin-left: auto;
`