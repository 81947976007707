import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, Select, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { getBankDetails, updateAccountDetails } from '@data'
import { showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { Spacings } from '@styles'
// import { capitalizeEveryWord, capitalizeWord } from '@utils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const InputWrapper = styled.div`
	padding: ${Spacings.SPACING_1B} 0;
	& > div {
		width: 100%;
	}
`

const getFormState = () => {
	const _state = {
		bank_name: {
			label: strings('select', 'bank'),
			placeholder: strings(['enter', 'bank', 'name']),
			disabled: true,
			value: {},
			dirty: false,
		},
		ifsc: {
			label: strings('ifsc'),
			placeholder: strings(['enter', 'ifsc', 'code']),
			disabled: false,
			value: '',
		},
		account_number: {
			label: strings('account', 'number'),
			placeholder: strings(['enter', 'account', 'number']),
			disabled: false,
			value: '',
		},
	}
	return _state
}

const AddBank = ({ orgId, update }) => {
	const [bankList, setBankList] = useState([])
	const [formState, setFormState] = useState(getFormState())
	const dispatch = useDispatch()

	const updateState = (key) => (value) => {
		if (key === 'ifsc' || key === 'account_number') {
			value = value.toUpperCase()
		}
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
	}
	const addBankAccount = () => {
		const accountDetails = {
			bank_name: formState.bank_name?.value?.name,
			ifsc_code: formState.ifsc?.value,
			account_number: formState.account_number?.value,
		}
		updateAccountDetails(orgId, accountDetails)
			.then((response) => {
				// console.log(response)
				dispatch(showToast(true, strings('msg_account_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings('msg_account_added_failure'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(toggleDrawer(false))
			})
	}

	const isDisabled = Object.keys(formState.bank_name?.value)?.length === 0 || formState.ifsc.value === ''

	useEffect(() => {
		getBankDetails()
			.then((response) => {
				setBankList(response)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	return (
		<>
			<DrawerBodyWrapper>
				<InputWrapper>
					<Select
						label={formState.bank_name?.label}
						placeholder={formState.bank_name?.placeholder}
						data={bankList}
						displayKey='name'
						primaryKey='institution_id'
						value={formState.bank_name.value}
						onChange={updateState('bank_name')}
					/>
				</InputWrapper>
				<InputWrapper>
					<Input
						label={formState.ifsc.label}
						id={formState.ifsc.id}
						type='text'
						value={formState.ifsc.value}
						onChange={updateState('ifsc')}
						placeholder={formState.ifsc.placeholder}
					/>
				</InputWrapper>
				<InputWrapper>
					<Input
						label={formState.account_number.label}
						id={formState.account_number.id}
						type='text'
						value={formState.account_number.value}
						onChange={updateState('account_number')}
						placeholder={formState.account_number.placeholder}
					/>
				</InputWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={isDisabled} onClick={addBankAccount}>
					{strings(['add'])}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddBank
