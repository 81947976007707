const getUrl = {
	getNews: '/news',
	getBrands: '/brand/all',

	getMobile: '/mobile',
	getUsers: '/user/getAllUsers',
	getTableUsers: '/user/getAllPageableUsers',
	getUserById: '/user',
	getUserByEmail: '/user/getByEmailId',
	getAllEmployees: '/user/getAllEmployees',
	getAllFabricationPartners: '/user/getAllFabricationPartners',
	// getAllUserLocations: '/user/getAllUserLocations',
	getUserAddresses: '/user/getUserAddresses',
	unregisteredUsers: '/user/unregisteredUsers',
	getBillingAddresses: '/user/getBillingAddresses',

	getEnquiries: '/enquiry/getAllEnquiries',
	getEnquiryById: '/enquiry',
	getBuyerEnquiries: '/enquiry/getAllEnquiriesOfBuyer',
	getSupplierEnquiries: '/enquiry/getAllQuotesOfSupplier',
	getAdminEnquiries: '/admin/enquiry/getAdminEnquiries',
	getAdminAssignedEnquiries: '/enquiry/getAssignedEnquiries',
	getScrapRequirement: '/enquiry/getScrapRequirement',
	getScrapSupplierQuotes: '/enquiry/getScrapSupplierQuotes',
	getScrapSupplierQuotesV2: '/enquiry/v2/getScrapSupplierQuotes',

	getOrders: '/order/getAllOrders',
	getAllAdminOrders: '/admin/order/getAllOrders',
	getActiveInvoices: '/order/getActiveInvoices',
	getAllActiveInvoices: '/admin/order/getActiveInvoices',
	getOrderById: '/order/getOrderById',
	getPurchaseOrders: '/order/getAllPurchaseOrdersOfUser',
	getSupplierOrders: '/order/getAllSupplyOrdersOfUser',

	getProductById: '/product',
	getProducts: '/product/getAll',
	getProductOfCategory: '/product/getProductsOfCategory',

	getCategories: '/category/getAll',
	getCategoryById: '/category',
	getTopCategories: '/category/getTop',

	getAllBrand: '/brand/all',

	getAllLedgersOfUser: '/ledger/getLedgersOfUser',
	getPurchaseInvoices: '/ledger/getAllPurchaseInvoicesOfUser',
	getSaleInvoices: '/ledger/getAllSaleInvoicesOfUser',
	getAllLedgers: '/ledger/getAllLedgers',
	getAllLedgerSummary: '/ledger/getAllLedgerSummary',
	getLedgerSummaryByOrganisationId: '/ledger/getLedgerSummaryByOrganisationId',
	getduePaymentsReport: '/ledger/duePaymentsReport',

	getAllPaymentReceivable: '/payment-receivables/all',
	getPaymentReceivablesById: 'payment-receivables/getById',
	getDueLedgerReport: '/ledger/dueLedgerReport',
	getDataCorrectionList: 'ledger/getDataCorrectionList',

	downloadFile: 'files/loadDocument',

	getUserDashboard: 'dashboard/getUserDashboardData',
	getCompanyDashboard: 'dashboard/getCompanyDashboardData',
	getManagerDashboardData: 'dashboard/getManagerDashboardData',

	getAllAuctions: '/auction/getAllAuctions',
	getAllAuctionsOfSupplier: '/auction/getAllAuctionsOfSupplier',
	getAllQuotesOfBuyer: '/auction/getAllQuotesOfBuyer',
	getAdminAuctions: '/auction/getAdminAuctions',
	getAssignedAuctions: '/auction/getAssignedAuctions',
	getAuctionById: '/auction',

	getLogisticsById: '/logistics/getLogisticsRequestById',
	getAllLogistics: '/logistics/getAllLogisticsRequests',
	getAllLogisticsOrders: '/logistics/getAllLogisticsOrders',
	getLogisticsOrderById: '/logistics/getLogisticsOrderById',
	getAllLogisticsOrdersOfUser: 'logistics/getAllLogisticsOrdersOfUser',
	getAllLogisticsRequestsOfUser: '/logistics/getAllLogisticsRequestsOfUser',
	getLogisticsRequestById: '/logistics/getLogisticsRequestById',
	getAllProductPhotosBuyer: '/order/getAllProductPhotosBuyer',

	getAllMachineTemplates: '/machine/getAllMachineTemplates',
	getMachineById: '/machine/getMachineById',

	getFactoryById: '/factory/getFactoryById',
	getAllFactories: '/factory/getAllFactories',
	getFactoriesOfUser: '/factory/getAllFactoriesOfUser',
	getAllFabricationOrdersOfUser: '/factory/getAllFabricationOrdersOfUser',
	getAllFabricationOrders: 'factory/getAllFabricationOrders',
	searchOutputItemsById: 'factory/searchOutputItemsById',
	getFabricationOrderById: 'factory/getFabricationOrderById',

	getEnquiryMISReport: 'reports/enquiry/mis',
	getEnquiryMISReportV2: 'reports/v2/enquiry/mis',
	getEnquiryMISSupplierQuoteReport: 'reports/enquiry/supplierQuote/mis',
	getEnquiryMISSupplierItemWiseReport: 'reports/enquiry/supplierQuote/mis/itemwise',
	getEnquiryMISSalesReport: 'reports/enquiry/sales/mis',
	getEnquiryMISSalesItemWiseReport: 'reports/enquiry/salesQuote/mis/itemwise',
	getEnquiryCategoryMonthlyReport: 'reports/enquiry/category-weight-monthly',
	getEnquiryManagerByReport: 'reports/enquiry/by-manager',
	getBuyerEnquiryCohortReport: 'reports/enquiry/user-cohort',
	getUserDataReport: 'reports/users/data',
	getZohoUsers: 'reports/zoho-users',
	getInvoiceOrderAmountMismatchReport: 'reports/invoiceOrderAmountMismatch',
	getCustomerRevenueReport: 'reports/internal/customerRevenueReport',
	getMonthlyOrderReport: 'reports/internal/monthlyOrderReport',
	getSimilarOrganisationReport: 'reports/internal/similarOrganisations',
	getMissingServicedWeightInvoices: '/reports/internal/missingServicedWeightInvoices',
	getLedgerWiseGrossMarginReport: 'reports/ledgerWiseGrossMargin',
	getLedgerWiseGrossMarginV2Report: 'reports/V2/internal/ledgerWiseGrossMargin',
	getInternalDeletedOrderLedger: 'reports/internal/deletedOrderLedger',
	getNewUserReport: 'reports/user/newUserReport',
	getPaymentReceivablesReport: 'reports/paymentReceivablesReport',
	getAgeingReceivablesReport: '/reports/ageingReceivable/organisation',
	getAgeingReceivablesTypeReport: '/reports/ageingReceivable/due',
	getSupplierAllotedWeight: '/reports/order/supplierAllottedWeight',

	fetchOrderBook: 'reports/order/book',

	getPastRecords: 'zoho/getAllPastRecords',
	getPastRecordOfUser: 'zoho/getPastRecordOfUser',
	getrefreshAllPastRecords: '/zoho/refreshAllPastRecords',

	getSupplierQuotesReport: 'reports/users/bestSupplierQuotes',
	getMonthlyInvoiceReport: 'reports/invoice/monthly',
	getOrderMISReport: 'reports/orders/mis',
	getBuyerPendingWeightReport: 'reports/buyer/pendingWeight',
	getBuyerOrderMisInvoiceWise: 'reports/order/buyerQuote/mis/invoiceWise',
	getBuyerOrderMisItemwise: '/reports/order/buyerReport/mis/itemWise',
	getSupplierOrderMisInvoiceWise: 'reports/order/supplierQuote/mis/invoiceWise',
	getSupplierOrderMisItemwise: 'reports/order/supplierReport/mis/itemWise',

	getlogisticsPrices: 'logisticsPrices/getAll',
	getlogisticsPricesById: 'logisticsPrices/getById',
	getLogisticsPricesRangeByPinCodes: 'logisticsPrices/getByPinCodes',
	getAllCommodityPrices: 'commodity-price/getAll',
	getLatestCommodityPrices: 'commodity-price/getLatestPrices',
	getAllCommodity: '/commodity/all',

	getUserMessages: 'userInteraction/getUserData',
	getAdminMessages: 'userInteraction/getAdminData',
	getNotifications: 'userInteraction/recentNotifications',

	getAllInvoice: '/invoice/getAll',
	downloadBills: '/export/downloadBills',

	getBankDetails: '/creditReport/getBankDetails',
	getCreditReports: '/creditReport/all',
	getReportById: '/creditReport/getReportById',
	getAllReports: '/creditReport/getAllReports',
	restartBankStatementAnalysis: '/creditReport/restartBSA',
	getBSAExcelReport: '/creditReport/downloadBSAExcel',
	latestReport: 'creditReport/latestReport',

	gettrips: '/order/trips',
	getZohoClientId: '/zoho/grantCode',
	fetchContactId: '/zoho/fetchContactId',

	downloadUsers: '/export/downloadUsers',
	downloadFabricationOrders: '/export/downloadFabricationOrders',
	downloadLedgers: '/export/downloadLedgers',
	downloadOrders: '/export/downloadOrders',
	downloadActiveInvoices: '/export/downloadActiveInvoices',
	downloadZohoPastRecord: '/export/downloadZohoPastRecord',
	exportLeads: '/export/downloadLeads',

	getAllCreditRequest: '/requestCredit/getAllCreditRequest',
	getAllBanners: '/banner/getAllBanners',
	getBlogById: '/content/',
	getAllBlog: '/content/blog/all',

	getAllContentNews: '/content/news/all',
	getAllContent: '/content/all',

	getAllClients: '/organisation/getAllClients',
	getOrganisationByUser: '/organisation/getOrganisationByUser',
	getAllOrgSuppliers: '/organisation/getAllSuppliers',
	getAllLogisticPartners: '/organisation/getAllLogisticPartners',
	getAllOrganisationLocations: '/organisation/getAllOrganisationLocations',
	getAllOrganisations: '/organisation/getAllOrganisations',
	getOrganisation: '/organisation',
	getUsersOfOrganisation: '/organisation/getUsersOfOrganisation',
	getOrganisationKycData: '/organisation/getOrganisationKycData',
	getAllEnquiryPhoto: '/enquiryPhoto/all',
	getJourneyBySourceId: '/journey',
	getLedgersOfUser: '/ledger/getLedgersOfUser',
	getLedgerSummaryByOrgId: '/ledger/getLedgerSummaryByOrganisationId',
	getAllLeadOrganisations: '/lead/all',
	getLeadById: '/lead/',
	getLeadJourneyByOrgId: '/lead/leadJourney',
	getLeadsByOrgId: '/lead/getLeadsByOrg',
	downloadLeads: '/export/downloadLeads',
	fetchCompanyReport: '/reports/fetchCompanyReport',
	fetchOrganisationReport: '/reports/fetchOrganisationReport',
	downloadPaymentReceivables: '/export/downloadPaymentReceivables',
	getMonthlyTarget: '/user/getMonthlyTarget',
	generateReport: 'financials/generateReport',

	getAllPunchOrder: '/punchOrder/all',
	getPunchOrderById: '/punchOrder',

	getCreditLimitByOrdId: '/creditLimit/organisation',

	getAllGlossary: '/glossary/all',
	getGlossaryById: '/glossary/getById',
	getGlossaryBySlug: '/glossary/getBySlug',
}

export default getUrl
