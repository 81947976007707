import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { becomeRequestRole, DRAWER_TYPES, strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { DrawerBodyWrapper, DrawerFooterWrapper } from '@base'
import OrgDetailsComponent from './OrgDetails'
import PersonalDetailsComponent from './PersonalDetails'
import { ButtonWrapper, ProfileActionButton } from './styles'

const BodyWrapper = styled.div``

const EditDetails = ({ type, updateUserProfile, isSupplierRequest, requestRoleType, userData }) => {
	const dispatch = useDispatch()
	const [formState, updateState] = useState({})

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const saveHandler = () => {
		dispatch(toggleDrawer(false))
		if (isSupplierRequest) {
			let sendRequest = ''

			if (requestRoleType === becomeRequestRole.supplier_Request) {
				sendRequest = { supplier_rights_requested: true }
			} else if (requestRoleType === becomeRequestRole.manufacturer_Request) {
				sendRequest = { manufacturer_rights_requested: true }
			} else if (requestRoleType === becomeRequestRole.logistics_Request) {
				sendRequest = { logistics_rights_requested: true }
			} else if (requestRoleType === becomeRequestRole.fabricator_Request) {
				sendRequest = { fabricator_rights_requested: true }
			} else if (requestRoleType === becomeRequestRole.scrap_Request) {
				let commodity = [...userData?.commodity]
				commodity[0]['category_name'] = userData?.commodity[0]?.category_name
					? [...userData?.commodity[0]?.category_name, 'SCRAP']
					: ['SCRAP']
				sendRequest = { supplier_rights_requested: true, kyc_requested: true, commodity }
			}

			updateUserProfile({ ...formState?.data, ...sendRequest }, true)
		} else {
			updateUserProfile(formState?.data)
		}
	}

	const renderEditSection = () => {
		if (type === DRAWER_TYPES.EDIT_PERSONAL_DETAILS)
			return <PersonalDetailsComponent editable update={updateState} updateUserProfile={updateUserProfile} />
		if (type === DRAWER_TYPES.EDIT_ORG_DETAILS) return <OrgDetailsComponent editable update={updateState} requestRoleType={requestRoleType} />
	}
	return (
		<>
			<DrawerBodyWrapper>
				<BodyWrapper>{renderEditSection()}</BodyWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<ButtonWrapper>
					<ProfileActionButton small uppercase type='tertiary' onClick={cancelHandler}>
						{strings('cancel')}
					</ProfileActionButton>
					<ProfileActionButton small uppercase disabled={formState?.disableSaveButton} onClick={saveHandler}>
						{isSupplierRequest ? strings('raise_request') : strings('save')}
					</ProfileActionButton>
				</ButtonWrapper>
			</DrawerFooterWrapper>
		</>
	)
}

export default EditDetails
