import axios from 'axios'
import { Address as AddressModel } from '../../model'

export const getAddressFromPincode = (pincode) =>
	new Promise((resolve, reject) => {
		if (pincode.toString().length === 6) {
			const address = new AddressModel()
			axios
				.create({ baseURL: 'https://api.postalpincode.in/pincode/' })
				.get(pincode.toString())
				.then((response) => {
					if (response.data[0]?.Status?.toLowerCase() === 'error') {
						getAddressFromGoogle(pincode)
							.then((_response) => resolve(_response))
							.catch((_err) => reject(_err))
					} else if (response.data[0].PostOffice) {
						const postOfficeData = response.data[0].PostOffice[0]
						address.city = postOfficeData.Block
						if (postOfficeData.Block === 'NA') 
							address.city = postOfficeData.District
						address.district = postOfficeData.District
						address.division = postOfficeData.Division
						address.country = postOfficeData.Country
						address.state = postOfficeData.State
						address.pincode = pincode
						resolve(address)
					}
				})
				.catch((err) => {
					getAddressFromGoogle(pincode)
						.then((_response) => resolve(_response))
						.catch((_err) => reject(_err))
				})
		} else if (pincode.toString().length >= 4) {
			getAddressFromGoogle(pincode)
				.then((_response) => resolve(_response))
				.catch((_err) => reject(_err))
		}
	})

const getAddressFromGoogle = (pincode) =>
	new Promise((resolve, reject) => {
		const googleUrl =
			'https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyAvMq-nF1pfUUgkzc0u_W_Fb_w2a7xF-6Y&address=' + pincode
		axios
			.create({ baseURL: googleUrl })
			.get()
			.then((response) => {
				if (response.data.status === 'OK') {
					const results = response.data.results[0]
					if (results) {
						const address = new AddressModel()
						const addressComponent = results.address_components
						address.pincode = pincode
						addressComponent.forEach((item) => {
							if (item.types.includes('country')) {
								address.country = item.long_name
							} else if (item.types.includes('locality')) {
								address.city = item.long_name
								address.district = item.long_name
								address.state = item.long_name
								address.division = item.long_name
							} else if (item.types.includes('administrative_area_level_3')) {
								if (!address.city) {
									address.city = item.long_name
								}
								address.district = item.long_name
								address.division = item.long_name
							} else if (item.types.includes('administrative_area_level_2')) {
								if (!address.city) {
									address.city = item.long_name
								}
								if (!address.district) {
									address.district = item.long_name
								}
								address.division = item.long_name
							} else if (item.types.includes('administrative_area_level_1')) {
								if (!address.city) {
									address.city = item.long_name
									address.district = item.long_name
									address.division = item.long_name
								}
								address.state = item.long_name
							} else if (item.types.includes('postal_code')) {
								address.pincode = item.long_name
								if (isNaN(pincode)) { 
									address.complete_address = pincode
								}
							}
						})

						resolve(address)
					}
				} else {
					reject('')
				}
			})
			.catch((_err) => {
				reject('')
			})
	})
