import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Input } from '@base'
import { CancelOutlined, Send } from '@material-ui/icons'
import { SectionItemWrapperWithTwoItems } from './styles'

const IconWapper = styled(Button)`
	align-self: center;
	min-width: 2rem;
`

const RejectReason = ({ kycStatushandler, setIsActiveReason, setpNumber, stepName, rejectReasonMsg }) => {
	const [isReason, setIsReason] = useState(rejectReasonMsg)
	const rejectReasonHandler = () => {
		kycStatushandler(isReason, setpNumber, false, `${stepName} request reject`)
		setIsReason()
	}
	return (
		<SectionItemWrapperWithTwoItems>
			<Input
				type='text'
				disabled={false}
				label={'Reason'}
				value={isReason}
				onChange={(val) => setIsReason(val)}
				placeholder={'enter reject reason'}
			/>

			<IconWapper
				small
				type='primary'
				margin='0px 0px 0px 3px'
				disabled={!isReason}
				onClick={() => {
					rejectReasonHandler()
				}}
			>
				<Send fontSize='small' />
			</IconWapper>
			<IconWapper
				small
				type='secondary'
				margin='0px 0px 0px 3px'
				variant='destructive'
				onClick={() => {
					setIsActiveReason(false)
				}}
			>
				<CancelOutlined fontSize='small' />
			</IconWapper>
		</SectionItemWrapperWithTwoItems>
	)
}

export default RejectReason
