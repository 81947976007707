import { Button, DrawerBodyWrapper, DrawerFooterWrapper } from '@base'
import { strings } from '@constants'
import React, { useEffect, useState } from 'react'
import GenerateEstimateForm from './GenerateEstimateForm'
import { downloadEstimate, getBillingAddressesApi } from '@data'
import { convertDataFile } from '@utils'

const accountDetails = [
	{
		account_number: '724005000281',
		bank_name: 'ICICI',
		ifsc_code: 'ICIC0007240',
	},
	{
		account_number: '10101209696',
		bank_name: 'IDFC',
		ifsc_code: 'IDFB0020101',
	},
	{
		account_number: '20062200480734',
		bank_name: 'SBM',
		ifsc_code: 'STCB0000065',
	},
]

const gstDetails = [
	{
		name: 'MBOOK TECHNOLOGY PRIVATE LIMITED',
		gst: '06AAOCM1740Q1ZY',
		location: 'Haryana',
		address: {
			complete_address: '4, UNIT NO.426, STAR TOWER, VILLAGE SILOKHERA, SECTOR 30, Gurugram, Gurugram, Haryana, 122002',
			city: 'Gurugram',
			state: 'Haryana',
			pincode: 122002,
			country_code: 'IN',
			country: 'Inida',
		},
	},
	{
		name: 'MBOOK TECHNOLOGY PRIVATE LIMITED',
		gst: '07AAOCM1740Q1ZW',
		location: 'Delhi',
		address: {
			complete_address: 'UGF Right Side In tower A, 24, Kila No.-21, Kila No. 21, Mehrauli, South West Delhi, Delhi, 110030',
			city: 'Delhi',
			state: 'Delhi',
			pincode: 110030,
			country_code: 'IN',
			country: 'Inida',
		},
	},
	{
		name: 'MBOOK TECHNOLOGY PRIVATE LIMITED',
		gst: '19AAOCM1740Q1ZR',
		location: 'West Bengal',
		address: {
			complete_address:
				'4, LOTUS-4E, PURTI FLOWER APPT, BUDGE BUDGE TRUNK ROAD, Maheshtala, Kolkata, South Twenty Four Parganas, West Bengal, 700141',
			city: 'Maheshtala',
			state: 'West Bengal',
			pincode: 700141,
			country_code: 'IN',
			country: 'Inida',
		},
	},
	{
		name: 'MBOOK TECHNOLOGY PRIVATE LIMITED',
		gst: '21AAOCM1740Q1Z6',
		location: 'Odisha',
		address: {
			complete_address: 'JAGANNATH MATHA LANE, 2047, HATASAHI, Old Town, Bhubaneswar, Khordha, Odisha, 751002',
			city: 'Khordha',
			state: 'Odisha',
			pincode: 751002,
			country_code: 'IN',
			country: 'Inida',
		},
	},
]

const initialFormState = {
	remarks: {
		name: 'remarks',
		label: strings('remarks'),
		disabled: false,
		value: '',
		dirty: false,
	},
	gst_detail: {
		name: 'gst_detail',
		label: strings(['select', 'gst', 'number']),
		disabled: false,
		value: '',
		dirty: false,
	},
	account_detail: {
		name: 'account_detail',
		label: strings(['select', 'account', 'details']),
		disabled: false,
		value: '',
		dirty: false,
	},
	billing_address: {
		name: strings('billing', 'address'),
		label: strings(['select', 'billing', 'address']),
		disabled: false,
		value: '',
		dirty: false,
	},
}

const deriveFormStateQuotesItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.billing_address.value = data?.billing_address ?? ''
	derivedState.billing_address.disabled = !editable

	return derivedState
}

const Index = ({ enquiry, editable = true }) => {
	const [formState, setFormState] = useState(initialFormState)
	const [addresses, setAddresses] = useState()

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateQuotesItemData(_oldState, enquiry, !!editable))
		enquiry &&
			getBillingAddressesApi(enquiry?.buyer_id)
				.then((res) => {
					if (res.length > 0) setAddresses(res)
				})
				.catch((e) => console.log(e))
	}, [editable])

	const onChangeHandler = (key) => (value) => {
		setFormState({ ...formState, [key]: { ...formState[key], value } })
	}

	const onClickGenerateEstimateHandler = () => {
		const gst_detail = gstDetails.find((item) => item?.gst === formState.gst_detail.value)
		const account_detail = accountDetails.find((item) => item?.account_number === formState.account_detail.value)
		const billing_address = formState.billing_address?.value
		const remarks = formState.remarks.value
		downloadEstimate(enquiry?.enquiry_id, { gst_detail, account_detail, billing_address, remarks })
			.then((res) => {
				convertDataFile(res?.data, 'mb_estimate_' + new Date().getTime(), 'application/pdf')
			})
			.catch((err) => console.log(err))
	}

	const clearHandler = () => {
		setFormState(initialFormState)
	}

	const disableButton = !formState?.gst_detail?.value || !formState?.account_detail?.value || !formState?.billing_address?.value
	return (
		<>
			<DrawerBodyWrapper>
				<GenerateEstimateForm
					formState={formState}
					accountDetails={accountDetails}
					gstDetails={gstDetails}
					addresses={addresses}
					onChangeHandler={onChangeHandler}
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button margin={`0 0 0 auto`} small uppercase type='primary' onClick={onClickGenerateEstimateHandler} disabled={disableButton}>
					{strings('generate', 'estimate')}
				</Button>
				<Button small uppercase type='secondary' variant='destructive' onClick={clearHandler} disabled={disableButton}>
					{strings('clear')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default Index
