import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Add } from '@material-ui/icons'
import { strings } from '@constants'
import { Devices, Spacings, Text } from '@styles'
import { Accordion, DrawerBodyWrapper, DrawerFooterWrapper, SectionHeader, Toggle } from '@base'
import { displayAmount } from '@utils'
import Summary from './Summary'

const Body = styled.div`
	display: flex;
	flex-direction: column;
`

const ToggleWrapper = styled.div`
	margin-left: auto;
`

const MathIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${Spacings.SPACING_2B};
	flex-grow: ${({ noGrow }) => (noGrow ? 0 : 1)};

	@media ${Devices.tablet} {
		flex-grow: 1;
	}

	& > svg {
		fill: ${({ theme }) => theme.palette.text.darkblack};
		font-size: ${Text.LARGE};
	}
`

const TotalAmount = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;

	@media ${Devices.tablet} {
		padding: ${Spacings.SPACING_1B} 0;
	}
`

const SummaryItemCount = styled.div`
	display: flex;
	align-items: center;
	& > span {
		background: ${({ theme }) => theme.palette.background.blue};
		color: ${({ theme }) => theme.palette.text.white};
		font-size: ${Text.SMALL};
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const TotalAmountInfo = styled.div`
	font-size: ${Text.MEDIUM};
	display: flex;
	flex-grow: 1;
	justify-content: center;
	flex-direction: column;

	& > span {
		margin-bottom: ${Spacings.SPACING_1B};
	}

	@media ${Devices.tablet} {
		flex-direction: row;
		align-items: center;
		justify-content: initial;
		& > span {
			margin-bottom: 0;
		}
	}
`

const SummaryItemValue = styled.div`
	font-size: ${({ big }) => (big ? Text.LARGE : Text.MEDIUM)};
	flex-basis: 35%;
	font-weight: ${({ big }) => (big ? Text.BOLD_600 : 'initial')};
	color: ${({ theme }) => theme.palette.text.darkblack};
`

const SummaryCalculation = styled.div`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.palette.text.darkblack};
	& > span {
		margin: 0 ${Spacings.SPACING_1B};
	}
`

const AdminOrderSummary = ({ data }) => {
	const [isPurchaseOrder, setIsPurchaseOrder] = useState(true)

	const { userData, weightKey, orderTotalAmount, buyerSupplierListKey } = useMemo(() => {
		let userData
		let orderTotalAmount
		if (isPurchaseOrder) {
			userData = data.buyer_list
		} else {
			userData = data.supplier_list
		}

		const getServicedWeight = (invoices, item) => {
			return invoices.reduce(
				(total, _i) => {
					let _weight = total.weight
					if (_i.order_items) {
						_weight += _i.order_items.find((_ix) => _ix.product_id === item.product_id).serviced_weight
					}
					return { weight: _weight }
				},
				{ weight: 0 }
			).weight
		}

		const weightKey = isPurchaseOrder ? 'ordered_weight' : 'allotted_weight'
		const buyerSupplierListKey = isPurchaseOrder ? 'buyer' : 'supplier'
		const invoiceKey = isPurchaseOrder ? 'buyer_invoices' : 'supplier_invoices'

		let _itemsAmount = 0
		let _logistics = 0

		if (Array.isArray(userData)) {
			_itemsAmount = userData.reduce((_partialAmount, _buyer) => {
				const invoices = _buyer[invoiceKey] || []
				return _buyer.order_items.reduce(
					(_amount, _item) => _amount + getServicedWeight(invoices, _item) * _item.price_per_kg,
					_partialAmount
				)
			}, 0)
		} else {
			const invoices = userData[invoiceKey] || []
			_itemsAmount = userData.order_items.reduce((_amount, _item) => _amount + getServicedWeight(invoices, _item) * _item.price_per_kg, 0)
		}
		orderTotalAmount = (_itemsAmount + _logistics) * 1.18

		return {
			userData,
			weightKey,
			orderTotalAmount,
			buyerSupplierListKey,
		}
	}, [data, isPurchaseOrder])

	const renderTotalAmount = () => {
		return (
			<>
				<TotalAmount>
					<TotalAmountInfo>
						<span>{strings('total_amount')}</span>
						{isPurchaseOrder && (
							<SummaryCalculation>
								<span>(</span>
								<SummaryItemCount>
									<span>1</span>
								</SummaryItemCount>
								<MathIcon noGrow>
									<Add />
								</MathIcon>
								<SummaryItemCount>
									<span>2</span>
								</SummaryItemCount>
								{/* <MathIcon noGrow>
									<Add />
								</MathIcon>
								<SummaryItemCount>
									<span>3</span>
								</SummaryItemCount> */}
								<span>)</span>
							</SummaryCalculation>
						)}
					</TotalAmountInfo>
					<SummaryItemValue big>{displayAmount(orderTotalAmount)}</SummaryItemValue>
				</TotalAmount>
			</>
		)
	}

	const toggleClick = (flag) => () => setIsPurchaseOrder(flag)

	const renderSummary = () => {
		if (Array.isArray(userData)) {
			return userData.map((_data, index) => {
				return (
					<Accordion
						header={_data[buyerSupplierListKey]?.full_name ?? _data[buyerSupplierListKey]?.user_id}
						key={`accordion-summary-${index}`}
						defaultOpen={!isPurchaseOrder}
					>
						<Summary data={_data} weightKey={weightKey} showTotal />
					</Accordion>
				)
			})
		}
		return <Summary data={userData} weightKey={weightKey} />
	}

	return (
		<>
			<DrawerBodyWrapper>
				<SectionHeader>
					<ToggleWrapper>
						<Toggle
							options={[
								{
									label: strings('buyer'),
									click: toggleClick(true),
									active: isPurchaseOrder,
								},
								{
									label: strings('suppliers'),
									click: toggleClick(false),
									active: !isPurchaseOrder,
								},
							]}
						/>
					</ToggleWrapper>
				</SectionHeader>
				<Body>{renderSummary()}</Body>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderTotalAmount()}</DrawerFooterWrapper>
		</>
	)
}

export default AdminOrderSummary
