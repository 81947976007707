import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Button, Spinner, TOAST_TYPE } from '@base'
import { MODAL_TYPES, strings } from '@constants'
import { toggleModal } from '@data/state/action/root'
import { showToast } from '@data/state/action'
import { DoneOutlineIcon, ErrorOutlineIcon, FormWrapper, InputWrapper, SectionItemWrapper, ViewItemWrapper } from './styles'
import UploadStatent from './UploadStatement'

const StatusForm = React.memo(
	({
		maxDate,
		state,
		updateState,
		bankDetails,
		addMonths,
		reportDetails,
		fetchPastRecords,
		restartBankStatement,
		uploadStatentHandler,
		initiateGenerateReportAPI,
	}) => {
		const { pan, bsa_download_status, mca_download_status, customerId } = state
		const dispatch = useDispatch()
		const [activeBSAReport, isActiveBSAReport] = useState(false)

		const onChange = (key) => (value) => {
			updateState(key, { value, dirty: true })
		}

		const confirmStatus = (e, _type) => {
			e.stopPropagation()
			const msg = _type === 'bsa' ? strings('restart_bank_analysis') : strings('fetch_mca_again')

			dispatch(
				toggleModal(true, MODAL_TYPES.CONFIRMATION, {
					overlayClickable: true,
					title: `${msg}?`,
					confirmAction: (actionType) => {
						actionType && restartBankStatement()
						dispatch(toggleModal(false))
					},
				})
			)
		}

		const downloadStatus = (status, _type) => {
			if (status === null) {
				return <ErrorOutlineIcon onClick={(e) => confirmStatus(e, _type)} />
			} else if (status === false) {
				return <Spinner />
			} else {
				return <DoneOutlineIcon />
			}
		}

		const fetchPastRecordsHandler = () => {
			if (customerId?.value) return fetchPastRecords(customerId?.value)
			dispatch(showToast(true, 'Please enter customer ID', { type: TOAST_TYPE.ERROR }))
		}

		const isActiveBSAReportHandler = () => {
			initiateGenerateReportAPI()
			isActiveBSAReport(true)
		}

		const viewhandler = () => {
			const bsaDownloadStatus =
				reportDetails?.bsa_download_status === null ||
				(!reportDetails?.bsa_download_status && reportDetails?.bsa_report?.perfios_status !== 'GENERATE_REPORT_STARTED')
			return (
				<>
					<SectionItemWrapper>
						<Input
							uppercase
							type='text'
							label={pan?.label}
							disabled={true}
							value={pan?.value}
							pattern='\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}|[A-Z]{5}[0-9]{4}[A-Z]{1}'
							onChange={onChange('pan')}
							errorMap={{
								patternMismatch: strings('enter_pan_gst'),
							}}
						/>
					</SectionItemWrapper>

					{/* Upload Statement  */}
					<UploadStatent
						maxDate={maxDate}
						state={state}
						addMonths={addMonths}
						bankDetails={bankDetails}
						reportDetails={reportDetails}
						onChange={onChange}
						uploadStatentHandler={uploadStatentHandler}
						isActiveBSAReport={isActiveBSAReport}
						initiateGenerateReportAPI={initiateGenerateReportAPI}
						bsaDownloadStatus={bsaDownloadStatus}
						isActiveBSAReportHandler={isActiveBSAReportHandler}
					/>
					{/* <InputWrapper>
						<Input
							uppercase
							type='text'
							label={customerId?.label}
							disabled={customerId?.disabled}
							value={customerId?.value}
							onChange={onChange('customerId')}
						/>
						<SectionItemWrapper isCustomer>
							<Button xs type='primary' disabled={customerId?.disabled} onClick={fetchPastRecordsHandler}>
								{strings('fetch_past_records')}
							</Button>
						</SectionItemWrapper>
					</InputWrapper> */}
					<ViewItemWrapper>
						<SectionItemWrapper isStatement>
							<span>{strings('financials')}</span>
							{downloadStatus(mca_download_status, 'mca')}
						</SectionItemWrapper>
						{(activeBSAReport ||
							(!reportDetails?.bsa_download_status && reportDetails?.bsa_report?.perfios_status === 'GENERATE_REPORT_STARTED') ||
							reportDetails?.bsa_download_status) && (
							<SectionItemWrapper isStatement>
								<span>{strings('bsa')}</span>
								{downloadStatus(bsa_download_status, 'bsa')}
							</SectionItemWrapper>
						)}
					</ViewItemWrapper>
				</>
			)
		}

		return <FormWrapper>{viewhandler()}</FormWrapper>
	}
)

export default StatusForm
