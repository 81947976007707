import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Button, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import EstimateForm from './EstimateForm'
import API from '@data/conn/api'
import getUrl from '@data/conn/get/paths'
import { createPunchOrder, getAllEmployees, uploadPunchOrderFile, getPunchOrderById } from '@data'
import { Wrapper } from '../styles'
import { showLoader, showToast } from '@data/state/action'
import { toggleFileViewer } from '@data/state/action/root'
import { displayName } from '@utils'
import { editPunchOrder } from '@data/conn/put/punchOrder'
import { useForceUpdate } from '@hooks'

const getFormState = (data) => {
	const _state = {
		user_details: {
			placeholder: strings('select', 'customer'),
			label: strings('customer'),
			value: {},
			required: true,
			disabled: false,
			primaryKey: 'organisation_id',
			displayKey: 'display_name',
		},
		business_category: {
			id: 'business-category',
			placeholder: strings(['select', 'business_category']),
			label: strings('business_category'),
			value: '',
			required: true,
			disabled: false,
			primaryKey: 'key',
			displayKey: 'label',
		},
		project_name: {
			id: 'project-name',
			placeholder: strings(['enter', 'project_name']),
			label: strings('project_name'),
			value: '',
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			required: true,
		},
		region: {
			id: 'region',
			placeholder: strings('select', 'region'),
			label: strings('region'),
			required: true,
			value: '',
			disabled: false,
			primaryKey: 'key',
			displayKey: 'label',
		},
		total_quantity: {
			id: 'total-quantity',
			placeholder: strings(['total', 'quantity']),
			label: strings('total', 'quantity'),
			value: '',
			required: true,
			disabled: false,
		},
		total_po_amount: {
			id: 'po-amount',
			placeholder: strings(['enter', 'po', 'amount']),
			label: strings(['total', 'po', 'amount']),
			value: '',
			required: true,
			disabled: false,
		},
		shipping_address: {
			id: 'shipping-address',
			placeholder: strings(['enter', 'shipping_pincode']),
			label: strings('shipping_pincode'),
			value: {},
			required: true,
			disabled: false,
		},
		contribution_margin: {
			id: 'contribution-margin',
			placeholder: strings(['enter', 'contribution_margin']),
			label: strings('contribution_margin'),
			value: '',
			required: true,
			disabled: false,
		},
		marketing_expense: {
			id: 'marketing-expense',
			placeholder: strings(['enter', 'marketing_expense']),
			label: strings('marketing_expense'),
			value: '',
			required: true,
			disabled: false,
		},
		payment_terms: {
			id: 'payment-terms',
			placeholder: strings(['select', 'payment', 'terms']),
			label: strings('payment', 'terms'),
			value: '',
			primaryKey: 'key',
			displayKey: 'label',
			required: true,
			disabled: false,
		},
		net_margin: {
			id: 'net-margin',
			placeholder: strings(['enter', 'net_margin']),
			label: strings('net_margin'),
			value: '',
			required: true,
			disabled: true,
		},
		supplier: {
			id: 'spoc-name',
			placeholder: strings(['select', 'supply', 'spoc', 'name']),
			label: strings(['supply', 'spoc', 'name']),
			value: {},
			primaryKey: 'user_id',
			displayKey: 'full_name',
			required: true,
			disabled: false,
		},
		logistics_handler: {
			id: 'logistics-handler',
			placeholder: strings(['select', 'logistics_handler']),
			label: strings('logistics_handler'),
			displayKey: 'label',
			primaryKey: 'key',
			required: true,
			disabled: false,
			value: '',
		},
		expected_delivery_date: {
			id: 'delivery-date',
			label: strings(['expected', 'delivery', 'date']),
			value: new Date().getTime(),
			required: true,
			disabled: false,
		},
		expected_transit_time: {
			id: 'transit-time',
			placeholder: strings(['enter', 'expected_transit_time']),
			label: strings('expected_transit_time'),
			value: '',
			required: true,
			disabled: false,
		},
		customer_po: {
			id: 'customer-po',
			value: '',
			required: true,
			disabled: false,
		},
		management_approval: {
			id: 'approval-document',
			value: '',
			required: false,
			disabled: false,
		},
		freight_per_mt: {
			id: 'freight-per-mt',
			value: '',
			label: strings(['freight', 'per', 'mt']),
			placeholder: strings(['enter', 'freight', 'per', 'mt']),
			required: false,
			disabled: false,
		},
		po_number: {
			id: 'po-number',
			label: strings(['po', 'number']),
			placeholder: strings(['enter', 'po', 'number']),
			value: '',
			disabled: false,
			required: false,
		},
		enquiry_id: {
			id: 'enquiry-id',
			label: strings('enquiry_id'),
			placeholder: strings('enter', 'enquiry_id'),
			value: '',
			required: false,
			disabled: false,
		},
		order_id: {
			id: 'order-id',
			label: strings('order_id'),
			placeholder: strings('enter', 'order_id'),
			value: '',
			required: false,
			disabled: false,
		},
		payment_tenure: {
			id: 'payment-tenure',
			label: strings(['payment', 'tenure', '(', 'in', 'days', ')']),
			placeholder: strings(['enter', 'payment', 'tenure']),
			value: '',
			required: true,
			disabled: false,
		},
	}

	if (data) {
		if (data?.user_details) {
			_state.user_details.value = data?.user_details ?? {}
			_state.user_details.value['display_name'] = displayName(data?.user_details)
		}
		_state.business_category.value = data?.business_category
		_state.project_name.value = data?.project_name
		_state.region.value = data?.region
		_state.total_quantity.value = data?.total_quantity
		_state.total_po_amount.value = data?.total_po_amount
		_state.shipping_address.value = data?.shipping_address
		_state.contribution_margin.value = data?.contribution_margin
		_state.marketing_expense.value = data?.marketing_expense
		_state.net_margin.value = data?.net_margin
		_state.supplier.value = data?.supplier
		_state.logistics_handler.value = data?.logistics_handler
		_state.expected_delivery_date.value = data?.expected_delivery_date
		_state.expected_transit_time.value = data?.expected_transit_time
		_state.customer_po.value = data?.customer_po
		_state.management_approval.value = data?.management_approval
		_state.po_number.value = data?.po_number
		_state.enquiry_id.value = data?.enquiry_id
		_state.order_id.value = data?.order_id
		_state.freight_per_mt.value = data?.freight_per_mt
		_state.payment_tenure.value = data?.payment_tenure
		_state.payment_terms.value = data?.payment_terms
	}
	return _state
}

const OrderEstimateForm = ({ isView = false }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [forceUpdate, update] = useForceUpdate()
	const { punchOrderId } = useParams()
	const [formState, setFormState] = useState(getFormState())
	const [clientData, setClientData] = useState([])
	const [spocList, setSpocList] = useState([])
	const [punchOrder, setPunchOrder] = useState({})

	useEffect(() => {
		if (!punchOrderId) return

		getPunchOrderById(punchOrderId)
			.then((response) => {
				setFormState(getFormState(response))
				setPunchOrder(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [punchOrderId, forceUpdate])

	useEffect(() => {
		API.get(getUrl.getAllClients)
			.then((response) => {
				response.data?.map((item) => {
					item['display_name'] = displayName(item)
					return item
				})
				setClientData(response.data)
			})
			.catch((err) => console.log(err))

		getAllEmployees()
			.then((response) => {
				setSpocList(response)
			})
			.catch(() => {})
	}, [])

	const isAproveddd = useMemo(() => {
		if (!formState?.payment_tenure?.value || !formState?.total_po_amount?.value) {
			return false
		}
		let days = (formState?.payment_tenure?.value ?? 0) + (formState?.expected_transit_time?.value ?? 0)
		let n_margin = formState?.contribution_margin?.value ?? 0 - formState?.marketing_expense?.value ?? 0
		let yx = days * (2 / 3000)
		let amt = (formState?.total_po_amount?.value / 1.18) * yx
		return amt >= n_margin * formState?.total_quantity?.value
	}, [
		formState?.net_margin,
		formState?.contribution_margin,
		formState?.total_po_amount,
		formState?.expected_transit_time,
		formState?.payment_terms,
		formState?.marketing_expense,
		formState?.total_quantity,
	])

	const updateState = (key) => (value) => {
		if (['business_category', 'region', 'logistics_handler', 'project_name'].includes(key)) {
			value = value.key
		} else if (key === 'supplier') {
			value = value?.user_details
		} else if (key === 'payment_terms') {
			value = value?.label
		}
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
		setPunchOrder((_state) => {
			return { ..._state, [key]: value }
		})
		if (key === 'user_details') {
			updateState('shipping_address')({})
		}
	}
	console.log(formState)
	const viewFileHandler = (type) => (file) => {
		if (!file) {
			return
		}
		dispatch(toggleFileViewer(true, { files: file }))
	}
	const uploadDocumentHandler = (type) => (file) => {
		if (!file) {
			return
		}

		dispatch(showLoader(true))
		uploadPunchOrderFile(file)
			.then((response) => {
				updateState(type)(response)
				dispatch(showToast(true, strings('uploaded_the_file_successfully'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				console.log(error)
				dispatch(showToast(true, strings('document_upload_failed'), { type: TOAST_TYPE.ERROR }))
			})
		dispatch(showLoader(false))
	}
	const addPunchOrderHandler = () => {
		dispatch(showLoader(true))
		createPunchOrder({ ...punchOrder, expected_delivery_date: formState?.expected_delivery_date?.value })
			.then((response) => {
				dispatch(showToast(true, strings('msg_punch_order_created'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings('msg_punch_order_failure'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
				navigate(-1)
			})
	}

	const updatePunchOrderHandler = () => {
		editPunchOrder(punchOrder)
			.then(() => {
				dispatch(showToast(true, strings('msg_punch_order_update_success'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((error) => {
				if (error?.client_error_code === 'E_002' && error?.message) {
					dispatch(showToast(true, error.message, { type: TOAST_TYPE.ERROR }))
				} else {
					dispatch(showToast(true, strings('msg_punch_order_update_failure'), { type: TOAST_TYPE.ERROR }))
				}
			})
	}

	const isDisabled = useMemo(() => {
		let _disable = Object.values(formState).some((d) => d.required && !d.value)
		_disable =
			_disable ||
			Object.keys(formState.user_details.value).length === 0 ||
			Object.keys(formState.supplier.value).length === 0 ||
			Object.keys(formState.shipping_address.value).length === 0
		return _disable
	}, [formState])

	const updateDisabled = useMemo(() => {
		if (formState?.po_number?.value || formState?.enquiry_id?.value || formState?.order_id?.value) {
			return false
		} else {
			return true
		}
	}, [formState])

	return (
		<>
			<Wrapper>
				<EstimateForm
					formState={formState}
					updateState={updateState}
					clientData={clientData}
					spocList={spocList}
					approvalProofCheck={isAproveddd}
					viewFileHandler={viewFileHandler}
					uploadDocumentHandler={uploadDocumentHandler}
					isView={isView}
				/>
			</Wrapper>
			<DrawerFooterWrapper>
				{!isView ? (
					<Button small uppercase type='primary' disabled={isDisabled || punchOrderId} onClick={addPunchOrderHandler}>
						{strings(['add'])}
					</Button>
				) : (
					<Button small uppercase type='primary' disabled={updateDisabled} onClick={updatePunchOrderHandler}>
						{strings(['update'])}
					</Button>
				)}
			</DrawerFooterWrapper>
		</>
	)
}

export default OrderEstimateForm
