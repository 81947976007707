import API from '../api'
import postUrl from './paths'

 const addInvoice = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.addInvoice, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

    export { addInvoice }