import { createGlobalStyle } from 'styled-components'
import { Devices } from '.'

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html {
        font-size: 13px;
        @media ${Devices.tablet} {
            font-size: 14px;
        }
        @media ${Devices.laptop} {
            font-size: 15px;
        }

    }
    body {
        background: ${({ theme }) => theme.palette.background.main};
        height: 100%;
		width: 100%;
    }
    a {
        text-decoration: none;
    }

    #root {
        height: 100%;
        width: 100%;
    }

    input {
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
`
