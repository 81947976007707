import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderMeta } from '@data/state/action/root'

const useHeaderMeta = (onLoadMeta = {}, shouldClear = true, onUnLoadMeta = {}, deps = [], skip = false) => {
	const dispatch = useDispatch()
	useEffect(() => {
		if (skip) return

		dispatch(setHeaderMeta(onLoadMeta))

		return () => {
			if (shouldClear & !skip) {
				dispatch(setHeaderMeta(onUnLoadMeta))
			}
		}
	}, [...deps])
}

export default useHeaderMeta
