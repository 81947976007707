import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useParams } from 'react-router'
import { H6, Input, Button, DrawerBodyWrapper, DrawerFooterWrapper } from '@base'
import { getAddressFromPincode, getUserByID } from '@data'
import { Address, User } from '@data/model'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '@data/state/action/user'
import { toggleDrawer } from '@data/state/action/root'
import { strings } from '@constants'

const Error = styled(H6)`
	color: ${({ theme }) => theme.palette.text.messageRed};
`

const BodyWrapper = styled.div``
const AddressWrapper = styled.div`
	opacity: ${({ show }) => (show ? 1 : 0)};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	transition: all 0.2s ease-in-out;
`
const InputWrapper = styled.div`
	padding: 0.5rem 0;
	${({ checkbox }) => {
		if (checkbox) {
			return css`
				display: flex;
			`
		}
	}}
`

const ActionButton = styled(Button)``

const initialPincodeState = {
	value: '',
	valid: false,
}

const initialAddressState = {
	city: '',
	district: '',
	state: '',
	street_address: '',
	country: '',
}

const CreateAddress = ({
	addToUserAddresses,
	addToNewEnquiry,
	addCallback,
	updateNewEnquiryCallback,
	updateCallback,
	addToNewAuction,
	isAdmin,
	addToOrg,
	addToOrgCallback,
}) => {
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [userData, setUserData] = useState({})
	const dispatch = useDispatch()

	const completeAddress = useRef()
	const timeoutId = useRef()
	const [loading, setLoading] = useState(false)
	const [pincode, setPincode] = useState(initialPincodeState)
	const [address, setAddress] = useState(initialAddressState)
	const [apiError, setApiError] = useState('')
	const userId = useParams()?.userId

	const enableButton = Boolean(pincode.value && pincode.value.toString().length >= 4 && !apiError && !loading)

	const pincodeChangeHandler = (key) => (value) => {
		if (apiError) {
			setApiError(false)
		}
		setPincode((val) => ({ ...val, [key]: String(value) }))
	}

	const streetAddressChangeHandler = (value) => {
		setAddress((val) => ({
			...val,
			street_address: value,
		}))
	}

	useEffect(() => {
		if (userId) {
			getUserByID(userId).then((response) => {
				setUserData(response)
			})
		} else {
			setUserData(user)
		}
	}, [userId, user])

	const addAddressHandler = () => {
		if (!enableButton) return

		completeAddress.current.complete_address = address.street_address.trim()

		if (addToUserAddresses) {
			userData.addAddress(completeAddress.current)
			const updatedUser = new User({ ...userData })
			dispatch(updateUser(updatedUser, isAdmin))
		}

		if (addToNewEnquiry && updateNewEnquiryCallback) {
			updateNewEnquiryCallback({ shipping_address: completeAddress.current })
		}

		if (addToNewAuction && updateCallback) {
			updateCallback(completeAddress.current, true)
		}

		if (addCallback && typeof addCallback === 'function') {
			addCallback(completeAddress.current)
		}

		if (addToOrg) {
			addToOrgCallback(completeAddress.current)
		}

		dispatch(toggleDrawer(false))
	}

	useEffect(() => {
		clearTimeout(timeoutId.current)
		if (pincode.value && pincode.value.toString().length >= 4 && !loading) {
			timeoutId.current = setTimeout(getAddress, pincode.value.toString().length === 6 ? 500 : 2000)
		}

		return () => {
			setAddress(initialAddressState)
			clearTimeout(timeoutId.current)
		}
	}, [pincode])

	const getAddress = () => {
		clearTimeout(timeoutId.current)
		setLoading(true)
		getAddressFromPincode(pincode.value)
			.then((address) => {
				if (!address.city || !address.state) {
					return Promise.reject()
				}
				completeAddress.current = new Address(address)
				setAddress((add) => ({
					...add,
					city: address?.city,
					district: address?.district,
					state: address?.state,
					country: address?.country,
					pincode: address?.pincode,
					complete_address: address?.complete_address,
					street_address: address?.complete_address,
				}))
			})
			.catch(() => {
				setApiError(strings('msg_failed_pincode_details'))
			})
			.finally((_) => {
				setLoading(false)
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<BodyWrapper>
					<InputWrapper>
						<Input
							label={strings('pincode')}
							id='pincode'
							value={pincode.value}
							min={1000}
							max={99999999}
							pattern='^[0-9-]*'
							disabled={loading}
							onChange={pincodeChangeHandler('value')}
							setValidity={pincodeChangeHandler('valid')}
							placeholder={strings('enter_location_pincode')}
							errorMap={{
								rangeOverflow: strings('invalid_pincode'),
								rangeUnderflow: strings('invalid_pincode'),
							}}
						/>
						{apiError && <Error>{apiError}</Error>}
					</InputWrapper>
					<AddressWrapper show={address.city && address.state}>
						<InputWrapper>
							<Input label={strings('city')} id='city' type='text' value={address.city} disabled />
						</InputWrapper>
						<InputWrapper>
							<Input label={strings('district')} id='district' type='text' value={address.district} disabled />
						</InputWrapper>
						<InputWrapper>
							<Input label={strings('state')} id='state' type='text' value={address.state} disabled />
						</InputWrapper>
						<InputWrapper>
							<Input label={strings('country')} id='country' type='text' value={address.country} disabled />
						</InputWrapper>
						<InputWrapper>
							<Input
								label={strings('address')}
								id='street_address'
								type='text'
								value={address.street_address}
								onChange={streetAddressChangeHandler}
							/>
						</InputWrapper>
					</AddressWrapper>
				</BodyWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<ActionButton small uppercase type='primary' disabled={!enableButton} onClick={addAddressHandler}>
					{strings('add_address')}
				</ActionButton>
			</DrawerFooterWrapper>
		</>
	)
}

export default CreateAddress
