import { Map } from 'immutable'

const initialState = Map({
	showLoader: false,
	sidebarTitle: 'Dashboard',
	toast: { title: '', open: false, options: { autoHide: true, actions: null, autoHideTime: 4000 } },
	tabTitle: { title: '', count: 0, showBack: false },
	modal: { open: false, children: null },
	// tabTitleCount: { title: '', count: 0 },
	showDrawer: false,
	drawerType: null,
	search: { searchFilter: {}, type: null },
	headerMeta: {},
	showFileViewer: false,
	fileViewerData: {},
	notification: { play: false },
})

export default initialState
