import React from 'react'
import { ThemeProvider } from 'styled-components'
import firebase from 'firebase/compat/app'
import { useRoutes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { FirebaseConfig } from '@constants'
import store from '@data/state/store'
import { GlobalStyles, LightTheme } from '@styles'
import { routes } from '@routes'
import UnderMaintenance from '@pages/Login/LoginFlows/UnderMaintenance'

const App = () => {
	const routing = useRoutes(routes)
	const underMaintenance = false

	!firebase.apps.length ? firebase.initializeApp(FirebaseConfig) : firebase.app()
	
	if (underMaintenance) {
		return <UnderMaintenance />
	}

	return (
		<ThemeProvider theme={LightTheme}>
			<GlobalStyles />
			<Provider store={store}>{routing}</Provider>
		</ThemeProvider>
	)
}

export default App
