import React from 'react'
import styled from 'styled-components'
import { Pill } from '@base'
import { Spacings } from '@styles'

const CategoryWapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const CommodityCategoryList = ({ categories }) => {
	return (
		<CategoryWapper>
			{categories?.map((item, index) => (
				<Pill margin={`${Spacings.SPACING_2B} ${Spacings.SPACING_2B}`} value={item} key={index} status={'quote_per_kg'} />
			))}
		</CategoryWapper>
	)
}

export default CommodityCategoryList
