import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { strings } from '@constants'
import { getAllUsers } from '@data'
import { shareEnquiry } from '@data/conn/post/share'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { Devices, Spacings } from '@styles'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import ShareEnquiryForm from './ShareEnquiryForm'
import { getAllEmployees } from '@data/'
import { isClient } from '@utils/adminFilter'

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

const initialFormState = {
	associated_suppliers: {
		label: strings('associated_suppliers'),
		disabled: false,
		readOnly: false,
		displayKey: 'display_name',
		primaryKey: 'organisation_id',
		placeholder: strings(['select', 'associated_suppliers']),
		data: [],
		value: [],
		dirty: false,
	},
	non_associated_suppliers: {
		label: strings('non_associated_suppliers'),
		disabled: false,
		readOnly: false,
		displayKey: 'display_name',
		primaryKey: 'organisation_id',
		placeholder: strings(['select', 'non_associated_suppliers']),
		data: [],
		value: [],
		dirty: false,
	},
	buyer_message: {
		label: strings('msg_to_buyer'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	associated_supplier_message: {
		label: strings('msg_to_associated_suppliers'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	non_associated_supplier_message: {
		label: strings('msg_to_non_associated_suppliers'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	admins: {
		label: strings('admins'),
		disabled: false,
		readOnly: false,
		displayKey: 'display_name',
		primaryKey: 'user_id',
		placeholder: strings(['select', 'admins']),
		data: [],
		value: [],
		dirty: false,
	},
	admin_message: {
		label: strings('admins_message'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.associated_suppliers.value = data?.associated_suppliers ?? []
	derivedState.associated_suppliers.disabled = !editable
	derivedState.associated_suppliers.readOnly = !editable

	derivedState.non_associated_suppliers.value = data?.non_associated_suppliers ?? []
	derivedState.non_associated_suppliers.disabled = !editable
	derivedState.non_associated_suppliers.readOnly = !editable

	derivedState.admins.value = data?.non_associated_suppliers ?? []
	derivedState.admins.disabled = !editable
	derivedState.admins.readOnly = !editable

	derivedState.buyer_message.value = data?.buyer_message ?? ''
	derivedState.buyer_message.disabled = !editable
	derivedState.buyer_message.readOnly = !editable

	derivedState.associated_supplier_message.value = data?.associated_supplier_message ?? ''
	derivedState.associated_supplier_message.disabled = !editable
	derivedState.associated_supplier_message.readOnly = !editable

	derivedState.non_associated_supplier_message.value = data?.non_associated_supplier_message ?? ''
	derivedState.non_associated_supplier_message.disabled = !editable
	derivedState.non_associated_supplier_message.readOnly = !editable

	derivedState.admin_message.value = data?.admin_message ?? ''
	derivedState.admin_message.disabled = !editable
	derivedState.admin_message.readOnly = !editable

	return derivedState
}

const ShareEnquiry = ({ enquiryId, editable = true, enquiryItems, associatedSuppliersDetails, buyerDetails }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(initialFormState)
	const [nonAssociatedSuppliers, setNonAssociatedSuppliers] = useState()
	const [associatedSuppliers, setAssociatedSuppliers] = useState()
	const [_admins, setAadmins] = useState()
	const [buyer, setBuyer] = useState()
	const [_distance, setDistance] = useState()

	const userDispalyUser = (data) => {
		return data?.map((item) => {
			item['display_name'] = `${(item.full_name || '') + ' '}(${item.mobile_number})`
			return item
		})
	}

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, enquiryItems, !!editable))
		getAllEmployees().then((data) => {
			const usersDetails = data.map((item) => item)
			let getAdmin = usersDetails.filter(({ role }) => !isClient(role))
			getAdmin = userDispalyUser(getAdmin)
			setAadmins(getAdmin)
		})
		getAllClint()
	}, [editable])

	const getAllClint = (distance = null) => {
		dispatch(showLoader(true))
		getAllUsers(distance ? { distance, enquiryId } : null)
			.then((data) => {
				const usersDetails = data.map((item) => item)
				let associatedids = new Set(associatedSuppliersDetails?.map(({ user_id }) => user_id))
				!distance && buyerhalder(usersDetails)

				if (_distance) {
					associatedUserFilter(usersDetails, associatedids, true)
					return
				}

				associatedUserFilter(usersDetails, associatedids)
				associatedUserFilter(usersDetails, associatedids, true)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	const buyerhalder = (usersDetails) => {
		let buyerUser = usersDetails.find((item) => buyerDetails?.user_id === item?.user_id)
		setBuyer(buyerUser)
	}

	const associatedUserFilter = (usersDetails, associatedids, nonAssociated = false) => {
		let associatedidsUser = usersDetails.filter(({ user_id }) => (nonAssociated ? !associatedids.has(user_id) : associatedids.has(user_id)))

		associatedidsUser = userDispalyUser(associatedidsUser)

		if (nonAssociated) {
			setNonAssociatedSuppliers(associatedidsUser)
			return
		}

		setAssociatedSuppliers(associatedidsUser)
	}

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const formStateToEnquiryItem = () => {
		return {
			buyer_message: formState.buyer_message.value.trim(),
			associated_suppliers: formState.associated_suppliers.value,
			associated_supplier_message: formState.associated_supplier_message.value.trim(),
			non_associated_suppliers: formState.non_associated_suppliers.value,
			non_associated_supplier_message: formState.non_associated_supplier_message.value.trim(),
			admins: formState.admins.value,
			admin_message: formState.admin_message.value.trim(),
		}
	}

	const checkValidation = () => {
		if (formState.associated_suppliers.value.length === 0 && formState.associated_supplier_message.value !== '') {
			return strings('pls_select_associate_supplier')
		} else if (formState.associated_suppliers.value.length !== 0 && formState.associated_supplier_message.value === '') {
			return strings('pls_enter_associate_supplier_msg')
		} else if (formState.non_associated_suppliers.value.length === 0 && formState.non_associated_supplier_message.value !== '') {
			return strings('pls_select_non_associate_supplier')
		} else if (formState.non_associated_suppliers.value.length !== 0 && formState.non_associated_supplier_message.value === '') {
			return strings('pls_enter_non_associate_supplier_msg')
		} else if (formState.admins.value.length !== 0 && formState.admin_message.value === '') {
			return strings('pls_enter_admin_msg')
		}
		return ''
	}

	const addNewItemHandler = () => {
		const newEnquiryItemData = formStateToEnquiryItem()
		if (newEnquiryItemData) {
			if (checkValidation() !== '') {
				dispatch(showToast(true, checkValidation(), { type: TOAST_TYPE.ERROR }))
				return
			} else {
				shareEnquiry(enquiryId, { buyer: buyer, ...newEnquiryItemData })
					.then((res) => {
						dispatch(showToast(true, strings('enquiry_shared_success'), { type: TOAST_TYPE.SUCCESS }))
						dispatch(toggleDrawer(false))
					})
					.catch((err) => {
						console.log(err)
					})
			}
		}
	}

	const disableButton =
		formState.buyer_message.value.trim() === '' &&
		formState.associated_suppliers.value.length === 0 &&
		formState.associated_supplier_message.value.trim() === '' &&
		formState.non_associated_suppliers.value.length === 0 &&
		formState.non_associated_supplier_message.value.trim() === '' &&
		formState.admins.value.length === 0 &&
		formState.admin_message.value.trim() === ''

	const renderButtons = () => {
		return (
			<ActionButton small type='primary' disabled={disableButton} onClick={addNewItemHandler}>
				{strings('share')}
			</ActionButton>
		)
	}
	return (
		<>
			<DrawerBodyWrapper>
				<ShareEnquiryForm
					state={formState}
					updateState={updateFormState}
					associatedSuppliers={associatedSuppliers}
					nonAssociatedSuppliers={nonAssociatedSuppliers}
					_Admins={_admins}
					setDistance={setDistance}
					distance={_distance}
					distanceHandler={getAllClint}
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default ShareEnquiry
