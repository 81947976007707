import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Select, TOAST_TYPE } from '@base'
import { MODAL_TYPES, strings } from '@constants'
import { deleteAdmin } from '@data'
import { showToast } from '@data/state/action'
import { toggleDrawer, toggleModal } from '@data/state/action/root'
import { Spacings } from '@styles'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const Header = styled.div`
	margin: ${Spacings.SPACING_2B} ${Spacings.SPACING_2B} 0 ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
`
const _deleteAdmin = { user_id: 'DELETE_ADMIN', full_name: 'Delete Admin', mobile_number: '' }

const DeleteAdmin = ({ label, user, getAllAdmins, refreshUpdate }) => {
	const _getAllAdmins = [_deleteAdmin, ...getAllAdmins]
	const [newAdmin, setNewAdmin] = useState({})
	const dispatch = useDispatch()

	const replaceAdmin = () => {
		deleteAdmin(newAdmin?.user_id === _deleteAdmin?.user_id ? null : newAdmin?.user_id, user?.user_id)
			.then(() => {
				dispatch(showToast(true, strings(['admin', 'deleted']), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, strings(['admin', 'not', 'deleted']), { type: TOAST_TYPE.ERROR }))
				console.log(err)
			})
			.finally(() => {
				dispatch(toggleDrawer(false))
				refreshUpdate()
			})
	}
	const replaceAdminHandler = () => {
		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings('msg_are_you_sure_want_to_delete_admin'),
				confirmAction: (actionType) => {
					actionType && replaceAdmin()
					dispatch(toggleModal(false))
				},
			})
		)
	}
	return (
		<>
			<DrawerBodyWrapper>
				<Header>{strings('delete_admin_info_text')}</Header>
				<Select
					// label={label}
					id='new-admin'
					data={_getAllAdmins}
					type='text'
					value={newAdmin}
					onChange={(value) => {
						setNewAdmin(value)
					}}
					placeholder={strings(['select', 'new', 'admin'])}
					displayKey='full_name'
					primaryKey='user_id'
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small type='primary' onClick={replaceAdminHandler} disabled={!newAdmin?.user_id}>
					{strings('delete', 'admin')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default DeleteAdmin
