// import API from '../api';
// import getUrl from './paths';
import { brands } from '@constants'

export const getBrands = () =>
	new Promise((resolve, reject) => {
		resolve(brands)

		// API.get(getUrl.getBrands).then(response => {
		//   if(response.error) {
		//     reject(response.error)
		//   } else {
		//     resolve(response.data);
		//   }
		//
		// }).catch(err => resolve(brands));
	})
