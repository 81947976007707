import { DrawerBodyWrapper, DrawerFooterWrapper, PageLevelTableWrapper, Table } from '@base'
import { strings } from '@constants'
import React from 'react'
import styled from 'styled-components'

const PageLevelWrapper = styled(PageLevelTableWrapper)`
	height: 100%;
`

const CrossDirectorList = ({ director }) => {
	const columns = [
		{
			label: strings(['cross', 'directorships']),
			key: (data) => {
				return data
			},
			mobile: true,
		},
	]
	return (
		<>
			<DrawerBodyWrapper>
				<PageLevelWrapper>
					<Table fullHeight data={director} columns={columns} loading={false} rowClickable={false} hideFooter />
				</PageLevelWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper></DrawerFooterWrapper>
		</>
	)
}

export default CrossDirectorList
