import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Description, CloudUpload } from '@material-ui/icons'
import { Button, Calendar, Checkbox, PageLevelTableWrapper, Select, Table, UploadButton } from '@base'
import { strings } from '@constants'
import { toggleFileViewer } from '@data/state/action/root'
import { FormElementLabel, InputWrapper, SectionItemWrapper, SectionItemWrapperWithTwoItems, SelectWrapper, ViewItemWrapper } from './styles'
import { Spacings } from '@styles'

const TableWrapper = styled(PageLevelTableWrapper)`
	padding-bottom: 0;
	height: 130px;
`
const FileIcon = styled(Description)`
	color: ${({ theme }) => theme.palette.background.blue};
	cursor: pointer;
`
const InputWrapperDiv = styled(InputWrapper)`
	margin-top: ${Spacings.SPACING_1B};
`
const UploadStatement = ({
	maxDate,
	bankDetails,
	reportDetails,
	onChange,
	uploadStatentHandler,
	bsaDownloadStatus,
	isActiveBSAReportHandler,
	addMonths,
	state,
}) => {
	const dispatch = useDispatch()
	const { isEStatement, statement, bankName, fromDate, toDate } = state
	const bankDocuments = reportDetails?.bsa_report?.bank_documents || []
	const uniqueDataList = [...new Map(bankDocuments?.map((item) => [item['institution_id'], item])).values()]
	const getDate = (date) => (+date === 0 ? new Date().getTime() : +date)

	const changeHandler = (statusObject) => {
		onChange('bankName')(statusObject.institution_id)
	}

	const uploadTCHandler = (file) => {
		onChange('statement')(file)
	}

	const dateChangeHandler = (key) => (e) => {
		onChange('fromDate')(addMonths(new Date(e))?._fromDate)
		onChange('toDate')(addMonths(new Date(e), +5)?._toDate)
	}

	const onCheckBoxChange = (e) => onChange('isEStatement')(e.target.checked)

	const columns = [
		{
			label: 'Bank Name',
			key: (_item) => {
				const _bankName = bankDetails.find((item) => item?.institution_id === _item?.institution_id)
				return <>{_bankName?.name}</>
			},
			mobile: true,
		},
		{
			label: 'Statment',
			key: (_item) => {
				const bankStatement = bankDocuments.filter((item) => item?.institution_id === _item?.institution_id) || []
				return (
					<div>
						{bankStatement?.map((_item, index) => {
							return (
								<span key={index}>
									<FileIcon
										onClick={() => {
											dispatch(toggleFileViewer(true, { files: _item?.pathname }))
										}}
									/>
								</span>
							)
						})}
					</div>
				)
			},
			mobile: true,
		},
	]

	return (
		<>
			<ViewItemWrapper>
				<InputWrapperDiv>
					<Calendar
						label={fromDate.label}
						disabled={false}
						id={fromDate.id}
						value={getDate(fromDate.value)}
						onChange={dateChangeHandler('fromDate')}
						showMonthYearPicker
						maxDate={maxDate}
					/>
					<Calendar
						label={toDate.label}
						disabled={true}
						id={toDate.id}
						value={getDate(toDate.value)}
						onChange={dateChangeHandler('toDate')}
						showMonthYearPicker
					/>
					<SectionItemWrapperWithTwoItems>
						<Checkbox
							id='Checkbox'
							disabled={isEStatement?.disabled}
							size={1.125}
							checked={isEStatement?.value}
							value={isEStatement?.value}
							onChange={onCheckBoxChange}
						/>
						<FormElementLabel disabled={isEStatement?.disabled} htmlFor='Checkbox'>
							{isEStatement?.label}
						</FormElementLabel>
					</SectionItemWrapperWithTwoItems>
				</InputWrapperDiv>
				<SectionItemWrapper>
					<TableWrapper>
						<Table fullHeight data={uniqueDataList} columns={columns} loading={false} rowClickable={false} hideFooter noScroll />
					</TableWrapper>
				</SectionItemWrapper>
				{bsaDownloadStatus && (
					<>
						<SectionItemWrapper isCustomer>
							<UploadButton accept={'image'} xs uppercase onChange={uploadTCHandler} icon={<CloudUpload />} />
							<SelectWrapper>
								<Select
									label={bankName?.label}
									data={bankDetails}
									displayKey='name'
									primaryKey='institution_id'
									value={bankDetails?.find((_s) => _s.institution_id === bankName.value) ?? ''}
									onChange={changeHandler}
								/>
							</SelectWrapper>
						</SectionItemWrapper>

						<SectionItemWrapper isCustomer>
							<Button
								xs
								disabled={!statement?.value}
								type='secondary'
								variant='destructive'
								margin='2px'
								onClick={() => uploadStatentHandler()}
							>
								{strings('upload')}
							</Button>
							{bsaDownloadStatus && (
								<Button disabled={uniqueDataList?.length === 0} xs type='primary' onClick={() => isActiveBSAReportHandler()}>
									Generate BSA Report
								</Button>
							)}
						</SectionItemWrapper>
					</>
				)}
			</ViewItemWrapper>
		</>
	)
}

export default UploadStatement
