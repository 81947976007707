import { CONTENT_TYPE, strings } from '@constants'
import { useHeaderMeta } from '@hooks'
import { adminNews } from '@utils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	ActionButton,
	DuoWrapper,
	ErrorMessage,
	FooterWrapper,
	Label,
	PsuedoFullHeightWrapper,
	RadioButtonInputWrapper,
	RadioButtonWrapper,
	RadioOptionsWrapper,
	Section,
	Wrapper,
} from '../styles'
import { Calendar, Input, RadioButton, Select, TOAST_TYPE, Textarea } from '@base'
import { showLoader, showToast } from '@data/state/action'
import { createContent, editContent, getAllCommodityApi } from '@data'
import { toggleDrawer } from '@data/state/action/root'

const regionData = [{ region_name: 'Domestic' }, { region_name: 'Global' }]

const deriveStateFromData = (data) => {
	const _state = {
		title: {
			label: strings('title'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings(['enter', 'title']),
			dirty: false,
		},
		commodity: {
			label: strings('select', 'commodity'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings(['select', 'commodity']),
			dirty: false,
		},
		region: {
			label: strings('select', 'region'),
			value: { region_name: 'Domestic' },
			disabled: false,
			required: false,
			placeholder: strings(['select', 'region']),
			dirty: false,
		},
		summary: {
			label: strings('summary'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings(['enter', 'summary']),
			dirty: false,
			valid: false,
		},
		image_path: {
			label: strings('image', 'path'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings(['enter', 'image', 'path']),
			dirty: false,
		},
		source: {
			label: strings('source'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings(['enter', 'source']),
			dirty: false,
		},
		source_link: {
			label: strings('source', 'link'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings(['enter', 'source', 'link']),
			dirty: false,
		},
		date: {
			label: strings('date'),
			value: new Date(),
			disabled: false,
			required: true,
			placeholder: strings(['select', 'date']),
			dirty: false,
		},
		type: {
			label: strings('type'),
			value: CONTENT_TYPE.NEWS,
			dirty: false,
		},
	}
	if (data) {
		_state.title.value = data?.title
		_state.title.dirty = true

		_state.image_path.value = data?.image_path
		_state.image_path.dirty = true

		_state.source.value = data?.source
		_state.source.dirty = true

		_state.source_link.value = data?.source_link
		_state.source_link.dirty = true

		_state.date.value = data?.date
		_state.date.dirty = true

		_state.summary.value = data?.summary
		_state.summary.dirty = true

		_state.commodity.value = data?.commodity || {}
		_state.commodity.dirty = true

		_state.region.value = regionData.find((item) => item?.region_name === data?.region) || {}
		_state.region.dirty = true

		_state.type.value = data?.type
		_state.type.dirty = true
	}
	return _state
}

const CreateNews = ({ newsData, update }) => {
	useHeaderMeta(adminNews)
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())
	const [getCommodity, setGetCommodity] = useState()

	useEffect(() => {
		getAllCommodityApi().then((res) => {
			setGetCommodity(res)
			!newsData && updateState('commodity', 'value')(res?.[0])
		})
	}, [])

	useEffect(() => {
		setFormState((_) => deriveStateFromData(newsData))
	}, [newsData])

	const updateState = (inputType, key) => (value) => {
		setFormState((state) => ({
			...state,
			[inputType]: {
				...state[inputType],
				[key]: value,
			},
		}))
	}

	const submitHandler = () => {
		dispatch(showLoader(true))
		const apiCall = newsData
			? editContent(
					{
						...newsData,
						content_id: newsData?.content_id,
						title: formState?.title?.value,
						summary: formState?.summary?.value,
						image_path: formState?.image_path?.value,
						source: formState?.source?.value,
						source_link: formState?.source_link?.value,
						date: new Date(formState?.date?.value).getTime(),
						commodity: formState?.commodity?.value,
						region: formState?.region?.value?.region_name,
					},
					formState?.type?.value
			  )
			: createContent(
					{
						title: formState?.title?.value,
						summary: formState?.summary?.value,
						image_path: formState?.image_path?.value,
						source: formState?.source?.value,
						source_link: formState?.source_link?.value,
						date: new Date(formState?.date?.value).getTime(),
						commodity: formState?.commodity?.value,
						region: formState?.region?.value?.region_name,
					},
					formState?.type?.value
			  )

		apiCall
			.then((res) => {
				dispatch(showToast(true, newsData ? strings('success_updated_news') : strings('success_created_news'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('msg_failed_to_update_news'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const checkDisabled = !formState?.source?.value || !formState?.summary?.value || !formState?.title?.value

	const renderButtons = () => {
		return (
			<>
				<ActionButton small disabled={checkDisabled} type='primary' onClick={submitHandler}>
					{newsData ? strings('update') : strings('add')}
				</ActionButton>
			</>
		)
	}

	return (
		<Wrapper>
			<PsuedoFullHeightWrapper>
				<DuoWrapper>
					<Section fullWidth>
						<Input
							type='text'
							label={formState?.title?.label}
							disabled={formState?.title.disabled}
							value={formState?.title?.value}
							placeholder={formState?.title?.placeholder}
							setValidity={updateState('title', 'valid')}
							onChange={updateState('title', 'value')}
							required={formState?.title?.required}
						/>
						<Input
							type='text'
							label={formState?.source?.label}
							disabled={formState?.source.disabled}
							value={formState?.source?.value}
							placeholder={formState?.source?.placeholder}
							setValidity={updateState('source', 'valid')}
							onChange={updateState('source', 'value')}
							required={formState?.source?.required}
						/>
						<RadioButtonInputWrapper>
							<div>Type:</div>
							<RadioOptionsWrapper>
								<RadioButtonWrapper>
									<RadioButton
										id='content-type-radio'
										size='1.125'
										checked={formState?.type?.value === CONTENT_TYPE.NEWS}
										value={CONTENT_TYPE.NEWS}
										onChange={() => updateState('type', 'value')(CONTENT_TYPE.NEWS)}
									/>
									<Label htmlFor='ability-radio'>News </Label>
								</RadioButtonWrapper>
								<RadioButtonWrapper>
									<RadioButton
										id='content-type-radio'
										size='1.125'
										checked={formState?.type?.value === CONTENT_TYPE.MEDIA_COVERAGE}
										value={CONTENT_TYPE.MEDIA_COVERAGE}
										onChange={() => updateState('type', 'value')(CONTENT_TYPE.MEDIA_COVERAGE)}
									/>
									<Label htmlFor='ability-radio'>Media Coverage </Label>
								</RadioButtonWrapper>
							</RadioOptionsWrapper>
						</RadioButtonInputWrapper>
						<Input
							type='url'
							id='image-path'
							label={formState?.image_path?.label}
							disabled={formState?.image_path.disabled}
							value={formState?.image_path?.value}
							placeholder={formState?.image_path?.placeholder}
							required={formState?.image_path?.required}
							onChange={updateState('image_path', 'value')}
							setValidity={updateState('image_path', 'valid')}
							pattern='https?://.*'
							errorMap={{
								patternMismatch: strings('enter_valid_url_link'),
							}}
						/>
						<Input
							type='url'
							id='source-path'
							label={formState?.source_link?.label}
							disabled={formState?.source_link.disabled}
							value={formState?.source_link?.value}
							placeholder={formState?.source_link?.placeholder}
							required={formState?.source_link?.required}
							onChange={updateState('source_link', 'value')}
							setValidity={updateState('source_link', 'valid')}
							pattern='https?://.*'
							errorMap={{
								patternMismatch: strings('enter_valid_url_link'),
							}}
						/>
						<Calendar
							id={'date-id'}
							label={formState.date.label}
							disabled={formState?.date.disabled}
							value={formState?.date.value}
							onChange={updateState('date', 'value')}
						/>
						<Select
							label={formState?.commodity?.label}
							data={getCommodity}
							displayKey='commodity_name'
							primaryKey='commodity_name'
							value={formState?.commodity?.value}
							onChange={updateState('commodity', 'value')}
						/>
						<Select
							label={formState?.region?.label}
							data={regionData}
							displayKey='region_name'
							primaryKey='region_name'
							value={formState?.region?.value}
							onChange={updateState('region', 'value')}
						/>
						<Textarea
							id='quote-summary'
							label={strings('summary')}
							type='text'
							rows={6}
							placeholder={strings(['enter', 'summary'])}
							value={formState?.summary.value}
							onChange={updateState('summary', 'value')}
							maxlength={10}
						/>
						<ErrorMessage>
							{`${strings('word', 'count')} : ${formState?.summary?.value?.trim().split(/\s+/).length || 0} / 60`}
						</ErrorMessage>
					</Section>
				</DuoWrapper>
			</PsuedoFullHeightWrapper>
			<FooterWrapper>{renderButtons()}</FooterWrapper>
		</Wrapper>
	)
}

export default CreateNews
