import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Select, Input } from '@base'
import { Spacings } from '@styles'
import { roles, strings } from '@constants'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: flex-end;

	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`

const OrderItemForm = React.memo(({ orderItems, state, updateState, defaultBrand, defualtCategoryId, checkTmtScrap }) => {
	const { category, product, weight, brands } = state
	const [tmtProduct, setTmtProduct] = useState()
	const user = useSelector((state) => state.getIn(['user', 'user']))

	useEffect(() => {
		if (!product?.tmtProduct) {
			const updateTmtValue = product?.data
				.map((item) => {
					const _product = orderItems?.find((item2) => item.product_id === item2.product_id)
					if (_product) {
						return null
					}

					item.weight = ''
					return item
				})
				.filter(Boolean)
			setTmtProduct(updateTmtValue)
			if (product?.value?.product_id?.includes('scrap')) {
				updateState('brands', { value: defaultBrand, disabled: true, dirty: true })
			}
		}
	}, [product])

	useEffect(() => {
		if (user?.role === roles.manufacturer) {
			updateState('category', { value: category.data.find((_c) => _c.category_id === 'scrap'), dirty: true, disabled: true })
			updateState('brands', { value: defaultBrand, disabled: true, dirty: true })
		} else if (defualtCategoryId?.includes('scrap')) {
			updateState('brands', { value: defaultBrand, disabled: true, dirty: true })
		}
	}, [user, category.data, defualtCategoryId])

	const onChange = (key) => (value) => {
		if (key === 'weight') {
			value = value === '' ? null : parseFloat(value) * 1000
		}

		if (key === 'category') {
			if (value?.category_id.includes('scrap')) {
				updateState('brands', { value: defaultBrand, disabled: true, dirty: true })
			} else {
				updateState('brands', { value: '', disabled: false, dirty: true })
			}
		}

		updateState(key, { value, dirty: true })
	}

	const tmtProductHandler = (tmtProd, index) => {
		tmtProduct[index] = tmtProd
		updateState('product', { tmtProduct, dirty: true })
	}

	const selectedBrandUi = () => {
		return (
			<SectionItemWrapper>
				<Select
					id='order-item-brands-select'
					disabled={brands?.disabled}
					label={brands?.label}
					displayKey={brands?.displayKey}
					primaryKey={brands?.primaryKey}
					placeholder={brands?.placeholder}
					data={brands?.data}
					value={brands?.value}
					onChange={onChange('brands')}
				/>
			</SectionItemWrapper>
		)
	}

	const simpleProductUi = () => {
		return (
			<>
				<SectionItemWrapper>
					<Select
						id='order-item-product-select'
						disabled={product?.disabled}
						label={product?.label}
						displayKey={product?.displayKey}
						primaryKey={product?.primaryKey}
						placeholder={product?.placeholder}
						data={product?.data}
						value={product?.value}
						onChange={onChange('product')}
						createNewOption={true}
					/>
				</SectionItemWrapper>
				{selectedBrandUi()}
				<SectionItemWrapper>
					<Input
						type='number'
						disabled={weight?.disabled}
						label={weight?.label}
						value={weight?.value ? weight.value / 1000 : weight?.value}
						onChange={onChange('weight')}
					/>
				</SectionItemWrapper>
			</>
		)
	}

	const tmtProductUi = () => {
		return (
			<>
				{selectedBrandUi()}
				{tmtProduct &&
					tmtProduct.map((item, index) => {
						return (
							<SectionItemWrapper key={index}>
								<SectionItemWrapperWithTwoItems>
									<Input type='text' disabled={true} label={strings('product', 'name')} value={item?.product_name} />
									<Input
										type='number'
										disabled={item?.disabled}
										label={weight?.label}
										value={item?.weight ? item?.weight / 1000 : item?.weight}
										onChange={(value) => {
											tmtProductHandler({ ...item, weight: value === '' ? null : parseFloat(value) * 1000 }, index)
										}}
									/>
								</SectionItemWrapperWithTwoItems>
							</SectionItemWrapper>
						)
					})}
			</>
		)
	}

	const productNameUi = () => {
		if (!checkTmtScrap.find((item) => item === category?.value?.category_id)) {
			return simpleProductUi()
		}
		return tmtProductUi()
	}

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Select
					id='order-item-category-select'
					disabled={category?.disabled}
					label={category?.label}
					displayKey={category?.displayKey}
					primaryKey={category?.primaryKey}
					placeholder={category?.placeholder}
					data={category?.data}
					value={category?.value}
					onChange={onChange('category')}
				/>
			</SectionItemWrapper>
			{productNameUi()}
		</FormWrapper>
	)
})

export default OrderItemForm
