class OrderProductItem {
	constructor(product_id, allotted_weight, product_name, category_name) {
		if (typeof product_id === 'object') {
			Object.assign(this, product_id)
			this.allotted_weight = product_id.ordered_weight
			this.price_per_kg = null
			this.tc_available = this.tc_required
			this.serviced_weight = null
			return
		}

		this.allotted_weight = allotted_weight ? allotted_weight : null
		this.serviced_weight = null
		this.brand_ordered = 'ANY'
		this.product_id = product_id ? product_id : null
		this.product_name = product_name ? product_name : null
		this.category_name = category_name ? category_name : null
		this.logistics_price = null
		this.minimum_guaranteed_weight = null
		this.minimum_order_quantity = null
		this.price_per_kg = null
		this.quote_type = 'FOR'

		this.tc_available = true
	}
}

export default OrderProductItem
