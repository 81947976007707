import { Record } from 'immutable'
import Address from './Address'

const OrgGstDetailRecord = Record({
	address: new Address(),
	gst: '',
	photos: [],
	poc_name: '',
	poc_phone: '',
	primary: false,
	verified: true,
})

class OrgGstDetail extends OrgGstDetailRecord {
	update(updates) {
		return this.merge(updates)
	}
}

export default OrgGstDetail
