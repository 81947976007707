import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { H5, H6 } from '@base'

const BaseWrapper = styled.div`
	padding: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
	}
`

export const Wrapper = styled(BaseWrapper)`
	display: flex;
	margin-bottom: ${Spacings.SPACING_4B};
	flex-grow: 1;
	@media ${Devices.tablet} {
		margin-right: ${({ isRight }) => (isRight ? `${Spacings.SPACING_4B}` : 0)};
	}
`
export const LedgersSection = styled.div`
	display: flex;
	flex-grow: 1;
	border-right: ${({ theme, rightBorder }) => (rightBorder ? `2px solid ${theme.palette.text.grey}` : 0)};
	align-items: ${({ flexStart }) => (flexStart ? 'flex-start' : 'flex-end')};
	padding: ${Spacings.SPACING_3B};
	flex-direction: ${({ isFlexColumn }) => (isFlexColumn ? 'column' : 'row')};
`

export const LedgersText = styled(H6)`
	color: ${({ theme, isvalue }) => (isvalue ? theme.palette.text.darkblack : theme.palette.text.greydark)};
	font-weight: ${Text.BOLD_600};
	padding: ${({ ispadding }) => (ispadding ? '5px 0' : '0')};
	margin: ${Spacings.SPACING_1B} 0;
`
export const LedgersValue = styled(H5)`
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-weight: ${Text.BOLD_600};
	padding: ${({ ispadding }) => (ispadding ? '5px 0' : '0')};
	margin: ${Spacings.SPACING_1B} 0;
`
export const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const ResponsiveStepWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex-direction: row;
		width: 32%;
	}
`
export const SearchWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-left: ${Spacings.SPACING_4B};
	margin-left: auto;
	flex-wrap: nowrap;
	${({ isRight }) => {
		if (isRight) {
			return css`
				justify-content: flex-end;
			`
		}
	}}
`
