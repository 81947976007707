import API from '../api'
import postUrl from './paths'

export const createOrder = (enquiryId, userId, orderDate = new Date().getTime()) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.createOrder, null, { enquiryId, userId, orderDate })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addSupplierToOrder = (orderId, supplierQuote, newAddressAdded = false) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addSupplierToOrder, supplierQuote, { orderId, newAddressAdded })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addBuyerToOrder = (orderId, buyerQuote, newAddressAdded = false) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addBuyerToOrder, buyerQuote, { orderId, newAddressAdded })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addTrip = (orderId, invoiceId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addTrip, {}, { orderId, invoiceId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const closeTrip = (orderId, buyerInvoiceId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.closeTrip, {}, { orderId, buyerInvoiceId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
