import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showLoader, showModal, showToast } from '@data/state/action'
import { createLedger } from '@data'
import { Ledger as LedgerModel, User as UserModel } from '@data/model'
import { strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import {
	Footer,
	ActionButton,
	FormWrapper,
	SectionItemWrapper,
	FormHeading,
	InputWapper,
	DivInput,
	RadioButtonWrapper,
	Label,
	RadioInputWapper,
} from './style'
import { DrawerBodyWrapper, Input, RadioButton, SectionHeader, Select, Calendar, TOAST_TYPE } from '@base'

const TRANSACTION_TYPE = {
	DEBIT: 'DEBIT',
	CREDIT: 'CREDIT',
}

export const TRANSACTION_SOURCE = {
	DEPOSIT: 'DEPOSIT',
	CREDIT_NOTE: 'CREDIT_NOTE',
	TDS: 'TDS',
}

const AddTransaction = ({ users, update }) => {
	const dispatch = useDispatch()
	const [ledger, setLedger] = useState(new LedgerModel())
	const [selectedUser, setSelectedUser] = useState()

	useEffect(
		() =>
			users.map((item) => {
				item['display_name'] = `${(item.organisation_name || item.full_name || '') + ' '}(${item.mobile_number})`
				return item
			}),
		[users]
	)

	const onValueChange = (value, key) => {
		const tempLedger = Object.assign({}, ledger)
		tempLedger[key] = value
		setLedger(tempLedger)
	}

	const onClickSave = () => {
		if (ledger.transaction_source === TRANSACTION_SOURCE.DEPOSIT || ledger.transaction_source === 'CREDIT') {
			ledger.transaction_type = null
		}

		ledger.user_details = new UserModel(selectedUser).getUserDetails()
		dispatch(showLoader(true))
		createLedger(ledger)
			.then(() => {
				update()
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, strings('msg_transaction_added'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(showModal(false))
			})
			.catch((err) => console.log(err))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	let disableButton = ledger.transaction_amount === 0 || ledger.transaction_amount === '' || !selectedUser

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<SectionItemWrapper>
						<Calendar
							required={true}
							mindate={new Date(0)}
							label={strings('transaction', 'date')}
							value={ledger.transaction_date}
							onChange={(val) => onValueChange(val, 'transaction_date')}
						/>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<FormHeading>{strings('transaction', 'source')}</FormHeading>
						<RadioInputWapper>
							<RadioButtonWrapper>
								<RadioButton
									id='deposit-radio'
									size='1.125'
									checked={ledger.transaction_source === TRANSACTION_SOURCE.DEPOSIT}
									value={TRANSACTION_SOURCE.DEPOSIT}
									onChange={(e) => onValueChange(e.target.value, 'transaction_source')}
								/>
								<Label htmlFor='deposit-radio'>{strings('deposit')}</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<RadioButton
									id='credit-note-radio'
									size='1.125'
									checked={ledger.transaction_source === TRANSACTION_SOURCE.CREDIT_NOTE}
									value={TRANSACTION_SOURCE.CREDIT_NOTE}
									onChange={(e) => onValueChange(e.target.value, 'transaction_source')}
								/>
								<Label htmlFor='credit-note-radio'>{strings('credit', 'note')}</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<RadioButton
									id='tds-radio'
									size='1.125'
									checked={ledger.transaction_source === TRANSACTION_SOURCE.TDS}
									value={TRANSACTION_SOURCE.TDS}
									onChange={(e) => onValueChange(e.target.value, 'transaction_source')}
								/>
								<Label htmlFor='tds-radio'>{strings('tds')}</Label>
							</RadioButtonWrapper>
						</RadioInputWapper>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<FormHeading>{strings('transaction_type')}</FormHeading>
						{/* {(ledger.transaction_source === TRANSACTION_SOURCE.DEPOSIT ||
							ledger.transaction_source === TRANSACTION_SOURCE.CREDIT_NOTE) && ( */}
						<RadioInputWapper>
							<RadioButtonWrapper>
								<RadioButton
									id='debit-radio'
									size='1.125'
									checked={ledger.money_transaction_type === TRANSACTION_TYPE.DEBIT}
									value={TRANSACTION_TYPE.DEBIT}
									onChange={(e) => onValueChange(e.target.value, 'money_transaction_type')}
								/>
								<Label htmlFor='debit-radio'>{strings(['debit', '(', 'paid by MB', ')'])}</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<RadioButton
									id='credit-radio'
									size='1.125'
									checked={ledger.money_transaction_type === TRANSACTION_TYPE.CREDIT}
									value={TRANSACTION_TYPE.CREDIT}
									onChange={(e) => onValueChange(e.target.value, 'money_transaction_type')}
								/>
								<Label htmlFor='credit-radio'>{strings(['credit', '(', 'received by MB', ')'])}</Label>
							</RadioButtonWrapper>
						</RadioInputWapper>
						{/* )} */}
					</SectionItemWrapper>
					<SectionItemWrapper>
						<Select
							id='stateOrder-status-select'
							placeholder={strings(['select', 'user'])}
							data={users}
							displayKey='display_name'
							primaryKey='organisation_id'
							value={selectedUser ?? {}}
							onChange={(user) => {
								setSelectedUser(users.find((_u) => _u.organisation_id === user.organisation_id))
							}}
							label={strings(['select', 'user'])}
						/>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<InputWapper>
							<DivInput>
								<Input
									label={strings('transaction', 'no')}
									type='text'
									placeholder={strings('transaction', 'no')}
									value={ledger.original_document_number}
									onChange={(val) => onValueChange(val, 'original_document_number')}
								/>
							</DivInput>
							<DivInput>
								<Input
									label={strings('transaction', 'remarks')}
									type='text'
									placeholder={strings('transaction', 'remarks')}
									value={ledger.transaction_remarks}
									onChange={(val) => onValueChange(val, 'transaction_remarks')}
								/>
							</DivInput>
							<DivInput>
								<Input
									label={strings('transaction', 'amount')}
									type='number'
									placeholder={strings('transaction', 'amount')}
									value={ledger.transaction_amount}
									onChange={(val) => onValueChange(val, 'transaction_amount')}
								/>
							</DivInput>
						</InputWapper>
					</SectionItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<Footer>
				<ActionButton small type='primary' disabled={disableButton} onClick={onClickSave}>
					{strings('save')}
				</ActionButton>
			</Footer>
		</>
	)
}

export default AddTransaction
