import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Input, Pill } from '@base'
import { InfoWrapper, ChoiceText } from './styles'
import { getUserByID } from '@data'
import { strings } from '@constants'
import { Spacings } from '@styles'
import { isClient } from 'src/utils/adminFilter'

const deriveFormStateFromProps = (defaultValue) => ({
	company_name: {
		default: defaultValue?.company_name,
		value: defaultValue?.company_name ?? '',
		valid: false,
	},
	gst_number: {
		default: defaultValue?.gst_number,
		value: defaultValue?.gst_number ?? '',
		valid: false,
		optional: true,
	},
	pan_number: {
		default: defaultValue?.pan_number,
		value: defaultValue?.pan_number ?? '',
		valid: false,
		optional: true,
	},
	// point_of_contact_name: {
	// 	default: defaultValue?.point_of_contact_name ?? '',
	// 	value: defaultValue?.point_of_contact_name ?? '',
	// 	valid: false,
	// 	optional: true,
	// },
	// point_of_contact_number: {
	// 	default: defaultValue?.point_of_contact_number ?? '',
	// 	value: defaultValue?.point_of_contact_number ?? '',
	// 	valid: false,
	// 	optional: true,
	// },
})

const OrgDetailsComponent = ({ update, editable, externalUser, isAdmin, requestRoleType }) => {
	const userId = useParams()?.userId
	const [userData, setUserData] = useState({})
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const userUpdateInProgress = useSelector((state) => state.getIn(['user', 'userUpdateInProgress']))
	const [formState, setFormState] = useState({})

	useEffect(() => {
		if (userId) {
			getUserByID(userId).then((response) => {
				setUserData(response)
			})
		} else {
			setUserData(user)
		}
	}, [userId, user, userUpdateInProgress])

	useEffect(() => {
		if (externalUser) {
			setFormState(deriveFormStateFromProps(externalUser?.organisation ?? {}))
			return
		}

		setFormState(deriveFormStateFromProps(userData?.organisation ?? {}))
	}, [userData, externalUser])

	const updateState = (inputType, key) => (value) => {
		setFormState((state) => ({
			...state,
			[inputType]: {
				...state[inputType],
				[key]: value,
			},
		}))
	}

	useEffect(() => {
		if (update) {
			const values = Object.values(formState)
			const disableSaveButton =
				!!values.find((i) => !i.optional && !i.valid) ||
				!!values.find((i) => i.optional && !(i.valid || i.value === '')) ||
				values.every((i) => i.default === i.value)

			update({
				data: {
					organisation: {
						company_name: formState.company_name?.value.trim(),
						gst_number: formState.gst_number?.value.trim(),
						pan_number: formState.pan_number?.value.trim(),
						point_of_contact_name: formState.point_of_contact_name?.value.trim(),
						point_of_contact_number: formState.point_of_contact_number?.value.trim(),
					},
				},
				disableSaveButton,
			})
		}
	}, [formState, update])

	const preferCategory = () => {
		return (
			<InfoWrapper isCategory>
				{userData?.commodity?.[0]?.category_name?.map((item) => {
					return <Pill value={item} margin={`0 ${Spacings.SPACING_1B} ${Spacings.SPACING_1B} 0 `} />
				})}
			</InfoWrapper>
		)
	}

	const gsitHandler = (val) => {
		updateState('gst_number', 'value')(val)
		if (formState.gst_number?.value.length <= 12) {
			updateState('pan_number', 'value')(formState.gst_number?.value.substring(2))
		} 
	}

	return (
		<>
			<InfoWrapper>
				<Input
					label={strings('organisation', 'name')}
					id='org-name'
					type='text'
					disabled={!editable}
					value={formState?.company_name?.value}
					onChange={updateState('company_name', 'value')}
					setValidity={updateState('company_name', 'valid')}
					placeholder={strings('your_organisation_name')}
					errorMap={{
						valueMissing: strings('org_name_required'),
					}}
					required
				/>
			</InfoWrapper>
			{editable && <ChoiceText>{strings('provide_one_below_details')}</ChoiceText>}
			<InfoWrapper>
				<Input
					label={strings('organisation', 'gstin')}
					id='org-gstin'
					type='text'
					pattern='\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}'
					errorMap={{
						patternMismatch: strings('enter_digit_gstin'),
						valueMissing: strings('gstin_required'),
					}}
					disabled={!editable}
					value={formState?.gst_number?.value}
					onChange={(e) => gsitHandler(e)}
					setValidity={updateState('gst_number', 'valid')}
					placeholder={strings('digit_gstin')}
					uppercase
				/>
			</InfoWrapper>
			<InfoWrapper>
				<Input
					label={strings('organisation', 'pan')}
					id='org-pan'
					type='text'
					disabled={!editable}
					pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}'
					value={formState?.pan_number?.value}
					onChange={updateState('pan_number', 'value')}
					setValidity={updateState('pan_number', 'valid')}
					placeholder={strings('your_org_pan')}
					errorMap={{
						patternMismatch: strings('enter_valid_pan'),
						valueMissing: strings('pan_required'),
					}}
					uppercase
				/>
			</InfoWrapper>

			{!isClient(user.role) && preferCategory()}
			{/* {requestRoleType === becomeRequestRole.logistics_Request && (
				<>
					<InfoWrapper>
						<Input
							label={strings('point_contact_name')}
							id='contact-name'
							type='text'
							disabled={!editable}
							value={formState?.point_of_contact_name?.value}
							onChange={updateState('point_of_contact_name', 'value')}
							setValidity={updateState('point_of_contact_name', 'valid')}
							placeholder={strings('placeholder_point_contact_name')}
							errorMap={{
								valueMissing: strings('point_contact_required'),
							}}
						/>
					</InfoWrapper>
					<InfoWrapper>
						<Input
							label={strings('point_contact_mobile_num')}
							id='contact-mobile-number'
							type='text'
							pattern='[789][0-9]{9}'
							disabled={!editable}
							value={formState?.point_of_contact_number?.value}
							onChange={updateState('point_of_contact_number', 'value')}
							setValidity={updateState('point_of_contact_number', 'valid')}
							placeholder={strings('ponit_contact_num')}
							errorMap={{
								patternMismatch: `${strings('enter_valid_contact_num')}`,
								valueMissing: `${strings('contact_num_required')}`,
							}}
						/>
					</InfoWrapper>
				</>
			)} */}
		</>
	)
}

export default OrgDetailsComponent
