import { useReducer } from 'react'

const useReducerWithThunk = (reducer, initialState) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const getState = () => state

	const customDispatch = (action) => {
		if (typeof action === 'function') {
			return action(customDispatch, getState)
		} else {
			dispatch(action)
		}
	}

	return [state, customDispatch]
}

export default useReducerWithThunk
