import styled, { css } from 'styled-components'
import { Spacings, Devices } from '@styles'
import { DrawerFooterWrapper, Button } from '@base'

export const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

export const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	margin-top: ${Spacings.SPACING_2B};
	${({ isStatement, isFullHeight, isFooter }) => {
		if (isFullHeight) {
			return css`
				height: 54vh;
			`
		}
		if (isStatement) {
			return css`
				display: flex;
				justify-content: space-between;
			`
		}
		if (isFooter) {
			return css`
				display: flex;
				justify-content: flex-end;
			`
		}
	}}

	& > div {
		width: 100%;
	}
`

export const ActionButtonWapper = styled.div`
	display: flex;
	justify-content: flex-end;
`
export const ButtonIconWapper = styled.div`
	display: flex;
`

export const ButtonIcon = styled(Button)`
	min-width: auto;
	padding: 0px ${Spacings.SPACING_1B};
`

export const BodySectionWrapper = styled(DrawerFooterWrapper)`
	padding: 0px;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	${({ isSetHeight }) => {
		if (isSetHeight) {
			return css`
				height: 53vh;
				@media ${Devices.mobile} {
					height: 100vh;
				}
			`
		}
	}}
`

export const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const ActionButton = styled(Button)`
	margin-top: ${Spacings.SPACING_2B};
	margin-left: auto;
`
export const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
export const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	margin-top: ${Spacings.SPACING_2B};
	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`

export const RadioInputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: ${Spacings.SPACING_2B};
	@media ${Devices.tablet} {
		flex-direction: row;
	}
`

export const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`

export const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

export const PermissionStatusWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: ${Spacings.SPACING_10B};

	& > h4 {
		text-align: center;
	}
`

export const StepsToAllow = styled.div`
	margin-top: ${Spacings.SPACING_4B};
	& > h5 {
		color: ${({ theme }) => theme.palette.text.greydark};
		line-height: ${Text.LINE_HEIGHT_MAX};
		display: flex;
		align-items: center;
		margin-top: ${Spacings.SPACING_2B};

		& > svg {
			font-size: ${Text.LARGE};
			margin: 0 ${Spacings.SPACING_2};
		}
	}
`
