import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { useDispatch } from 'react-redux'
import { Devices, Spacings } from '@styles'
import { strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { showLoader, showToast } from '@data/state/action'
import LogisticsQuotesForm from './LogisticsQuotesForm'
import { User } from '@data/model'
import { updateLogistics, createNewLogisticsFromData } from './state/actions'

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

const initialFormState = {
	logistics_quote_per_mt: {
		label: strings('label_logistics_quotation_per_mt'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	lumpsum_logistics_quote: {
		label: strings('label_lumpsum_logistics_quotation'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	minimum_guaranteed_weight: {
		label: strings(['mgw_full']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	logistics_partner: {
		label: strings(['logistics', 'partner']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	order_won: {
		label: strings('finalize_quote'),
		disabled: false,
		readOnly: false,
		value: false,
		dirty: false,
	},
}

const deriveFormStateQuotesItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.logistics_quote_per_mt.value = data?.logistics_quote_per_mt ?? ''
	derivedState.logistics_quote_per_mt.disabled = !editable
	derivedState.logistics_quote_per_mt.readOnly = !editable

	derivedState.lumpsum_logistics_quote.value = data?.lumpsum_logistics_quote ?? ''
	derivedState.lumpsum_logistics_quote.disabled = !editable
	derivedState.lumpsum_logistics_quote.readOnly = !editable

	derivedState.minimum_guaranteed_weight.value = data?.minimum_guaranteed_weight ?? ''
	derivedState.minimum_guaranteed_weight.disabled = !editable
	derivedState.minimum_guaranteed_weight.readOnly = !editable

	derivedState.logistics_partner.value = data?.logistics_partner ?? ''
	derivedState.logistics_partner.disabled = !editable
	derivedState.logistics_partner.readOnly = !editable

	derivedState.order_won.value = data?.order_won ?? ''
	derivedState.order_won.disabled = !editable
	derivedState.order_won.readOnly = !editable

	return derivedState
}

const AddLogisticsQuotes = ({
	itemId,
	editable = true,
	logisticsPartnerById,
	getState,
	addNewLogistiscQuotesCallback,
	updateLogistiscQuotesCallback,
	logisticsPartner,
	logistic,
	deleteLogistiscQuotesCallback,
	localDispatch,
}) => {
	itemId = itemId ?? logisticsPartnerById?.id
	const globalDispatch = useDispatch()
	const [formState, setFormState] = useState(initialFormState)
	const selectedPartner = logisticsPartner?.find((item) => item.organisation_id === logisticsPartnerById?.logistics_partner?.organisation_id)
	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateQuotesItemData(_oldState, logisticsPartnerById, !!editable))
	}, [editable])

	const disableButton = formState.logistics_quote_per_mt.value === '' && formState.lumpsum_logistics_quote.value === ''

	const formStateToQuotesItem = () => {
		return {
			logistics_quote_per_mt: formState.logistics_quote_per_mt.value === '' ? 0 : parseFloat(formState.logistics_quote_per_mt.value),
			lumpsum_logistics_quote: formState.lumpsum_logistics_quote.value === '' ? 0 : parseFloat(formState.lumpsum_logistics_quote.value),
			minimum_guaranteed_weight: formState.minimum_guaranteed_weight.value === '' ? 0 : parseFloat(formState.minimum_guaranteed_weight.value),
			order_won: formState.order_won.value,
			logistics_partner: new User(formState.logistics_partner.value).getUserDetails(),
		}
	}

	const addNewItemHandler = () => {
		let newQuotesItemData = formStateToQuotesItem()
		if (newQuotesItemData.minimum_guaranteed_weight <= 0) {
			globalDispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (newQuotesItemData) {
			newQuotesItemData = { id: Math.floor(Math.random() * 1000), ...newQuotesItemData }
			globalDispatch(showLoader(true))
			updateLogistics({ ...logistic, ...logistic.all_logistics_quotes.push(newQuotesItemData) })
				.then((response) => {
					localDispatch(createNewLogisticsFromData(response))
				})
				.finally(() => {
					globalDispatch(showLoader(false))
					globalDispatch(toggleDrawer(false))
				})
		}
	}

	const updateHandler = () => {
		const newQuotesItemData = formStateToQuotesItem()
		if (newQuotesItemData.minimum_guaranteed_weight <= 0) {
			globalDispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (newQuotesItemData) {
			const udateQoute = logistic.all_logistics_quotes.map((item) => {
				if (item?.logistics_partner?.user_id === newQuotesItemData?.logistics_partner?.user_id) {
					return {
						id: Math.floor(Math.random() * 1000),
						...newQuotesItemData,
					}
				}
				return item
			})

			globalDispatch(showLoader(true))
			updateLogistics({ ...logistic, all_logistics_quotes: udateQoute })
				.then((response) => {
					localDispatch(createNewLogisticsFromData(response))
				})
				.finally(() => {
					globalDispatch(showLoader(false))
					globalDispatch(toggleDrawer(false))
				})
		}
	}

	const cancelEditHandler = () => {
		globalDispatch(toggleDrawer(false))
	}

	const renderButtons = () => {
		if (!logisticsPartnerById && editable) {
			return (
				<ActionButton small type='primary' disabled={disableButton} onClick={addNewItemHandler}>
					{logisticsPartnerById ? strings('edit', 'quotes') : strings('add', 'quotes')}
				</ActionButton>
			)
		} else if (logisticsPartnerById && editable) {
			return (
				<>
					<ActionButton small disabled={disableButton} type='primary' onClick={updateHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
				</>
			)
		}
	}
	return (
		<>
			<DrawerBodyWrapper>
				<LogisticsQuotesForm
					state={formState}
					logisticsPartner={logisticsPartner}
					updateState={updateFormState}
					selectedPartner={selectedPartner}
					logistic={logistic}
					dispatch={globalDispatch}
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default AddLogisticsQuotes
