import { Devices } from '@styles'
import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const ReactPlayerWrapper = styled(ReactPlayer)`
	overflow: hidden;
	object-fit: cover;
	height: 12rem !important;
	@media ${Devices.mobile} {
		height: 17rem !important;
	}
	@media ${Devices.tablet} {
		height: 20rem !important;
	}
	@media ${Devices.laptop} {
		height: 17rem !important;
	}
	& > video {
		width: 100% !important;
	}
`
const VideoPlayer = ({ url }) => {
	return <ReactPlayerWrapper width={'auto'} url={url} controls />
}

export default VideoPlayer
