import API from '../api'
import putUrl from './paths'

const updateInvoices = (payload) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateInvoices, payload)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

const addOtherInvoiceDocuments = (file, invoiceNumber) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.put(putUrl.addOtherInvoiceDocuments, formData, { invoiceNumber })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { updateInvoices, addOtherInvoiceDocuments }
