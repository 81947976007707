import React from 'react'
import { Input } from '@base'
import { CustomSectionHeader, FormChildItemWrapper } from '../styles'
import { strings } from '@constants'
import { FormItemWrapper } from '../../FinalReport/styles'

const FinanceForm = ({ item, index, onChangeHandle }) => {
  const { comments, score } = item
	return (
		<>
			<CustomSectionHeader>{item?.heading}</CustomSectionHeader>
			<FormChildItemWrapper>
				<FormItemWrapper isScore>
					<Input
						type='number'
						label={score?.label}
						value={score?.value}
						name={score?.name}
						max={score?.placeholder}
						placeholder={`${strings('max_score')} ${score?.placeholder}`}
						onChange={(val) => {
							onChangeHandle(index, score?.name, val)
						}}
					/>
				</FormItemWrapper>
				<FormItemWrapper isFullWidth>
					<Input
						type='text'
						label={comments?.label}
						value={comments?.value}
						name={comments?.name}
						onChange={(val) => {
							onChangeHandle(index, comments?.name, val)
						}}
					/>
				</FormItemWrapper>
			</FormChildItemWrapper>
		</>
	)
}

export default FinanceForm
