import React, { useEffect, useMemo, useState } from 'react'
import { PageLevelTableWrapper } from '@base'
import { strings } from '@constants'
import { AccountCircle as AccountCircleIcon, Score as ScoreIcon, Assignment } from '@material-ui/icons'
import { BoxWapper, ResponsiveLeft, ResponsiveRight, ResponsiveStepWrapper, Wrapper } from '@pages/Admin/Underwriting/ViewReport/FinalReport/styles'
import Sales from './Sales'
import Supply from './Supply'
import Management from './Management'
import styled from 'styled-components'
import { getLatestReport } from '@data'
import { showLoader } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { useOutletContext, useParams } from 'react-router'
import { useForceUpdate } from '@hooks'

const PageLevelTable = styled(PageLevelTableWrapper)`
	padding-bottom: 0rem;
`
const TabWrapper = styled.div`
	position: sticky;
	top: 3.3rem;
	zindex: 498;
`

const tabsInfo = [
	{ name: strings('sales'), icon: AccountCircleIcon },
	{ name: strings('supply'), icon: Assignment },
	{ name: strings('management'), icon: ScoreIcon },
]

const Index = () => {
	const dispatch = useDispatch()
	const orgIdFromParams = useParams()?.orgId
	const [selectedTab, setSelectedTab] = useState(0)
	const [data, setData] = useState()
	const [forceUpdate, update] = useForceUpdate()
	const [, , orgData] = useOutletContext()

	useEffect(() => {
		dispatch(showLoader(true))
		getLatestReport(orgIdFromParams)
			.then((response) => {
				setData(response)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(dispatch(showLoader(false)))
	}, [forceUpdate])

	const tabs = useMemo(() => {
		return tabsInfo.map((_t, index) => ({
			label: _t?.name,
			icon: _t?.icon,
			selected: index === selectedTab,
			onClick: () => setSelectedTab(index),
		}))
	}, [selectedTab])
	return (
		<PageLevelTable>
			<ResponsiveStepWrapper>
				<ResponsiveLeft>
					<TabWrapper>
						<Wrapper isMenu>
							{tabs.map((_item, index) => {
								return (
									<BoxWapper isSelected={_item.selected} onClick={_item.onClick} key={index}>
										<_item.icon />
										<p>{_item?.label}</p>
									</BoxWapper>
								)
							})}
						</Wrapper>
					</TabWrapper>
				</ResponsiveLeft>
				<ResponsiveRight>
					{selectedTab === 0 && <Sales data={{ ...data, pan: orgData?.pan }} update={update} />}
					{selectedTab === 1 && <Supply data={{ ...data, pan: orgData?.pan }} update={update} />}
					{selectedTab === 2 && <Management data={{ ...data, pan: orgData?.pan }} update={update} />}
				</ResponsiveRight>
			</ResponsiveStepWrapper>
		</PageLevelTable>
	)
}

export default Index
