import React, { useMemo } from 'react'
import { FormHeading, FormItemWrapper, FormWrapper, Label, RadioButtonWrapper, RadioInputWapper } from './styles'
import { Input, RadioButton, Select } from '@base'

const GenerateEstimateForm = ({ formState, accountDetails, gstDetails, addresses, onChangeHandler }) => {
	const _address = useMemo(
		() =>
			addresses?.map((item) => {
				item['complete_address'] = item?.address?.complete_address
				return item
			}),
		[addresses]
	)
	const onValueChange = (value, key) => {
		onChangeHandler(key)(value)
	}
	return (
		<FormWrapper>
			<FormItemWrapper>
				<FormHeading>{formState?.gst_detail?.label}</FormHeading>
				<RadioInputWapper gstDetail>
					{gstDetails?.map((item, index) => (
						<RadioButtonWrapper key={item.gst}>
							<RadioButton
								id={`${index}-gst-detail`}
								size='1.125'
								checked={item?.gst === formState.gst_detail.value}
								value={item.gst}
								onChange={(e) => onValueChange(e.target.value, 'gst_detail')}
							/>
							<Label htmlFor={`${index}-gst-detail`}>{` ${item?.gst} - GST ${item.location}`}</Label>
						</RadioButtonWrapper>
					))}
				</RadioInputWapper>
			</FormItemWrapper>

			<FormItemWrapper>
				<FormHeading>{formState?.account_detail?.label}</FormHeading>
				<RadioInputWapper>
					{accountDetails?.map((item, index) => (
						<RadioButtonWrapper key={item?.account_number}>
							<RadioButton
								id={`${index}-account-details`}
								size='1.125'
								checked={item?.account_number === formState.account_detail.value}
								value={item?.account_number}
								onChange={(e) => onValueChange(e.target.value, 'account_detail')}
							/>
							<Label htmlFor={`${index}-account-details`}>{`${item?.account_number} - ${item.bank_name}`}</Label>
						</RadioButtonWrapper>
					))}
				</RadioInputWapper>
			</FormItemWrapper>
			<FormItemWrapper>
				<Select
					primaryKey={'gst'}
					displayKey={'complete_address'}
					data={addresses}
					label={formState?.billing_address?.label}
					value={formState?.billing_address?.value ? _address?.find((item) => item?.gst === formState?.billing_address?.value?.gst) : {}}
					placeholder={formState?.billing_address?.placeholder}
					onChange={onChangeHandler('billing_address')}
				/>
			</FormItemWrapper>

			<FormItemWrapper>
				<Input
					type='text'
					label={formState?.remarks?.label}
					value={formState?.remarks?.value}
					disabled={formState?.remarks?.disabled}
					onChange={onChangeHandler('remarks')}
				/>
			</FormItemWrapper>
		</FormWrapper>
	)
}

export default GenerateEstimateForm
