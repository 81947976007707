import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ButtonWrapper, DetailsWrapper, FinancialHeader, FinancialLabel, GridListWrapper } from '../../styles'
import { Button, ItemCard, SectionHeader, TOAST_TYPE, UploadButton } from '@base'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { uploadCran } from '@data'
import { toggleFileViewer } from '@data/state/action/root'
import { Spacings } from '@styles'
import { displayAmount } from '@utils'

const FinancialHighlights = ({ orgData, financialData, update }) => {
	const dispatch = useDispatch()
	const [cranFile, setCranFile] = useState()

	const showErrorFinancial = useMemo(() => {
		if (financialData?.data_fetched) {
			return (
				financialData?.total_revenue &&
				financialData?.current_ratio &&
				financialData?.ebitda &&
				financialData?.debt_equity &&
				financialData?.pat &&
				financialData?.interest_coverage
			)
		}
		return false
	}, [financialData])

	useEffect(() => {
		setCranFile(financialData?.cran)
	}, [financialData])

	const itemData = [
		{
			label: strings(['revenue', '(', 'cr', ')']),
			value: financialData?.total_revenue ? displayAmount(financialData?.total_revenue / 10000000) : '--',
		},
		{
			label: strings('current', 'ratio'),
			value: financialData?.current_ratio ? financialData?.current_ratio.toFixed(2) : '--',
		},
		{
			label: strings(['EBITDA', '(', '%', ')']),
			value: financialData?.ebitda ? financialData?.ebitda.toFixed(2) : '--',
		},
		{
			label: 'D/E',
			value: financialData?.debt_equity ? financialData?.debt_equity.toFixed(2) : '--',
		},

		{
			label: strings(['PAT', '(', 'cr', ')']),
			value: financialData?.pat ? displayAmount(financialData?.pat / 10000000) : '--',
		},
		{
			label: strings(['interest', 'coverage', '(', '%', ')']),
			value: financialData?.interest_coverage ? financialData?.interest_coverage.toFixed(2) : '--',
		},
	]

	const uploadBuyerInvoiceHandler = (file) => {
		if (!file) return
		dispatch(showLoader(true))
		uploadCran(file, orgData?.organisation_id)
			.then((res) => {
				setCranFile(res?.file_path)
				dispatch(showToast(true, res?.response_message, { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('document_upload_failed'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const viewTCHandler = () => {
		dispatch(toggleFileViewer(true, { files: cranFile }))
	}

	return (
		<>
			<DetailsWrapper>
				<SectionHeader isFinancialHeader>
					<FinancialHeader>
						<span>{strings(['financial', 'highlights'])} </span>
						{!showErrorFinancial ? (
							<FinancialLabel error>{strings('msg_financial_data_not_found')}</FinancialLabel>
						) : (
							<FinancialLabel>{financialData?.financial_year}</FinancialLabel>
						)}
					</FinancialHeader>
					<ButtonWrapper noGap>
						<Button xs uppercase type='secondary' disabled={!cranFile} onClick={viewTCHandler} margin={`0 ${Spacings.SPACING_3B} 0 auto`}>
							{strings('view')}
						</Button>
						<UploadButton label={strings('upload', 'CRAN')} xs accept='pdf' type='primary' onChange={uploadBuyerInvoiceHandler} />
					</ButtonWrapper>
				</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<ItemCard medium data={itemData} key={`financial-highlights-item`} />
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default FinancialHighlights
