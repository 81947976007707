import React, { useCallback, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import styled from 'styled-components'
import { Button } from '@base'
import { Spacings } from '@styles'
import { ButtonContainer, ContainerWrapper } from '.'
import { HighlightOffOutlined, CameraOutlined, FlipCameraIosOutlined, CloudUploadOutlined } from '@material-ui/icons'

const WebcamWrapper = styled(Webcam)`
	overflow: hidden;
	object-fit: cover;
	height: 38vh;
`
const ImageWrapper = styled.div`
	overflow: hidden;
	object-fit: cover;
	height: 38vh;
	& > img {
		width: 100% !important;
	}
`

const ButtonCamera = styled(Button)`
	min-width: ${Spacings.SPACING_12B};
	padding: 0;
`
const ClosedIcon = styled(HighlightOffOutlined)`
	margin-right: auto;
	cursor: pointer;
	color: ${({ theme, isRecord }) => theme.palette.text.red};
`

const WebcamImage = ({ adminView, setActiveImageWebcam, capturePhotoHandler }) => {
	const [img, setImg] = useState(null)
	const webcamRef = useRef(null)
	const [loading, isLoading] = useState(true)

	const videoConstraints = {
		facingMode: 'user',
	}

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		setImg(imageSrc)
	}, [webcamRef])

	const uploadImageHandler = () => {
		capturePhotoHandler(img)
		setImg(null)
		// setActiveImageWebcam(false)
	}

	return (
		<ContainerWrapper fullWidth>
			{!adminView && (
				<ButtonContainer>
					<ClosedIcon
						fontSize='medium'
						onClick={() => {
							setActiveImageWebcam(false)
						}}
					/>
					<ButtonCamera type='primary' disabled={loading || img} xs uppercase onClick={capture}>
						{/* capture */}
						<CameraOutlined fontSize='medium' />
					</ButtonCamera>
					<ButtonCamera type='primary' disabled={!img} margin='0px 2px' xs uppercase onClick={() => setImg(null)}>
						{/* retake */}
						<FlipCameraIosOutlined fontSize='medium' />
					</ButtonCamera>
					<ButtonCamera xs type='primary' disabled={!img} onClick={uploadImageHandler}>
						{/* upload */}
						<CloudUploadOutlined fontSize='medium' />
					</ButtonCamera>
				</ButtonContainer>
			)}
			{!img ? (
				<WebcamWrapper
					muted={true}
					audio={true}
					mirrored={true}
					ref={webcamRef}
					screenshotFormat={'image/jpeg'}
					videoConstraints={videoConstraints}
					onUserMedia={() => isLoading(false)}
				/>
			) : (
				<ImageWrapper>
					<img src={img} alt='screenshot' />
				</ImageWrapper>
			)}
		</ContainerWrapper>
	)
}

export default WebcamImage
