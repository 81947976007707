import API from '../api'
import deleteUrl from './paths'

const deleteAdminOrder = (orderId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteOrder, { orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const deleteOrderDocumentApi = ({filePath, orderId, organisation_id, type, userId, invoiceNo = null}) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteOrderDocument, { filePath, orderId, organisation_id, type, userId, invoiceNo })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { deleteAdminOrder, deleteOrderDocumentApi }
