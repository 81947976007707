import API from '../api'
import getUrl from './paths'

const getJourneyBySourceId = (journeySourceId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getJourneyBySourceId, { journeySourceId, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { getJourneyBySourceId }
