import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Devices, Spacings } from '@styles'
import { strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { showLoader, showToast } from '@data/state/action'
import LogisticsPartnerForm from './LogisticsPartnerForm'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { submitLogisticsQuote, editLogisticsQuote } from '../../Admin/Logistics/CreateLogistics/state/actions'

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

const initialFormState = {
	logistics_quote_per_mt: {
		label: strings('label_logistics_quotation_per_mt'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	lumpsum_logistics_quote: {
		label: strings('label_lumpsum_logistics_quotation'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	minimum_guaranteed_weight: {
		label: strings(['mgw_full']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	logistics_partner: {
		label: strings(['logistics', 'partner']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	order_won: {
		label: strings('finalize_quote'),
		disabled: false,
		readOnly: false,
		value: false,
		dirty: false,
	},
}

const deriveFormStateQuotesItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.logistics_quote_per_mt.value = data?.logistics_quote_per_mt ?? ''
	derivedState.logistics_quote_per_mt.readOnly = !editable

	derivedState.lumpsum_logistics_quote.value = data?.lumpsum_logistics_quote ?? ''
	derivedState.lumpsum_logistics_quote.readOnly = !editable

	derivedState.minimum_guaranteed_weight.value = data?.minimum_guaranteed_weight ?? ''
	derivedState.minimum_guaranteed_weight.readOnly = !editable

	return derivedState
}

const LogisticsPartnerQuotes = ({ logisticsId, logistic, editable, callBackApi, viewOnly }) => {
	const globalDispatch = useDispatch()
	const [formState, setFormState] = useState(initialFormState)
	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateQuotesItemData(_oldState, logistic, !!editable))
	}, [editable])

	const disableButton = formState.logistics_quote_per_mt.value === '' && formState.lumpsum_logistics_quote.value === ''

	const formStateToQuotesItem = () => {
		return {
			logistics_quote_per_mt: formState.logistics_quote_per_mt.value === '' ? 0 : parseFloat(formState.logistics_quote_per_mt.value),
			lumpsum_logistics_quote: formState.lumpsum_logistics_quote.value === '' ? 0 : parseFloat(formState.lumpsum_logistics_quote.value),
			minimum_guaranteed_weight: formState.minimum_guaranteed_weight.value === '' ? 0 : parseFloat(formState.minimum_guaranteed_weight.value),
			order_won: formState.order_won.value,
		}
	}

	const addNewItemHandler = () => {
		let newQuotesItemData = formStateToQuotesItem()
		if (newQuotesItemData.minimum_guaranteed_weight <= 0) {
			globalDispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (newQuotesItemData) {
			globalDispatch(showLoader(true))
			let requestApi = ''
			if (editable) {
				requestApi = editLogisticsQuote({ ...logistic, ...newQuotesItemData }, logisticsId)
			} else {
				requestApi = submitLogisticsQuote({ ...logistic, ...newQuotesItemData }, logisticsId)
			}

			requestApi
				.then((response) => {
					callBackApi()
				})
				.finally(() => {
					globalDispatch(showLoader(false))
					globalDispatch(toggleDrawer(false))
				})
		}
	}

	const cancelEditHandler = () => {
		globalDispatch(toggleDrawer(false))
	}

	const renderButtons = () => {
		if (!editable) {
			return (
				<ActionButton small type='primary' disabled={disableButton} onClick={addNewItemHandler}>
					{strings('add', 'quotes')}
				</ActionButton>
			)
		} else {
			return (
				<>
					<ActionButton small disabled={disableButton || viewOnly} type='primary' onClick={addNewItemHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
				</>
			)
		}
	}
	return (
		<>
			<DrawerBodyWrapper>
				<LogisticsPartnerForm state={formState} updateState={updateFormState} viewOnly={viewOnly} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default LogisticsPartnerQuotes
