import { Button, DrawerBodyWrapper, Input, TOAST_TYPE } from '@base'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { updateOrganisationApi } from '@data/conn/put/organisation'
import { EditDetailWrapper } from '../styles'

const EditOrgName = ({ orgDetails, update }) => {
	const dispatch = useDispatch()

	const [orgName, setOrgName] = useState(orgDetails?.organisation_name)

	useEffect(() => {
		setOrgName(orgDetails?.organisation_name)
	}, [orgDetails?.organisation_name])

	const onNameChange = (value) => {
		const _trimmedValue = value.trimStart()
		setOrgName(_trimmedValue)
	}

	const onNameUpdate = () => {
		if (!orgName.trim()) return

		dispatch(showLoader(true))
		updateOrganisationApi(orgDetails.organisation_id, 'ORG_NAME', orgName.trim())
			.then(() => {
				dispatch(showToast(true, 'Successfully updated organisation name', { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while updating organisation name.', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<EditDetailWrapper>
					<Input placeholder='Enter a name' label='Organisation Name' type='text' value={orgName} onChange={onNameChange} />
					<div>
						<Button disabled={!orgName} onClick={onNameUpdate}>
							Update
						</Button>
					</div>
				</EditDetailWrapper>
			</DrawerBodyWrapper>
		</>
	)
}

export default EditOrgName
