import API from '../api'
import postUrl from './paths'

export const createLedger = (ledger) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.createLedger, ledger)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const createPaymentReceivable = (paymentReceivables) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.createCollection, paymentReceivables)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
