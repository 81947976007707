import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { getAllReports, getReportById } from '@data'
import styled from 'styled-components'
import { Spacings } from '@styles'
import { Chip, DrawerBodyWrapper, ItemCard } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { showDateFormat } from '@utils'

const OrderItemsList = styled.div`
	margin-bottom: ${Spacings.SPACING_2B};
	min-height: 10rem;
	width: 100%;
`

const ItemWapper = styled.div`
	cursor: pointer;
`

const PastReport = ({ reportDetails, label, editable = true, bankDetails, pastType, update }) => {
	const dispatch = useDispatch()
	const [pastData, setPastData] = useState()

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: pastType ? reportDetails?.pan : label,
			})
		)
	}, [dispatch, pastType, reportDetails, label])

	useEffect(() => {
		pastType &&
			getAllReports(reportDetails?.pan)
				.then((res) => {
					setPastData(res)
				})
				.catch((err) => {
					console.log(err)
				})
	}, [pastType])

	const itemClickHandler = (item) => {
		getReportById(item?.pan, item?.report_id)
			.then((res) => {
				dispatch(
					toggleDrawer(true, DRAWER_TYPES.UNDERWRITING_STATUS, {
						label: strings('report', 'status'),
						bankDetails,
						reportDetails: { ...res, pan: reportDetails?.pan },
						pastType: null,
						editable: !editable,
						update,
					})
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<DrawerBodyWrapper>
			<OrderItemsList>
				{pastData?.map((item, index) => {
					const _status =
						item?.mca_status && item?.bsa_status && item?.bsa_report_score > -1 && item?.fsa_report_score > -1 ? (
							<Chip label={strings('completed')} status={'success'} />
						) : (
							<Chip label={strings('pending')} />
						)

					const itemData = [
						{
							label: strings('report', 'id'),
							value: item?.report_id,
						},
						{
							label: strings('date'),
							value: showDateFormat(new Date(item?.report_created_date)) ?? '--',
						},
						{
							label: strings('credit', 'score'),
							value: item?.credit_score ?? '--',
						},
						{
							label: strings('status'),
							value: _status,
						},
					]
					return (
						<ItemWapper
							onClick={() => {
								itemClickHandler(item)
							}}
							key={index}
						>
							<ItemCard extraSmall clickable={false} data={itemData} key={`stateOrder-item-${index}`} rowData={true} />
						</ItemWapper>
					)
				})}
			</OrderItemsList>
		</DrawerBodyWrapper>
	)
}

export default PastReport
