import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MBOOK_BANK, strings } from '@constants'
import { updateOrganisationApi } from '@data'
import { Button, DrawerBodyWrapper, Select, TOAST_TYPE } from '@base'
import { FormItemWrapper, InputWrapper } from '../Pdc/PdcForm'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'

const ItemWrapper = styled(InputWrapper)`
	&:nth-child(2) {
		flex: 1;
	}
`
const _MBOOK_BANK = MBOOK_BANK?.map((item) => ({ ...item, display_name: item?.account_name || item?.account_id })) || []

const getFormState = (data) => {
	const _state = {
		bank: {
			id: 'bank',
			label: strings('select', 'bank'),
			placeholder: strings(['select', 'bank']),
			disabled: false,
			value: {},
			required: true,
			data: _MBOOK_BANK,
		},
	}
	if (data?.mbook_bank) {
		_state.bank.value = _MBOOK_BANK?.find((item) => item?.account_id === data?.mbook_bank?.account_id) || {}
	}
	return _state
}

const BankForm = ({ data, update, financialData }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(getFormState())

	useEffect(() => {
		setFormState(getFormState(financialData))
	}, [financialData])

	const updateState = (key) => (value) => {
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
	}

	const buttonHandler = () => {
		const { bank } = formState
		dispatch(showLoader(true))
		updateOrganisationApi(data.organisation_id, 'MBOOK_BANK', null, {
			bankName: bank?.value?.bank_name,
			accountId: bank?.value?.account_id,
			accountNumber: bank?.value?.account_number,
			ifscCode: bank?.value?.ifsc,
		})
			.then(() => {
				dispatch(showToast(true, 'Successfully updated organisation bank.', { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while updating organisation bank.', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	return (
		<DrawerBodyWrapper>
			<FormItemWrapper>
				<InputWrapper>
					<Select
						id={formState.bank.id}
						label={formState.bank?.label}
						placeholder={formState.bank?.placeholder}
						data={formState?.bank?.data}
						displayKey='display_name'
						primaryKey='account_id'
						value={formState.bank.value ?? {}}
						onChange={updateState('bank')}
						disabled={formState.bank.disabled}
						required={formState.bank.required}
						errorMap={{
							valueMissing: `${strings('bank is required')}`,
						}}
					/>
				</InputWrapper>
				<ItemWrapper>
					<Button disabled={!formState?.bank?.value} onClick={() => buttonHandler()}>
						Update
					</Button>
				</ItemWrapper>
			</FormItemWrapper>
		</DrawerBodyWrapper>
	)
}

export default BankForm
