import { useEffect } from 'react'

const useEnter = (handler, skip) => {
	useEffect(() => {
		if (skip) return

		const keyUpHandler = (e) => {
			e.preventDefault()
			if (e.keyCode === 13) {
				handler()
			}
		}

		window.addEventListener('keyup', keyUpHandler)

		return () => {
			window.removeEventListener('keyup', keyUpHandler)
		}
	}, [handler, skip])
}

export default useEnter
