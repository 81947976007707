import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ButtonContainer, ContainerWrapper, BannerText, BannerWrapper, StepTopWrapper, SubSectionHeading, SuccessHeading } from '.'
import { Button, DrawerFooterWrapper, TOAST_TYPE, UploadMultipleFileButton } from '@base'
import { Spacings } from '@styles'
import { MODAL_TYPES, strings } from '@constants'
import { uploadKYCDocument } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { ChevronLeftOutlined, ChevronRightOutlined, RadioButtonChecked } from '@material-ui/icons'
import { ActionButtonWapper, ButtonIcon, ButtonIconWapper, SectionItemWrapper } from '../styles'
import RejectReason from '../RejectReason'
import VideoPlayer from './VideoPlayer'
import { toggleModal } from '@data/state/action/root'
import { getServerUrl } from '@utils'

const WebcamWrapper = styled(Webcam)`
	overflow: hidden;
	object-fit: cover;
	height: 50vh;
`
const ButtonTrip = styled(Button)`
	min-width: ${Spacings.SPACING_12B};
`
const RadioButtonCheckedIcon = styled(RadioButtonChecked)`
	margin-right: auto;
	color: ${({ theme, isRecord }) => (isRecord ? theme.palette.text.red : theme.palette.text.disabled)};
`
const FooterWrapper = styled(DrawerFooterWrapper)`
	display: flex;
	flex-direction: ${({ isDirection }) => isDirection || 'row'};
	width: 100%;
	justify-content: flex-end;
	box-shadow: none;
	padding: ${Spacings.SPACING_4B} 0px ${Spacings.SPACING_4B} ${Spacings.SPACING_4B};
`

const WebcamVideo = ({ org, adminView, kycStatushandler, selectedStep, preStep, update }) => {
	const dispatch = useDispatch()
	const webcamRef = useRef(null)
	const mediaRecorderRef = useRef(null)
	const [capturing, setCapturing] = useState(false)
	const [recordedChunks, setRecordedChunks] = useState([])
	const [loading, isLoading] = useState(true)
	const [isShowVideoControls, setIsShowVideoControls] = useState(false)
	const [isActiveReason, setIsActiveReason] = useState(false)
	const [successMessage, setSuccessMessage] = useState(false)
	const [dataUrl, setDataUrl] = useState()
	const serverUrl = getServerUrl()

	const videoConstraints = {
		facingMode: 'user',
	}

	const handleDataAvailable = useCallback(
		({ data }) => {
			if (data.size > 0) {
				setRecordedChunks((prev) => prev.concat(data))
			}
		},
		[setRecordedChunks]
	)

	const handleStartCaptureClick = useCallback(() => {
		setCapturing(true)
		setRecordedChunks([])
		let options = ''
		if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
			options = { mimeType: 'video/webm; codecs=vp9' }
		} else if (MediaRecorder.isTypeSupported('video/webm')) {
			options = { mimeType: 'video/webm' }
		} else if (MediaRecorder.isTypeSupported('video/mp4')) {
			options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 }
		} else {
			console.error('no suitable mimetype found for this device')
		}
		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, options)
		mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable)
		mediaRecorderRef.current.start()
	}, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable])

	const handleStopCaptureClick = () => {
		mediaRecorderRef.current.stop()
	}

	useEffect(() => {
		if (org?.documents?.kyc_video) {
			setDataUrl(serverUrl + org?.documents?.kyc_video)
			if (!adminView) setSuccessMessage(true)
		}
	}, [org?.documents?.kyc_video])

	const handleDownload = useCallback(() => {
		if (recordedChunks.length) {
			const blob = new Blob(recordedChunks, {
				type: 'video/mp4',
			})
			return blob
		}
	}, [recordedChunks])

	const addVideoHandler = () => {
		uploadKYCDocumentVideo(handleDownload())
	}

	const uploadKYCDocumentVideo = (file) => {
		dispatch(showLoader(true))
		uploadKYCDocument(file, 'kyc_video', org?.user?.organisation_id)
			.then((res) => {
				dispatch(showToast(true, adminView ? 'Video Successfully Uploaded' : 'Your Kyc has been completed', { type: TOAST_TYPE.SUCCESS }))
				if (!adminView) {
					setSuccessMessage(true)
				} else {
					update()
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				if (!adminView) {
					stopWebCam()
					setIsShowVideoControls(true)
					setCapturing(false)
					setRecordedChunks([])
				}
				dispatch(showLoader(false))
			})
	}

	function stopWebCam() {
		let stream = webcamRef?.current?.stream
		const tracks = stream?.getTracks()
		tracks?.forEach((track) => track?.stop())
	}

	const renderText = () => {
		return (
			<>
				<SubSectionHeading>Please read the following lines in the video</SubSectionHeading>
				<StepTopWrapper>
					<BannerWrapper
						extendInMobile
						text={
							<BannerText>
								<span>{strings('msg_video_kyc_text')}</span>
							</BannerText>
						}
					/>
				</StepTopWrapper>
			</>
		)
	}

	const handleUserMedia = () => {
		isLoading(false)
	}

	const startDisabled = () => {
		if (capturing && recordedChunks.length === 0) {
			return true
		} else if (!capturing && recordedChunks.length !== 0) {
			return true
		}
		return false
	}

	const acceptHandler = () => {
		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: `Have you checked all documents?`,
				confirmAction: (actionType) => {
					if (actionType) {
						kycStatushandler('', selectedStep, true, `Kyc for organisation ${org?.user?.organisation_name} has been completed`)
					}
					dispatch(toggleModal(false))
				},
			})
		)
	}

	const videoKycUi = () => {
		if (successMessage) {
			return <SuccessHeading>Thank you for submitting your kyc details. We will update you soon.</SuccessHeading>
		}
		if (!adminView) {
			return (
				<>
					<ButtonContainer>
						<RadioButtonCheckedIcon fontSize='medium' isRecord={capturing && recordedChunks.length === 0} />
						<ButtonTrip
							type='primary'
							disabled={isShowVideoControls ? isShowVideoControls : loading || startDisabled()}
							xs
							uppercase
							onClick={handleStartCaptureClick}
						>
							{recordedChunks.length !== 0 ? 'Record Again' : strings('start')}
						</ButtonTrip>
						<ButtonTrip
							type='primary'
							disabled={isShowVideoControls ? isShowVideoControls : !capturing || recordedChunks.length !== 0}
							margin='0px 2px'
							xs
							uppercase
							onClick={handleStopCaptureClick}
						>
							{strings('stop')}
						</ButtonTrip>
						<ButtonTrip
							xs
							type='primary'
							disabled={isShowVideoControls ? isShowVideoControls : recordedChunks.length === 0}
							onClick={addVideoHandler}
						>
							{strings('confirm')}
						</ButtonTrip>
					</ButtonContainer>
					<WebcamWrapper
						muted={true}
						audio={true}
						mirrored={true}
						ref={webcamRef}
						videoConstraints={videoConstraints}
						onUserMedia={handleUserMedia}
					/>
				</>
			)
		}

		return <VideoPlayer url={dataUrl} />
	}

	return (
		<>
			<ContainerWrapper fullWidth>
				{videoKycUi()}
				{!successMessage && renderText()}
			</ContainerWrapper>

			{adminView && (
				<FooterWrapper isDirection='column'>
					<SectionItemWrapper isFooter>
						<ButtonIconWapper>
							<ButtonIcon
								type='secondary'
								small
								uppercase
								onClick={() => {
									preStep()
								}}
							>
								<ChevronLeftOutlined />
							</ButtonIcon>
							<ButtonIcon type='secondary' small margin={'0px 0px 0px 5px'} uppercase disabled={true}>
								<ChevronRightOutlined />
							</ButtonIcon>
						</ButtonIconWapper>
						<ActionButtonWapper>
							<UploadMultipleFileButton
								label={strings('upload')}
								acceptFile={'video/mp4,video/x-m4v,video/*'}
								xs
								uppercase
								margin={'0px 5px'}
								onChange={(file) => {
									uploadKYCDocumentVideo(file[0])
								}}
							/>
							<Button
								xs
								type='secondary'
								variant='destructive'
								disabled={!dataUrl}
								onClick={() => {
									setIsActiveReason(true)
								}}
							>
								{strings('reject')}
							</Button>

							<Button xs type='primary' disabled={!dataUrl} margin='0px 0px 0px 3px' onClick={acceptHandler}>
								{strings('accept')}
							</Button>
						</ActionButtonWapper>
					</SectionItemWrapper>
					{isActiveReason && (
						<RejectReason
							kycStatushandler={kycStatushandler}
							setIsActiveReason={setIsActiveReason}
							setpNumber={selectedStep}
							stepName='Vedio kyc'
							rejectReasonMsg={org?.kyc_reject_reason?.[3] || ''}
						/>
					)}
				</FooterWrapper>
			)}
		</>
	)
}

export default WebcamVideo
