import API from '../api'
import postUrl from './paths'

export const createContent = (content, contentType) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.createContent, { ...content }, { contentType })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err)
			})
	})

export const contentUploadFile = (file, contentId = null, type = 'BLOG') =>
	// NEWS, BLOG, MEDIA_COVERAGE
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.contentUploadFile, formData, { contentId, type })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
