import getUrl from './paths'
import { strings } from '@constants'

export const downloadUrl =
	(process.env.REACT_APP_SERVER === 'develop' ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_URL) + getUrl.downloadFile + '?filePath='

export const downloadFile = (filePath) =>
	new Promise((resolve, reject) => {
		if (!filePath) reject(strings('error_contact_support'))
		const tagElement = document.createElement('a')
		tagElement.id = 'hidden_download_link'
		tagElement.target = '_blank'
		tagElement.href = downloadUrl + filePath

		tagElement.download = filePath.split('/').splice(-1)[0]
		tagElement.click()
	})