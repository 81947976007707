import API from '../api'
import postUrl from './paths'

export const createOrganisation = (query) =>
	new Promise((resolve, reject) =>
		API.post(postUrl.createOrganisation, { removeDefault: true }, query)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)
export const addUser = (query) =>
	new Promise((resolve, reject) =>
		API.post(postUrl.addUser, {}, query)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)
export const addRemarks = ({ orgId, remarks, remarksBy }) =>
	new Promise((resolve, reject) =>
		API.post(postUrl.addRemarks, remarksBy, { orgId, remarks })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const addUserCommodity = (userCommodityList, organisationId) =>
	new Promise((resolve, reject) =>
		API.post(postUrl.addUserCommodity, userCommodityList, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const addSalesAdmin = (userId, organisationId) =>
	new Promise((resolve, reject) =>
		API.post(postUrl.addSalesAdmin, {}, { userId, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)
