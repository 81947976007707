import styled, { css } from 'styled-components'
import { Devices, Spacings } from '@styles'
import { H5 } from '@base'

export const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const FormItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
export const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`

export const RadioInputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: ${Spacings.SPACING_2B};
`
export const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`
export const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
