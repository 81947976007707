import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Loader, TOAST_TYPE } from '@base'
import { brands as BRANDS, strings } from '@constants'
import { editOrder, getCategories, getProductByCategory } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import OrderItemForm from './OrderItemForm'
import { toggleDrawer } from '@data/state/action/root'

const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
`

const initialFormState = {
	category: {
		label: strings('category'),
		disabled: false,
		readOnly: false,
		displayKey: 'category_name',
		primaryKey: 'category_id',
		placeholder: strings('select_a_category'),
		data: [],
		value: '',
		dirty: false,
		shouldFetchProducts: { flag: true },
	},
	product: {
		label: strings('product'),
		disabled: false,
		readOnly: false,
		displayKey: 'product_name',
		primaryKey: 'product_id',
		placeholder: strings('search_product'),
		data: [],
		value: '',
		dirty: false,
	},
	weight: {
		label: strings(['weight', '(', 'mt', ')']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	brands: {
		label: strings('brands'),
		disabled: false,
		readOnly: false,
		displayKey: 'name',
		primaryKey: 'id',
		placeholder: strings('select_brands'),
		data: [],
		value: [],
		dirty: false,
	},
}

const AddOrderItem = ({ order, defualtCategoryId, update, supplierPO }) => {
	const buyer = order?.buyer_list[0]
	const supplier = order?.supplier_list?.find((s) => s.supplier_po_number === supplierPO)
	const weightKey = supplierPO ? 'serviced_weight' : 'ordered_weight'
	const tcKey = supplierPO ? 'tc_available' : 'tc_required'

	const orderItems = supplierPO ? supplier?.order_items : buyer?.order_items

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)

	const [formState, setFormState] = useState(initialFormState)

	const lastCategoryProductsFetched = useRef()

	const checkTmtScrap = ['ms_tmt_bars', 'scrap', 'lead_scrap']
	const defaultBrand = { name: 'ANY', id: 'any' }

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	useEffect(() => {
		setLoading(true)
		const promises = [
			getCategories(0, 1000),
			Promise.resolve(
				BRANDS.map((brand) => ({
					name: brand,
					id: brand.split(' ').join('_').toLowerCase(),
				}))
			),
		]
		Promise.all(promises)
			.then(([categories, brands]) => {
				updateFormState('category', { data: categories.data })
				updateFormState('brands', { data: brands })
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	useEffect(() => {
		if (formState.category.dirty) {
			updateFormState('product', { value: '', data: [], dirty: false })
		}
		const categoryId = formState.category.value?.category_id
		if (categoryId && categoryId !== lastCategoryProductsFetched.current) {
			lastCategoryProductsFetched.current = categoryId
			getProductByCategory(categoryId).then((data) => {
				const updates = { data, value: '' }
				updateFormState('product', updates)
			})
		}
	}, [formState.category.value, formState.category.shouldFetchProducts])

	useEffect(() => {
		if (formState.product.dirty) {
			const selectedProduct = formState.product.value

			const sameProductItemsLength = orderItems?.filter((_item) => _item.product_id === selectedProduct?.product_id).length
			if (sameProductItemsLength > 0) {
				dispatch(showToast(true, 'Item already exists.', { type: TOAST_TYPE.ERROR }))
				updateFormState('product', { value: '' })
			}
		}
	}, [formState.product.value])

	const disableButton = () => {
		if (checkTmtScrap.includes(formState.category.value?.category_id))
			return !formState.category.value?.category_id || !formState.brands.value || !formState.product?.tmtProduct?.some((i) => !!i.weight)
		return (
			!formState.category.value?.category_id ||
			(formState.product.data.length && !formState.product.value?.product_id) ||
			!formState.weight.value ||
			!formState.brands.value.name
		)
	}

	const formStateToOrderItem = () => {
		if (formState.product.tmtProduct) {
			return formState.product.tmtProduct
				.map(
					(item) =>
						item.weight && {
							category_name: formState.category.value.category_name,
							product_id: item.product_id,
							product_name: item.product_name,

							brand_ordered: formState.brands.value.name,
							[tcKey]: true,
							price_per_kg: null,
							...(supplierPO
								? { serviced_weight: null, allotted_weight: parseFloat(item.weight) }
								: {
										ordered_weight: parseFloat(item.weight),
								  }),
						}
				)
				.filter(Boolean)
		}
		return [
			{
				category_name: formState.category.value.category_name,
				product_id: formState.product.value.product_id,
				product_name: formState.product.value?.product_name,
				brand_ordered: formState.brands.value.name,
				[tcKey]: true,
				price_per_kg: null,
				...(supplierPO
					? { serviced_weight: null, allotted_weight: formState.weight.value === '' ? '' : parseFloat(formState.weight.value) }
					: {
							ordered_weight: formState.weight.value === '' ? '' : parseFloat(formState.weight.value),
					  }),
			},
		]
	}

	const formStateToOrderInvoiceItem = () => {
		if (formState.product.tmtProduct) {
			return formState.product.tmtProduct
				.map(
					(item) =>
						item.weight && {
							category_name: formState.category.value.category_name,
							category_id: formState.category.value.category_id,
							product_id: item.product_id,
							product_name: item.product_name,
							brand_ordered: formState.brands.value.name,
							serviced_weight: null,
							ordered_weight: null,
						}
				)
				.filter(Boolean)
		}
		return [
			{
				category_name: formState.category.value.category_name,
				category_id: formState.category.value.category_id,
				product_id: formState.product.value.product_id,
				product_name: formState.product.value?.product_name,
				brand_ordered: formState.brands.value.name,
				serviced_weight: null,
				ordered_weight: null,
			},
		]
	}

	const addNewItemHandler = () => {
		const updatedOrder = { ...order }

		if (supplierPO) {
			const supplier = updatedOrder.supplier_list.find((s) => s.supplier_po_number === supplierPO)
			const orderItems = supplier.order_items
			const supplierInvoices = supplier.supplier_invoices

			supplier.order_items = [...orderItems, ...formStateToOrderItem()]

			if (supplierInvoices) {
				supplier.supplier_invoices = supplierInvoices.map((invoice) => {
					if (!Array.isArray(invoice?.order_items)) return invoice

					return {
						...invoice,
						order_items: [...invoice.order_items, ...formStateToOrderInvoiceItem()],
					}
				})
			}
		} else {
			const buyer = updatedOrder.buyer_list[0]
			const orderItems = buyer.order_items
			const buyerInvoices = buyer.buyer_invoices

			buyer.order_items = [...orderItems, ...formStateToOrderItem()]

			if (buyerInvoices) {
				buyer.buyer_invoices = buyerInvoices.map((invoice) => {
					if (!Array.isArray(invoice?.order_items)) return invoice

					return {
						...invoice,
						order_items: [...invoice.order_items, ...formStateToOrderInvoiceItem()],
					}
				})
			}
		}

		dispatch(showLoader(true))
		editOrder(updatedOrder)
			.then((_response) => {
				dispatch(showToast(true, strings('msg_order_editted'), { type: TOAST_TYPE.SUCCESS }))
				update()
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Failed to add item. Try again', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return loading ? (
		<LoaderWrapper>
			<Loader />
		</LoaderWrapper>
	) : (
		<>
			<DrawerBodyWrapper>
				<OrderItemForm
					orderItems={orderItems}
					state={formState}
					updateState={updateFormState}
					defaultBrand={defaultBrand}
					defualtCategoryId={defualtCategoryId}
					checkTmtScrap={checkTmtScrap}
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small type='primary' disabled={disableButton()} onClick={addNewItemHandler}>
					{strings('add_item')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddOrderItem
