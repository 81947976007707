import API from "../api";
import getUrl from "./paths";

export const getAllGlossary = (page = 0) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getAllGlossary, { page })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })

export const getGlossaryById = (glossaryId) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getGlossaryById, { glossaryId })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })

export const getGlossaryBySlug = (slug) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getGlossaryBySlug, { slug })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })