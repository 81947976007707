import OrganisationRoutes from './OrganisationRoutes'
import LoginRoutes from './LoginRoutes'
import AdminRoutes from './AdminRoutes'
// import LogisticRoutes from './LogisticRoutes'
// import FabricatorRoutes from './FabricatorRoutes'
import paths from '../paths'
// import ManufacturerRoutes from './ManufacturerRoutes'
import MBDocuments from '@pages/Admin/MBDocuments'
import DeleteProfile from '@pages/DeleteProfile'

export const routes = [
	AdminRoutes,
	OrganisationRoutes,
	// LogisticRoutes,
	// FabricatorRoutes,
	LoginRoutes,
	{
		path: paths.mbDocuments,
		element: <MBDocuments />,
	},
	{
		path: paths.deleteProfile,
		element: <DeleteProfile />,
	},
	{
		path: paths.notFound,
		element: <div>Not Found</div>,
	},
]

const getTitlesFromRoutes = (routes, baseRoute, isNested = false) => {
	return routes.reduce((acc, _r) => {
		let _acc = acc
		if (_r.title) {
			const _key = isNested ? _r.path : _r.path ? `${baseRoute}/${_r.path}` : baseRoute
			_acc = {
				..._acc,
				[_key]: _r.title,
			}
		}
		if (Array.isArray(_r.children)) {
			_acc = {
				..._acc,
				...getTitlesFromRoutes(_r.children, baseRoute, true),
			}
		}
		return _acc
	}, {})
}

const getRouteTitleMap = (routes, baseRoute) => {
	const _routes = routes.children ?? []
	return getTitlesFromRoutes(_routes, baseRoute)
}

export const OrganisationRouteTitleMap = getRouteTitleMap(OrganisationRoutes, paths.home)
// export const BuyerRouteTitleMap = getRouteTitleMap(BuyerRoutes, paths.buyer)
export const AdminRouteTitleMap = getRouteTitleMap(AdminRoutes, paths.admin)
// export const LogisticsRouteTitleMap = getRouteTitleMap(LogisticRoutes, paths.logistics)
// export const FabricatorRouteTitleMap = getRouteTitleMap(FabricatorRoutes, paths.fabricator)
// export const ManufacturerRouteTitleMap = getRouteTitleMap(ManufacturerRoutes, paths.manufacturer)
