import { Add, Close, DragHandle } from '@material-ui/icons'
import { useMemo } from 'react'
import styled from 'styled-components'
import { OTHER_CHARGES_INVOICE, strings } from '@constants'
import { Devices, Spacings, Text } from '@styles'
import { H5, H6 } from '@base'
import { displayAmount, displayWeight } from '@utils'

const Heading = styled(H5)`
	color: ${({ theme }) => theme.palette.text.label};
	margin-top: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_3B};

	&:first-of-type {
		margin-top: ${Spacings.SPACING_1B};
	}
`

const Item = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.main};
	border-radius: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`

const ProductName = styled(H6)`
	margin-bottom: ${Spacings.SPACING_2B};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.black};
`
const CalculationWrapper = styled.div`
	display: flex;
`
const Calculation = styled.div`
	flex-grow: 1;
	&:last-of-type {
		flex-basis: 35%;
		flex-grow: 0;
	}
`
const Info = styled.div`
	color: ${({ theme }) => theme.palette.text.label};
	font-size: ${Text.EXTRA_SMALL};
	line-height: ${Text.LINE_HEIGHT_MEDIUM};
`
const Value = styled.div`
	font-size: ${Text.MEDIUM};
	color: ${({ theme }) => theme.palette.text.darkblack};
`
const MathIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${Spacings.SPACING_2B};
	flex-grow: ${({ noGrow }) => (noGrow ? 0 : 1)};

	@media ${Devices.tablet} {
		flex-grow: 1;
	}

	& > svg {
		fill: ${({ theme }) => theme.palette.text.darkblack};
		font-size: ${Text.LARGE};
	}
`

const SummaryItem = styled.div`
	display: flex;
	padding: ${Spacings.SPACING_3B};
	border: 1px solid ${({ theme }) => theme.palette.background.blue};
	background: ${({ theme }) => theme.palette.background.white};
	border-radius: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
	align-items: center;
`

const SummaryItemCount = styled.div`
	display: flex;
	align-items: center;
	& > span {
		background: ${({ theme }) => theme.palette.background.blue};
		color: ${({ theme }) => theme.palette.text.white};
		font-size: ${Text.SMALL};
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const SummaryItemInfo = styled.div`
	font-size: ${Text.SMALL};
	flex-grow: 1;
	padding-left: ${Spacings.SPACING_2B};
`

const SummaryItemValue = styled.div`
	font-size: ${({ big }) => (big ? Text.LARGE : Text.MEDIUM)};
	flex-basis: 35%;
	font-weight: ${({ big }) => (big ? Text.BOLD_600 : 'initial')};
	color: ${({ theme }) => theme.palette.text.darkblack};
`

const SummaryCalculation = styled.div`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.palette.text.darkblack};
	& > span {
		margin: 0 ${Spacings.SPACING_1B};
	}
`

const TaxHeading = styled(Heading)`
	display: flex;

	& > span {
		margin-right: ${Spacings.SPACING_1B};
	}
`
const TotalAmountInfo = styled.div`
	font-size: ${Text.MEDIUM};
	display: flex;
	flex-grow: 1;
	justify-content: center;
	flex-direction: column;

	& > span {
		margin-bottom: ${Spacings.SPACING_1B};
	}

	@media ${Devices.tablet} {
		flex-direction: row;
		align-items: center;
		justify-content: initial;
		& > span {
			margin-bottom: 0;
		}
	}
`

const TotalAmount = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	margin-top: ${Spacings.SPACING_3B};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	padding-top: ${Spacings.SPACING_2B};

	@media ${Devices.tablet} {
		padding-top: ${Spacings.SPACING_3B};
	}
`

const Summary = ({ data, weightKey, showTotal, otherChargesInvoice }) => {
	const getServicedWeight = (invoices, item) => {
		return invoices.reduce(
			(total, _i) => {
				let _weight = total.weight
				if (_i.order_items) {
					_weight += _i.order_items.find((_ix) => _ix.product_id === item.product_id).serviced_weight
				}
				return { weight: _weight }
			},
			{ weight: 0 }
		).weight
	}

	const getTotalItemAmount = (dataInvoices, productId) => {
		let avg = 0
		dataInvoices?.map((item) => {
			item?.order_items?.map((_i) => {
				if (productId == _i.product_id) {
					avg += _i?.price_per_kg * _i.serviced_weight ?? 0
				}
			})
		})
		return avg
	}

	const { totalItemsAmount, totalLogistics, totalGST, dataInvoices, totalOtherCharges } = useMemo(() => {
		const invoices = data?.buyer_invoices || data?.supplier_invoices || []

		let totalOtherCharges =
			invoices
				?.map((element, i) =>
					Object.keys(element)
						?.map((element2) => otherChargesInvoice?.includes(element2) && element[element2] !== null && { [element2]: element[element2] })
						?.filter((item) => item)
						?.reduce((a, b) => a + +Object.values(b), 0)
				)
				?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0

		let totalItemsAmount = data?.order_items.reduce((amount, item) => amount + item['serviced_weight'], 0)
		// let totalWeight = data
		// let totalLogistics = data.lumpsum_logistics_quote || 0
		// if (data.logistics_quote_per_mt) {
		// 	totalLogistics = (data.logistics_quote_per_mt * totalWeight) / 1000
		// }
		let totalGST = 0
		if (!data) {
			return {
				totalItemsAmount,
				totalOtherCharges,
				// totalLogistics,
				totalGST,
			}
		}

		totalItemsAmount = data?.order_items.reduce((amount, item) => amount + getTotalItemAmount(invoices, item?.product_id), 0)
		if (totalItemsAmount === 0) {
			totalOtherCharges = 0
		}
		// if (totalItemsAmount === 0) {
		// 	totalLogistics = 0
		// } else {
		// 	if (data.logistics_quote_per_mt) {
		// 		totalLogistics =
		// 			(data.order_items.reduce((amount, item) => amount + getServicedWeight(invoices, item), 0) * data.logistics_quote_per_mt) / 1000
		// 	}
		// }
		return {
			totalItemsAmount,
			// totalLogistics,
			// totalGST: (totalItemsAmount + totalLogistics) * 0.18,
			totalGST: (totalItemsAmount + totalOtherCharges) * 0.18,
			dataInvoices: invoices,
			totalOtherCharges,
		}
	}, [data, weightKey])

	const renderItemBreakup = () => {
		return (
			<>
				<Heading>{strings('items_cost')}</Heading>
				{(data?.order_items ?? []).map((_item, index) => {
					const _ordered_weight = _item[weightKey]
					const _serviced_weight = getServicedWeight(dataInvoices, _item)
					let _total_amt = getTotalItemAmount(dataInvoices, _item?.product_id)
					return (
						<Item key={`item-breakup-${index}`}>
							<ProductName>{_item?.product_name}</ProductName>
							<CalculationWrapper>
								<Calculation>
									<Info>{strings('serviced_weight')}</Info>
									<Value>{displayWeight(_serviced_weight / 1000) + ' (' + displayWeight(_ordered_weight / 1000) + ')'}</Value>
								</Calculation>
								<MathIcon>
									<Close />
								</MathIcon>
								<Calculation>
									<Info>{strings('price_per_kg')}</Info>
									<Value>{displayAmount(_total_amt / _serviced_weight)}</Value>
								</Calculation>
								<MathIcon>
									<DragHandle />
								</MathIcon>
								<Calculation>
									<Info>{strings('amount')}</Info>
									<Value>{displayAmount(_total_amt)}</Value>
								</Calculation>
							</CalculationWrapper>
						</Item>
					)
				})}
				<SummaryItem>
					<SummaryItemCount>
						<span>1</span>
					</SummaryItemCount>
					<SummaryItemInfo>{strings('total_items_amount')}</SummaryItemInfo>
					<SummaryItemValue>{displayAmount(totalItemsAmount)}</SummaryItemValue>
				</SummaryItem>
			</>
		)
	}

	// const renderLogistics = () => {
	// 	return (
	// 		<>
	// 			<Heading>{strings('logistics')}</Heading>
	// 			<SummaryItem>
	// 				<SummaryItemCount>
	// 					<span>2</span>
	// 				</SummaryItemCount>
	// 				<SummaryItemInfo>{strings('total_logistics_amount')}</SummaryItemInfo>
	// 				<SummaryItemValue>{displayAmount(totalLogistics)}</SummaryItemValue>
	// 			</SummaryItem>
	// 		</>
	// 	)
	// }

	const totalOtherCharge = () => {
		return (
			<>
				<Heading>{strings(['total', 'other', 'charges'])}</Heading>
				<SummaryItem>
					<SummaryItemCount>
						<span>2</span>
					</SummaryItemCount>
					<SummaryItemInfo>{strings('total_other_charge')}</SummaryItemInfo>
					<SummaryItemValue>{displayAmount(totalOtherCharges)}</SummaryItemValue>
				</SummaryItem>
			</>
		)
	}

	const renderTotalWithTax = () => {
		return (
			<>
				<TaxHeading>
					<span>{strings('gst', 'calculation')}</span>
					<SummaryCalculation>
						<span>[</span>
						<span>(</span>
						<SummaryItemCount>
							<span>1</span>
						</SummaryItemCount>
						<MathIcon>
							<Add />
						</MathIcon>
						<SummaryItemCount>
							<span>2</span>
						</SummaryItemCount>
						<span>)</span>
						<MathIcon>
							<Close />
						</MathIcon>
						<Value>18%</Value>
						<span>]</span>
						{/* <span>(</span>
						<SummaryItemCount>
							<span>1</span>
						</SummaryItemCount>
						<MathIcon>
							<Close />
						</MathIcon>
						<Value>18%</Value>
						<span>)</span> */}
					</SummaryCalculation>
				</TaxHeading>
				<SummaryItem>
					<SummaryItemCount>
						<span>3</span>
					</SummaryItemCount>
					<SummaryItemInfo>{strings('total_gst')}</SummaryItemInfo>
					<SummaryItemValue>{displayAmount(totalGST)}</SummaryItemValue>
				</SummaryItem>
			</>
		)
	}
	const renderTotalAmount = () => {
		return (
			<>
				<TotalAmount>
					<TotalAmountInfo>
						<span>{strings('total_amount')}</span>
						<SummaryCalculation>
							<span>(</span>
							<SummaryItemCount>
								<span>1</span>
							</SummaryItemCount>
							<MathIcon noGrow>
								<Add />
							</MathIcon>
							<SummaryItemCount>
								<span>2</span>
							</SummaryItemCount>
							<MathIcon noGrow>
								<Add />
							</MathIcon>
							<SummaryItemCount>
								<span>3</span>
							</SummaryItemCount>
							<span>)</span>
						</SummaryCalculation>
					</TotalAmountInfo>
					{/* <SummaryItemValue>{displayAmount(totalItemsAmount + totalLogistics + totalGST)}</SummaryItemValue> */}
					<SummaryItemValue>{displayAmount(totalItemsAmount + totalOtherCharges + totalGST)}</SummaryItemValue>
				</TotalAmount>
			</>
		)
	}
	return (
		<>
			{renderItemBreakup()}
			{/* {renderLogistics()} */}
			{totalOtherCharge()}
			{renderTotalWithTax()}
			{showTotal && renderTotalAmount()}
		</>
	)
}

export default Summary
