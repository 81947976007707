import { PageLevelTableWrapper, TOAST_TYPE, Table, useTableReducer } from '@base'
import { strings, transactionTypeDuePayments } from '@constants'
import { getduePaymentsReport } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { paths } from '@routes'
import { removeTrailingSlash } from '@utils'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

const DuePayments = () => {
	const globalDispatch = useDispatch()
	const [state, dispatch, actions] = useTableReducer()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const currentPath = removeTrailingSlash(pathname)

	useEffect(() => {
		dispatch(actions.fetchTableDataProgress())
		let transactionType = currentPath === paths.admin_advance_to_supplier ? transactionTypeDuePayments.purchase : null
		getduePaymentsReport(transactionType)
			.then((response) => {
				dispatch(actions.fetchTableDataSuccess(response, response.count))
			})
			.catch((err) => {
				dispatch(actions.fetchTableDataFailure(err))
				globalDispatch(showToast(true, strings('msg_failed_to_fetch'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				globalDispatch(showLoader(false))
			})
	}, [state.query, pathname])

	const columns = useMemo(
		currentPath !== paths.admin_advance_to_supplier
			? () => [
					{
						label: strings('id'),
						key: 'organisation_id',
						mobile: true,
					},
					{
						label: strings('organisation'),
						key: 'organisation',
						mobile: true,
					},
					{
						label: strings('current'),
						key: 'current',
						mobile: true,
					},
					{
						label: strings('0-7'),
						key: '0-7',
						mobile: true,
					},
					{
						label: strings('7-15'),
						key: '7-15',
						mobile: false,
					},
					{
						label: '15-30',
						key: '15-30',
						mobile: false,
					},
					{
						label: '30-45',
						key: '30-45',
						mobile: false,
					},
					{
						label: '45+',
						key: '45+',
						mobile: false,
					},
					{
						label: strings('total'),
						key: 'total',
						mobile: true,
					},
					{
						label: strings('interest'),
						key: 'interest',
						mobile: false,
					},
					{
						label: strings('sales', 'person'),
						key: 'sales_person',
						mobile: true,
					},
					{
						label: strings('region'),
						key: 'region',
						mobile: false,
					},
					{
						label: strings('platform'),
						key: 'platform',
						mobile: false,
					},
			  ]
			: () => [
					{
						label: strings('id'),
						key: 'organisation_id',
						mobile: true,
					},

					{
						label: strings('organisation'),
						key: 'organisation',
						mobile: true,
					},

					{
						label: strings('total'),
						key: 'total',
						mobile: true,
					},

					{
						label: strings('supply', 'person'),
						key: 'supply_person',
						mobile: true,
					},
					{
						label: strings('region'),
						key: 'region',
						mobile: false,
					},
					{
						label: strings('platform'),
						key: 'platform',
						mobile: false,
					},
					{
						label: strings(['last', 'Payment', 'date']),
						key: 'lastPaymentDate',
						mobile: false,
					},
			  ],
		[pathname]
	)
	const rowClickHandler = (rowData) => {
		rowData?.organisation_id && navigate(paths.admin_organisation_ledger(rowData.organisation_id))
	}

	return (
		<PageLevelTableWrapper>
			<Table
				fullHeight
				data={state.data}
				columns={columns}
				loading={state.loading}
				totalItemCount={state.total_count}
				page={state.query.page}
				hideFooter={true}
				rowClickable={true}
				onRowClick={rowClickHandler}
			/>
		</PageLevelTableWrapper>
	)
}

export default DuePayments
