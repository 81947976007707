import styled, { css } from 'styled-components'
import { Devices, Spacings } from '@styles'
import { FloatingButton, NavigationWrapper } from '@base'

export const Wrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
`
export const ButtonWrapper = styled.div`
	display: flex;
	margin-left: ${Spacings.SPACING_1B};
`

export const TopNavigationWrapper = styled(NavigationWrapper)`
	display: flex;
	justify-content: space-between;
`
export const ButtonIconWapper = styled.div`
	display: flex;
	align-items: center;

	& > span {
		margin: ${Spacings.SPACING_1B};
	}
`
export const FloatingButtonWapper = styled(FloatingButton)`
	min-width: ${Spacings.SPACING_8B};
`
export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	& > div {
		width: 100%;
	}
	${({ isFullWidth, isScore }) => {
		if (isFullWidth) {
			return css`
				width: 100%;
				margin-right: ${Spacings.SPACING_1B};
			`
		}
		if (isScore) {
			return css`
				margin-right: ${Spacings.SPACING_1B};
			`
		}
	}}
`

export const BaseWrapper = styled.div`
	display: flex;
	height: 100%;
	gap: 1rem;
	flex-direction: column;
	@media ${Devices.mobile} {
		flex-direction: row;
	}
`
export const ContainerWrapper = styled.div`
	padding: ${({ isPadding }) => (isPadding ? `${Spacings.SPACING_4B}` : `0 ${Spacings.SPACING_4B}`)};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
		margin-bottom: ${Spacings.SPACING_4B};
	}
	flex-grow: 1;
`
export const PsuedoWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B} 0 -${Spacings.SPACING_32B};
	}
`
export const FooterWapper = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
`
