import API from '../api'
import putUrl from './paths'

export const editCommodityApi = (commodity) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editCommodity, commodity)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
