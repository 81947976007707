import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { Button } from '@base'
import EditForm from './Form'
import { updateSalesTeamFeedback } from '@data'
import { showLoader, showToast } from '@data/state/action'

const initialFormState = {
	email: {
		label: 'Email',
		disabled: false,
		value: '',
		dirty: false,
	},
	accounts_email: {
		label: 'Accounts Email',
		disabled: false,
		value: '',
		dirty: false,
	},
	contact: {
		label: 'Contact',
		disabled: false,
		value: '',
		dirty: false,
	},
	accounts_contact: {
		label: 'Accounts Contact',
		disabled: false,
		value: '',
		dirty: false,
	},
	corporate_office: {
		label: 'Corporate Office',
		disabled: false,
		value: '',
		dirty: false,
	},
	site: {
		label: 'Site',
		disabled: false,
		value: '',
		dirty: false,
	},
	basis_industry_classification: {
		label: 'Basis Industry Classification',
		disabled: false,
		value: '',
		dirty: false,
	},
	claimed_turnover: {
		label: 'Claimed Turnover',
		disabled: false,
		value: '',
		dirty: false,
	},
	supplier_payment_terms: {
		label: 'Payment Terms',
		disabled: false,
		value: '',
		dirty: false,
	},
	supplier_monthly_procurement: {
		label: 'Monthly Procurement',
		disabled: false,
		value: '',
		dirty: false,
	},
	supplier_past_issues: {
		label: 'Past Issues',
		disabled: false,
		value: '',
		dirty: false,
	},
	supplier_2_payment_terms: {
		label: 'Payment Terms',
		disabled: false,
		value: '',
		dirty: false,
	},
	supplier_2_monthly_procurement: {
		label: 'Monthly Procurement',
		disabled: false,
		value: '',
		dirty: false,
	},
	supplier_2_past_issues: {
		label: 'Past Issues',
		disabled: false,
		value: '',
		dirty: false,
	},
	business_vintage: {
		label: 'Business Vintage',
		disabled: false,
		value: '',
		dirty: false,
	},
	pep: {
		label: 'PEP (Y/N)',
		disabled: false,
		value: '',
		dirty: false,
	},
	age: {
		label: 'Age',
		disabled: false,
		value: '',
		dirty: false,
	},
	sales_team_experience_custome: {
		label: 'Sales team experience with the customer',
		disabled: false,
		value: '',
		dirty: false,
	},
	background_aggregators: {
		label: 'Background from other aggregators',
		disabled: false,
		value: '',
		dirty: false,
	},
	heading: [],
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }
	const feedbackData = data?.sales_team_feedback

	derivedState.email.value = data?.email ?? ''
	derivedState.accounts_email.value = data?.accounts_email ?? ''
	derivedState.contact.value = data?.contact ?? ''
	derivedState.accounts_contact.value = data?.accounts_contact ?? ''

	derivedState.corporate_office.value = feedbackData?.[0]?.sales_team_comment[0]?.value ?? ''

	derivedState.site.value = feedbackData?.[1]?.sales_team_comment[0]?.value ?? ''

	derivedState.basis_industry_classification.value = feedbackData?.[2]?.sales_team_comment[0]?.value ?? ''

	derivedState.claimed_turnover.value = feedbackData?.[3]?.sales_team_comment[0]?.value ?? ''

	derivedState.supplier_payment_terms.value = feedbackData?.[4]?.sales_team_comment[0]?.value ?? ''

	derivedState.supplier_monthly_procurement.value = feedbackData?.[4]?.sales_team_comment[1]?.value ?? ''

	derivedState.supplier_past_issues.value = feedbackData?.[4]?.sales_team_comment[2]?.value ?? ''

	derivedState.supplier_2_payment_terms.value = feedbackData?.[5]?.sales_team_comment[0]?.value ?? ''

	derivedState.supplier_2_monthly_procurement.value = feedbackData?.[5]?.sales_team_comment[1]?.value ?? ''

	derivedState.supplier_2_past_issues.value = feedbackData?.[5]?.sales_team_comment[2]?.value ?? ''

	derivedState.business_vintage.value = feedbackData?.[6]?.sales_team_comment[0]?.value ?? ''

	derivedState.pep.value = feedbackData?.[6]?.sales_team_comment[1]?.value ?? ''

	derivedState.age.value = feedbackData?.[6]?.sales_team_comment[2]?.value ?? ''

	derivedState.sales_team_experience_custome.value = feedbackData?.[7]?.sales_team_comment[0]?.value ?? ''

	derivedState.background_aggregators.value = feedbackData?.[7]?.sales_team_comment[1]?.value ?? ''

	derivedState.heading = feedbackData?.map((item) => item?.criteria_name)

	return derivedState
}

const salesTeamFeedback = (formData, salesTeamData) => {
	return [
		{
			criteria_name: salesTeamData[0]?.criteria_name,
			scoring_criteria: salesTeamData[0]?.scoring_criteria,
			sales_team_comment: [
				{
					name: 'root',
					value: formData?.corporate_office?.value,
				},
			],
			max_score: salesTeamData[0]?.max_score,
			score: salesTeamData[0]?.score,
			comments: salesTeamData[0]?.comments,
		},
		{
			criteria_name: salesTeamData[1]?.criteria_name,
			scoring_criteria: salesTeamData[1]?.scoring_criteria,
			sales_team_comment: [
				{
					name: 'root',
					value: formData?.site?.value,
				},
			],
			max_score: salesTeamData[1]?.max_score,
			score: salesTeamData[1]?.score,
			comments: salesTeamData[1]?.comments,
		},
		{
			criteria_name: salesTeamData[2]?.criteria_name,
			scoring_criteria: salesTeamData[2]?.scoring_criteria,
			sales_team_comment: [
				{
					name: 'root',
					value: formData?.basis_industry_classification?.value,
				},
			],
			max_score: salesTeamData[2]?.max_score,
			score: salesTeamData[2]?.score,
			comments: salesTeamData[2]?.comments,
		},
		{
			criteria_name: salesTeamData[3]?.criteria_name,
			scoring_criteria: salesTeamData[3]?.scoring_criteria,
			sales_team_comment: [
				{
					name: 'root',
					value: formData?.claimed_turnover?.value,
				},
			],
			max_score: salesTeamData[3]?.max_score,
			score: salesTeamData[3]?.score,
			comments: salesTeamData[3]?.comments,
		},
		{
			criteria_name: salesTeamData[4]?.criteria_name,
			scoring_criteria: salesTeamData[4]?.scoring_criteria,
			sales_team_comment: [
				{
					name: salesTeamData[4]?.sales_team_comment[0]?.name,
					value: formData?.supplier_payment_terms?.value,
				},
				{
					name: salesTeamData[4]?.sales_team_comment[1]?.name,
					value: formData?.supplier_monthly_procurement?.value,
				},
				{
					name: salesTeamData[4]?.sales_team_comment[2]?.name,
					value: formData?.supplier_past_issues?.value,
				},
			],
			max_score: salesTeamData[4]?.max_score,
			score: salesTeamData[4]?.score,
			comments: salesTeamData[4]?.comments,
		},
		{
			criteria_name: salesTeamData[5]?.criteria_name,
			scoring_criteria: salesTeamData[5]?.scoring_criteria,
			sales_team_comment: [
				{
					name: salesTeamData[5]?.sales_team_comment[0]?.name,
					value: formData?.supplier_2_payment_terms?.value,
				},
				{
					name: salesTeamData[5]?.sales_team_comment[1]?.name,
					value: formData?.supplier_2_monthly_procurement?.value,
				},
				{
					name: salesTeamData[5]?.sales_team_comment[2]?.name,
					value: formData?.supplier_2_past_issues?.value,
				},
			],
			max_score: salesTeamData[5]?.max_score,
			score: salesTeamData[5]?.score,
			comments: salesTeamData[5]?.comments,
		},
		{
			criteria_name: salesTeamData[6]?.criteria_name,
			scoring_criteria: salesTeamData[6]?.scoring_criteria,
			sales_team_comment: [
				{
					name: salesTeamData[6]?.sales_team_comment[0]?.name,
					value: formData?.business_vintage?.value,
				},
				{
					name: salesTeamData[6]?.sales_team_comment[1]?.name,
					value: formData?.pep?.value,
				},
				{
					name: salesTeamData[6]?.sales_team_comment[2]?.name,
					value: formData?.age?.value,
				},
			],
			max_score: salesTeamData[6]?.max_score,
			score: salesTeamData[6]?.score,
			comments: salesTeamData[6]?.comments,
		},
		{
			criteria_name: salesTeamData[7]?.criteria_name,
			scoring_criteria: salesTeamData[7]?.scoring_criteria,
			sales_team_comment: [
				{
					name: salesTeamData[7]?.sales_team_comment[0]?.name,
					value: formData?.sales_team_experience_custome?.value,
				},
				{
					name: salesTeamData[7]?.sales_team_comment[1]?.name,
					value: formData?.background_aggregators?.value,
				},
			],
			max_score: salesTeamData[7]?.max_score,
			score: salesTeamData[7]?.score,
			comments: salesTeamData[7]?.comments,
		},
	]
}

const SalesForm = ({ salesData, editable = true, feedbackType, update }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [formState, setFormState] = useState(initialFormState)

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, salesData, !!editable))
	}, [editable])

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const buttonHandler = () => {
		dispatch(showLoader(true))
		updateSalesTeamFeedback(
			salesTeamFeedback(formState, salesData?.sales_team_feedback),
			salesData?.pan,
			salesData?.report_id,
			feedbackType,
			user?.user_id,
			formState?.email?.value,
			formState?.accounts_email?.value,
			formState?.contact?.value,
			formState?.accounts_contact?.value
		)
			.then((response) => {
				dispatch(showToast(true, 'Sales Feedback successfully Update', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const renderButtons = () => {
		const checkDisabled =
			formState?.email?.value !== '' ||
			formState?.accounts_email?.value !== '' ||
			formState?.contact?.value !== '' ||
			formState?.accounts_contact?.value !== '' ||
			formState?.corporate_office?.value !== '' ||
			formState?.site?.value !== '' ||
			formState?.basis_industry_classification?.value !== '' ||
			formState?.claimed_turnover?.value !== '' ||
			formState?.supplier_payment_terms?.value !== '' ||
			formState?.supplier_monthly_procurement?.value !== '' ||
			formState?.supplier_past_issues?.value !== '' ||
			formState?.supplier_2_payment_terms?.value !== '' ||
			formState?.supplier_2_monthly_procurement?.value !== '' ||
			formState?.supplier_2_past_issues?.value !== '' ||
			formState?.business_vintage?.value !== '' ||
			formState?.pep?.value !== '' ||
			formState?.age?.value !== '' ||
			formState?.sales_team_experience_custome?.value !== '' ||
			formState?.background_aggregators?.value !== ''

		return (
			<>
				<Button small type='primary' disabled={!checkDisabled} onClick={buttonHandler}>
					{strings('save')}
				</Button>
			</>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<EditForm state={formState} updateState={updateFormState} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default SalesForm
