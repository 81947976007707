import API from '../api'
import postUrl from './paths'

export const addInvoicesUsingReference = (userId, orderId, referenceNumber) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.fetchInvoices, {}, { orderId, userId, referenceNumber })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const fetchLogisticsBill = (billNo, user, loggedInUser) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.fetchLogisticsBill, user, { billNo, userId: loggedInUser })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err?.response?.data))
	})
