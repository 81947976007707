import styled from 'styled-components'
import { Devices, Spacings } from '@styles'
import { BaseWrapper } from '@base'

export const SearchWrapper = styled.div`
	display: flex;
	padding-left: ${Spacings.SPACING_4B};
	margin-left: auto;
	justify-content: flex-end;
`

export const FooterWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B};
	}
`

export const Wrapper = styled(BaseWrapper)`
	display: flex;
	flex-direction: column;
	overflow-y:scroll;
	&::-webkit-scrollbar {
			display: none;
		}
		/* Hide scrollbar for IE, Edge and Firefox */
		& {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
`