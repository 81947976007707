import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { NavigationWrapper, OutletWrapper, Tabs } from '@base'
import { useForceUpdate, useHeaderMeta } from '@hooks'
import { adminToolsDataCorrection } from '@utils'
import { strings } from '@constants'
import Ledger from './Ledger'

export const Wrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
`

const DataNavigationWrapper = styled(NavigationWrapper)`
	display: flex;
`

const tabsInfo = [strings('ledger')]

const DataCorrection = () => {
	const [selectedTab, setSelectedTab] = useState(0)
	const [forceUpdate, update] = useForceUpdate()
	useHeaderMeta(adminToolsDataCorrection)

	const tabs = useMemo(() => {
		return tabsInfo.map((_t, index) => ({
			label: _t,
			selected: index === selectedTab,
			onClick: () => setSelectedTab(index),
		}))
	}, [selectedTab])

	return (
		<Wrapper>
			<DataNavigationWrapper>
				<Tabs data={tabs} />
			</DataNavigationWrapper>
			<OutletWrapper>{selectedTab === 0 && <Ledger forceUpdate={forceUpdate} update={update} />}</OutletWrapper>
		</Wrapper>
	)
}

export default DataCorrection
