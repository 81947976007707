import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { DrawerBodyWrapper, DrawerFooterWrapper, SectionHeader, Select, BaseWrapper, Banner, Button } from '@base'
import { Devices, Spacings } from '@styles'
import { closingReason, strings } from '@constants'
import { editEnquiry } from '@data'
import { showLoader } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`
const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
const StepWrapper = styled(BaseWrapper)`
	flex-grow: 1;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	${({ isFlex }) => {
		if (isFlex) {
			return css`
				display: flex;
				flex-direction: column;
			`
		}
	}}
`

const StepTopWrapper = styled(StepWrapper)`
	padding: 0;
	flex-grow: 0;
	margin-bottom: ${Spacings.SPACING_2B};
	display: flex;

	button {
		min-width: fit-content;
	}
`

const BannerText = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

const ClosingReasonForm = ({ enquiry, update, close }) => {
	const dispatch = useDispatch()
	const closingReasons = Object.entries(closingReason).map(([key, value]) => ({ key, label: value }))
	const [closingReasonStatus, setClosingReasonStatus] = useState(closingReasons?.find((_s) => _s.label === enquiry?.closing_reason) || {})
	const changeRejectionReason = (value) => {
		setClosingReasonStatus(value)
	}

	const user = useSelector((_state) => _state.getIn(['user', 'user']))

	const updateHandler = () => {
		dispatch(showLoader(true))
		editEnquiry({ enquiry: { ...enquiry, closing_reason: closingReasonStatus.label }, enquiryClosed: true, user })
			.then((_res) => {
				update()
			})
			.catch((err) => console.log(err))
			.finally(() => {
				closeHandler()
				dispatch(showLoader(false))
			})
	}

	const closeHandler = () => {
		close()
		dispatch(toggleDrawer(false))
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<SectionItemWrapper>
						<StepTopWrapper>
							<Banner
								extendInMobile
								text={
									<BannerText>
										<span>{strings('msg_update_colse_reason_suppliers_rejection_reson')}</span>
									</BannerText>
								}
							/>
						</StepTopWrapper>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<Select
							label={strings('closing', 'reason')}
							id='closingReason-status-select'
							placeholder={strings('select_closing_reason')}
							data={closingReasons}
							displayKey='label'
							primaryKey='key'
							value={closingReasonStatus}
							onChange={changeRejectionReason}
							isTopPadding
						/>
					</SectionItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<ActionButton small disabled={enquiry?.closing_reason} type='primary' onClick={updateHandler}>
					{strings('update')}
				</ActionButton>
				<ActionButton small type='tertiary' onClick={closeHandler}>
					{strings('cancel')}
				</ActionButton>
			</DrawerFooterWrapper>
		</>
	)
}

export default ClosingReasonForm
