import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Spacings } from '@styles'
import { DrawerBodyWrapper, Input, SectionHeader, Button, DrawerFooterWrapper, Calendar, TOAST_TYPE } from '@base'
import { updateFabricationInvoice } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer, toggleFileViewer, updateDrawerMeta } from '@data/state/action/root'
import { strings } from '@constants'

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}
`

const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`

const deriveStateFromData = (data) => {
	const _state = {
		invoice_amount: {
			label: strings('invoice', 'amount'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings('placeholder_enter_invoice_amount'),
		},
		invoice_number: {
			label: strings('invoice', 'number'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings('placeholder_enter_invoice_number'),
		},
		invoice_date: {
			label: strings('invoice', 'date'),
			value: '',
			disabled: false,
			placeholder: strings('placeholder_enter_invoice_date'),
		},
	}

	if (!data) return _state

	_state.invoice_amount.value = data?.invoice_amount
	_state.invoice_number.value = data?.original_invoice_number || data?.invoice_number
	_state.invoice_date.value = data?.invoice_date
	return _state
}
const AdminEditFactoryInvoice = memo(({ invoiceData, orderId, update }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())

	useEffect(() => {
		setFormState((_) => deriveStateFromData(invoiceData))
	}, [invoiceData])

		useEffect(() => {
			dispatch(
				updateDrawerMeta({
					label: (
						<>
							{strings(['factory', 'invoice', 'details'])}
							<ActionButton
								small
								uppercase
								type='secondary'
								onClick={() => {
									if (invoiceData?.invoice_document) {
										dispatch(toggleFileViewer(true, { files: invoiceData.invoice_document }))
									}
								}}
							>
								{strings('view', 'invoice')}
							</ActionButton>
						</>
					),
				})
			)
		}, [dispatch, invoiceData])

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onChange = (key) => (value) => updateState(key, { value })

	const disabledUpdateButton = useMemo(() => {
		if (!invoiceData) return true
		const nonDisabledEntries = Object.entries(formState).filter(([key, val]) => !val.disabled)

		return (
			nonDisabledEntries.some(([key, val]) => val.required && !val.value) ||
			nonDisabledEntries.every(([key, val]) => val.value === invoiceData[key])
		)
	}, [formState, invoiceData])

	const updateInvoiceInfo = () => {
		if (disabledUpdateButton) return

		dispatch(showLoader(true))

		updateFabricationInvoice(
			formState.invoice_amount.value,
			formState.invoice_date.value ?? new Date().getTime(),
			invoiceData.invoice_number,
			orderId,
			formState.invoice_number.value
		)
			.then((response) => {
				dispatch(showToast(true, strings('msg_invoice_details_updated'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('msg_failed_to_update_invoice_details'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<FormItemWrapper>
						<Input
							type='text'
							label={formState.invoice_number?.label}
							disabled={formState.invoice_number?.disabled}
							value={formState.invoice_number?.value}
							placeholder={formState.invoice_number?.placeholder}
							onChange={onChange('invoice_number')}
							required={formState.invoice_number?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Calendar
							label={formState.invoice_date?.label}
							disabled={formState.invoice_date?.disabled}
							value={formState.invoice_date?.value}
							placeholder={formState.invoice_date?.placeholder}
							onChange={onChange('invoice_date')}
							required={formState.invoice_date?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Input
							type='number'
							label={formState.invoice_amount?.label}
							disabled={formState.invoice_amount?.disabled}
							value={formState.invoice_amount?.value}
							placeholder={formState.invoice_amount?.placeholder}
							onChange={onChange('invoice_amount')}
							required={formState.invoice_amount?.required}
						/>
					</FormItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<ActionButton type='primary' small uppercase disabled={disabledUpdateButton} onClick={updateInvoiceInfo}>
					{strings('update')}
				</ActionButton>
			</DrawerFooterWrapper>
		</>
	)
})

export default AdminEditFactoryInvoice
