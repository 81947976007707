import API from "../api";
import getUrl from "./paths";

export const getCreditLimitByOrdId = (orgId) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getCreditLimitByOrdId, {orgId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })