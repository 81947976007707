import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { ViewButton, ViewButtonText } from './styles'
import {
	getReportById,
	fetchPastRecordsFromZoho,
	restartBankStatementAnalysis,
	uploadProcessFile,
	initiateGenerateReport,
	fetchBSAExcelReport,
} from '@data'
import { showLoader, showToast } from '@data/state/action'
import StatusForm from './StatusForm'
import { useNavigate } from 'react-router'
import { paths } from '@routes'
import { Assessment, Cached, Visibility } from '@material-ui/icons'
import { updateDrawerMeta } from '@data/state/action/root'

const initialFormState = {
	pan: {
		label: strings(['gst', '/', 'pan']),
		disabled: true,
		value: '',
		dirty: false,
	},
	statement: {
		label: strings(['select', 'statement']),
		disabled: false,
		value: '',
		dirty: false,
	},
	bankName: {
		label: strings('select', 'bank'),
		disabled: true,
		value: '',
		dirty: false,
	},
	fromDate: {
		id: 'from',
		label: strings('from'),
		disabled: true,
		value: '',
		show: true,
	},
	toDate: {
		id: 'to',
		label: strings('to'),
		disabled: true,
		value: '',
		show: true,
	},
	isEStatement: {
		label: strings('e_statement'),
		disabled: false,
		value: '',
		dirty: false,
	},
	bsa_download_status: '',
	mca_download_status: '',
	perfios_status: '',
	bsaScore: '',
	fsaScore: '',
	// customerId: {
	// 	label: strings('zoho_customer'),
	// 	disabled: false,
	// 	value: '',
	// 	dirty: false,
	// },
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }
	derivedState.pan.value = data?.pan ?? ''
	derivedState.pan.disabled = !editable

	derivedState.bankName.value = data?.bsa_report?.bank_documents[0]?.institution_id ?? ''
	derivedState.bankName.disabled = !editable

	derivedState.fromDate.value = data?.bsa_report?.from_date
		? addMonths(new Date(data?.bsa_report?.from_date))?._fromDate
		: addMonths(new Date(), -5)?._fromDate
	derivedState.toDate.value = data?.bsa_report?.to_date ? addMonths(new Date(data?.bsa_report?.to_date))?._toDate : addMonths(new Date())?._toDate

	derivedState.isEStatement.value = data?.bsa_report?.is_estatement ?? true
	derivedState.isEStatement.disabled = !editable

	derivedState.bsa_download_status = data?.bsa_download_status ?? null
	derivedState.bsaScore = data?.bsa_report?.score ?? null
	derivedState.mca_download_status = data?.mca_download_status ?? null
	derivedState.fsaScore = data?.fsa_report?.score ?? null
	// derivedState.customerId.value = data?.zoho_customer_id ?? null
	derivedState.perfios_status = data?.perfios_status ?? null
	// derivedState.customerId.disabled = (data?.zoho_customer_id || data?.past_records_status) ?? false

	return derivedState
}

function addMonths(date, months = 0) {
	date.setMonth(date.getMonth() + months)
	let _toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12, 1, 1)

	let _fromDate = new Date(date.getFullYear(), date.getMonth(), 1, 12, 1, 1)
	return { _fromDate, _toDate }
}

const UnderwritingStatus = ({ reportDetails, label, editable = true, bankDetails, pastType, update }) => {
	const [formState, setFormState] = useState(initialFormState)
	const [_reportDetails, setReportDetails] = useState(reportDetails)
	const [documentUploading, setDocumentUploading] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const maxDate = addMonths(new Date(), -5)?._fromDate || new Date()

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, reportDetails, !!editable))
	}, [editable])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: pastType ? reportDetails?.pan : label,
			})
		)
	}, [dispatch, pastType, reportDetails, label])

	const buttonHandler = () => {
		setDocumentUploading(true)
		getReportById(reportDetails?.pan, reportDetails?.report_id)
			.then((res) => {
				setFormState((_oldState) => deriveFormStateFromItemData(_oldState, { ...res, pan: reportDetails?.pan }, !!editable))
				dispatch(showToast(true, 'Status Update', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `Status not Update ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				typeof update === 'function' && update()
				setDocumentUploading(false)
			})
	}

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const disableButton = () => {
		if (documentUploading) return true
		return false
	}

	const viewButtonHandler = () => {
		navigate(`${paths.admin_underwriting_view}?pan=${reportDetails?.pan}&report_id=${reportDetails?.report_id}`)
	}

	const fetchPastRecords = (customer_id) => {
		setDocumentUploading(true)
		dispatch(showLoader(true))
		fetchPastRecordsFromZoho(customer_id, reportDetails?.pan, reportDetails?.report_id, user?.user_id)
			.then((res) => {
				setFormState((_oldState) => deriveFormStateFromItemData(_oldState, { ...res, pan: reportDetails?.pan }, !!editable))
				dispatch(showToast(true, 'Fetch past records successfully', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `Past records not fetch ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				typeof update === 'function' && update()
				setDocumentUploading(false)
				dispatch(showLoader(false))
			})
	}

	const restartBankStatement = () => {
		setDocumentUploading(true)
		dispatch(showLoader(true))
		restartBankStatementAnalysis(reportDetails?.pan, reportDetails?.report_id, user?.user_id)
			.then((res) => {
				setFormState((_oldState) => deriveFormStateFromItemData(_oldState, { ...res, pan: reportDetails?.pan }, !!editable))
				dispatch(showToast(true, 'Restart bank statement successfully', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `Bank statement not restart ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				typeof update === 'function' && update()
				setDocumentUploading(false)
				dispatch(showLoader(false))
			})
	}

	const uploadStatentHandler = () => {
		dispatch(showLoader(true))
		uploadProcessFile(
			formState.statement?.value,
			formState.pan?.value,
			reportDetails?.report_id,
			formState.bankName?.value,
			formState.fromDate?.value.getTime(),
			formState.toDate?.value.getTime(),
			formState.isEStatement?.value
		)
			.then((res) => {
				setFormState((_oldState) => deriveFormStateFromItemData(_oldState, { ...res, pan: reportDetails?.pan }, !!editable))
				setReportDetails({ ..._reportDetails, ...res })
				dispatch(showToast(true, 'Statement upload successfully', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `Past records not fetch ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				typeof update === 'function' && update()
				dispatch(showLoader(false))
			})
	}

	const initiateGenerateReportAPI = () => {
		dispatch(showLoader(true))
		initiateGenerateReport(formState.pan?.value, reportDetails?.report_id)
			.then((res) => {
				setFormState((_oldState) =>
					deriveFormStateFromItemData(
						_oldState,
						{ ...res, pan: reportDetails?.pan, perfios_status: res?.bsa_report?.perfios_status },
						!!editable
					)
				)
				dispatch(showToast(true, 'Generate report successfully', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `Generate report not fetch ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				typeof update === 'function' && update()
				dispatch(showLoader(false))
			})
	}

	const downloadStatement = () => {
		fetchBSAExcelReport(reportDetails?.pan, reportDetails?.report_id)
			.then((response) => {})
			.catch((err) => console.log(err))
	}

	const renderButtons = () => {
		if (!pastType) {
			return (
				<>
					<ViewButton xs type='primary' onClick={viewButtonHandler}>
						<ViewButtonText>
							<Visibility fontSize='small' />
							<span> {strings('view')}</span>
						</ViewButtonText>
					</ViewButton>

					<ViewButton xs type='primary' disabled={disableButton()} onClick={buttonHandler}>
						<ViewButtonText>
							<Cached fontSize='small' />
							<span> {strings('check', 'status')}</span>
						</ViewButtonText>
					</ViewButton>
					<ViewButton xs type='primary' onClick={downloadStatement}>
						<ViewButtonText>
							<Assessment fontSize='small' />
						</ViewButtonText>
					</ViewButton>
				</>
			)
		}
	}

	return (
		<>
			<DrawerBodyWrapper>
				<StatusForm
					maxDate={maxDate}
					state={formState}
					updateState={updateFormState}
					bankDetails={bankDetails}
					addMonths={addMonths}
					reportDetails={_reportDetails}
					fetchPastRecords={fetchPastRecords}
					restartBankStatement={restartBankStatement}
					uploadStatentHandler={uploadStatentHandler}
					initiateGenerateReportAPI={initiateGenerateReportAPI}
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default UnderwritingStatus
