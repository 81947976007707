import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Checkbox, Select, Input } from '@base'
import { Spacings } from '@styles'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`

const LogisticsItemForm = React.memo(({ state, updateState }) => {
	const { category, product, requiredWeight, brand, message, tcRequired } = state
	const [weightInMT, setWeight] = useState(requiredWeight?.value / 1000 || '')

	const onChange = (key) => (value) => {
		if (key === 'requiredWeight') {
			setWeight(value)
			value = value * 1000
		}

		updateState(key, { value, dirty: true })
	}

	const onCheckBoxChange = (e) => onChange('tcRequired')(e.target.checked)

	useEffect(() => {
		setWeight(requiredWeight.value / 1000 || '')
	}, [requiredWeight.value])

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Select
					id='enquiry-category-select'
					disabled={category?.disabled}
					label={category?.label}
					displayKey={category?.displayKey}
					primaryKey={category?.primaryKey}
					placeholder={category?.placeholder}
					data={category?.data}
					value={category?.value}
					onChange={onChange('category')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Select
					id='enquiry-product-select'
					disabled={product?.disabled}
					label={product?.label}
					displayKey={product?.displayKey}
					primaryKey={product?.primaryKey}
					placeholder={product?.placeholder}
					data={product?.data}
					value={product?.value}
					onChange={onChange('product')}
					createNewOption={true}
				/>
			</SectionItemWrapper>
			<SectionItemWrapperWithTwoItems>
				<Input
					type='number'
					disabled={requiredWeight?.disabled}
					label={requiredWeight?.label}
					value={weightInMT}
					onChange={onChange('requiredWeight')}
				/>

				<Select
					id='enquiry-brand-select'
					disabled={brand?.disabled}
					label={brand?.label}
					displayKey={brand?.displayKey}
					primaryKey={brand?.primaryKey}
					placeholder={brand?.placeholder}
					data={brand?.data}
					value={brand?.value}
					onChange={onChange('brand')}
				/>
			</SectionItemWrapperWithTwoItems>
			<SectionItemWrapper>
				<Input type='text' label={message?.label} disabled={message?.disabled} value={message?.value} onChange={onChange('message')} />
			</SectionItemWrapper>
			<SectionItemWrapperWithTwoItems>
				<Checkbox
					id='Checkbox'
					size={1.125}
					disabled={tcRequired?.disabled}
					checked={tcRequired?.value}
					value={tcRequired?.value}
					onChange={onCheckBoxChange}
				/>
				<FormElementLabel disabled={tcRequired?.disabled} htmlFor='Checkbox'>
					{tcRequired?.label}
				</FormElementLabel>
			</SectionItemWrapperWithTwoItems>
		</FormWrapper>
	)
})

export default LogisticsItemForm
