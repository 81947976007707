import { Button, Calendar, DrawerBodyWrapper, TOAST_TYPE } from '@base'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { updateOrganisationApi } from '@data/conn/put/organisation'
import { EditDetailWrapper } from '../styles'
import styled from 'styled-components'
import { strings } from '@constants'

const CalendarWapper = styled.div`
	width: 100%;
`

const EditIncorporationDate = ({ orgDetails, update }) => {
	const dispatch = useDispatch()
	const [incorporationDate, setIncorporationDate] = useState()

	useEffect(() => {
		setIncorporationDate(orgDetails?.incorporation_date || new Date().getTime())
	}, [orgDetails?.incorporation_date])

	const onIncorporationDate = (value) => {
		setIncorporationDate(value)
	}

	const onNameUpdate = () => {
		if (!incorporationDate) return

		dispatch(showLoader(true))
		updateOrganisationApi(orgDetails.organisation_id, 'INCORPORATION_DATE', null, { incorporation_date: incorporationDate })
			.then(() => {
				dispatch(showToast(true, strings(['successfully', 'updated', 'incorporation', 'date']), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while updating incorporation date.', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<EditDetailWrapper>
					<CalendarWapper>
						<Calendar
							placeholder={strings(['select', 'incorporation', 'date'])}
							label={strings('incorporation', 'date')}
							value={incorporationDate}
							onChange={onIncorporationDate}
							maxDate={new Date()}
						/>
					</CalendarWapper>
					<div>
						<Button disabled={!incorporationDate} onClick={onNameUpdate}>
							{strings('update')}
						</Button>
					</div>
				</EditDetailWrapper>
			</DrawerBodyWrapper>
		</>
	)
}

export default EditIncorporationDate
