import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { strings } from '@constants'
import { addInventory, getCategories, getProductByCategory, updateInventory, updateInventoryDetail } from '@data'
import { showToast } from '@data/state/action'
import { toggleDrawer, updateDrawerMeta } from '@data/state/action/root'
import { Spacings } from '@styles'
import { DrawerBodyWrapper, DrawerFooterWrapper, Button, SectionHeader, Select, Input, TOAST_TYPE } from '@base'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const FormItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const initialFormState = {
	category: {
		label: strings('category'),
		disabled: false,
		displayKey: 'category_name',
		primaryKey: 'category_id',
		placeholder: strings('select_a_category'),
		data: [],
		value: '',
		dirty: false,
		shouldFetchProducts: { flag: true },
	},
	product: {
		label: strings('product'),
		disabled: false,
		displayKey: 'product_name',
		primaryKey: 'product_id',
		placeholder: strings('select_a_product'),
		data: [],
		value: '',
		dirty: false,
	},
	capacity: {
		label: strings(['monthly', 'average', 'capacity']),
		placeholder: strings('placeholder_enter_weight_in_mt'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
}

const AddInventory = ({ inventory = [], onInventoryListUpdate }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const commodityFilter = user?.commodity?.find((item) => item?.active)
	const selectedUser = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'selectedUser']))
	const selectedRow = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'selectedRow']))
	const inventoryCreater = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'inventoryCreater']))
	const [formState, setFormState] = useState(initialFormState)
	const [loading, setLoading] = useState(false)
	const disableAddButton = loading || !formState.category.value?.category_id || !formState.product.value?.product_id || !formState.capacity.value

	useEffect(() => {
		setLoading(true)
		getAllCategories()
	}, [])

	useEffect(() => {
		if (selectedRow) {
			getAllCategories(selectedRow.category_id)
			getProductByCategoryId(selectedRow.category_id, selectedRow.product_id)
			updateState('capacity', { value: selectedRow.average_capacity })
		}
	}, [selectedRow])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: selectedRow !== undefined ? strings('update_item_details') : strings('add_item_details'),
			})
		)
	}, [dispatch, selectedRow])

	const getAllCategories = (selectedCatyId = '') => {
		getCategories(0, 100, commodityFilter?.commodity_name)
			.then((response) => {
				if (selectedCatyId != '') {
					const value = response.data.find((item) => item.category_id == selectedCatyId)
					updateState('category', { value, data: response.data, dirty: true, disabled: true })
				} else {
					updateState('category', { data: response.data })
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const getProductByCategoryId = (categoryId, product_id = '') => {
		getProductByCategory(categoryId)
			.then((response) => {
				if (product_id !== '') {
					const value = response.find((item) => item.product_id === selectedRow.product_id)
					updateState('product', { data: response, value, disabled: true })
				} else {
					updateState('product', { data: response })
				}
				return response
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const updateState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
		if (key === 'category') {
			updateState('product', { value: '', data: [], dirty: false })
			getProductByCategoryId(value.category_id)
		}
	}

	const addButtonClick = () => {
		const inventerFilter = selectedRow !== undefined ? inventory.filter((item) => item.product_id !== selectedRow.product_id) : inventory
		const newInventory = {
			average_capacity: Number(formState.capacity.value),
			category_id: formState.category.value.category_id,
			category_name: formState.category.value.category_name,
			product_id: formState.product.value.product_id,
			product_name: formState.product.value.product_name,
		}
		const data =
			inventoryCreater?.id !== ''
				? {
						id: inventoryCreater.id,
						organisation_id: selectedUser.organisation_id, // supplier id
						created_by: inventoryCreater.created_by, // who is created
						updated_by: user.user_id, // who is updated
				  }
				: {
						organisation_id: selectedUser.organisation_id, // supplier id
						created_by: user.user_id, // who is created
						updated_by: user.user_id, // who is updated
				  }
		const organisationId = {
			organisationId: selectedUser.organisation_id, // supplier id
		}
		data.inventory_details = [...inventerFilter, newInventory]
		setLoading(true)
		if(selectedRow!==undefined){
			updateInventoryDetail(newInventory,organisationId)
			.then(onInventoryUpdateSuccess)
			.catch(onInventoryUpdateFailure)
			.finally(()=>{
				setLoading(false)
			})
		}
		else if (inventory?.length) {
			updateInventory(newInventory, organisationId)
				.then(onInventoryUpdateSuccess)
				.catch(onInventoryUpdateFailure)
				.finally(() => {
					setLoading(false)
				})
		} else {
			addInventory(data)
				.then(onInventoryUpdateSuccess)
				.catch(onInventoryUpdateFailure)
				.finally(() => {
					setLoading(false)
				})
		}
	}

	const onInventoryUpdateSuccess = () => {
		dispatch(toggleDrawer(false))
		dispatch(showToast(true, strings('inventory_updated_successfully'), { type: TOAST_TYPE.SUCCESS }))
		if (typeof onInventoryListUpdate === 'function') {
			onInventoryListUpdate()
		}
	}

	const onInventoryUpdateFailure = () => {
		dispatch(showToast(true, strings('msg_failed_update_inventory'), { type: TOAST_TYPE.ERROR }))
	}

	const renderForm = () => (
		<FormWrapper>
			<FormItemWrapper>
				<Select
					id='enquiry-category-select'
					disabled={loading || formState.category?.disabled}
					label={formState.category?.label}
					displayKey={formState.category?.displayKey}
					primaryKey={formState.category?.primaryKey}
					placeholder={formState.category?.placeholder}
					data={formState.category?.data}
					value={formState.category?.value}
					onChange={onChange('category')}
				/>
			</FormItemWrapper>
			<FormItemWrapper>
				<Select
					id='enquiry-product-select'
					disabled={loading || formState.product?.disabled}
					label={formState.product?.label}
					displayKey={formState.product?.displayKey}
					primaryKey={formState.product?.primaryKey}
					placeholder={formState.product?.placeholder}
					data={formState.product?.data}
					value={formState.product?.value}
					onChange={onChange('product')}
				/>
			</FormItemWrapper>
			<FormItemWrapper>
				<Input
					type='number'
					disabled={loading || formState.capacity?.disabled}
					label={formState.capacity?.label}
					value={formState.capacity?.value}
					placeholder={formState.capacity?.placeholder}
					onChange={onChange('capacity')}
				/>
			</FormItemWrapper>
		</FormWrapper>
	)

	return (
		<>
			<DrawerBodyWrapper>{renderForm()}</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase onClick={addButtonClick} disabled={disableAddButton}>
					{selectedRow !== undefined ? strings('update') : strings('add')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddInventory
