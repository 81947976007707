import API from '../api'
import getUrl from './paths'

export const getAllEnquiryPhoto = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllEnquiryPhoto, { size: 100 })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
