import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { strings } from '@constants'
import { DrawerBodyWrapper, DrawerFooterWrapper, Input, TOAST_TYPE } from '@base'
import { ButtonWrapper, DocUploadButton, FormItemWrapper, ProfileActionButton, InfoWrapper } from '../styles'
import { toggleDrawer } from '@data/state/action/root'
import { requestCreditApi, uploadFinancialDocsApi } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { BaseWrapper, Banner } from '@base'
import { Spacings } from '@styles'

export const StepTopWrapper = styled(BaseWrapper)`
	padding: 0;
	flex-grow: 0;
	margin: ${Spacings.SPACING_4B} 0px;
	display: flex;

	button {
		min-width: fit-content;
	}
`

const initialFormState = {
	amount: {
		label: strings('request', 'amount'),
		placeholder: strings('enter_request_amount'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	tenure: {
		label: strings('request', 'tenure'),
		placeholder: strings('enter_request_tenure'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	gstNumber: {
		label: strings('gst', 'number'),
		placeholder: strings('digit_gstin'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	bankStatement: {
		label: strings(['upload', 'bank', 'statement']),
		disabled: false,
		readOnly: false,
		value: [],
		dirty: false,
	},
	bspl: {
		label: strings(['upload', 'bspl', 'statement']),
		disabled: false,
		readOnly: false,
		value: [],
		dirty: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }

	derivedState.amount.value = data?.amount ?? ''
	derivedState.amount.disabled = editable

	derivedState.tenure.value = data?.tenure ?? ''
	derivedState.tenure.disabled = editable

	derivedState.gstNumber.value = data?.gst_number ?? ''
	derivedState.gstNumber.disabled = editable

	derivedState.bankStatement.value = data?.bank_stetment ?? []

	return derivedState
}

const RequestCredit = ({ item, editable = true }) => {
	const [formState, setFormState] = useState(initialFormState)
	const [requestId, setRequestId] = useState()
	const [uploadFinancialRequest, setUploadFinancialRequest] = useState(false)
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, item, !!editable))
	}, [editable])

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const saveHandler = () => {
		dispatch(showLoader(true))
		if (uploadFinancialRequest) {
			uploadFinancialDocsApi(formState?.bspl?.value, requestId)
				.then((res) => {
					dispatch(toggleDrawer(false))
					dispatch(showToast(true, strings('bspl_req_sent'), { type: TOAST_TYPE.SUCCESS }))
				})
				.catch((err) => {
					dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		} else {
			requestCreditApi(
				formState?.gstNumber?.value,
				formState?.amount?.value,
				formState?.tenure?.value,
				formState?.bankStatement?.value,
				user?.user_id
			)
				.then((res) => {
					dispatch(showToast(true, strings('req_credit_sent'), { type: TOAST_TYPE.SUCCESS }))
					const response = JSON.parse(res.data)
					setRequestId(response?.requestId)
					res?.status_code === 203 && setUploadFinancialRequest(true)
				})
				.catch((err) => {
					dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
	}
	const updateState = (inputType, key) => (value) => {
		setFormState((state) => ({
			...state,
			[inputType]: {
				...state[inputType],
				[key]: value,
			},
		}))
	}

	const uploadBankStatementHandler = (files) => {
		const getPaths = Array.from(files).map((f) => f)
		updateState('bankStatement', 'value')(getPaths)
	}

	const uploadbsplHandler = (files) => {
		const getPaths = Array.from(files).map((f) => f)
		updateState('bspl', 'value')(getPaths)
	}

	function disableButton() {
		if (uploadFinancialRequest) {
			return formState?.bspl.value.length !== 0
		} else {
			return formState.amount?.value && formState?.tenure?.value && formState?.gstNumber?.value && formState?.bankStatement.value.length !== 0
		}
	}

	const uploadBsplhandler = () => {
		return (
			<>
				<StepTopWrapper>
					<Banner extendInMobile text={strings('provide_balance_sheet_pl')} />
				</StepTopWrapper>
				<FormItemWrapper>
					<DocUploadButton
						accept={'all'}
						multiple
						label={
							formState?.bspl.value.length > 0
								? strings(['selected', formState?.bspl.value.length, 'bspl', 'statement'])
								: formState?.bspl.label
						}
						xs
						uppercase
						onChange={(file) => uploadbsplHandler(file)}
					/>
				</FormItemWrapper>
			</>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<StepTopWrapper>
					<Banner extendInMobile text={strings('sanctioned_credit_facility_charged')} />
				</StepTopWrapper>
				<InfoWrapper>
					<Input
						label={formState?.gstNumber.label}
						id='org-gstin'
						type='text'
						pattern='\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}'
						errorMap={{
							patternMismatch: strings('enter_digit_gstin'),
							valueMissing: strings('gstin_required'),
						}}
						disabled={!editable || uploadFinancialRequest}
						value={formState?.gstNumber?.value}
						onChange={updateState('gstNumber', 'value')}
						setValidity={updateState('gstNumber', 'valid')}
						placeholder={formState?.gstNumber.placeholder}
						uppercase
						required
					/>
				</InfoWrapper>
				<InfoWrapper>
					<Input
						label={formState?.amount.label}
						id='sanction-amount'
						type='number'
						disabled={!editable || uploadFinancialRequest}
						value={formState?.amount?.value}
						onChange={updateState('amount', 'value')}
						setValidity={updateState('amount', 'valid')}
						placeholder={formState?.amount.placeholder}
						errorMap={{
							valueMissing: `${strings('sanction_required')}`,
						}}
						required
					/>
				</InfoWrapper>
				<InfoWrapper>
					<Input
						label={formState?.tenure.label}
						id='sanction-tenure'
						type='number'
						max={60}
						disabled={!editable || uploadFinancialRequest}
						value={formState?.tenure?.value}
						onChange={updateState('tenure', 'value')}
						setValidity={updateState('tenure', 'valid')}
						placeholder={formState?.tenure.placeholder}
						errorMap={{
							valueMissing: `${strings('tenure_required')}`,
							rangeOverflow: `${strings('max_60_days_allowed')}`,
						}}
						required
					/>
				</InfoWrapper>
				<FormItemWrapper>
					<DocUploadButton
						disabled={uploadFinancialRequest}
						accept={'all'}
						multiple
						label={
							formState?.bankStatement.value.length > 0
								? strings(['selected', formState?.bankStatement.value.length, 'bank', 'statement'])
								: formState?.bankStatement.label
						}
						xs
						uppercase
						onChange={(file) => uploadBankStatementHandler(file)}
					/>
				</FormItemWrapper>
				{uploadFinancialRequest && uploadBsplhandler()}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<ButtonWrapper>
					<ProfileActionButton small uppercase type='tertiary' onClick={cancelHandler}>
						{strings('cancel')}
					</ProfileActionButton>
					<ProfileActionButton small uppercase disabled={!disableButton()} onClick={saveHandler}>
						{strings('submit')}
					</ProfileActionButton>
				</ButtonWrapper>
			</DrawerFooterWrapper>
		</>
	)
}

export default RequestCredit
