import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useOutletContext } from 'react-router'
import {
	Chip,
	FloatingButton,
	H5,
	NavigationWrapper,
	PageLevelTableWrapper,
	Search,
	Table,
	TableExportColumns,
	TOAST_TYPE,
	useTableReducer,
} from '@base'
import { COLLECTION_STATUS, DRAWER_TYPES, MB_ROLES, collectionFilter, searchFilter, strings } from '@constants'
import { downloadPaymentReceivables, getAllPaymentReceivable } from '@data'
import { toggleDrawer } from '@data/state/action/root'
import { convertDataFile, dataArrayToCsv, displayAmount, showDateFormat } from '@utils'
import { Spacings, Text } from '@styles'
import { SearchWrapper } from '../style'
import { Add, GetApp } from '@material-ui/icons'
import { showLoader, showToast } from '@data/state/action'

const EditCollectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

const AmountWapper = styled(H5)`
	font-weight: ${Text.BOLD_600};
`

const ChipWapper = styled(Chip)`
	${({ isCustom }) => {
		if (isCustom) {
			return css`
				background: ${({ theme }) => theme.palette.background.orangeLight};
				color: ${({ theme }) => theme.palette.text.darkblack};
			`
		}
	}}
`

const CopySpan = styled.span`
	cursor: copy;
`

const Collections = ({ isSales }) => {
	const [state, dispatch, actions] = useTableReducer(collectionFilter)
	const [forceUpdate, update] = useOutletContext()
	const globalDispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	useEffect(() => {
		if (state.loading) return
		dispatch(actions.fetchTableDataProgress())
		getAllPaymentReceivable(
			state.query.page,
			[MB_ROLES.SALES].includes(user?.role) ? { ...state.query.filters, managerFilter: user.user_id } : state.query.filters
		)
			.then((response) => {
				dispatch(actions.fetchTableDataSuccess(response.data, response.count))
			})
			.catch((err) => dispatch(actions.fetchTableDataFailure(err)))
	}, [state.query, forceUpdate])

	const columns = useMemo(
		() => [
			{
				label: strings('client'),
				key: (data) => {
					return <div>{data?.user_details?.organisation_name ? data?.user_details?.organisation_name : data?.user_details?.full_name}</div>
				},
				mobile: true,
			},
			{
				label: strings(['amount', '/ ', 'mode_of_payment']),
				key: (data) => {
					if (!data) {
						return '-'
					}
					return (
						<>
							<div>
								<AmountWapper>{data?.payment_amount ? displayAmount(data?.payment_amount) : '-'}</AmountWapper>
							</div>
							<div>
								<small>{data?.payment_mode ? data?.payment_mode : '-'}</small>
							</div>
						</>
					)
				},
				mobile: true,
			},
			{
				label: strings('expected', 'date'),
				key: (data) => showDateFormat(new Date(data.expected_payment_date)),
				mobile: true,
			},
			{
				label: strings(['manager', '/ ', 'region']),
				key: (data) => {
					if (!data) return '-'
					return (
						<>
							<div>
								<b>
									{data?.manager?.slice(-1)?.[0]?.full_name
										? data?.manager?.slice(-1)?.[0]?.full_name
										: data?.manager?.slice(-1)?.[0]?.user_id}{' '}
								</b>
							</div>
							<div>
								<small>{data?.region ? data?.region : '-'}</small>
							</div>
						</>
					)
				},
				mobile: true,
			},
			{
				label: strings('message'),
				key: (data) => {
					return <div>{data?.message ? data?.message : '-'}</div>
				},
				mobile: true,
			},
			{
				label: strings('status'),
				key: (data) => {
					return (
						<div>
							{data?.status ? (
								<ChipWapper label={data?.status} status={statusHandler(data?.status)} isCustom={data?.status === 'RESCHEDULED'} />
							) : (
								'-'
							)}
						</div>
					)
				},
				mobile: true,
			},
		],
		[]
	)

	const statusHandler = (status) => {
		const dataArray = Object.keys(COLLECTION_STATUS)
		switch (status) {
			case dataArray[0]:
				return 'invoice'
			case dataArray[1]:
				return 'credit_note'
			case dataArray[2]:
				return 'paid'
			case 'RESCHEDULED':
				return 'custom'
			default:
				break
		}
	}

	const copyReceivableId = (e, row) => {
		e.stopPropagation()
		navigator.clipboard.writeText(row?.receivable_id)
		globalDispatch(
			showToast(true, strings(['receivable', 'id', 'copied']), {
				type: TOAST_TYPE.INFO,
			})
		)
	}

	const rowClickHandler = (row) => {
		if (row?.remarks == null || row?.remarks?.length === 0) {
			globalDispatch(toggleDrawer(true, DRAWER_TYPES.ADD_COLLECTION, { paymentReceivables: row, isAddRemark: true, update }))
		} else {
			globalDispatch(
				toggleDrawer(true, DRAWER_TYPES.COLLECTION_JOURNEY, {
					row,
					update,
					label: (
						<div>
							<span>{`${row?.user_details?.organisation_name || row?.user_details?.organisation_id} `}</span>
							<CopySpan onClick={(e) => copyReceivableId(e, row)}>{`(${row?.receivable_id})`}</CopySpan>
						</div>
					),
				})
			)
		}
	}

	const changePageHandler = (next = true) => {
		dispatch(actions.changePage(next))
	}

	const createCollectionHandler = () => {
		globalDispatch(toggleDrawer(true, DRAWER_TYPES.ADD_COLLECTION, { update, isEdit: false }))
	}

	const clickHandler = () => {
		globalDispatch(toggleDrawer(true, DRAWER_TYPES.OPEN_SEARCH_PANEL, { searchType: searchFilter.SALES_COLLECTION_FILTER }))
	}

	const exportHandler = () => {
		globalDispatch(showLoader(true))

		downloadPaymentReceivables(
			[MB_ROLES.SALES].includes(user?.role) ? { ...state.query.filters, managerFilter: user.user_id } : state.query.filters
		)
			.then((res) => {
				const csvData = dataArrayToCsv(TableExportColumns?.CollectionsColumn(), res)
				convertDataFile(csvData, 'CollectionRecords')
			})
			.catch((e) => console.log(e))
			.finally(() => {
				globalDispatch(showLoader(false))
			})
	}

	return (
		<PageLevelTableWrapper>
			{/* {[MB_ROLES.SALES].includes(user?.role) && (
				<NavigationWrapper>
					<SearchWrapper isRight>
						<FloatingButton
							desktopLabel={strings('create')}
							mobileLabel={<Add fontSize='small' />}
							margin={`0 ${Spacings.SPACING_1B} auto 0`}
							type='primary'
							small
							notFixed
							onClick={createCollectionHandler}
						/>
						<FloatingButton
							desktopLabel={strings('export')}
							mobileLabel={<GetApp fontSize='small' />}
							margin={`0 ${Spacings.SPACING_1B} 0 0`}
							type='primary'
							small
							notFixed
							onClick={exportHandler}
						/>
						<Search clickHandler={clickHandler} />
					</SearchWrapper>
				</NavigationWrapper>
			)} */}
			<EditCollectionWrapper>
				<Table
					fullHeight
					data={state.data}
					columns={columns}
					loading={state.loading}
					onRowClick={rowClickHandler}
					rowsToDisplay={10}
					totalItemCount={state.total_count}
					page={state.query.page}
					onPageChange={changePageHandler}
				/>
			</EditCollectionWrapper>
		</PageLevelTableWrapper>
	)
}

export default Collections
