import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

export const getAllContent = (page = 0, detailResponse = false, types, contentFilters = null) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getAllContent, { page, detailResponse, types: types, ...contentFilters })
            .then((response) => {
                resolve(convertPagableData(response.data))
            })
            .catch((error) => {
                reject(error)
            })
    })