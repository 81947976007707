import API from '../api'
import getUrl from './paths'

export const getReport = (api, fromDate = null, toDate = null, type = null) =>
	new Promise((resolve, reject) => {
		API.get(api, { fromDate, toDate, timeout: 1000 * 60 * 5, type })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})


export const fetchCompanyReport = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.fetchCompanyReport, {})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})

export const fetchOrganisationReport = (organisation_id) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.fetchOrganisationReport, { organisation_id })
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})


export const fetchOrderBook = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.fetchOrderBook)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})