import API from '../api'
import getUrl from './paths'
import { auctionStatus as auctionStatusC } from '@constants'
import convertPagableData from '../convertPagableData'

export const getAuctions = (page = 0, auctionFilters = null, size = 1000) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllAuctions, { page, size, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAuctionById = (auctionId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAuctionById, { auctionId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllAuctionsOfSupplier = (page = 0, auctionFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllAuctionsOfSupplier, { page, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllQuotesOfBuyer = (page = 0, auctionFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllQuotesOfBuyer, { page, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAdminAuctions = (auctionStatus = auctionStatusC.unverified, employeeId, page = 0, auctionFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAdminAuctions, { auctionStatus, page, employeeId, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAssignedAuctions = (auctionStatus = auctionStatusC.unverified, employeeId, page = 0, auctionFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAssignedAuctions, { auctionStatus, page, employeeId, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})
