import React from 'react'
import { useDispatch } from 'react-redux'
import { DRAWER_TYPES, strings } from '@constants'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { Button, SectionHeader } from '@base'
import { toggleDrawer } from '@data/state/action/root'
import styled from 'styled-components'
import { Spacings, Text } from '@styles'
import { displayName, getDate } from '@utils'

const ListWrapper = styled(GridListWrapper)`
	overflow-y: scroll;
	height: 13rem;
`

const Wrapper = styled.div`
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	border: 1px solid ${({ theme }) => theme.palette.background.tablecolumn};
	border-radius: ${Spacings.SPACING_2B};
	width: 100%;
	height: 100%;
`
const TextWrapper = styled.div`
	font-size: ${Text.MEDIUM};
	display: inline-block;
	color: ${({ theme }) => theme.palette.text.darkblack};
	margin-bottom: ${Spacings.SPACING_2B};
	font-weight: 600;
`
const FooterText = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: ${Text.SMALL};
`

const Remarks = ({ orgData, financialData, update }) => {
	const dispatch = useDispatch()
	const _remarks = financialData?.remarks || []

	const buttonHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.FINANCIAL_REMARK, {
				label: 'Add Remark',
				organisation_id: orgData?.organisation_id,
				update: update,
			})
		)
	}

	return (
		<DetailsWrapper>
			<SectionHeader>
				{strings('remarks')}
				<Button xs onClick={buttonHandler}>
					{strings('add')}
				</Button>
			</SectionHeader>
			<ListWrapper itemPerRow={[1, 1, 1]}>
				{_remarks?.map((item, index) => (
					<Wrapper key={`${index}-remark`}>
						<TextWrapper>{item?.remark}</TextWrapper>
						<FooterText>
							<span>
								{displayName(item?.remark_by) || '-'}, {`(${item?.type})`}
							</span>
							<span>{getDate(new Date(item?.created_at))} </span>
						</FooterText>
					</Wrapper>
				))}
			</ListWrapper>
		</DetailsWrapper>
	)
}

export default Remarks
