import { Button, DrawerBodyWrapper, Input, TOAST_TYPE } from '@base'
import { showLoader, showToast } from '@data/state/action'
import { MODAL_TYPES, strings } from '@constants'
import { changeOrgPan, setInsuredLimit } from '@data'
import { toggleDrawer, toggleModal } from '@data/state/action/root'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { EditDetailWrapper } from '../../styles'

const EditInsurerCredit = ({ organisationId, update, insured_amount }) => {
	const dispatch = useDispatch()
	const [insurerCredit, setInsurerCredit] = useState()

	useEffect(() => {
		setInsurerCredit(insured_amount)
	}, [insured_amount])

	const onInsurerCreditChange = (value) => {
		setInsurerCredit(value)
	}

	const onInsurerCreditUpdate = () => (e) => {
		if (!insurerCredit) return

		e.stopPropagation()

		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings(['confirm', 'to', 'insurer', 'credit']),
				confirmAction: (actionType) => {
					dispatch(showLoader(true))
					if (actionType) {
						setInsuredLimit(insurerCredit, organisationId)
							.then(() => {
								dispatch(showToast(true, 'Successfully updated insurer credit', { type: TOAST_TYPE.SUCCESS }))
								dispatch(toggleDrawer(false))
								update()
							})
							.catch((err) => {
								console.log(err)
								dispatch(showToast(true, err?.message, { type: TOAST_TYPE.ERROR }))
							})
							.finally(() => {
								dispatch(showLoader(false))
							})
					} else {
						dispatch(showLoader(false))
					}
					dispatch(toggleModal(false))
				},
			})
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<EditDetailWrapper>
					<Input
						placeholder='Enter a Insurer Credit'
						label='Insurer Credit'
						type='number'
						value={insurerCredit}
						uppercase
						onChange={onInsurerCreditChange}
						required={true}
					/>
					<div>
						<Button disabled={!insurerCredit} onClick={onInsurerCreditUpdate()}>
							Update
						</Button>
					</div>
				</EditDetailWrapper>
			</DrawerBodyWrapper>
		</>
	)
}

export default EditInsurerCredit
