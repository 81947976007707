import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

export const getAllLedgersOfUser = (userId = null, page = 0, ledgerFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLedgersOfUser, { userId, page, size: 10, ...ledgerFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getPurchaseInvoices = (userId = null, page = 0, ledgerFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getPurchaseInvoices, { userId, page, removeDefault: true, size: 10, ...ledgerFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getSaleInvoices = (userId = null, page = 0, ledgerFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getSaleInvoices, { userId, page, removeDefault: true, size: 10, ...ledgerFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllLedgers = (page = 0, ledgerFilters = null, userId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLedgers, { page, size: 10, ...ledgerFilters, userId })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllLedgerSummary = (ledgerFilters = null, userId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllLedgerSummary, { removeDefault: true, userId, ...ledgerFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getLedgerSummaryByOrganisationIdApi = (ledgerFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLedgerSummaryByOrganisationId, { ...ledgerFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadLedgersApi = (ordersFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadLedgers, { ...ordersFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export const getduePaymentsReport = (transactionType = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getduePaymentsReport, { transactionType })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadZohoPastRecord = (pastRecordfilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadZohoPastRecord, { ...pastRecordfilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllPaymentReceivable = (page = 0, paymentReceivablesFilter) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllPaymentReceivable, { page, size: 10, ...paymentReceivablesFilter })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getPaymentReceivablesById = (paymentReceivablesId = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getPaymentReceivablesById, { paymentReceivablesId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getDueLedgerReport = (organisationId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getDueLedgerReport, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadPaymentReceivables = (paymentReceivablesfilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadPaymentReceivables, { ...paymentReceivablesfilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getDataCorrectionList = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getDataCorrectionList, { removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
