import {
	UPDATE_DRAWER_META,
	TOGGLE_DRAWER,
	TOGGLE_MODAL,
	SET_HEADER_META,
	UPDATE_MODAL_META,
	TOGGLE_FILE_VIEWER,
	PLAY_NOTIFICATION_SOUND,
} from '../../actions'

export const toggleDrawer = (showDrawer, drawerType = null, drawerTypeMeta = {}) => ({
	type: TOGGLE_DRAWER,
	showDrawer,
	drawerType,
	drawerTypeMeta,
})

export const updateDrawerMeta = (drawerTypeMeta) => ({
	type: UPDATE_DRAWER_META,
	drawerTypeMeta,
})
export const toggleModal = (showModal, modalType = null, modalMeta = {}) => ({
	type: TOGGLE_MODAL,
	showModal,
	modalType,
	modalMeta,
})

export const updateModalrMeta = (modalMeta) => ({
	type: UPDATE_MODAL_META,
	modalMeta,
})

export const setHeaderMeta = (payload) => ({
	type: SET_HEADER_META,
	payload,
})

export const toggleFileViewer = (showFileViewer, fileViewerData = {}) => ({
	type: TOGGLE_FILE_VIEWER,
	showFileViewer,
	fileViewerData,
})

export const playNotification = (payload) => ({
	type: PLAY_NOTIFICATION_SOUND,
	payload,
})
