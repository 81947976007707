export const LightTheme = {
	palette: {
		background: {
			main: '#f2f5f8',
			blue: '#1967D2',
			blueLight: '#1967D2CC',
			borderColor: '#9c9c9c',
			dark: '#001F3A',
			light: '#d2e6f580', // rgba(210, 230, 245, 0.5)
			lightgrey: '#19354E',
			searchgrey: '#F4F5F7',
			tablegrey: '#F6F6F6',
			tablecolumn: '#D8E3EE',
			white: '#ffffff',
			disabled: '#0000001f',
			messageRed: '#c21625bf',
			blueLightest: '#f2f5f8',
			greenpill: '#91ecbd59',
			border: '#bdbdbd',
			yellow: '#ffcc00',
			yellowLight: '#ffcc0073',
			yellowLightest: '#ffcc0017',
			green: '#7ceb6a',
			greenLight: '#7ceb6a73',
			greenLightest: '#72e3a959',
			pill: '#ebf1fa',
			red: '#c21625',
			redLight: '#c21625CC',
			redLightest: '#c2162517',
			greenDark: '#2e7d32',
			redDark: '#d32f2f',
			blackGrey: '#323232',
			chatMessageYellow: '#fde9a8',
			chatMessageBlue: '#e8f3fa',
			orangeLight: '#ffa07a',
			orangeLightest: '#ffa07a21',
			vehicleGreen: '#3fd481f7',
			pastelBeige: '#F5EFE6',
			pastelBeigeDark: '#E8DFCA',
		},
		primary: {
			main: '#D2E6F5',
		},
		secondary: {
			main: '#669DF6',
		},
		text: {
			primary: '#222',
			secondary: '#669DF6',
			blue: '#1967D2',
			blueLight: '#1967D2CC',
			white: '#ffffff',
			lightWhite: '#ffffffbf', //rgb(255 255 255 / 75%)
			grey: '#efefef',
			greydark: '#9B9B9B',
			black: '#595A5B',
			darkblack: '#323234',
			tableHeader: '#474F5E',
			messageRed: '#c21625bf',
			disabled: '#00000042',
			blueLightest: '#f2f5f8',
			label: '#9c9c9c',
			green: '#27ac70',
			red: '#c21625',
			redLight: '#c2162573',
			redLightest: '#c2162517',
		},
	},
}
