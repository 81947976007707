import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Select } from '@base'
import { strings } from '@constants'
import { getAllEmployees } from '@data'
import { updateLead } from '@data/conn/put/leads'
import { User } from '@data/model'
import { showLoader } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { Clear } from '@material-ui/icons'
import { Spacings, Text } from '@styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

const SelectedEmpoyee = styled.div`
	display: flex;
	align-items: center;
	font-size: ${Text.MEDIUM};
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	margin: ${Spacings.SPACING_2B} 0;

	& > div {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
`

const MakePrimaryButton = styled.span`
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.blue};
	border-radius: ${Spacings.SPACING_2B};
	border: 1px solid ${({ theme }) => theme.palette.text.blue};
	cursor: pointer;
	width: fit-content;
	margin-right: ${Spacings.SPACING_3B};

	${({ showAsText }) => {
		if (showAsText) {
			return css`
				cursor: initial;
				background: ${({ theme }) => theme.palette.background.greenDark};
				color: ${({ theme }) => theme.palette.text.white};
				border: none;
			`
		}
	}}
`

const ClearIcon = styled(Clear)`
	cursor: pointer;
`

const EditLeadManager = ({ leads, update }) => {
	const dispatch = useDispatch()

	const [managerList, setManagerList] = useState([])
	const [loading, setLoading] = useState(false)
	// const [selectedManagers, setSelectedManagers] = useState(data?.manager ?? [])

	const primaryManager = useMemo(() => {
		if (leads?.manager && leads?.manager.length) {
			return leads?.manager.find((_item) => _item.is_primary)
		}
		return null
	}, [leads])

	const alreadyAssignedUserIds = useMemo(() => {
		if (leads?.manager && leads?.manager?.length) {
			return leads?.manager?.map((_item) => _item.user_id)
		}
		return null
	}, [leads])

	const selectableManagers = managerList.filter((_e) => !_e.selected)
	const selectedManagers = managerList.filter((_e) => _e.selected)
	useEffect(() => {
		getAllEmployees()
			.then((response) => {
				let _managers = response
				if (alreadyAssignedUserIds) {
					_managers = _managers.map((_e) => {
						if (alreadyAssignedUserIds.includes(_e.user_id)) {
							_e.selected = true

							if (primaryManager && primaryManager.user_id === _e.user_id) {
								_e.isPrimary = true
							}
						}
						return _e
					})
				}

				setManagerList(_managers)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [leads])

	const editManagerHandler = () => {
		setLoading(true)
		const managers = selectedManagers.map((_e) => {
			const _temp = new User(_e).getUserDetails()
			if (_e.isPrimary) {
				_temp.is_primary = true
			}
			return _temp
		})

		dispatch(showLoader(true))
		let _leads = { ...leads, manager: managers }
		updateLead(_leads)
			.then((response) => {
				update()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
				dispatch(toggleDrawer(false))
				setLoading(false)
			})
	}

	const makePrimaryManager = (userId) => {
		if (!loading) {
			setManagerList((_data) =>
				_data?.map((_e) => {
					_e.isPrimary = _e.user_id === userId
					return _e
				})
			)
		}
	}

	const removeHandler = (userId) => {
		if (!loading) {
			setManagerList((_data) =>
				_data.map((_e) => {
					if (_e.user_id === userId) {
						_e.selected = false
						if (_e.isPrimary) {
							_e.isPrimary = false
						}
					}
					return _e
				})
			)
		}
	}

	const renderSelectedManagers = () =>
		selectedManagers?.map((_employee) => (
			<SelectedEmpoyee key={`employee-${_employee?.user_id}`}>
				<div>
					<span>
						<b>{_employee.full_name}</b>
					</span>
					<span>
						<small>{_employee?.mobile_number}</small>
					</span>
				</div>
				{!_employee?.isPrimary && (
					<MakePrimaryButton onClick={() => makePrimaryManager(_employee.user_id)}>{strings('set_primary_manager')}</MakePrimaryButton>
				)}
				{_employee?.isPrimary && <MakePrimaryButton showAsText>{strings('primary')}</MakePrimaryButton>}
				{!_employee?.isPrimary && <ClearIcon fontSize='small' onClick={() => removeHandler(_employee.user_id)} />}
			</SelectedEmpoyee>
		))

	const onChangeHandler = (selectedEmployee) => {
		if (selectedEmployee) {
			setManagerList((_data) =>
				_data.map((_e) => {
					if (_e.user_id === selectedEmployee.user_id) {
						_e.selected = true
					}
					return _e
				})
			)
		}
	}

	return (
		<>
			<DrawerBodyWrapper>
				<Select
					// label={label}
					id='employee-select'
					data={selectableManagers}
					type='text'
					value={{}}
					onChange={onChangeHandler}
					placeholder={strings(['select', 'manager'])}
					displayKey='full_name'
					primaryKey='user_id'
					label={strings('select_employee_s')}
				/>
				{renderSelectedManagers()}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small type='primary' disabled={false} onClick={editManagerHandler}>
					{strings('edit', 'manager')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default EditLeadManager
