import { DrawerBodyWrapper, DrawerFooterWrapper, PageLevelTableWrapper, Stepper, TOAST_TYPE } from '@base'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { strings } from '@constants'
import Aadhaar from './steps/Aadhaar'
import PanCard from './steps/PanCard/index'
import Webcam from './steps/WebCam'
import Documents from './steps/Documents'
import { showLoader, showToast } from '@data/state/action'
import { kycStatusApi } from '@data'
import { toggleDrawer } from '@data/state/action/root'

const PageLevelWrapper = styled(PageLevelTableWrapper)`
	height: 100%;
	padding-bottom: 0px !important;
`

const stepperSteps = [
	{
		label: strings('pan'),
		id: 1,
	},
	{
		label: strings('aadhaar'),
		id: 2,
	},
	{
		label: strings('documents'),
		id: 3,
	},
	{
		label: strings('video'),
		id: 4,
	},
]

const CompleteProfileDrawer = ({ kycData, adminView = false, update }) => {
	const dispatch = useDispatch()
	const [selectedStep, setSelectedStep] = useState(1)
	const [orgData, setOrgData] = useState(kycData)

	const changeStep = (step) => {
		setSelectedStep(step)
	}

	useEffect(() => {
		if (adminView) {
			if (!kycData?.kyc_status?.[0]) {
				setSelectedStep(1)
			} else if (!kycData?.kyc_status?.[1]) {
				setSelectedStep(2)
			} else if (!kycData?.kyc_status?.[2]) {
				setSelectedStep(3)
			} else {
				setSelectedStep(4)
			}
		} else {
			if (!kycData?.kyc_status?.[0]) {
				setSelectedStep(1)
			} else if (!kycData?.kyc_status?.[1]) {
				setSelectedStep(2)
			} else if (!kycData?.kyc_status?.[2]) {
				setSelectedStep(3)
			} else if (!kycData?.kyc_status?.[3]) {
				setSelectedStep(4)
			} else if (kycData?.aadhaar_file_response && kycData?.aadhaar_file_response.status_code === 101) {
				setSelectedStep(3)
			} else if (kycData?.pan_verification_details) {
				setSelectedStep(2)
			}
		}
	}, [])

	const kycStatushandler = (kycRejectReason = '', changeStepNumber, kycStatus = false, notificationMsg) => {
		dispatch(showLoader(true))
		kycStatusApi(kycRejectReason, kycStatus, changeStepNumber, orgData?.user?.organisation_id)
			.then(() => {
				if (kycStatus) {
					dispatch(showToast(true, notificationMsg, { type: TOAST_TYPE.SUCCESS }))
				} else {
					dispatch(showToast(true, notificationMsg, { type: TOAST_TYPE.SUCCESS }))
				}

				update()

				if (changeStepNumber === 4) {
					dispatch(toggleDrawer(false))
				} else {
					changeStep(changeStepNumber + 1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<PageLevelWrapper>
					{selectedStep === 1 && (
						<PanCard
							nextStep={() => changeStep(2)}
							org={orgData}
							adminView={adminView}
							kycStatushandler={kycStatushandler}
							selectedStep={selectedStep}
							update={update}
						/>
					)}
					{selectedStep === 2 && (
						<Aadhaar
							nextStep={() => changeStep(3)}
							org={orgData}
							adminView={adminView}
							kycStatushandler={kycStatushandler}
							selectedStep={selectedStep}
							preStep={() => changeStep(1)}
						/>
					)}
					{selectedStep === 3 && (
						<Documents
							nextStep={() => changeStep(4)}
							org={orgData}
							adminView={adminView}
							kycStatushandler={kycStatushandler}
							selectedStep={selectedStep}
							setOrgData={setOrgData}
							update={update}
							preStep={() => changeStep(2)}
						/>
					)}
					{selectedStep === 4 && (
						<Webcam
							org={orgData}
							adminView={adminView}
							kycStatushandler={kycStatushandler}
							selectedStep={selectedStep}
							preStep={() => changeStep(3)}
							update={update}
						/>
					)}
				</PageLevelWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper justifyContent='center'>
				<Stepper steps={stepperSteps} selectedStep={selectedStep} />
			</DrawerFooterWrapper>
		</>
	)
}

export default CompleteProfileDrawer
