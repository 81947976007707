import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spacings } from '@styles'
import { DrawerBodyWrapper, Input, Button, Calendar, SectionHeader } from '@base'
import { strings } from '@constants'
import { useDispatch } from 'react-redux'
import { toggleFileViewer, updateDrawerMeta } from '@data/state/action/root'

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}
`

const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`
const deriveStateFromData = (data) => {
	const _state = {
		invoice_amount: {
			label: strings('invoice', 'amount'),
			value: '',
			disabled: true,
			required: true,
			placeholder: strings('placeholder_enter_invoice_amount'),
		},
		invoice_number: {
			label: strings('invoice', 'number'),
			value: '',
			disabled: true,
			required: true,
			placeholder: strings('placeholder_enter_invoice_number'),
		},
		invoice_date: {
			label: strings('invoice', 'date'),
			value: '',
			disabled: true,
			placeholder: strings('placeholder_enter_invoice_date'),
		},
	}

	if (!data) return _state

	_state.invoice_amount.value = data?.invoice_amount
	_state.invoice_number.value = data?.original_invoice_number || data?.invoice_number
	_state.invoice_date.value = data?.invoice_date
	return _state
}
const EditFactoryInvoice = memo(({ invoiceData }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())

	useEffect(() => {
		setFormState((_) => deriveStateFromData(invoiceData))
	}, [invoiceData])

		useEffect(() => {
			dispatch(
				updateDrawerMeta({
					label: (
						<>
							{strings(['factory', 'invoice', 'details'])}
							<ActionButton
								noAutoLeft
								small
								uppercase
								type='secondary'
								onClick={() => {
									if (invoiceData?.invoice_document) {
										dispatch(toggleFileViewer(true, { files: invoiceData.invoice_document }))
									}
								}}
							>
								{strings('view', 'invoice')}
							</ActionButton>
						</>
					),
				})
			)
		}, [dispatch, invoiceData])

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<FormItemWrapper>
						<Input
							type='text'
							label={formState.invoice_number?.label}
							disabled={formState.invoice_number?.disabled}
							value={formState.invoice_number?.value}
							placeholder={formState.invoice_number?.placeholder}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Calendar
							label={formState.invoice_date?.label}
							disabled={formState.invoice_date?.disabled}
							value={formState.invoice_date?.value}
							placeholder={formState.invoice_date?.placeholder}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Input
							type='number'
							label={formState.invoice_amount?.label}
							disabled={formState.invoice_amount?.disabled}
							value={formState.invoice_amount?.value}
							placeholder={formState.invoice_amount?.placeholder}
						/>
					</FormItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
		</>
	)
})

export default EditFactoryInvoice
