import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Description, PictureAsPdf } from '@material-ui/icons'

import {  DrawerBodyWrapper, DrawerFooterWrapper, SectionHeader, TOAST_TYPE, UploadButton } from '@base'
import { strings } from '@constants'
import { generateReport, uploadOrgFinancialDocument } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { useForceUpdate } from '@hooks'
import { GridListWrapper,  } from '../../styles'
import { DownloadIconWrapper } from '@pages/Admin/Invoice/EditInvoice/styles'
import { toggleFileViewer } from '@data/state/action/root'

const PdfIcon = styled(PictureAsPdf)`
	color: ${({ theme }) => theme.palette.background.blue};
	cursor: pointer;
`

const ExcelIcon = styled(Description)`
	color: ${({ theme }) => theme.palette.background.blue};
	cursor: pointer;
`

const AddFinancialDoc = ({ orgId }) => {
	const dispatch = useDispatch()
	const [forceUpdate, update] = useForceUpdate()
	const [file, setFile] = useState()
	const [financialDocs, setFinancialDocs] = useState([])

	useEffect(() => {
		generateReport(orgId)
			.then((response) => {
				setFinancialDocs(response?.data?.documents)
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {})
	}, [forceUpdate])

	const onFileViewHandler = (link, onDelete) => {
		dispatch(toggleFileViewer(true, { files: link, onDelete }))
	}

	const uploadFinancialDocument = (file) => {
		if (!file) {
			return
		}
		dispatch(showLoader(true))
		uploadOrgFinancialDocument(orgId, file)
			.then((response) => {
				setFile(response?.file_path)
				dispatch(showToast(true, strings('uploaded_the_file_successfully'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let error_msg = error?.client_error_code === 'E_002' ? error?.message : strings('document_upload_failed')
				dispatch(showToast(true, error_msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<SectionHeader>{strings('documents', ':')}</SectionHeader>
				<GridListWrapper itemPerRow={[6, 6, 6]}>
					{financialDocs?.map((item) => {
						let isPdf = item.split('.').pop() === 'pdf'
						return (
							<div>
								<DownloadIconWrapper>
									{isPdf ? (
										<PdfIcon onClick={() => onFileViewHandler(item)} />
									) : (
										<ExcelIcon onClick={() => onFileViewHandler(item)} />
									)}
								</DownloadIconWrapper>
							</div>
						)
					})}
				</GridListWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<UploadButton margin={`0 0 0 auto`} label={strings('upload', 'document')} xs uppercase onChange={uploadFinancialDocument} />
			</DrawerFooterWrapper>
		</>
	)
}

export default AddFinancialDoc
