import React from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { Button, ItemCard, SectionHeader } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { useDispatch } from 'react-redux'
import { User } from '@data/model'

const BusinessDetails = ({ orgData, financialData, update }) => {
	let userDetails = new User({ ...orgData?.default_admin }).getUserDetails()
	const dispatch = useDispatch()
	const Edit = financialData?.business_detail !== null
	const buttonHandler = () => {
		Edit
			? editHandler()
			: dispatch(
					toggleDrawer(true, DRAWER_TYPES.BUSINESS_DETAILS, {
						label: 'Add Business Details',
						userDetails,
						organisationId: orgData?.organisation_id,
						update,
					})
			  )
	}

	const formatListFormCard = (listArray = []) => {
		let tempArray = listArray?.map((item) => item).join(', ') || '--'
		if (tempArray?.length > 15) {
			tempArray = tempArray?.substring(0, 13) + '...'
		}

		return tempArray
	}

	const itemData = [
		{
			label: strings(['type', 'of', 'business']),
			value: formatListFormCard(financialData?.business_detail?.business_types),
		},
		{
			label: strings(['key', 'projects']),
			value: formatListFormCard(financialData?.business_detail?.projects_key),
			wrap: true,
		},
		{
			label: strings(['completed', 'projects']),
			value: formatListFormCard(financialData?.business_detail?.projects_completed),
		},
		{
			label: strings(['mbook', 'projects']),
			value: formatListFormCard(financialData?.business_detail?.projects_mbook),
		},
		{
			label: strings(['department', 'list']),
			value: formatListFormCard(financialData?.business_detail?.departments),
		},
		{
			label: strings('material'),
			value: formatListFormCard(financialData?.business_detail?.materials),
		},
	]

	const editHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.BUSINESS_DETAILS, {
				label: 'Edit Business Details',
				data: financialData,
				userDetails,
				organisationId: orgData?.organisation_id,
				update,
			})
		)
	}

	return (
		<>
			<DetailsWrapper>
				<SectionHeader>
					{strings('business', 'details')}
					{
						<Button xs onClick={buttonHandler}>
							{Edit ? strings('edit') : strings('add')}
						</Button>
					}
				</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<ItemCard
						medium
						data={itemData}
						key={`business-details-item`}
						clickHandler={editHandler}
						clickable={financialData?.business_detail}
					/>
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default BusinessDetails
