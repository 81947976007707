import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Spacings } from '@styles'
import { decimalSeparatedNumber } from '@utils'
import { toggleDrawer } from '@data/state/action/root'
import FactoryEnquiryItem from '@data/model/NewModals/CloudFactory/FactoryEnquiryItems'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, SectionHeader, CloudInventoryCard } from '@base'

import { ExntededPanelItemsWrapper, ExntendedItemWrapper, CardButtonsWrapper, ListLabel } from '../../../../styles'
import { strings } from '@constants'

const AddOutputItems = ({items, selectedItems, update, clickedCardId }) => {
	const dispatch = useDispatch()

	const [enquiryItems, setEnquiryItems] = useState([])
	const [highlight, setHighlight] = useState()

	useEffect(() => {
		if (Array.isArray(items)) {
			setEnquiryItems(
				items
					.map((_i) => new FactoryEnquiryItem(_i))
					.map((_i) => {
						const _item = selectedItems.find((_si) => _si.identifier === _i.identifier)
						if (_item) {
							return _i.update({ ..._item.toJS(), selected: true })
						}
						return _i.update({ selected: false })
					})
			)
		}

		if (clickedCardId) {
			setHighlight(clickedCardId)
			const timerId = setTimeout(() => {
				setHighlight(undefined)
			}, 1000)

			return () => {
				clearTimeout(timerId)
				setHighlight(undefined)
			}
		}
	}, [])

	const updateItem = (identifier, key) => (val) => {
		setEnquiryItems((_items) => {
			const _itemIndex = enquiryItems.findIndex((_i) => _i.identifier === identifier)
			if (_itemIndex !== -1) {
				_items[_itemIndex] = _items[_itemIndex].update({ [key]: val })
				return [..._items]
			}
			return _items
		})
	}

	const addItem = (item) => () => {
		if (!item.isValid()) return

		setEnquiryItems((items) => {
			const itemIndex = items.findIndex((_i) => _i.identifier === item.identifier)
			if (itemIndex !== -1) {
				items[itemIndex] = item.update({ selected: true })
			}
			return [...items]
		})
	}

	const removeSelectedItem = (item) => () => {
		if (highlight) {
			setHighlight(undefined)
		}
		setEnquiryItems((items) => {
			const itemIndex = items.findIndex((_i) => _i.identifier === item.identifier)
			if (itemIndex !== -1) {
				items[itemIndex] = item.update({ selected: false })
			}
			return [...items]
		})
	}

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const doneHandler = () => {
		const selectedItems = enquiryItems.filter((_i) => _i.selected)
		update(selectedItems)
		dispatch(toggleDrawer(false))
	}

	const renderEnquiryItemCard = (item, isSelected) => {
		const _item = item.toJS()
		const data = [
			{
				label: strings('category'),
				value: _item.category_name,
				wrap: true,
			},
			{
				label: strings('product'),
				value: _item.product_name,
				wrap: true,
			},
			{
				label: strings('brand'),
				value: _item.brand_ordered || '-',
				wrap: true,
			},
			{
				label: strings('ordered_weight_mt'),
				value: decimalSeparatedNumber(_item.ordered_weight / 1000),
			},
			{
				label: strings('tc', 'required'),
				value: _item.tc_required ? strings('yes') : strings('no'),
			},
		]

		const bottomPanel = (
			<>
				<ExntededPanelItemsWrapper>
					<ExntendedItemWrapper width={50}>
						<Input
							type='number'
							label={strings('serviced_weight_mt')}
							placeholder={strings('enter_serviced_weight')}
							value={_item.serviced_weight}
							onChange={updateItem(_item.identifier, 'serviced_weight')}
						/>
					</ExntendedItemWrapper>
				</ExntededPanelItemsWrapper>
				<CardButtonsWrapper>
					{isSelected && (
						<Button xs type='secondary' variant='destructive' margin='0 0 0 auto' onClick={removeSelectedItem(item)}>
							{strings('remove')}
						</Button>
					)}
					{!isSelected && (
						<Button xs margin='0 0 0 auto' disabled={!item.isValid()} onClick={addItem(item)}>
							{strings('add')}
						</Button>
					)}
				</CardButtonsWrapper>
			</>
		)
		return (
			<CloudInventoryCard
				showBorder={highlight === _item.identifier}
				key={`input-order-item-${_item.identifier}`}
				data={data}
				renderBottomPanel={bottomPanel}
			/>
		)
	}

	const renderCards = () => {
		const selectedCardsItems = enquiryItems.filter((_i) => _i.selected)
		const nonSelectedCardsItems = enquiryItems.filter((_i) => !_i.selected)

		return (
			<>
				{!!selectedCardsItems.length && <ListLabel>{strings('selected_items')}</ListLabel>}
				{selectedCardsItems.map((item) => renderEnquiryItemCard(item, true))}
				{!!selectedCardsItems.length && <br />}
				{!!nonSelectedCardsItems.length && <ListLabel>{strings('available_items')}</ListLabel>}
				{nonSelectedCardsItems.map((item) => renderEnquiryItemCard(item, false))}
			</>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				{renderCards()}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small onClick={doneHandler}>
					{strings('done')}
				</Button>
				<Button small type='secondary' margin={`0 ${Spacings.SPACING_3B} 0 auto`} onClick={cancelHandler}>
					{strings('cancel')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddOutputItems
