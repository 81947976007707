import API from '../api'
import putUrl from './paths'

export const updateLead = (lead) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.updateLead, { ...lead })
            .then((response) => {
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })

export const updateLeadStatus = (leadId, taskType, notes) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.updateLeadStatus, { ...notes }, { leadId, taskType })
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })

export const addLeadTask = (leadId, task) =>
    new Promise((resolve, reject) => {
        API.put(putUrl.addLeadTask, { ...task }, { leadId })
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })