import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Checkbox, Select, Input, Toggle, TOAST_TYPE } from '@base'
import { Devices, Spacings } from '@styles'
import { showToast } from '@data/state/action'
import { strings } from '@constants'
import { capitalizeWord } from '@utils'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`

const TotalAmountSection = styled.div`
	padding: ${Spacings.SPACING_3B};
	display: flex;
	user-select: none;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		padding: 0 0 ${Spacings.SPACING_4B} 0;
	}
`
const FlexWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const LogisticsQuotesForm = React.memo(({ state, updateState, logisticsPartner, selectedPartner, logistic, dispatch }) => {
	const { logistics_quote_per_mt, lumpsum_logistics_quote, minimum_guaranteed_weight, logistics_partner, order_won } = state

	const [selectedLogisticsPartner, setSelectedLogisticsPartner] = useState(selectedPartner ?? {})
	const [isQuoteType, setQuoteType] = useState(strings('cost_mt'))

	useEffect(() => {
		if (lumpsum_logistics_quote.value !== 0 && lumpsum_logistics_quote.value !== '') {
			setQuoteType(strings('lumpsum'))
		}
		if (logistics_quote_per_mt.value !== 0 && logistics_quote_per_mt.value !== '') {
			setQuoteType(strings('cost_mt'))
		}
	}, [lumpsum_logistics_quote.value])

	const onChange = (key) => (value) => {
		if (key === 'logistics_partner') {
			const checkPartner = logistic.all_logistics_quotes.find((item) => item?.logistics_partner.user_id === value.user_id)
			if (checkPartner) {
				dispatch(showToast(true, strings('logistic_ptnr_exists'), { type: TOAST_TYPE.ERROR }))
				return
			}
			setSelectedLogisticsPartner(value)
		}
		if (key === 'logistics_quote_per_mt') {
			updateState(key, { value, dirty: true })
			updateState('lumpsum_logistics_quote', { value: 0, dirty: true })
		} else if (key === 'lumpsum_logistics_quote') {
			updateState(key, { value, dirty: true })
			updateState('logistics_quote_per_mt', { value: 0, dirty: true })
		} else {
			updateState(key, { value, dirty: true })
		}
	}

	const onCheckBoxChange = (e) => onChange('order_won')(e.target.checked)

	const setQuoteTypeHandler = (type) => (e) => {
		e.stopPropagation()
		if (isQuoteType === type) return
		setQuoteType(type)
	}

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Select
					id={`logistics_partner`}
					placeholder={logistics_partner?.placeholder}
					data={logisticsPartner}
					displayKey='display_name'
					primaryKey='organisation_id'
					value={selectedLogisticsPartner}
					onChange={onChange('logistics_partner')}
					disabled={selectedPartner}
					label={logistics_partner?.label}
				/>
			</SectionItemWrapper>

			<TotalAmountSection>
				<FlexWrapper>
					<Toggle
						options={[
							{
								label: strings('cost_per_mt'),
								click: setQuoteTypeHandler(strings('cost_mt')),
								active: isQuoteType === strings('cost_mt'),
							},
							{
								label: capitalizeWord(strings('lumpsum')),
								click: setQuoteTypeHandler(strings('lumpsum')),
								active: isQuoteType === strings('lumpsum'),
							},
						]}
					/>
				</FlexWrapper>
			</TotalAmountSection>

			{isQuoteType === strings('cost_mt') ? (
				<SectionItemWrapper>
					<Input
						type='number'
						label={logistics_quote_per_mt?.label}
						disabled={logistics_quote_per_mt?.disabled}
						value={logistics_quote_per_mt?.value}
						onChange={onChange('logistics_quote_per_mt')}
					/>
				</SectionItemWrapper>
			) : (
				<SectionItemWrapper>
					<Input
						type='number'
						label={lumpsum_logistics_quote?.label}
						disabled={lumpsum_logistics_quote?.disabled}
						value={lumpsum_logistics_quote?.value}
						onChange={onChange('lumpsum_logistics_quote')}
					/>
				</SectionItemWrapper>
			)}

			<SectionItemWrapper>
				<Input
					type='number'
					label={minimum_guaranteed_weight?.label}
					disabled={minimum_guaranteed_weight?.disabled}
					value={minimum_guaranteed_weight?.value}
					onChange={onChange('minimum_guaranteed_weight')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapperWithTwoItems>
				<Checkbox id='Checkbox' size={1.125} disabled={order_won?.disabled} checked={order_won?.value} onChange={onCheckBoxChange} />
				<FormElementLabel disabled={order_won?.disabled} htmlFor='Checkbox'>
					{order_won?.label}
				</FormElementLabel>
			</SectionItemWrapperWithTwoItems>
		</FormWrapper>
	)
})

export default LogisticsQuotesForm
