import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

const getAllCommodityPrices = (page = 0, searchFilter = {}) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllCommodityPrices, { page, ...searchFilter })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

const getLatestCommodityPrices = (page = 0, searchFilter = {}, includeAppData = false) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLatestCommodityPrices, { page, size: 100, includeAppData, ...searchFilter })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

const getAllCommodityApi = (page = 0, searchFilter = {}) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllCommodity, { page, ...searchFilter })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { getAllCommodityPrices, getLatestCommodityPrices, getAllCommodityApi }
