import { Button, DrawerBodyWrapper, DrawerFooterWrapper, EmptyState, H6 } from '@base'
import { strings, ORG_ROLES } from '@constants'
import OrgRole from '@data/model/NewModals/OrgRole'
import { useMemo } from 'react'
import { ButtonWrapper, RoleCard, GridListWrapper, RoleTypeHeader } from '../styles'

const AddOrgRole = ({ existingRoles, requestRolesHandler, requestRole }) => {
	const availableRoles = useMemo(
		() =>
			Object.values(ORG_ROLES)
				.map((role) =>
					existingRoles.findIndex((r) => r.platform === role.platform && r.role === role.role && r.approved) === -1 ? role : null
				)
				.filter(Boolean)
				.map((r) => new OrgRole(r).toJS()),
		[existingRoles]
	)

	const onAdd = (role) => {
		if (requestRole) {
			role.requested = true
		} else {
			role.approved = true
		}
		requestRolesHandler([...existingRoles, role], true)
	}

	return availableRoles?.length ? (
		<DrawerBodyWrapper>
			<RoleTypeHeader>Available Roles</RoleTypeHeader>
			<GridListWrapper itemPerRow={[1, 1, 1]}>
				{availableRoles.map((role) => (
					<RoleCard key={role.platform + role.role}>
						<H6>
							{role.platform} {role.role}
						</H6>
						<ButtonWrapper>
							<Button xs onClick={() => onAdd(role)}>
								{requestRole ? 'Request Role' : 'Add role'}
							</Button>
						</ButtonWrapper>
					</RoleCard>
				))}
			</GridListWrapper>
		</DrawerBodyWrapper>
	) : (
		<DrawerBodyWrapper>
			<EmptyState noAction text={`No more roles to be ${requestRole ? 'requested' : 'added'}`} />
		</DrawerBodyWrapper>
	)
}

export default AddOrgRole
