import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { updateSalesTeamFeedback } from '@data/conn/post/underwriting'
import { showLoader, showToast } from '@data/state/action'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormWrapper } from '../styles'
import FinanceForm from './FinanceForm'

const initialFormState = {
	comments: {
		name: 'comments',
		label: strings('comments'),
		disabled: false,
		value: '',
		dirty: false,
	},
	score: {
		name: 'score',
		palceholder: '',
		label: strings('score'),
		disabled: false,
		value: '',
		dirty: false,
	},
	heading: '',
}

const Finance = ({ financeData, editable = true, feedbackType, update }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const [initialState, setInitialState] = useState([])

	useEffect(() => {
		const _setForm = []
		for (let index = 0; index < financeData?.sales_team_feedback?.length; index++) {
			_setForm.push({
				...initialFormState,
				heading: financeData?.sales_team_feedback[index]?.criteria_name,
				['comments']: { ...initialFormState['comments'], ['value']: financeData?.sales_team_feedback[index]?.comments },
				['score']: {
					...initialFormState['score'],
					['value']: financeData?.sales_team_feedback[index]?.score,
					['placeholder']: financeData?.sales_team_feedback[index]?.max_score,
				},
			})
		}
		setInitialState(_setForm)
	}, [editable])

	const onChangeHandle = (index, name, value) => {
		setInitialState((state) => {
			return state.map((_item, _index) => {
				if (_index === index) {
					return { ..._item, [name]: { ..._item[name], value } }
				}
				return _item
			})
		})
	}

	const buttonHandler = () => {
		const salesTeamFeedback = financeData?.sales_team_feedback?.map((item, index) => {
			return { ...item, comments: initialState[index]?.comments?.value, score: initialState[index]?.score?.value }
		})
		dispatch(showLoader(true))
		updateSalesTeamFeedback(salesTeamFeedback, financeData?.pan, financeData?.report_id, feedbackType, user?.user_id)
			.then((response) => {
				dispatch(showToast(true, strings('finance_feedback_update'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, `${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const disabledButton = () => {
		return true
		// return initialState.find((item, index) => {
		// 	return item?.comments?.value && item?.score?.value ? true : false
		// })
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					{initialState?.map((item, index) => (
						<FinanceForm item={item} index={index} onChangeHandle={onChangeHandle} key={index} />
					))}
				</FormWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small type='primary' disabled={!disabledButton()} onClick={buttonHandler}>
					{strings('save')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default Finance
