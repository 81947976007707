import {
	AccountBalanceWallet as AccountBalanceWalletIcon,
	Assignment as AssignmentIcon,
	Home as HomeIcon,
	LocalOffer as LocalOfferIcon,
	ShoppingCart as ShoppingCartIcon,
	BusinessCenter as BusinessCenterIcon,
	LocalShipping,
	Settings,
	Flag,
	Flip,
	LocationCity,
	ShoppingCart,
	FitnessCenter as FitnessCenterIcon,
} from '@material-ui/icons'
import { strings } from '@constants'
import paths from '../paths'

const organisationMenu = (orgRoles) => {
	const sideMenuItems = [{ title: strings('dashboard'), icon: <HomeIcon />, href: paths.home }]

	let scrapSupplier = false
	let marketplaceSupplier = false

	orgRoles?.forEach((item) => {
		const role = item.role.toUpperCase()
		const platform = item.platform.toUpperCase()
		const approved = item.approved

		if (approved) {
			if (role === 'BUYER' && platform === 'MARKETPLACE') {
				sideMenuItems.push({
					title: strings('buy'),
					submenu: [
						{ title: strings(['enquiry', '(', 'rfq', ')']), href: paths.create_enquiry, icon: <ShoppingCartIcon /> },
						{ title: strings('manage_purchase_orders'), href: paths.manage_purchase_order_status('active'), icon: <Settings /> },
						{
							title: strings('bid_auction'),
							href: paths.bid_auction_active,
							icon: <Flag />,
						},
					],
				})
			}

			if (role === 'SUPPLIER') {
				if (platform === 'MARKETPLACE') {
					marketplaceSupplier = true
				} else if (platform === 'SCRAP') {
					scrapSupplier = true
				} else if (platform === 'FABRICATOR') {
					sideMenuItems.push({
						title: strings('cloud_factory'),
						submenu: [
							{ title: strings(['manage', 'cloud', 'orders']), href: paths.fabricator_orders, icon: <ShoppingCart /> },
							{ title: strings('factories'), icon: <LocationCity />, href: paths.fabricator_factories },
						],
					})
				} else if (platform === 'LOGISTICS') {
					sideMenuItems.push({
						title: strings('logistics'),
						submenu: [
							{ title: strings('requests'), href: paths.logistics_requests, icon: <ShoppingCartIcon /> },
							{ title: strings(['manage', 'logistic', 'orders']), href: paths.manage_logistics_orders, icon: <Settings /> },
						],
					})
				}
			}
		}
	})

	if (marketplaceSupplier || scrapSupplier) {
		const supplierSubmenu = []
		if (marketplaceSupplier) {
			supplierSubmenu.push({ title: strings('fullfill_enquiry'), href: paths.fullfill_enquiry_open, icon: <BusinessCenterIcon /> })
			supplierSubmenu.push({ title: strings('sell', 'inventory'), href: paths.create_auction, icon: <LocalOfferIcon /> })
		}

		if (scrapSupplier) {
			supplierSubmenu.push({ title: strings('supply_scrap'), href: paths.supply_scrap_quote, icon: <Flip /> })
		}

		supplierSubmenu.push({ title: strings('manage_supply_orders'), href: paths.manage_supply_order_status('active'), icon: <Settings /> })

		sideMenuItems.push({ title: strings('sell'), submenu: supplierSubmenu })
	}

	sideMenuItems.push({ title: strings('payment_summary'), icon: <AccountBalanceWalletIcon />, href: paths.ledger })
	sideMenuItems.push({ title: strings('calculate', 'logistics'), href: paths.logistics_calculator, icon: <LocalShipping /> })
	sideMenuItems.push({ title: strings('list_inventory'), icon: <AssignmentIcon />, href: paths.trader_list_inventory })
	sideMenuItems.push({ title: strings('weight', 'calculator'), icon: <FitnessCenterIcon />, href: paths.tools_weight_calculator })
	return sideMenuItems
}

export default organisationMenu
