const postUrl = {
	addCategory: '/category',
	addProduct: '/product',
	weightConvertor: '/product/weightConvertor',
	addBrand: '/brand',
	saveAllBrands: '/brand/saveAll',

	createEnquiry: '/enquiry',
	downloadEstimate: '/enquiry/generateEstimatePdf',

	createOrder: '/order/createOrderFromEnquiry',
	createUser: '/user',
	createLedger: '/ledger',
	createCollection: '/payment-receivables',
	uploadBulkUsers: '/user/uploadUserCSV',
	updateUserFCM: '/user/updateFcm',

	getUserByPhone: '/login/getByPhone',
	setUserName: '/login/setUserName',
	setRegisteredGst: '/login/setRegisteredGst',
	enterPanDetails: '/login/enterPanDetails',

	addSupplierToOrder: '/order/addSupplierToOrder',
	addBuyerToOrder: '/order/addBuyerToOrder',

	uploadBuyerPO: '/files/uploadBuyerPo',
	uploadBuyerPoList: '/files/uploadBuyerPoList',
	uploadSupplierPoList: '/files/uploadSupplierPoList',
	uploadBuyerInvoice: '/files/uploadBuyerInvoice',
	uploadBuyerReceivedTc: '/files/uploadBuyerReceivedTc',
	uploadBuyerBilty: '/files/uploadBuyerBilty',
	uploadBuyerDKT: '/files/uploadBuyerDkt',
	uploadBuyerUpdatedTC: '/files/uploadBuyerUpdatedTc',
	uploadBuyerEwayBill: '/files/uploadBuyerEwayBill',
	uploadBuyerDeliveryReceipt: '/files/uploadBuyerDeliveryReceipt',

	uploadSupplierPO: '/files/uploadSupplierPo',
	uploadSupplierInvoice: '/files/uploadSupplierInvoice',
	uploadSupplierReceivedTc: '/files/uploadSupplierReceivedTc',
	uploadSupplierBilty: '/files/uploadSupplierBilty',
	uploadSupplierDKT: '/files/uploadSupplierDkt',
	uploadSupplierEwayBill: '/files/uploadSupplierEwayBill',
	uploadSupplierProductPhotos: '/files/uploadSupplierProductPhotos',

	uploadAuctionReceivedTc: '/files/uploadAuctionReceivedTc',
	uploadAuctionStockPhoto: '/files/uploadAuctionStockPhoto',

	createAuction: '/auction',
	createOrderFromAuction: '/order/createOrderFromAuction',

	createLogistics: '/logistics/createLogisticsRequests',

	convertToOrderLogisticsRequest: '/logistics/createOrderFromLogisticsRequest',

	uploadLogisticsInvoice: '/logistics/uploadLogisticsInvoice',

	uploadDispatchWeighmentSlip: '/logistics/uploadDispatchWeighmentSlip',
	uploadDropWeighmentSlip: '/logistics/uploadDropWeighmentSlip',
	uploadLorryReceipt: '/logistics/uploadLorryReceipt',
	uploadMaterialReceivingDoc: '/logistics/uploadMaterialReceivingDoc',
	shareEnquiry: '/enquiry/shareEnquiry',

	createMachine: '/machine',
	createFactory: '/factory',

	uploadUserBusinessPhotos: '/files/uploadUserBusinessPhotos',
	uploadFactoryPhoto: '/files/uploadFactoryPhoto',
	uploadFactoryMachinePhoto: '/files/uploadFactoryMachinePhoto',
	uploadMachineTemplatePhoto: '/files/uploadMachineTemplatePhoto',
	createFabricationOrder: '/factory/createFabricationOrder',
	uploadFabricationInvoice: '/files/uploadFabricationInvoice',
	uploadFabricationJourneyPhotos: '/files/uploadFabricationJourneyPhotos',
	uploadFactoryReceivingPhoto: '/files/uploadFactoryReceivingPhoto',
	uploadFactoryMbReceivingPhoto: '/files/uploadFactoryMbReceivingPhoto',
	uploadTestCertificate: '/files/uploadTestCertificate',
	uploadBuyerInvoiceDocuments: '/files/uploadBuyerInvoiceDocuments',
	uploadSupplierInvoiceDocuments: '/files/uploadSupplierInvoiceDocuments',

	toolsEndorseInvoice: '/tools/endorseInvoice',
	toolsEndorseTestCertificate: '/tools/endorseTestCertificate',
	logisticsPrices: '/logisticsPrices',

	createUserInteraction: '/userInteraction',
	closeUserInteraction: '/userInteraction/markAsClosed',
	updateOffer: '/userInteraction/updateOffer',

	addInvoice: '/invoice',
	toolsCalculateDistance: '/tools/calculateDistance',

	createCreditReport: '/creditReport',
	downloadCreditReport: '/creditReport/downloadCreditReport',
	updateSalesTeamFeedback: '/creditReport/updateSalesTeamFeedback',
	updateTenorAmount: '/creditReport/updateTenorAmount',
	fetchPastRecords: '/creditReport/fetchPastRecords',
	markAsCompleted: '/creditReport/markAsCompleted',
	uploadProcessFile: '/creditReport/uploadProcessFile',
	initiateGenerateReport: '/creditReport/initiateGenerateReport',
	uploadFsaReport: '/creditReport/uploadFsaReport',
	updateTeamFeedback: 'creditReport/updateTeamFeedback',

	fetchInvoices: '/zoho/fetchInvoices',
	fetchLogisticsBill: '/zoho/fetchBills',

	addTrip: '/order/trip',
	closeTrip: '/order/closeTrip',

	generateAadhaarOTP: '/kyc/generateAadhaarOtp',
	verifyAadhaarOTP: '/kyc/verifyAadhaarOTP',
	uploadKYCDocument: '/kyc/addDocument',
	verifyPan: '/kyc/panVerification',
	commodity: '/commodity',
	addCommodityPriceList: '/commodity-price/addList',

	requestCredit: '/requestCredit/requestCredit',
	uploadFinancialDocs: '/requestCredit/uploadFinancialDocs',
	refreshZohoPastRecords: '/zoho/refreshPastRecordOfUser',
	createBanner: '/banner/create',

	createOrganisation: '/organisation/createOrganisation',
	addUser: '/organisation/addUser',
	addRemarks: '/organisation/addRemarks',
	activateCommodity: '/organisation/activateCommodity',
	addUserCommodity: '/organisation/addCommodity',
	updateReportingManager: '/user/updateReportingManager',
	addSalesAdmin: '/organisation/addSalesAdmin',

	createContent: '/content/createContent',
	contentUploadFile: 'content/uploadFile',

	uploadCran: '/financials/uploadCran',
	addPdc: '/financials/addPdc',
	uploadOrgFinancialDocument: '/financials/uploadDocument',

	createLead: '/lead',

	createPunchOrder: '/punchOrder',
	uploadPunchOrderFile: '/punchOrder/uploadFile',

	createCreditLimit: '/creditLimit',
	uploadCreditLimitDocument: '/creditLimit/uploadFile',
	uploadPdcChequeImage: '/financials/uploadChequeImage',

	createGlossary: '/glossary'
}

export default postUrl
