import React from 'react'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import { AddressCard, Button, Calendar, Input, Select, TOAST_TYPE, UploadButton } from '@base'
import { Devices, Spacings, Text } from '@styles'
import { DRAWER_TYPES, LOGISTICS_HANDLER_TYPES, preferredPaymentTerms, REGION_TYPES, strings } from '@constants'
import { addAddressToOrganisation, getUserAddressesApi } from '@data'
import { toggleDrawer } from '@data/state/action/root'
import { showToast } from '@data/state/action'

const getDate = (date) => (+date === 0 ? new Date().getTime() : +date)

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	column-gap: ${Spacings.SPACING_16B};
	& > div {
		width: 100%;
	}
	@media ${Devices.tablet} {
		flex-direction: row;
		padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_10B};
	}
`
const LeftWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const RightWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const InputWrapper = styled.div`
	padding: ${Spacings.SPACING_1B} 0;

	& > div {
		width: 100%;
	}
`

const BiFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	column-gap: ${Spacings.SPACING_3B};
	& > div {
		width: 100%;
	}
	@media (${Devices.tablet}) {
		flex-direction: row;
	}
`

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: ${Spacings.SPACING_5B} 0;
	& > div {
		width: 100%;
	}
`

const SectionHeading = styled.div`
	${({ isRequired }) => {
		if (isRequired) {
			return css`
				:after {
					font-size: ${Text.EXTRA_SMALL};
					content: ' (required *)';
					color: red;
					position: relative;
					top: -13%;
				}
			`
		}
	}}
`

const SectionItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: end;
	column-gap: ${Spacings.SPACING_3B};
	height: fit-content;
`

const AddressWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${Spacings.SPACING_3B};
`

const PROJECT_TYPES = {
	NHAI: 'NHAI',
	BORDER_ROAD: 'BORDER ROAD',
	CPWD_EDUCATION: 'CPWD- EDUCATION',
	CPWD_HOSPITAL: 'CPWD- HOSPITAL',
	CPWD_OTHERS: 'CPWD-OTHERS',
	FABRICATION_UNITS_TRADERS: 'FABRICATION UNITS/TRADERS',
	GENERAL_ENGINEERING: 'GENERAL ENGINEERING',
	MES: 'MES',
	OEM_2WHEELERS: 'OEM- 2WHEELERS',
	OEM_4WHEELERS: 'OEM- 4 WHEELERS',
	OTHERS: 'OTHERS',
	PEB: 'PEB',
	PRIVATE_CONSTRUCTIONS_PROJECT: 'PRIVATE CONSTRUCTIONS PROJECT',
	REAL_ESTATE: 'REAL ESTATE',
	RVNL: 'RVNL',
	STATE_PWD_STATE_NAME: 'STATE PWD (STATE NAME)',
	TUBE_INDUSTRY: 'TUBE INDUSTRY',
}

const businessCategorytypes = [
	{ key: 'MARKETPLACE', label: 'MARKETPLACE' },
	{ key: 'SCRAP', label: 'SCRAP' },
	{ key: 'CLOUD_FACTORY', label: 'CLOUD FACTORY' },
	{ key: 'ECOM', label: 'ECOM' },
	{ key: 'RECYCLING_BUSINESS_MS', label: 'RECYCLING BUSINESS -MS' },
	{ key: 'NON_FERROUS', label: 'NON FERROUS' },
	{ key: 'BITUMEN', label: 'BITUMEN' },
	{ key: 'EXPORT', label: 'EXPORT' },
	{ key: 'STRUCTURE', label: 'STRUCTURE' },
]
const regionTypes = Object.entries(REGION_TYPES).map(([key, value]) => ({ key, label: value }))

const logisticsHandlerTypes = Object.entries(LOGISTICS_HANDLER_TYPES).map(([key, value]) => ({ key, label: value }))

const projectNameTypes = Object.entries(PROJECT_TYPES).map(([key, value]) => ({ key, label: value }))

const paymentTypes = Object.entries(preferredPaymentTerms).map(([key, value]) => ({ key, label: value }))

const EstimateForm = ({ formState, updateState, clientData, spocList, approvalProofCheck, viewFileHandler, uploadDocumentHandler, isView }) => {
	const dispatch = useDispatch()

	const updateCallback = (address, isNewAddress = false) => {
		updateState('shipping_address')(address)
	}

	const addToOrgCallback = (address) => {
		addAddressToOrganisation(address, formState?.user_details?.value?.organisation_id)
			.then(() => {
				dispatch(showToast(true, 'Successfully added a new address', { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, 'Error while adding a new address', { type: TOAST_TYPE.ERROR }))
				console.log(err)
			})
	}

	const editAddressHandler = () => {
		let addresses = []
		if (formState?.user_details?.value?.user_id) {
			getUserAddressesApi(formState?.user_details?.value?.user_id)
				.then((response) => {
					addresses = response
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					dispatch(
						toggleDrawer(true, DRAWER_TYPES.SELECT_ADDRESS_FROM_ADDRESSBOOK, {
							user: { ...formState?.user_details?.value, addresses: addresses },
							updateCallback,
							label: strings('select_address'),
							addToOrgCallback,
							addToOrg: true,
						})
					)
				})
		}
	}

	const marginChange = (key) => (value) => {
		updateState(key)(value)

		if (key === 'contribution_margin' && formState?.marketing_expense?.value !== '') {
			updateState('net_margin')(value - formState?.marketing_expense?.value)
		} else if (key === 'marketing_expense' && formState?.contribution_margin?.value !== '') {
			updateState('net_margin')(formState?.contribution_margin?.value - value)
		} else {
			updateState('net_margin')(0)
		}

		if (value === '') {
			updateState('net_margin')(0)
		}
	}

	return (
		<MainWrapper>
			<LeftWrapper>
				<InputWrapper>
					<Select
						id={formState?.user_details.id}
						label={formState?.user_details?.label}
						placeholder={formState?.user_details?.placeholder}
						data={clientData}
						displayKey={formState?.user_details?.displayKey}
						primaryKey={formState?.user_details?.primaryKey}
						value={formState?.user_details?.value ?? {}}
						onChange={updateState('user_details')}
						disabled={formState?.user_details?.disabled || isView}
						required={formState?.user_details?.required}
					/>
				</InputWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Select
							id={formState?.business_category?.id}
							label={formState?.business_category?.label}
							placeholder={formState?.business_category?.placeholder}
							data={businessCategorytypes}
							displayKey={formState?.business_category?.displayKey}
							primaryKey={formState?.business_category?.primaryKey}
							value={businessCategorytypes.find((item) => item.key === formState?.business_category?.value) ?? {}}
							onChange={updateState('business_category')}
							disabled={formState?.business_category?.disabled || isView}
							required={formState?.business_category?.required}
						/>
					</InputWrapper>
					<InputWrapper>
						<Select
							id={formState?.region?.id}
							label={formState?.region?.label}
							placeholder={formState?.region?.placeholder}
							data={regionTypes}
							displayKey={formState?.region?.displayKey}
							primaryKey={formState?.region?.primaryKey}
							value={regionTypes.find((item) => item.key === formState?.region?.value) ?? {}}
							onChange={updateState('region')}
							disabled={formState?.region?.disabled || isView}
							required={formState?.region?.required}
						/>
					</InputWrapper>
				</BiFieldWrapper>
				<InputWrapper>
					<Select
						id={formState?.project_name?.id}
						placeholder={formState?.project_name?.placeholder}
						label={formState?.project_name?.label}
						data={projectNameTypes}
						value={projectNameTypes.find((item) => item.key === formState?.project_name?.value) ?? {}}
						disabled={formState?.project_name?.disabled || isView}
						required={formState?.project_name?.required}
						primaryKey={formState?.project_name?.primaryKey}
						displayKey={formState?.project_name?.displayKey}
						onChange={updateState('project_name')}
					/>
				</InputWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Select
							id={formState?.supplier?.id}
							label={formState?.supplier?.label}
							placeholder={formState?.supplier?.placeholder}
							data={spocList}
							displayKey={formState?.supplier?.displayKey}
							primaryKey={formState?.supplier?.primaryKey}
							value={formState?.supplier?.value ?? {}}
							onChange={updateState('supplier')}
							disabled={formState?.supplier?.disabled || isView}
							required={formState?.supplier?.required}
						/>
					</InputWrapper>
					<InputWrapper>
						<Select
							id={formState?.logistics_handler?.id}
							label={formState?.logistics_handler?.label}
							placeholder={formState?.logistics_handler?.placeholder}
							data={logisticsHandlerTypes}
							displayKey={formState?.logistics_handler?.displayKey}
							primaryKey={formState?.logistics_handler?.primaryKey}
							value={logisticsHandlerTypes.find((item) => item.key === formState?.logistics_handler?.value) ?? {}}
							onChange={updateState('logistics_handler')}
							disabled={formState?.logistics_handler?.disabled || isView}
							required={formState?.logistics_handler?.required}
						/>
					</InputWrapper>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Input
							id={formState?.total_quantity?.id}
							type='number'
							placeholder={formState?.total_quantity?.placeholder}
							label={formState?.total_quantity?.label}
							value={formState?.total_quantity?.value}
							disabled={formState?.total_quantity?.disabled || isView}
							required={formState?.total_quantity?.required}
							onChange={updateState('total_quantity')}
						/>
					</InputWrapper>
					<InputWrapper>
						<Input
							id={formState?.total_po_amount?.id}
							type='number'
							placeholder={formState?.total_po_amount?.placeholder}
							label={formState?.total_po_amount?.label}
							value={formState?.total_po_amount?.value}
							disabled={formState?.total_po_amount?.disabled || isView}
							required={formState?.total_po_amount?.required}
							onChange={updateState('total_po_amount')}
						/>
					</InputWrapper>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Input
							id={formState?.contribution_margin?.id}
							type='number'
							placeholder={formState?.contribution_margin?.placeholder}
							label={formState?.contribution_margin?.label}
							value={formState?.contribution_margin?.value}
							disabled={formState?.contribution_margin?.disabled || isView}
							required={formState?.contribution_margin?.required}
							onChange={marginChange('contribution_margin')}
						/>
					</InputWrapper>
					<InputWrapper>
						<Input
							id={formState?.marketing_expense?.id}
							type='number'
							placeholder={formState?.marketing_expense?.placeholder}
							label={formState?.marketing_expense?.label}
							value={formState?.marketing_expense?.value}
							disabled={formState?.marketing_expense?.disabled || isView}
							required={formState?.marketing_expense?.required}
							onChange={marginChange('marketing_expense')}
						/>
					</InputWrapper>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Select
							id={formState?.payment_terms?.id}
							placeholder={formState?.payment_terms?.placeholder}
							label={formState?.payment_terms?.label}
							data={paymentTypes}
							value={paymentTypes.find((item) => item.label === formState?.payment_terms?.value) ?? {}}
							displayKey={formState?.payment_terms?.displayKey}
							primaryKey={formState?.payment_terms?.primaryKey}
							disabled={formState?.payment_terms?.disabled || isView}
							required={formState?.payment_terms?.required}
							onChange={updateState('payment_terms')}
						/>
					</InputWrapper>
					<InputWrapper>
						<Calendar
							label={formState?.expected_delivery_date?.label}
							id={formState?.expected_delivery_date?.id}
							value={getDate(formState.expected_delivery_date.value)}
							onChange={updateState('expected_delivery_date')}
							endOfDay={false}
							disabled={isView}
						/>
					</InputWrapper>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Input
							id={formState?.payment_tenure?.id}
							type='number'
							placeholder={formState?.payment_tenure?.placeholder}
							label={formState?.payment_tenure?.label}
							value={formState?.payment_tenure?.value}
							disabled={formState?.payment_tenure?.disabled || isView}
							required={formState?.payment_tenure?.required}
							onChange={updateState('payment_tenure')}
						/>
					</InputWrapper>
					<InputWrapper>
						<Input
							id={formState?.expected_transit_time?.id}
							type='number'
							placeholder={formState?.expected_transit_time?.placeholder}
							label={formState?.expected_transit_time?.label}
							value={formState?.expected_transit_time?.value}
							disabled={formState?.expected_transit_time?.disabled || isView}
							required={formState?.expected_transit_time?.required}
							onChange={updateState('expected_transit_time')}
						/>
					</InputWrapper>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<InputWrapper>
						<Input
							id={formState?.net_margin?.id}
							type='number'
							placeholder={formState?.net_margin?.placeholder}
							label={formState?.net_margin?.label}
							value={formState?.net_margin?.value}
							disabled={formState?.net_margin?.disabled || isView}
							required={formState?.net_margin?.required}
						/>
					</InputWrapper>
					<InputWrapper>
						<Input
							id={formState?.freight_per_mt?.id}
							type='number'
							placeholder={formState?.freight_per_mt?.placeholder}
							label={formState?.freight_per_mt?.label}
							value={formState?.freight_per_mt?.value}
							disabled={formState?.freight_per_mt?.disabled || isView}
							required={formState?.freight_per_mt?.required}
							onChange={updateState('freight_per_mt')}
						/>
					</InputWrapper>
				</BiFieldWrapper>
			</LeftWrapper>
			<RightWrapper>
				<SectionWrapper>
					<SectionHeading isRequired={approvalProofCheck}>{strings(['management_approval_form', ':'])}</SectionHeading>
					<SectionItem>
						<Button
							type='secondary'
							variant='destructive'
							xs
							disabled={!formState?.management_approval?.value}
							onClick={() => {
								viewFileHandler('management_approval')(formState?.management_approval?.value)
							}}
						>
							{strings('view')}
						</Button>
						<UploadButton
							label={strings(['upload'])}
							small
							type='primary'
							disabled={isView}
							onChange={(file) => uploadDocumentHandler('management_approval')(file)}
							xs
						/>
					</SectionItem>
				</SectionWrapper>
				<SectionWrapper>
					<SectionHeading isRequired={!formState?.customer_po?.value}>{strings(['customer', 'po', 'confirmation', ':'])}</SectionHeading>
					<SectionItem>
						<Button
							type='secondary'
							xs
							variant='destructive'
							disabled={!formState?.customer_po?.value}
							onClick={() => viewFileHandler('customer_po')(formState?.customer_po?.value)}
						>
							{strings('view')}
						</Button>
						<UploadButton
							label={strings(['upload'])}
							small
							type='primary'
							disabled={isView}
							onChange={(file) => uploadDocumentHandler('customer_po')(file)}
							xs
						/>
					</SectionItem>
				</SectionWrapper>

				<AddressWrapper>
					<SectionWrapper>
						<SectionHeading>{strings(['shipping_location', ':'])}</SectionHeading>
						<Button
							type='primary'
							xs
							disabled={Object.keys(formState?.user_details?.value).length === 0 || isView}
							onClick={editAddressHandler}
						>
							{Object.keys(formState?.shipping_address?.value).length === 0 ? strings('select') : strings('edit')}
						</Button>
					</SectionWrapper>
					{Object.keys(formState?.shipping_address?.value).length !== 0 && <AddressCard data={formState?.shipping_address?.value} />}
				</AddressWrapper>
				{isView && (
					<>
						<InputWrapper>
							<Input
								id={formState?.po_number?.id}
								type='text'
								placeholder={formState?.po_number?.placeholder}
								label={formState?.po_number?.label}
								value={formState?.po_number?.value}
								disabled={formState?.po_number?.disabled}
								required={formState?.po_number?.required}
								onChange={updateState('po_number')}
							/>
						</InputWrapper>
						<BiFieldWrapper>
							<InputWrapper>
								<Input
									id={formState?.enquiry_id?.id}
									type='text'
									placeholder={formState?.enquiry_id?.placeholder}
									label={formState?.enquiry_id?.label}
									value={formState?.enquiry_id?.value}
									disabled={formState?.enquiry_id?.disabled}
									required={formState?.enquiry_id?.required}
									onChange={updateState('enquiry_id')}
								/>
							</InputWrapper>
							<InputWrapper>
								<Input
									id={formState?.order_id?.id}
									type='text'
									placeholder={formState?.order_id?.placeholder}
									label={formState?.order_id?.label}
									value={formState?.order_id?.value}
									disabled={formState?.order_id?.disabled}
									required={formState?.order_id?.required}
									onChange={updateState('order_id')}
								/>
							</InputWrapper>
						</BiFieldWrapper>
					</>
				)}
			</RightWrapper>
		</MainWrapper>
	)
}

export default EstimateForm
