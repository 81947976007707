import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, Toggle } from '@base'
import { Devices, Spacings } from '@styles'
import { strings } from '@constants'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const TotalAmountSection = styled.div`
	padding: ${Spacings.SPACING_3B};
	display: flex;
	user-select: none;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		padding: 0 0 ${Spacings.SPACING_4B} 0;
	}
`
const FlexWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const LogisticsPartnerForm = React.memo(({ state, updateState, viewOnly }) => {
	const { logistics_quote_per_mt, lumpsum_logistics_quote, minimum_guaranteed_weight } = state
	const [isQuoteType, setQuoteType] = useState(strings('cost_mt'))
	useEffect(() => {
		if (lumpsum_logistics_quote.value !== 0 && lumpsum_logistics_quote.value !== '') {
			setQuoteType(strings('lumpsum'))
		}
		if (logistics_quote_per_mt.value !== 0 && logistics_quote_per_mt.value !== '') {
			setQuoteType(strings('cost_mt'))
		}
	}, [lumpsum_logistics_quote.value])

	const onChange = (key) => (value) => {
		if (key === 'logistics_quote_per_mt') {
			updateState(key, { value, dirty: true })
			updateState('lumpsum_logistics_quote', { value: '', dirty: true })
		} else if (key === 'lumpsum_logistics_quote') {
			updateState(key, { value, dirty: true })
			updateState('logistics_quote_per_mt', { value: '', dirty: true })
		} else {
			updateState(key, { value, dirty: true })
		}
	}

	const setQuoteTypeHandler = (type) => (e) => {
		e.stopPropagation()
		if (isQuoteType === type) return
		setQuoteType(type)
	}

	return (
		<FormWrapper>
			<TotalAmountSection>
				<FlexWrapper>
					<Toggle
						options={[
							{
								label: strings('cost_per_mt'),
								click: setQuoteTypeHandler(strings('cost_mt')),
								active: isQuoteType === strings('cost_mt'),
							},
							{
								label: strings('lumpsum'),
								click: setQuoteTypeHandler(strings('lumpsum')),
								active: isQuoteType === strings('lumpsum'),
							},
						]}
					/>
				</FlexWrapper>
			</TotalAmountSection>

			{isQuoteType === strings('cost_mt') ? (
				<SectionItemWrapper>
					<Input
						type='number'
						label={logistics_quote_per_mt?.label}
						value={logistics_quote_per_mt?.value}
						onChange={onChange('logistics_quote_per_mt')}
						disabled={viewOnly}
					/>
				</SectionItemWrapper>
			) : (
				<SectionItemWrapper>
					<Input
						type='number'
						label={lumpsum_logistics_quote?.label}
						value={lumpsum_logistics_quote?.value}
						onChange={onChange('lumpsum_logistics_quote')}
						disabled={viewOnly}
					/>
				</SectionItemWrapper>
			)}

			<SectionItemWrapper>
				<Input
					type='number'
					label={minimum_guaranteed_weight?.label}
					value={minimum_guaranteed_weight?.value}
					onChange={onChange('minimum_guaranteed_weight')}
					disabled={viewOnly}
				/>
			</SectionItemWrapper>
		</FormWrapper>
	)
})

export default LogisticsPartnerForm
