import API from '../api'
import convertPagableData from '../convertPagableData'
import getUrl from './paths'

export const getOrganisation = (orgId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getOrganisation, { orgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllOrganisations = (page = 0,size=10, orgFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllOrganisations, { page,size, ...orgFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getOrganisationByUser = (userId) =>
	new Promise((resolve, reject) =>
		API.get(getUrl.getOrganisationByUser, { userId, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const getAllOrgLocations = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllOrganisationLocations)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllLogisticPartners = () =>
	new Promise((resolve, reject) =>
		API.get(getUrl.getAllLogisticPartners)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const getUsersOfOrganisation = (organisationId, userId) =>
	new Promise((resolve, reject) =>
		API.get(getUrl.getUsersOfOrganisation, { organisationId, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const getOrganisationKycData = (organisationId) =>
	new Promise((resolve, reject) =>
		API.get(getUrl.getOrganisationKycData, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const getLedgersOfUser = (organisationId, page = 0, orgFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLedgersOfUser, { organisationId, page, ...orgFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})
export const getLedgerSummaryByOrgId = (organisationId, ledgerFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLedgerSummaryByOrgId, { organisationId, ...ledgerFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

