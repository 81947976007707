import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Clear } from '@material-ui/icons'
import { editEnquiry, getAllEmployees } from '@data'
import { Spacings, Text } from '@styles'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, SectionHeader, Select, TOAST_TYPE } from '@base'
import { User } from '@data/model'
import { enquiryStatus, strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'

const BodyWrapper = styled.div``
const SelectWrapper = styled.div``
const SelectedEmpoyee = styled.div`
	display: flex;
	align-items: center;
	font-size: ${Text.MEDIUM};
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	margin: ${Spacings.SPACING_2B} 0;

	& > div {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
`
const ClearIcon = styled(Clear)`
	cursor: pointer;
`

const MakePrimaryButton = styled.span`
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.blue};
	border-radius: ${Spacings.SPACING_2B};
	border: 1px solid ${({ theme }) => theme.palette.text.blue};
	cursor: pointer;
	width: fit-content;
	margin-right: ${Spacings.SPACING_3B};

	${({ showAsText }) => {
		if (showAsText) {
			return css`
				cursor: initial;
				background: ${({ theme, isSupply }) => (isSupply ? theme.palette.background.greenDark : theme.palette.background.messageRed)};
				color: ${({ theme }) => theme.palette.text.white};
				border: none;
			`
		}
	}}
`

const AssignToEmployee = ({ enquiry, update }) => {
	const dispatch = useDispatch()
	const [employees, setEmployees] = useState([])
	const [loading, setLoading] = useState(false)
	const user = useSelector((_state) => _state.getIn(['user', 'user']))

	const { primarySaleUser, primarySupplyUser } = useMemo(() => {
		if (enquiry?.relationship_manager && enquiry?.relationship_manager.length) {
			return {
				primarySaleUser: enquiry.relationship_manager.find((_rm) => _rm.is_primary),
				primarySupplyUser: enquiry.relationship_manager.find((_rm) => _rm.is_primary_supply),
			}
		}
		return null
	}, [enquiry])

	const alreadyAssignedUserIds = useMemo(() => {
		if (enquiry?.relationship_manager && enquiry?.relationship_manager.length) {
			return enquiry.relationship_manager.map((_rm) => _rm.user_id)
		}
		return null
	}, [enquiry])

	const selectableEmployees = employees.filter((_e) => !_e.selected)
	const selectedEmployees = employees.filter((_e) => _e.selected)

	// const isPrimaryUserSame = () => {
	// 	const selectedPrimaryUser = selectedEmployees.find((_e) => _e.isPrimary)
	// 	return selectedPrimaryUser && primarySaleUser && selectedPrimaryUser?.user_id === primarySaleUser?.user_id
	// }

	// const sameAsAlreadyAssigned = () => {
	// 	if (!alreadyAssignedUserIds) return false

	// 	const selectedIDs = selectedEmployees.map((_se) => _se.user_id)
	// 	return (
	// 		Boolean(selectedIDs.length === alreadyAssignedUserIds.length && selectedIDs.every((_id) => alreadyAssignedUserIds.includes(_id))) &&
	// 		isPrimaryUserSame()
	// 	)
	// }

	const disabled = selectedEmployees?.length === 0 || loading

	useEffect(() => {
		setLoading(true)
		getAllEmployees()
			.then((res) => {
				let employees = res
				// setting already assigned employees
				if (alreadyAssignedUserIds) {
					employees = employees.map((_e) => {
						if (alreadyAssignedUserIds.includes(_e.user_id)) {
							_e.selected = true

							// setting primary user
							if (primarySaleUser && primarySaleUser.user_id === _e.user_id) {
								_e.isPrimary = true
							}
							if (primarySupplyUser && primarySupplyUser.user_id === _e.user_id) {
								_e.isPrimarySupply = true
							}
						}
						return _e
					})
				}

				employees = employees.map((_e) => ({ ..._e, display_name_number: _e.full_name ?? _e.mobile_number }))
				setEmployees(employees)
			})
			.catch((err) => {
				console.log('Error: ', err)
			})
			.finally(() => setLoading(false))
	}, [])

	// useEffect(() => {
	// 	if (selectedEmployees.length === 0) return

	// 	// If there no primary user, make the first one primary
	// 	const primaryUser = selectedEmployees.find((_e) => _e.isPrimary)
	// 	const _primarySupplyUser = selectedEmployees.find((_e) => _e.isPrimarySupply)
	// 	if (!primaryUser) {
	// 		makePrimary(selectedEmployees[0]?.user_id, 'sales')
	// 	}

	// 	if (!_primarySupplyUser) {
	// 		if (selectedEmployees.length > 1) {
	// 			if (primaryUser && primaryUser.user_id === selectedEmployees[1]?.user_id) {
	// 				makePrimary(selectedEmployees[0]?.user_id, 'supply')
	// 			} else makePrimary(selectedEmployees[1]?.user_id, 'supply')
	// 		}
	// 	}
	// }, [selectedEmployees])

	const onChange = (selectedEmpoyee) => {
		if (selectedEmpoyee) {
			setEmployees((_data) =>
				_data.map((_e) => {
					if (_e.user_id === selectedEmpoyee.user_id) {
						_e.selected = true
					}
					return _e
				})
			)
		}
	}

	const assignHandler = () => {
		setLoading(true)
		const relationshipManagers = selectedEmployees.map((_e) => {
			const _temp = new User(_e).getUserDetails()
			if (_e.isPrimary) {
				_temp.is_primary = true
			}
			if (_e.isPrimarySupply) {
				_temp.is_primary_supply = true
			}
			return _temp
		})

		if (enquiry.enquiry_status === enquiryStatus.created) {
			enquiry.enquiry_status = enquiryStatus.unverified
		}

		enquiry.relationship_manager = relationshipManagers

		dispatch(showLoader(true))

		editEnquiry({ enquiry, user, enquiryClosed: true })
			.then((res) => {
				if (res.status === 200) {
					update()
					dispatch(showToast(true, strings('msg_enquiry_assigned'), { type: TOAST_TYPE.SUCCESS }))
					dispatch(toggleDrawer(false))
				} else {
					return Promise.reject()
				}
			})
			.catch((err) => {
				dispatch(showToast(true, strings('unable_assign_enquiry'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
				setLoading(false)
			})
	}

	const removeHandler = (userId) => {
		if (!loading) {
			setEmployees((_data) =>
				_data.map((_e) => {
					if (_e.user_id === userId) {
						_e.selected = false
						if (_e.isPrimary) {
							_e.isPrimary = false
						}
						if (_e.isPrimarySupply) {
							_e.isPrimarySupply = false
						}
					}
					return _e
				})
			)
		}
	}

	const makePrimary = (userId, type = 'sales') => {
		if (!loading) {
			setEmployees((_data) =>
				_data.map((_e) => {
					if (type === 'supply') {
						_e.isPrimarySupply = _e.user_id === userId
					} else {
						_e.isPrimary = _e.user_id === userId
					}
					return _e
				})
			)
		}
	}

	const renderSelectedEmpoyees = () =>
		selectedEmployees.map((_employee) => (
			<SelectedEmpoyee key={`employee-${_employee?.user_id}`}>
				<div>
					<span>
						<b>{_employee.full_name}</b>
					</span>
					<span>
						<small>{_employee.mobile_number}</small>
					</span>
				</div>
				{!(_employee?.isPrimary) && (
					<MakePrimaryButton onClick={() => makePrimary(_employee.user_id, 'sales')}>{strings('set_sales_primary')}</MakePrimaryButton>
				)}
				{!(_employee?.isPrimarySupply) && (
					<MakePrimaryButton onClick={() => makePrimary(_employee.user_id, 'supply')}>{strings('set_supply_primary')}</MakePrimaryButton>
				)}
				{_employee?.isPrimary && <MakePrimaryButton showAsText>{strings('primary')}</MakePrimaryButton>}
				{_employee?.isPrimarySupply && (
					<MakePrimaryButton showAsText isSupply>
						{strings('supply', 'primary')}
					</MakePrimaryButton>
				)}
				{!(_employee?.isPrimary || _employee?.isPrimarySupply) && (
					<ClearIcon fontSize='small' onClick={() => removeHandler(_employee.user_id)} />
				)}
			</SelectedEmpoyee>
		))

	return (
		<>
			<DrawerBodyWrapper>
				<BodyWrapper>
					<SelectWrapper>
						<Select
							disabled={loading}
							id='employee-select'
							placeholder={loading ? strings('loading_employees') : strings('select_employee')}
							data={selectableEmployees}
							displayKey='display_name_number'
							primaryKey='user_id'
							value={{}}
							onChange={onChange}
							label={strings('select_employee_s')}
						/>
					</SelectWrapper>
					{renderSelectedEmpoyees()}
				</BodyWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={disabled} onClick={assignHandler}>
					{strings('assign')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AssignToEmployee
