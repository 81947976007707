import React from 'react'
import { FormWrapper, SectionItemWrapper } from '../../styles'
import { Input, Select } from '@base'
import { strings } from '@constants'

const _type = [{ name: 'metal' }, { name: 'oil' }, { name: "plastic" }, { name: "fabric" }, {name: "others"}]

const CommodityForm = React.memo(({ state, onCommodityChange }) => {
	const { commodity_name, commodity_type } = state

	let selectedCommodity = _type.find((cls) => cls.name === commodity_type)

	const onChange = (key) => (value) => {
		onCommodityChange(key, key === 'commodity_name'?value?.toLowerCase(): value)
	}

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Input
					type='text'
					label={strings('name')}
					value={commodity_name}
					onChange={onChange('commodity_name')}
					placeholder={strings('msg_no_special_char')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Select
					id='type'
					disabled={false}
					label={strings('type')}
					displayKey={'name'}
					primaryKey={'name'}
					placeholder={strings('select_type')}
					data={_type}
					value={selectedCommodity}
					onChange={onChange('commodity_type')}
				/>
			</SectionItemWrapper>
		</FormWrapper>
	)
})

export default CommodityForm
