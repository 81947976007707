import API from '../api'
import putUrl from './paths'

export const updateLogisticsPrice = (_formData) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateLogisticsPrices, _formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const deleteLogisticsInvoice = ( logisticsInvoiceId,logisticsOrderId) =>
	new Promise((resolve, reject) => {
		const queryParams = {
			logisticsOrderId,
			logisticsInvoiceId
		}
		API.put(putUrl.deleteLogisticsInvoice, {}, queryParams)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})