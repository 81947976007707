import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Brand, Product, Category as CategoryModel } from '@data/model'
import { DrawerBodyWrapper, TOAST_TYPE } from '@base'
import { strings, brands } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { showLoader, showToast } from '@data/state/action'
import { createProduct, editProduct, getCategories } from '@data'
import CreateProductForm from './CreateProductForm'
import { Footer, ActionButton } from '../styles'
import { capitalizeWord } from '@utils'

const convertAttributesToString = (attributesObject, category_attributes, thicknessFirst) => {
	let _newAttributes = ['width', 'height', 'diameter', 'thickness', 'gsm', 'length', 'customDetails']
	if (thicknessFirst) {
		_newAttributes = ['thickness', 'width', 'height', 'diameter', 'gsm', 'length', 'customDetails']
	}

	const dimensions = _newAttributes.reduce((accumulator, currentValue, index) => {
		if (index === 0) {
			accumulator = ''
		}
		if (attributesObject[currentValue] && category_attributes[currentValue] === true) {
			if (accumulator === '') {
				//   return attributesObject[currentValue] + 'mm';
				return attributesObject[currentValue]
			}
			// return accumulator + ' * ' + attributesObject[currentValue] + 'mm';
			return accumulator + ' * ' + attributesObject[currentValue]
		} else {
			return accumulator
		}
	}, 0)

	return dimensions
}

const createPID = (cid, name) => {
	if (!name) {
		return ''
	}
	return /*cid.replace(/\//,'').replace(/ /g, '') + ' ' + */ name.toLowerCase().trim().replace(/\*/g, ' ').replace(/\s\s+/g, ' ').replace(/ /g, '_')
}

const CreateProduct = ({ data, update }) => {
	const edit = !!data
	const [product, setProduct] = useState(new Product())
	const [categories, setCategories] = useState()

	const dispatch = useDispatch()

	const selectedCategory = useMemo(
		() => (categories ?? []).find((_c) => _c.category_id === product?.category_id) ?? new CategoryModel(),
		[categories, product]
	)

	const availableBrands = useMemo(() => {
		return brands.map(
			(_b) =>
				new Brand({
					brand_name: _b,
					rating: data?.rating,
					usual_price_per_kg: data?.usual_price_per_kg,
					weight_per_piece: data?.weight_per_piece,
				})
		)
	}, [data])

	useEffect(() => {
		getCategories(0).then((response) => {
			setCategories(response.data)
		})
	}, [])

	useEffect(() => {
		if (data) {
			setProduct({ ...data })
		}
	}, [data])

	const onProductChange = (key, value) => {
		const tempProduct = Object.assign({}, product)
		if (key === 'product_name') {
			tempProduct['product_name'] = value
			if (!edit) {
				tempProduct['product_id'] = createPID(selectedCategory.category_id, value)
			}
		} else if (key === 'category') {
			const dimensions = convertAttributesToString(product.product_attributes, value.category_attributes, value.thickness_first)
			tempProduct['category_id'] = value.category_id
			tempProduct['product_name'] = `${value.category_name} ${dimensions}`
			tempProduct['product_id'] = createPID(value.category_id, tempProduct.product_name)
		} else {
			tempProduct[key] = value
		}

		setProduct(tempProduct)
	}

	const setCategory = (value) => {
		if (value?.category_id) {
			onProductChange('category', value)
		}
	}

	const submitHandler = () => {
		if (checkIfProductValid(product)) {
			if (edit) {
				updateProductAction(product, data.product_id)
			} else {
				createProductAction(product)
			}
		}
	}

	const createProductAction = (tempProduct) => {
		dispatch(showLoader(true))
		createProduct(tempProduct)
			.then((_) => {
				dispatch(showToast(true, strings('msg_product_created'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const updateProductAction = (tempProduct, productId) => {
		dispatch(showLoader(true))
		editProduct(tempProduct, productId)
			.then((_) => {
				dispatch(showToast(true, strings('msg_product_editted'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const checkIfProductValid = () => {
		if (!product?.category_id) {
			dispatch(showToast(true, strings('select_category')), { type: TOAST_TYPE.ERROR })
			return false
		}

		let requiredAttribute
		Object.keys(selectedCategory.category_attributes)
			.reverse()
			.forEach((key) => {
				if (selectedCategory.category_attributes[key] && !product.product_attributes[key] && key !== 'length') {
					requiredAttribute = key
				}
			})

		if (requiredAttribute) {
			dispatch(showToast(true, `${capitalizeWord(requiredAttribute)} ${strings('attribute_is_mandatory')}`, { type: TOAST_TYPE.ERROR }))
			return false
		}
		if (product.brands.length === 0) {
			dispatch(showToast(true, strings('msg_pls_select_atleast_one_brand'), { type: TOAST_TYPE.ERROR }))
			return false
		}
		return true
	}

	const cancelEditHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const handleBrandsChange = (brand, toBeSelected) => {
		let productBrands = product.brands ?? []

		if (brand.brand_name === 'ANY') {
			productBrands = availableBrands.filter((_b) => _b.brand_name !== 'ANY')
		} else {
			if (toBeSelected) {
				productBrands.push(brand)
			} else {
				productBrands = productBrands.filter((_b) => _b.brand_name !== brand.brand_name)
			}
		}
		onProductChange('brands', productBrands)
	}

	const selectAllAttributes = (val) => {
		val ? onProductChange('brands', availableBrands) : onProductChange('brands', [])
	}

	const handleAttributeChange = (value, attribute) => {
		const tempProduct = Object.assign({}, product)

		Object.entries(selectedCategory.category_attributes).forEach(([key, value]) => {
			if (value === false) {
				tempProduct.product_attributes[key] = null
			}
		})

		tempProduct.product_attributes = {
			...tempProduct.product_attributes,
			[attribute]: attribute === 'custom_details' ? value?.trim() : parseFloat(parseFloat(value).toFixed(3)),
		}

		const dimensions = convertAttributesToString(
			tempProduct.product_attributes,
			selectedCategory.category_attributes,
			selectedCategory.thickness_first
		)
		tempProduct['category_name'] = selectedCategory.category_name
		tempProduct['product_name'] = `${selectedCategory.category_name} ${dimensions}`
		tempProduct['product_id'] = createPID(selectedCategory.category_id, tempProduct.product_name)

		setProduct(tempProduct)
	}

	const disableButton = !product?.product_name?.trim()

	const renderButtons = () => {
		if (!edit) {
			return (
				<ActionButton small type='primary' disabled={disableButton} onClick={submitHandler}>
					{strings('add')}
				</ActionButton>
			)
		} else {
			return (
				<>
					<ActionButton small disabled={disableButton} type='primary' onClick={submitHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
				</>
			)
		}
	}

	return (
		<>
			<DrawerBodyWrapper>
				<CreateProductForm
					availableBrands={availableBrands}
					categories={categories}
					selectedCategory={selectedCategory}
					setCategory={setCategory}
					state={product}
					handleBrandsChange={handleBrandsChange}
					handleAttributeChange={handleAttributeChange}
					onProductChange={onProductChange}
					selectAllAttributes={selectAllAttributes}
				/>
			</DrawerBodyWrapper>
			<Footer>{renderButtons()}</Footer>
		</>
	)
}

export default CreateProduct
