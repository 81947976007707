import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, RadioButton, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { Label, SectionWrapper, RadioButtonWrapper, StatusWrapper } from '../../Lead/CompleteLead'
import { getDate } from '@utils'
import { SectionItemWrapper } from '../../style'
import { showLoader, showToast } from '@data/state/action'
import { updateSharePrices } from '@data'

const OrgServices = ({ orgId, update, orgData }) => {
	const dispatch = useDispatch()
	const [isServiceEnabled, SetIsServiceEnabled] = useState(false)
	const [date, setDate] = useState()

	useEffect(() => {
		SetIsServiceEnabled(orgData?.share_prices ? orgData?.share_prices : false)
		setDate(orgData?.price_subscription_validity ? new Date(orgData?.price_subscription_validity) : new Date())
	}, [orgData])

	const addServicesHandler = () => {
		dispatch(showLoader(true))
		let _date = isServiceEnabled ? date.getTime() : null
		updateSharePrices(orgId, isServiceEnabled, _date)
			.then((response) => {
				dispatch(showToast(true, strings('msg_services_updated_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let error_msg = error?.message || strings('msg_service_update_fail')
				dispatch(showToast(true, error_msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<div>
					<div>{strings(['share', 'prices'])}</div>
					<StatusWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='services-radio-yes'
								size='1.125'
								checked={isServiceEnabled === true}
								value={true}
								onChange={() => {
									SetIsServiceEnabled(true)
								}}
							/>
							<Label>{strings('yes')}</Label>
						</RadioButtonWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='services-radio-no'
								size='1.125'
								checked={isServiceEnabled === false}
								value={false}
								onChange={() => {
									SetIsServiceEnabled(false)
								}}
							/>
							<Label>{strings('no')}</Label>
						</RadioButtonWrapper>
					</StatusWrapper>
				</div>
				{isServiceEnabled && (
					<SectionWrapper>
						<SectionItemWrapper>
							<Calendar
								label={strings('select', 'date')}
								id='date'
								value={date}
								onChange={(value) => {
									setDate(new Date(value))
								}}
								minDate={new Date()}
							/>
						</SectionItemWrapper>
					</SectionWrapper>
				)}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small xs onClick={addServicesHandler}>
					{strings('save')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default OrgServices
