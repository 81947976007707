class Address {
	constructor(obj) {
		if (obj) {
			Object.assign(this, obj)
			return
		}

		this.id = Math.floor(Math.random() * 1000)
		this.city = ''
		this.complete_address = ''
		this.country = 'India'
		this.country_code = ''
		this.district = ''
		this.name_tag = ''
		this.pincode = ''
		this.state = ''
		this.state_code = ''
	}

	static toString = (address) => {
		return (
			(address.complete_address ? address.complete_address + ', ' : '') +
			(address.city ? address.city + ', ' : '') +
			(address.district ? address.district + ', ' : '') +
			(address.division ? address.division + ', ' : '') +
			(address.state ? address.state + ', ' : '') +
			(address.pincode ? ' (' + address.pincode + ') - ' : '') +
			(address.country || '')
		)
	}

	getCityState = () => {
		return this.city + ', ' + this.state
	}
}

export default Address
