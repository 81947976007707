import API from '../api'
import getUrl from './paths'

export const getZohoClientId = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getZohoClientId)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const fetchContactIdApi = (clientId, gstNo, userId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.fetchContactId, { clientId, gstNo, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getZohoPastRecordOfUser = (userId, clientId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getPastRecordOfUser, { userId, clientId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

