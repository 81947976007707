import API from '../api'
import putUrl from './paths'

export const editPaymentReceivable = (paymentReceivablesId, remarks) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editPaymentReceivables, remarks, { paymentReceivablesId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addRemarkToPaymentReceivables = (remarks, paymentReceivablesId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.addremarkToPaymentReceivables, remarks, { paymentReceivablesId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

// collection status change api
export const changeStatusPaymentReceivables = (paymentReceivablesId, status) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.changeStatusPaymentReceivables, {}, { paymentReceivablesId, status })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const dataCorrection = (organisationId, tradingExpense, transactionId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.dataCorrection, {}, { organisationId, tradingExpense, transactionId, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
