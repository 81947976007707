import styled, { css, keyframes } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { Button } from '@base'
import backgroundImage from '@assets/login/home.jpg'

export const MainWrapper = styled.div`
	height: 100%;
`
export const BackgroundImage = styled.div`
	display: block;
	background: url(${backgroundImage});
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	@media ${Devices.tablet} {
		display: initial;
	}
`
export const LinkedInWapper = styled.div`
	display: flex;
	justify-content: center;
	padding: ${Spacings.SPACING_4B} 0px ${Spacings.SPACING_1B} 0px;
	@media ${Devices.tablet} {
		padding: 20px 0px;
	}
`
export const GoogleImage = styled.img`
	margin-left: 3px;
	border-radius: ${Spacings.SPACING_1B};
	width: 7rem;
	height: 2rem;
`

export const LoginContainer = styled.div`
	width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	position: relative;
	margin: ${Spacings.SPACING_6B};
	border-radius: ${Spacings.SPACING_6B};
	@media ${Devices.tablet} {
		margin: 0px;
		display: flex;
		width: 30%;
		box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.32);
		border-radius: ${Spacings.SPACING_6B} 0px 0px ${Spacings.SPACING_6B};
		height: 100vh;
		overflow: auto;
		padding-bottom: 20px;
	}
	@media ${Devices.laptop} {
		width: 30%;
	}
`
export const Content = styled.div`
	width: 100%;
	display: flex;
	padding: 0 ${Spacings.SPACING_4B};
`

export const LinkWrapper = styled.div`
	text-align: center;
	@media ${Devices.tablet} {
		margin: ${Spacings.SPACING_4B} 0px ${Spacings.SPACING_4B} 0px;
	}
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.background.borderColor};
`

export const LogoContainer = styled.div`
	margin-top: ${Spacings.SPACING_3B};
	display: flex;
	align-items: center;
	justify-content: center;
	@media ${Devices.mobile} {
		margin-top: ${Spacings.SPACING_12B};
	}
`

export const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`
export const RecaptchaContainer = styled.div`
	visibility: hidden;
	.grecaptcha-badge {
		right: 0px !important;
	}
`

export const RelativeAnimationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : Spacings.SPACING_1B)};
`

export const AnimatedWrapper = styled.div`
	display: flex;
	width: 100%;

	transform: rotateX(${({ show }) => (show ? '0deg' : '90deg')});
	opacity: ${({ show }) => (show ? '1' : '0')};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

	${({ show }) => {
		if (!show) {
			return css`
				position: absolute;
				top: 0;
				left: 0;
				transition: none;
			`
		} else {
			return css`
				transition: all 0.35s ease-in-out;
			`
		}
	}}
`

export const InputWrapper = styled.div`
	margin: ${({ margin }) => margin || 0};
	max-width: ${({ width }) => (width ? `${width}rem` : 'initial')};
	flex-grow: 1;
`

export const ButtonWrapper = styled.div`
	display: flex;
	margin-top: ${Spacings.SPACING_3B};
`

export const LoginButton = styled(Button)`
	min-width: 100%;
	min-height: 2.5rem;
	font-size: ${Text.MEDIUM};
`

export const LoginFormWrapper = styled.div`
	flex-grow: 1;
	padding: ${Spacings.SPACING_4B} ${Spacings.SPACING_5B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.white};
	position: relative;
`

export const LoginTextureWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
`

export const LoginFormTexture = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #cb2f2f08;
	transform: ${({ transform }) => transform};
`

export const PrivacyPolicyWrapper = styled.div`
	font-size: ${Text.SMALL};
	text-align: ${({ isTextLeft }) => (isTextLeft ? 'left' : 'center')};

	${({ IsExtraSmall }) => {
		if (IsExtraSmall) {
			return css`
				font-size: ${Text.EXTRA_SMALL};
			`
		}
	}}
	color: ${({ theme, redColor }) => (redColor ? theme.palette.text.red : theme.palette.text.darkblack)};
	margin-top: 0px;
	margin-bottom: ${({ isTextLeft }) => (isTextLeft ? Spacings.SPACING_4B : '0px')};
	@media ${Devices.tablet} {
		margin-top: ${({ isTextLeft }) => (isTextLeft ? '0px' : Spacings.SPACING_4B)};
	}
`

export const AnchorText = styled.a`
	color: ${({ theme }) => theme.palette.background.messageRed};
	font-weight: ${Text.BOLD_600};
`

export const SignIn = styled.p`
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-size: ${Text.X_LARGE};
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
`
export const WelcomeText = styled.p`
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-weight: ${({ isBoldText }) => (isBoldText ? Text.BOLD_600 : 'unset')};
	font-size: ${Text.MEDIUM};
	& > span {
		color: ${({ theme }) => theme.palette.background.messageRed};
		font-weight: ${Text.BOLD_600};
	}
	margin-bottom: ${({ isBoldText }) => (isBoldText ? '0px' : Spacings.SPACING_4B)};
	line-height: ${Text.LINE_HEIGHT_MAX};
`
export const FooterWapper = styled.div`
	width: 100%;
	@media ${Devices.mobile} {
		width: 60%;
	}
	@media ${Devices.tablet} {
		width: 80%;
	}
	@media ${Devices.laptop} {
		width: 60%;
	}
	margin-top: ${Spacings.SPACING_6B};
	background: #ffffff;
	box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.32);
	border-radius: ${Spacings.SPACING_6B};
`
export const CategoryContainer = styled.div`
	margin: ${Spacings.SPACING_3B} ${Spacings.SPACING_3B} ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`
export const CategoryWapper = styled.div`
	display: block;
	${({ isRemove }) => {
		if (isRemove) {
			return css`
				@media ${Devices.mobile} {
					display: none;
				}
				@media ${Devices.tablet} {
				}
				@media ${Devices.laptop} {
					display: block;
				}
			`
		}
	}}
`

export const CategoryImage = styled.img`
	width: ${Spacings.SPACING_18B};
	height: ${Spacings.SPACING_12B};
	border-radius: ${Spacings.SPACING_1B};
`

export const CategoryTitle = styled.div`
	font-size: ${Text.MICRO_SMALL};
	font-weight: ${Text.BOLD_600};
	text-align: center;
	color: ${({ theme }) => theme.palette.text.darkblack};
`
export const CategoryServed = styled.div`
	font-size: ${Text.MICRO_SMALL};
	text-align: center;
	color: ${({ theme }) => theme.palette.text.messageRed};
	margin-bottom: ${Spacings.SPACING_2B};
`
export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100%;
`
export const CarouselContainer = styled.div`
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media ${Devices.tablet} {
		display: flex;
		width: 65%;
		margin-left: ${Spacings.SPACING_5B};
	}
	@media ${Devices.laptop} {
		display: flex;
		width: 70%;
	}
`

const bounce = keyframes`
	0% {
		padding-top: 0;
	}
	20% {
		padding-top: 4px;
	}
	30% {
		padding-top: 8px;
	}
	40% {
		padding-top: 12px;
	}
	50% {
		padding-top: 16px;
	}
	60% {
		padding-top: 12px;
	}
	70% {
		padding-top: 8px;
	}
	80% {
		padding-top: 4px;
	}
	100% {
		padding-top: 0;
	}

`
export const ScrollContainer = styled.div`
	display: flex;
	margin: 0px 0px ${Spacings.SPACING_4B} 0px;
	@media ${Devices.mobile} {
	}
	flex-direction: column;
	align-items: center;
	animation-name: ${bounce};
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
	position: absolute;
	width: 100%;
	${({ isScrollUp }) => {
		if (isScrollUp) {
			return css`
				top: ${Spacings.SPACING_6B};
				margin: 0px 0px ${Spacings.SPACING_4B} 0px;
			`
		}
	}}
`
export const ScrollDownContainer = styled.div`
	margin-bottom: ${Spacings.SPACING_18B};
	@media ${Devices.tablet} {
		display: none;
	}
`

export const InfoPanel = styled.div`
	transition: all 0.15s ease-in-out;
	overflow-y: scroll;
	position: relative;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	// padding-top: ${Spacings.SPACING_7B};
	margin: ${Spacings.SPACING_6B};
	border-radius: ${Spacings.SPACING_6B};

	@media ${Devices.tablet} {
		min-height: fit-content;
		max-height: fit-content;
		padding: ${Spacings.SPACING_4B} 0;
		border: none;
		animation: none;
	}
`
export const LoginNumberWapper = styled.a`
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	color: ${({ theme, isDisabled }) => (isDisabled ? theme.palette.text.disabled : theme.palette.text.messageRed)};
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	text-decoration: underline;
	${({ isDisabled }) => {
		if (isDisabled) {
			return css`
				pointer-events: none;
			`
		}
	}}
`
export const ViewWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 0px 10px 5px 10px;
	margin: 10px 0;
	flex-direction: column;
	border-radius: ${Spacings.SPACING_3B};
	border: 1px solid rgba(234, 241, 250, 1);
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	border-color: ${({ theme }) => theme.palette.background.disabled};
	overflow-y: scroll;
	height: ${Spacings.SPACING_32B};
	::-webkit-scrollbar {
		opacity: 0;
		width: 0;
		display: none;
		background-color: transparent;
	}
`

export const SectionItemWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: unset;
	flex-basis: 100%;
	margin: ${Spacings.SPACING_2B};
	& > div {
		width: 100%;
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`
export const PanCardNumber = styled.div`
	font-size: ${({ isExtraSmall }) => (isExtraSmall ? Text.MICRO_SMALL : Text.SMALL)};
	text-align: left;
	font-weight: ${({ isExtraSmall }) => (isExtraSmall ? 'unset' : Text.BOLD_600)};
	color: ${({ theme }) => theme.palette.text.darkblack};
	margin-top: 0px;
`
