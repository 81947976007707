import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, H5, Input, MultiSelect, Select, TOAST_TYPE, Textarea, UploadButton } from '@base'
import { Spacings } from '@styles'
import { strings } from '@constants'
import { toggleDrawer, toggleFileViewer } from '@data/state/action/root'
import { addPdc, editPdc, getBankDetails, uploadPdcChequeImage } from '@data'
import { showLoader, showToast } from '@data/state/action'

export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}
`

export const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	&:nth-child(1) {
		margin-right: ${Spacings.SPACING_3B};
	}
`

const HeaderWrapper = styled(H5)`
	align-self: center;
	flex: 1;
`

const DocumentUploadWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: ${Spacings.SPACING_4B};
	& > div {
		display: flex;
		align-items: center;
	}
`

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${Spacings.SPACING_3B};
`

const _status = ['MANDATED', 'RECEIVED', 'RELEASED', 'UNCLAIMED', 'USED', 'BOUNCED']
const _type = ['SECURITY', 'TRANSACTION']
const _cheque_type = ['PDC', 'UDC']

const STATUS = Object.values(_status).map((value) => ({ key: value, label: value }))
const TYPE = Object.values(_type).map((value) => ({ title: value }))
const CHEQUE_TYPE = Object.values(_cheque_type).map((value) => ({ key: value, label: value }))

const getFormState = (data) => {
	const _state = {
		cheque_number: {
			id: 'cheque_number',
			label: strings('cheque', 'number'),
			placeholder: strings(['enter', 'cheque', 'number']),
			disabled: false,
			value: null,
			required: true,
		},
		chq_date: {
			id: 'chq_date',
			label: strings('cheque', 'date'),
			placeholder: strings(['select', 'cheque', 'date']),
			disabled: false,
			value: new Date().getTime(),
			required: true,
		},
		amount: {
			id: 'amount',
			label: strings('amount'),
			placeholder: strings(['enter', 'amount']),
			disabled: false,
			value: null,
			required: true,
		},
		bank: {
			id: 'bank',
			label: strings('select', 'bank'),
			placeholder: strings(['select', 'bank']),
			disabled: false,
			value: {},
			required: true,
		},
		expiry_date: {
			id: 'expiry_date',
			label: strings('expiry', 'date'),
			placeholder: strings(['select', 'expiry', 'date']),
			disabled: false,
			value: new Date().getTime() + 60 * 60 * 24 * 1000 * 90,
			required: false,
		},
		remarks: {
			id: 'remarks',
			label: strings('remarks'),
			placeholder: strings(['enter', 'remarks']),
			disabled: false,
			value: null,
			required: false,
		},
		status: {
			id: 'status',
			label: strings('status'),
			placeholder: strings(['select', 'status']),
			data: STATUS,
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			value: STATUS[0],
			required: false,
		},
		type: {
			id: 'type',
			label: strings('type'),
			placeholder: strings(['select', 'type']),
			data: TYPE,
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			value: TYPE[0],
			required: false,
		},
		received_date: {
			id: 'received_date',
			label: strings(['received', 'date']),
			placeholder: strings(['select', 'received', 'date']),
			disabled: false,
			value: null,
			required: false,
		},
		commitment_date: {
			id: 'commitment_date',
			label: strings(['commitment', 'date']),
			placeholder: strings(['select', 'commitment', 'date']),
			disabled: false,
			value: null,
			required: false,
		},
		released_date: {
			id: 'released_date',
			label: strings(['released', 'date']),
			placeholder: strings(['select', 'released', 'date']),
			disabled: false,
			value: null,
			required: false,
		},
		unclaimed_date: {
			id: 'unclaimed_date',
			label: strings('unclaimed', 'date'),
			placeholder: strings(['enter', 'unclaimed', 'date']),
			disabled: false,
			value: null,
			required: false,
		},
		bounce_date: {
			id: 'bounce_date',
			label: strings(['cheque', 'bounce', 'date']),
			placeholder: strings(['select', 'cheque', 'bounce', 'date']),
			disabled: false,
			value: null,
			required: false,
		},
		deposit_date: {
			id: 'deposit_date',
			label: strings('deposit', 'date'),
			placeholder: strings(['select', 'deposit', 'date']),
			disabled: false,
			value: null,
			required: false,
		},

		po_amount: {
			id: 'po_amount',
			label: strings('po', 'amount'),
			placeholder: strings(['enter', 'po', 'amount']),
			disabled: false,
			value: null,
			required: false,
		},
		cheque_image: {
			value: '',
			disabled: false,
		},
		cheque_type: {
			id: 'cheque-type',
			label: strings(['cheque', 'type']),
			placeholder: strings(['select', 'cheque', 'type']),
			data: CHEQUE_TYPE,
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			value: CHEQUE_TYPE[0],
			required: false,
		},
		invoice_numbers: {
			id: 'invoice-list',
			label: strings('invoice_numbers'),
			placeholder: strings(['enter', 'invoice_numbers']),
			data: [],
			primaryKey: 'title',
			displayKey: 'title',
			disabled: false,
			value: [],
			required: false,
		},
	}

	if (data) {
		_state.cheque_number.value = data?.cheque_number
		_state.cheque_number.disabled = true
		_state.chq_date.value = data?.chq_date
		_state.chq_date.disabled = true
		_state.amount.value = data?.amount
		_state.amount.disabled = true
		_state.bank.value = { institution_id: data?.bank, name: data?.bank }
		_state.bank.disabled = true
		if (data?.expiry_date) {
			_state.expiry_date.value = data?.expiry_date
			_state.expiry_date.disabled = true
		}
		_state.remarks.value = data?.remarks
		_state.status.value = STATUS.find((item) => data?.status === item.key) || {}
		if (data?.type) {
			_state.type.value = { title: data?.type }
			_state.type.disabled = true
		}
		if (data?.received_date) {
			_state.received_date.value = data?.received_date
			_state.received_date.disabled = true
		}
		if (data?.commitment_date) {
			_state.commitment_date.value = data?.commitment_date
			_state.commitment_date.disabled = true
		}
		if (data?.released_date) {
			_state.released_date.value = data?.released_date
			_state.released_date.disabled = true
		}
		if (data?.unclaimed_date) {
			_state.unclaimed_date.value = data?.unclaimed_date
			_state.unclaimed_date.disabled = true
		}
		if (data?.bounce_date) {
			_state.bounce_date.value = data?.bounce_date
			_state.bounce_date.disabled = true
		}
		if (data?.deposit_date) {
			_state.deposit_date.value = data?.deposit_date
			_state.deposit_date.disabled = true
		}
		if (data?.po_amount) {
			_state.po_amount.value = data?.po_amount
			_state.po_amount.disabled = true
		}
		if (data?.cheque_image) {
			_state.cheque_image.value = data?.cheque_image
			_state.cheque_image.disabled = true
		}
		if (data?.cheque_type) {
			_state.cheque_type.value = CHEQUE_TYPE.find((item) => item.key === data?.cheque_type)
			_state.cheque_type.disabled = true
		}
		if (data?.invoice_numbers) {
			_state.invoice_numbers.value = data?.invoice_numbers.map((item) => ({
				title: item,
			}))
		}
	}
	return _state
}

const PdcForm = ({ data, update, organisationId }) => {
	const dispatch = useDispatch()
	const [bankList, setBankList] = useState([])
	const [formState, setFormState] = useState(getFormState())

	useEffect(() => {
		getBankDetails()
			.then((response) => {
				setBankList(response)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	useEffect(() => {
		setFormState(getFormState(data))
	}, [data])

	const onChange = (key) => (value) => {
		updateState(key)(value)
		let _chequeDate = new Date(value)
		updateState('expiry_date')(new Date(_chequeDate.getTime() + 60 * 60 * 24 * 1000 * 90).getTime())
		// updateState('released_date')(new Date(_chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime())
		// updateState('unclaimed_date')(new Date(_chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime())
		// updateState('bounce_date')(new Date(_chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime())
		// updateState('deposit_date')(new Date(_chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime())
	}

	const updateState = (key) => (value) => {
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
	}

	const buttonHandler = () => {
		const obj = {
			amount: formState?.amount?.value,
			bank: formState?.bank?.value?.name,
			bounce_date: ['BOUNCED'].includes(formState.status.value?.key)
				? formState.bounce_date.value
					? new Date(formState.bounce_date.value).getTime()
					: new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime()
				: null,
			cheque_number: formState?.cheque_number?.value,
			chq_date: formState?.chq_date?.value,
			commitment_date: formState?.commitment_date?.value,
			deposit_date: ['USED'].includes(formState.status.value?.key)
				? formState.deposit_date.value
					? new Date(formState.deposit_date.value).getTime()
					: new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime()
				: null,
			expiry_date: formState?.expiry_date?.value,
			po_amount: formState?.po_amount?.value,
			received_date: formState?.received_date?.value,
			released_date: ['RELEASED', 'BOUNCED', 'USED', 'UNCLAIMED'].includes(formState.status.value?.key)
				? formState.released_date.value
					? new Date(formState.released_date.value).getTime()
					: new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime()
				: null,
			remarks: formState?.remarks?.value,
			status: formState?.status?.value?.key,
			type: formState?.type?.value?.title,
			unclaimed_date: ['UNCLAIMED'].includes(formState.status.value?.key)
				? formState.unclaimed_date.value
					? new Date(formState.unclaimed_date.value).getTime()
					: new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000).getTime()
				: null,
			cheque_image: formState?.cheque_image?.value,
			cheque_type: formState?.cheque_type?.value?.key,
			invoice_numbers: formState?.invoice_numbers?.value?.map((item) => item?.title),
		}
		dispatch(showLoader(true))
		if (data) {
			editPdc(obj, organisationId)
				.then((res) => {
					dispatch(showToast(true, strings('request_submit_sucess'), { type: TOAST_TYPE.SUCCESS }))
					update()
				})
				.catch((err) => {
					dispatch(showToast(true, err?.message, { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					dispatch(showLoader(false))
					dispatch(toggleDrawer(false))
				})
		} else {
			addPdc(obj, organisationId)
				.then((res) => {
					dispatch(showToast(true, strings('request_submit_sucess'), { type: TOAST_TYPE.SUCCESS }))
					update()
				})
				.catch((err) => {
					dispatch(showToast(true, err?.message, { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					dispatch(showLoader(false))
					dispatch(toggleDrawer(false))
				})
		}
	}

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const uploadChequeImage = (file) => {
		if (!file) {
			return
		}
		uploadPdcChequeImage(file)
			.then((response) => {
				updateState('cheque_image')(response.file_path)
				dispatch(showToast(true, strings('msg_cheque_image_uploaded_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				console.log(error)
				dispatch(showToast(true, strings('msg_cheque_image_uploaded_failed'), { type: TOAST_TYPE.ERROR }))
			})
	}

	const viewChequeImage = () => {
		dispatch(toggleFileViewer(true, { files: formState?.cheque_image?.value }))
	}

	const isDisabled = useMemo(() => {
		return (
			Object.keys(formState.bank?.value)?.length === 0 || !formState.cheque_number.value || !formState.amount.value || !formState.chq_date.value
		)
	}, [formState])

	let chequeDate = new Date(formState.chq_date.value)

	return (
		<>
			<DrawerBodyWrapper>
				<FormItemWrapper>
					<InputWrapper>
						<Select
							id={formState.bank.id}
							label={formState.bank?.label}
							placeholder={formState.bank?.placeholder}
							data={bankList}
							displayKey='name'
							primaryKey='institution_id'
							value={formState.bank.value ?? {}}
							onChange={updateState('bank')}
							disabled={formState.bank.disabled}
							required={formState.bank.required}
							errorMap={{
								valueMissing: `${strings('bank is required')}`,
							}}
						/>
					</InputWrapper>
					<InputWrapper>
						<Select
							id={formState.type.id}
							label={formState.type?.label}
							placeholder={formState.type?.placeholder}
							data={formState.type?.data}
							value={formState.type.value}
							onChange={updateState('type')}
							primaryKey={'title'}
							displayKey={'title'}
							disabled={formState.type.disabled}
							required={formState.type.required}
							errorMap={{
								valueMissing: `${strings('type is required')}`,
							}}
						/>
					</InputWrapper>
				</FormItemWrapper>
				<FormItemWrapper>
					<InputWrapper>
						<Input
							id={formState.cheque_number.id}
							label={formState.cheque_number.label}
							type='text'
							value={formState.cheque_number.value}
							onChange={updateState('cheque_number')}
							placeholder={formState.cheque_number.placeholder}
							disabled={formState.cheque_number.disabled}
							required={formState.cheque_number.required}
							errorMap={{
								valueMissing: `${strings('cheque is required')}`,
							}}
						/>
					</InputWrapper>
					<InputWrapper>
						<Input
							id={formState.amount.id}
							label={formState.amount.label}
							type='number'
							value={formState.amount.value}
							onChange={updateState('amount')}
							placeholder={formState.amount.placeholder}
							required={formState.amount.required}
							errorMap={{
								valueMissing: `${strings('amount is required')}`,
							}}
							disabled={formState.amount.disabled}
						/>
					</InputWrapper>
				</FormItemWrapper>

				<FormItemWrapper>
					<InputWrapper>
						<Calendar
							id={formState.chq_date.id}
							label={formState.chq_date.label}
							value={formState.chq_date.value}
							onChange={onChange('chq_date')}
							// minDate={new Date()}
							disabled={formState.chq_date.disabled}
							required={formState.chq_date.required}
							errorMap={{
								valueMissing: `${strings('cheque date required')}`,
							}}
						/>
					</InputWrapper>
					<InputWrapper>
						<Calendar
							id={formState.expiry_date.id}
							label={formState.expiry_date.label}
							value={
								formState.expiry_date.value
									? new Date(formState.expiry_date.value)
									: formState.chq_date.value && new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000 * 90)
							}
							onChange={updateState('expiry_date')}
							minDate={new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000 * 90)}
							disabled={formState.expiry_date.disabled}
							required={formState.expiry_date.required}
							errorMap={{
								valueMissing: `${strings('expiry date required')}`,
							}}
						/>
					</InputWrapper>
				</FormItemWrapper>
				<FormItemWrapper>
					<InputWrapper>
						<Select
							id={formState.status.id}
							label={formState.status?.label}
							placeholder={formState.status?.placeholder}
							data={formState.status?.data}
							value={formState.status.value}
							onChange={updateState('status')}
							primaryKey={formState.status?.primaryKey}
							displayKey={formState.status?.displayKey}
							disabled={formState.status.disabled}
							required={formState.status.required}
							errorMap={{
								valueMissing: `${strings('status is required')}`,
							}}
						/>
					</InputWrapper>
					<InputWrapper>
						<Select
							id={formState.cheque_type.id}
							label={formState.cheque_type?.label}
							placeholder={formState.cheque_type?.placeholder}
							data={formState.cheque_type?.data}
							value={formState.cheque_type.value}
							onChange={updateState('cheque_type')}
							primaryKey={formState?.cheque_type.primaryKey}
							displayKey={formState.cheque_type?.displayKey}
							disabled={formState.cheque_type.disabled}
							required={formState.cheque_type.required}
						/>
					</InputWrapper>
				</FormItemWrapper>

				<InputWrapper>
					<Calendar
						id={formState.commitment_date.id}
						label={formState.commitment_date.label}
						value={formState.commitment_date.value}
						onChange={updateState('commitment_date')}
						disabled={formState.commitment_date.disabled}
					/>
				</InputWrapper>

				{formState.status.value?.key !== 'MANDATED' && (
					<>
						<InputWrapper>
							<Calendar
								id={formState.received_date.id}
								label={formState.received_date.label}
								value={formState.received_date.value}
								onChange={updateState('received_date')}
								disabled={formState.received_date.disabled}
							/>
						</InputWrapper>
					</>
				)}

				{['RELEASED', 'BOUNCED', 'USED', 'UNCLAIMED'].includes(formState.status.value?.key) && (
					<InputWrapper>
						<Calendar
							id={formState.released_date.id}
							label={formState.released_date.label}
							value={
								formState.released_date.value
									? new Date(formState.released_date.value)
									: formState.chq_date.value && new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)
							}
							onChange={updateState('released_date')}
							minDate={new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)}
							errorMap={{
								valueMissing: `${strings('released date is required')}`,
							}}
							disabled={formState.released_date.disabled}
						/>
					</InputWrapper>
				)}

				{['UNCLAIMED'].includes(formState.status.value?.key) && (
					<>
						<InputWrapper>
							<Calendar
								id={formState.unclaimed_date.id}
								label={formState.unclaimed_date.label}
								value={
									formState.unclaimed_date.value
										? new Date(formState.unclaimed_date.value)
										: formState.chq_date.value && new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)
								}
								onChange={updateState('unclaimed_date')}
								minDate={new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)}
								errorMap={{
									valueMissing: `${strings('unclaimed date is required')}`,
								}}
								disabled={formState.unclaimed_date.disabled}
							/>
						</InputWrapper>
					</>
				)}

				{['BOUNCED'].includes(formState.status.value?.key) && (
					<InputWrapper>
						<Calendar
							id={formState.bounce_date.id}
							label={formState.bounce_date.label}
							value={
								formState.bounce_date.value
									? new Date(formState.bounce_date.value)
									: formState.chq_date.value && new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)
							}
							onChange={updateState('bounce_date')}
							required={formState.bounce_date.required}
							minDate={new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)}
							errorMap={{
								valueMissing: `${strings('cheque bounce date is required')}`,
							}}
							disabled={formState.bounce_date.disabled}
						/>
					</InputWrapper>
				)}

				{['USED'].includes(formState.status.value?.key) && (
					<InputWrapper>
						<Calendar
							id={formState.deposit_date.id}
							label={formState.deposit_date.label}
							value={
								formState.deposit_date.value
									? new Date(formState.deposit_date.value)
									: formState.chq_date.value && new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)
							}
							onChange={updateState('deposit_date')}
							minDate={new Date(chequeDate.getTime() + 60 * 60 * 24 * 1000)}
							errorMap={{
								valueMissing: `${strings('deposit date is required')}`,
							}}
							disabled={formState.deposit_date.disabled}
						/>
					</InputWrapper>
				)}

				<InputWrapper>
					<Input
						id={formState.po_amount.id}
						label={formState.po_amount.label}
						type='number'
						value={formState.po_amount.value}
						onChange={updateState('po_amount')}
						placeholder={formState.po_amount.placeholder}
						required={formState.po_amount.required}
						errorMap={{
							valueMissing: `${strings('po amount is required')}`,
						}}
						disabled={formState.po_amount.disabled}
					/>
				</InputWrapper>
				<InputWrapper>
					<Textarea
						id={formState.remarks.id}
						label={formState.remarks.label}
						type='text'
						value={formState.remarks.value}
						onChange={updateState('remarks')}
						placeholder={formState.remarks.placeholder}
						required={formState.remarks.required}
						errorMap={{
							valueMissing: `${strings('remarks is required')}`,
						}}
					/>
				</InputWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.invoice_numbers?.id}
						label={formState.invoice_numbers?.label}
						data={formState.invoice_numbers?.data}
						placeholder={formState.invoice_numbers?.placeholder}
						primaryKey={formState.invoice_numbers.primaryKey}
						displayKey={formState.invoice_numbers.displayKey}
						value={formState.invoice_numbers?.value || []}
						required={formState.invoice_numbers.required}
						onChange={updateState('invoice_numbers')}
						createNewOption={true}
					/>
				</InputWrapper>
				<InputWrapper>
					<DocumentUploadWrapper>
						<div>
							<HeaderWrapper>{strings(['cheque', 'image'])}</HeaderWrapper>
						</div>
						<ButtonWrapper>
							<Button small xs type='secondary' disabled={!formState.cheque_image.value} onClick={viewChequeImage}>
								{strings('view')}
							</Button>
							<UploadButton
								xs
								small
								variant='destructive'
								disabled={formState.cheque_image.disabled}
								label={formState.cheque_image?.value ? strings('change') : strings('upload')}
								onChange={uploadChequeImage}
							/>
						</ButtonWrapper>
					</DocumentUploadWrapper>
				</InputWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={isDisabled} onClick={buttonHandler}>
					{data ? strings(['edit']) : strings(['add'])}
				</Button>
				<Button margin={`0 auto 0 0`} small uppercase type='secondary' variant='destructive' onClick={cancelHandler}>
					{strings('cancel')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default PdcForm
