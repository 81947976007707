import API from '../api'
import postUrl from './paths'

export const createFactory = (factory, user) =>
	new Promise((resolve, reject) => {
		factory.created_by = user.user_id
		factory.created_at = new Date().getTime()
		API.post(postUrl.createFactory, factory)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadFactoryPhoto = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadFactoryPhoto, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadFactoryMachinePhoto = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadFactoryMachinePhoto, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadFabricationJourneyPhotos = (file, orderId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadFabricationJourneyPhotos, formData, { orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export const uploadFactoryMbReceivingPhoto = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadFactoryMbReceivingPhoto, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export const uploadFactoryReceivingPhoto = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadFactoryReceivingPhoto, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const createFabricationOrder = (orderData, user) =>
	new Promise((resolve, reject) => {
		if (!orderData.created_by) {
			orderData.created_by = user?.user_id
			orderData.created_at = new Date().getTime()
		}

		API.post(postUrl.createFabricationOrder, orderData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
