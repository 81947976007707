import { useEffect, useMemo } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import styled from 'styled-components'
import { PageLevelTableWrapper, TOAST_TYPE, Table, TableActionButton, TableColumns, useTableReducer } from '@base'
import { MODAL_TYPES, strings } from '@constants'
import { deleteOrganisationUser, getUsersOfOrganisation } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { useHeaderMeta } from '@hooks'
import { paths } from '@routes'
import { myPeersBreadCrumbs } from '@utils'
import { useDispatch, useSelector } from 'react-redux'
import { Spacings } from '@styles'
import { toggleModal } from '@data/state/action/root'
import { DeleteOutline } from '@material-ui/icons'

export const OrgRole = styled.div`
	font-size: ${Text.MICRO_SMALL};
	font-weight: ${Text.BOLD_600};
	padding: ${Spacings.SPACING_2} ${Spacings.SPACING_6};
	margin: ${({ margin }) => margin};
	margin-bottom: ${Spacings.SPACING_2};
	background: ${({ theme, pending }) => (pending ? theme.palette.background.yellowLight : theme.palette.background.greenLight)};
	border-radius: ${Spacings.SPACING_2B};
	&:last-of-type {
		margin-bottom: 0;
	}
`
const Peers = () => {
	const user = useSelector((state) => state.getIn(['user', 'user']))

	const orgId = user?.organisation_id

	const navigate = useNavigate()
	const globalDispatch = useDispatch()
	const { forceUpdate, update } = useOutletContext()

	const [state, dispatch, actions] = useTableReducer()

	useHeaderMeta(myPeersBreadCrumbs)

	useEffect(() => {
		if (state.loading) {
			return
		}
		dispatch(actions.fetchTableDataProgress())
		getUsersOfOrganisation(orgId, user.user_id)
			.then((res) => {
				if (Array.isArray(res)) {
					res = res.filter((u) => u.user_id !== user?.user_id)
					dispatch(actions.fetchTableDataSuccess(res))
				} else {
					return Promise.reject('invalid_response')
				}
			})
			.catch((err) => {
				dispatch(actions.fetchTableDataFailure(err))
				globalDispatch(showToast(true, strings('msg_failed_to_fetch'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				globalDispatch(showLoader(false))
			})
	}, [state.query, forceUpdate])

	const columns = useMemo(
		() => [
			{
				label: strings('user', 'id'),
				key: 'user_id',
				mobile: false,
			},
			{
				label: strings('name'),
				key: (item) => ({ name: item?.full_name, isDefaultAdmin: item?.is_default_admin }),
				mobile: true,
				renderer: ({ name, isDefaultAdmin }) => (
					<>
						<b>{name}</b>
						{isDefaultAdmin && <OrgRole margin={`${Spacings.SPACING_1B} 0 0 0`}>Default Admin</OrgRole>}
					</>
				),
			},
			{
				label: strings('mobile'),
				key: 'mobile_number',
				mobile: true,
			},
			{
				label: 'Email ID',
				key: 'email_id',
				mobile: false,
				default: '-',
			},
			{
				label: strings('role'),
				key: 'role',
				mobile: true,
			},
			TableColumns.actionsColumn({
				align: 'center',
				renderer: (row) => {
					if (row?.is_default_admin) return '-'
					return (
						<TableActionButton type='secondary' variant='destructive' small uppercase onClick={deleteHandler(row)}>
							<DeleteOutline fontSize='small' />
						</TableActionButton>
					)
				},
				mobile: true,
			}),
		],
		[user]
	)

	const deleteHandler = (row) => (e) => {
		e.stopPropagation()
		globalDispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings('msg_are_you_sure_want_to_delete_this_user'),
				confirmAction: (actionType) => {
					actionType && deletedUser(row)
					globalDispatch(toggleModal(false))
				},
			})
		)
	}

	const deletedUser = (row) => {
		globalDispatch(showLoader(true))
		deleteOrganisationUser(user?.user_id, orgId, row?.user_id)
			.then((response) => {
				globalDispatch(showToast(true, strings('msg_user_deleted'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				globalDispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				globalDispatch(showLoader(false))
			})
	}

	const rowClickHandler = (row) => {
		navigate(paths.my_peer(row.user_id))
	}

	return (
		<PageLevelTableWrapper>
			<Table
				fullHeight
				data={state.data}
				columns={columns}
				loading={state.loading}
				totalItemCount={state.total_count}
				page={state.query.page}
				onRowClick={rowClickHandler}
				hideFooter
			/>
		</PageLevelTableWrapper>
	)
}

export default Peers
