import API from '../api'
import getUrl from './paths'
import { poStatus as poStatusC } from '@constants'
import convertPagableData from '../convertPagableData'

export const getPurchaseOrders = (buyerId, buyerPoStatus = [poStatusC.active], page = 0, orderSource = null, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getPurchaseOrders, {
			buyerId,
			buyerPoStatus: buyerPoStatus.join(','),
			page,
			orderSource,
			...orderFilters,
		})
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getSupplyOrders = (supplierId, supplierPoStatus = [poStatusC.active], page = 0, orderSource = null, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getSupplierOrders, {
			supplierId,
			supplierPoStatus: supplierPoStatus.join(','),
			page,
			orderSource,
			...orderFilters,
		})
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getOrderById = (orderId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getOrderById, {
			orderId,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllOrders = (orderStatus, page, orderSource = null, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getOrders, {
			orderStatus: orderStatus.join(','),
			page,
			orderSource,
			...orderFilters,
		})
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllAdminOrders = (orderStatus, page, orderSource = null, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllAdminOrders, {
			orderStatus: orderStatus.join(','),
			page,
			orderSource,
			...orderFilters,
		})
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getActiveInvoices = (page, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getActiveInvoices, { page, ...orderFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllActiveInvoices = (page, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllActiveInvoices, { page, ...orderFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getActiveTrips = (page, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.gettrips, {
			page,
			...orderFilters,
		})
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const downloadOrdersApi = (orderStatus, ordersFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadOrders, { orderStatus: orderStatus.join(','), ...ordersFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadActiveInvoicesApi = (ordersFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadActiveInvoices, { ...ordersFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
