import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router'

import PdcList from './Pdc/PdcList'
import BusinessDetails from './BusinessDetails/BusinessDetails'
import FinancialHighlights from './FinancialHighlights/FinancialHighlights'
import PastRecords from './PastRecords/PastRecords'
import GetCollections from './GetCollections/GetCollections'
import { generateReport } from '@data'
import { showLoader } from '@data/state/action'
import { GridListWrapper } from '../styles'
import CustomerDetails from './CustomerDetails/CustomerDetails'
import BillingAddress from './BillingAddress'
import Alerts from './Alerts'
import ManagementContactDetails from './ManagementContactDetails'
import AdhocJourney from './AdhocJourney'
import Remarks from './Remarks'
import CreditLimitList from './CreditLimit/CreditLimitList'

const FinancialList = () => {
	const [forceUpdate, update, orgData] = useOutletContext()
	const dispatch = useDispatch()
	const user = useSelector((_state) => _state.getIn(['user', 'user']))
	const [financialData, setFinancialData] = useState()

	useEffect(() => {
		dispatch(showLoader(true))
		orgData &&
			generateReport(orgData?.organisation_id)
				.then((res) => {
					res?.data && setFinancialData(res?.data)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
	}, [orgData, forceUpdate])

	return (
		<>
			<GridListWrapper itemPerRow={[1, 2, 2]}>
				<CustomerDetails orgData={orgData} financialData={financialData} update={update} />
				<FinancialHighlights orgData={orgData} financialData={financialData} update={update} />
			</GridListWrapper>
			<PdcList orgData={orgData} financialData={financialData} update={update} />
			<BusinessDetails orgData={orgData} financialData={financialData} update={update} />
			<GridListWrapper itemPerRow={[1, 2, 2]}>
				<PastRecords financialData={financialData} update={update} user={user} />
				<Alerts orgData={orgData} financialData={financialData} update={update} />
			</GridListWrapper>
			<GridListWrapper itemPerRow={[1, 2, 2]}>
				<BillingAddress orgData={orgData} financialData={financialData} />
				<GetCollections financialData={financialData} />
			</GridListWrapper>
			<GridListWrapper itemPerRow={[1, 2, 2]}>
				<AdhocJourney orgData={orgData} financialData={financialData} update={update} />
				<CreditLimitList data={financialData} orgData={orgData} forceUpdate={forceUpdate} update={update} />
			</GridListWrapper>
			<GridListWrapper itemPerRow={[1, 2, 2]}>
				<ManagementContactDetails orgData={orgData} financialData={financialData} />
				<Remarks orgData={orgData} financialData={financialData} update={update} />
			</GridListWrapper>
		</>
	)
}

export default FinancialList
