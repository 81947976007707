import { Button, DrawerBodyWrapper, Input, TOAST_TYPE } from '@base'
import { showLoader, showToast } from '@data/state/action'
import { EditDetailWrapper } from '../styles'
import { MODAL_TYPES, strings } from '@constants'
import { updateOrganisationApi } from '@data'
import { toggleDrawer, toggleModal } from '@data/state/action/root'

const { useState, useEffect } = require('react')
const { useDispatch } = require('react-redux')

const EditOrgPanDetails = ({ orgDetails, update }) => {
	const dispatch = useDispatch()
	const [orgPan, setOrgPan] = useState(orgDetails?.pan)

	useEffect(() => {
		setOrgPan(orgDetails?.pan)
	}, [orgDetails?.pan])

	const onPanChange = (value) => {
		setOrgPan(value)
	}

	const onPanUpdate = () => (e) => {
		if (!orgPan) return

		e.stopPropagation()
		
		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings(['confirm','to','update','pan']),
				confirmAction: (actionType) => {
					dispatch(showLoader(true))
					if (actionType) {
						updateOrganisationApi(orgDetails.organisation_id, 'PAN', orgPan)
							.then(() => {
								dispatch(showToast(true, 'Successfully updated organisation pan', { type: TOAST_TYPE.SUCCESS }))
								dispatch(toggleDrawer(false))
								update()
							})
							.catch((err) => {
								console.log(err)
								dispatch(showToast(true, 'Error while updating organisation pan', { type: TOAST_TYPE.ERROR }))
							})
							.finally(() => {
								dispatch(showLoader(false))
							})
					}else{
						dispatch(showLoader(false))
					}
					dispatch(toggleModal(false))
				},
			})
		)
	}
	const isDisabled = ()=>{
		let panRegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/
		let gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
		if (panRegex.test(orgPan) || gstRegex.test(orgPan)) {
			return false
		}
		return true
	}
	return (
		<>
			<DrawerBodyWrapper>
				<EditDetailWrapper>
					<Input
						pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}'
						placeholder='Enter a name'
						label='Organisation Pan'
						type='text'
						value={orgPan}
						maxLength='10'
						errorMap={{
							patternMismatch: strings('enter_valid_pan'),
							valueMissing: strings('pan_required'),
						}}
						uppercase
						onChange={onPanChange}
					/>
					<div>
						<Button disabled={isDisabled()} onClick={onPanUpdate()}>
							Update
						</Button>
					</div>
				</EditDetailWrapper>
			</DrawerBodyWrapper>
		</>
	)
}

export default EditOrgPanDetails
