import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Pill, Textarea, TOAST_TYPE } from '@base'
import { editUser } from '@data'
import { User } from '@data/model'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import strings from '@constants/strings'
import { addRemarks } from '@data/conn/post/organisation'

const PillWrapper = styled.div`
	text-align: right;
`

const OrgRemark = ({ orgDetails, update }) => {
	const dispatch = useDispatch()
	const [remarks, setRemarks] = useState(orgDetails?.remarks)
	const user = useSelector((state) => state.getIn(['user', 'user']))

	const onChange = (value) => {
		setRemarks(value)
	}

	const addRemarksHandler = () => {
		dispatch(showLoader(true))
		addRemarks({ orgId: orgDetails.organisation_id, remarks, remarksBy: user.getUserDetails() })
			.then(() => {
				dispatch(showToast(true, strings('msg_remark_added'), { type: TOAST_TYPE.SUCCESS }))
				update()
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings('error_something_went_wrong'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<Textarea value={remarks} onChange={onChange} label={strings('remarks')} />
				{orgDetails.remarks && (
					<PillWrapper>
						<Pill label={`${strings('from')} - `} value={new User(orgDetails?.remarks_by).getTableName()} />
					</PillWrapper>
				)}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small onClick={addRemarksHandler} disabled={!remarks}>
					{orgDetails?.remarks ? strings('update', 'remark') : strings('add', 'remark')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default OrgRemark
