import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { Button } from '@base'
import { Done, ErrorOutline } from '@material-ui/icons'

export const ActionButton = styled(Button)``

export const ViewButton = styled(Button)`
		margin-right ${Spacings.SPACING_1B};
		min-width: ${Spacings.SPACING_8B};
		& > span {
			color: ${({ theme }) => theme.palette.background.white};
		}
`
export const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

export const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

export const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	// height: 100%;
	margin-top: ${Spacings.SPACING_2B};
	${({ isStatement }) => {
		if (isStatement) {
			return css`
				display: flex;
				justify-content: space-between;
			`
		}
	}}

	${({ isCustomer }) => {
		if (isCustomer) {
			return css`
				margin-top: 0;
				display: flex;
				align-items: center;
				margin-left: ${Spacings.SPACING_2B};
				justify-content: flex-end;
			`
		}
	}}
	& > div {
		width: 100%;
	}
`

export const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: ${Spacings.SPACING_2B};
	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`
export const SelectWrapper = styled.div`
	width: 100%;
	padding: 0.5rem 0 0.5rem 0.5rem;
`
export const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	div:nth-child(4) {
		margin-left: 5px;
	}
	& > div {
		width: 100%;
	}
`
export const ViewItemWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 0px 10px 5px 10px ;
	margin: 10px 0;
	flex-direction: column;
	border-radius: ${Spacings.SPACING_3B};
	border: 1px solid rgba(234, 241, 250, 1);
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	border-color: ${({ theme }) => theme.palette.background.disabled};

	${({ isColorChange }) => {
		if (isColorChange) {
			return css`
				background-color: ${({ theme }) => theme.palette.background.tablegrey};
			`
		}
	}}
`

export const DoneOutlineIcon = styled(Done)`
	color: ${({ theme }) => theme.palette.background.green};
`
export const ErrorOutlineIcon = styled(ErrorOutline)`
	cursor: pointer;
	color: ${({ theme }) => theme.palette.background.red};
`

export const DivWapper = styled.div`
	width: 100%;
`
export  const ViewButtonText = styled.span`
	color: ${({ theme, isBrand }) => !isBrand && theme.palette.text.blue};
	display: inline-block;
	position: relative;
	cursor: pointer;

	& > span {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-30%);
		padding: ${Spacings.SPACING_2B};
		font-size: ${Text.SMALL};
		background: ${({ theme }) => theme.palette.background.white};
		color: ${({ theme }) => theme.palette.text.black};
		box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
		border-radius: ${Spacings.SPACING_2B};
		transition: all 0.15s ease-in;
		display: inline-block;
		width: max-content;
		max-width: 180px;
		max-height: 120px;
		overflow: scroll;
		cursor: initial;

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}
		/* Hide scrollbar for IE, Edge and Firefox */
		& {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}

		@media ${Devices.tablet} {
			left: 0;
			transform: translateX(-10%);
		}
	}

	&:hover {
		& > span {
			visibility: visible;
			opacity: 1;
		}
	}
`