import { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { getNewsData } from '@data'
import { DrawerBodyWrapper, Button, Loader } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { Spacings, Text } from '@styles'
import { getDate, getTime } from '@utils'
import Image1 from '@assets/news/1.jpg'
import Image2 from '@assets/news/2.jpg'
import Image3 from '@assets/news/3.jpg'
import Image4 from '@assets/news/4.jpg'
import Image5 from '@assets/news/5.jpg'
import Image6 from '@assets/news/6.jpg'

const HeaderNewsWrapper = styled.div`
	display: flex;
`
const NewsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const NewsLink = styled.a`
	margin: ${Spacings.SPACING_3B} 0;
	display: flex;
`
const NewsCard = styled.div`
	box-model: border-box;
	font-size: ${Text.MEDIUM};
	cursor: pointer;
	background: ${({ theme }) => theme.palette.background.white};
	line-height: ${Text.LINE_HEIGHT_MEDIUM};

	display: flex;
	align-items: center;
`

const ThumbnailWrapper = styled.div`
	min-width: 5rem;
	width: 5rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.palette.background.border};
	border-radius: ${Spacings.SPACING_2B};
	overflow: hidden;
	margin-right: ${Spacings.SPACING_3B};
	& > span {
		font-size: ${Text.SMALL};
		color: ${({ theme }) => theme.palette.text.black};
	}
`
const Thumbnail = styled.img`
	width: 100%;
`
const InfoWrapper = styled.div`
	& > div:first-of-type {
		color: ${({ theme }) => theme.palette.text.black};
		margin-bottom: ${Spacings.SPACING_1B};
	}
	& > div:last-of-type {
		font-size: ${Text.EXTRA_SMALL};
		color: ${({ theme }) => theme.palette.text.label};
	}
`

const HeaderNewsLink = styled.a`
	min-width: fit-content;
	color: ${({ theme }) => theme.palette.text.black};
`
const RunningNewsWrapper = styled.div`
	overflow: hidden;
	display: flex;
	align-items: center;
	font-size: ${Text.MEDIUM};
	line-height: ${Text.LINE_HEIGHT_MEDIUM};
	flex-grow: 1;
	margin-right: ${Spacings.SPACING_4B};
	min-height: 2rem;
`
const RunningNews = styled.div`
	display: flex;
	align-items: center;
	width: max-content;
`
const NewsTitle = styled.div``
const Dot = styled.span`
	color: ${({ theme }) => theme.palette.text.messageRed};
	margin: 0 ${Spacings.SPACING_3B};
`

const SeeAllButton = styled(Button)`
	min-width: max-content;
`

const dummyImages = [Image1, Image2, Image3, Image4, Image5, Image6]

const News = ({ inHeader = false }) => {
	const lastChild = useRef()
	const runningNewsContainer = useRef()
	const runningNews = useRef()
	const dispatch = useDispatch()
	const [news, setNews] = useState([])

	useEffect(() => {
		getNewsData().then((response) => {
			setNews(response)
		})
	}, [])

	useEffect(() => {
		let animationFrameId
		let translation = 0
		let started = false

		const moveFunction = () => {
			if (runningNews.current && lastChild.current && runningNewsContainer.current) {
				const { right } = lastChild.current.getBoundingClientRect()
				const { x, width } = runningNewsContainer.current.getBoundingClientRect()

				if (right <= x && started) {
					started = false
				}

				if (!started) {
					translation = width
					started = true
				} else {
					translation = translation - 1
				}

				runningNews.current.style['transform'] = `translateX(${translation}px)`
				animationFrameId = requestAnimationFrame(moveFunction)
			}
		}

		const mouseoutHandler = () => {
			animationFrameId = requestAnimationFrame(moveFunction)
		}

		const mouseoverHandler = () => {
			cancelAnimationFrame(animationFrameId)
			runningNews.current.addEventListener('mouseleave', mouseoutHandler)
		}

		if (inHeader && runningNews.current) {
			animationFrameId = requestAnimationFrame(moveFunction)
			runningNews.current.addEventListener('mouseover', mouseoverHandler)
		}

		return () => {
			cancelAnimationFrame(animationFrameId)
			if (runningNews.current) {
				runningNews.current.removeEventListener('mouseover', mouseoverHandler)
			}
		}
	}, [inHeader, news])


	const clickHandler = () => {
		dispatch(toggleDrawer(true, DRAWER_TYPES.SHOW_NEWS))
	}

	if (inHeader) {
		return (
			<HeaderNewsWrapper>
				<RunningNewsWrapper ref={(_ref) => (runningNewsContainer.current = _ref)}>
					<RunningNews ref={(_ref) => (runningNews.current = _ref)}>
						{news?.map((_item, index) => (
							<Fragment key={`news-${index}`}>
								<HeaderNewsLink href={_item.url} target='_blank' rel='noreferrer'>
									<NewsTitle>{_item.title}</NewsTitle>
								</HeaderNewsLink>
								<Dot
									ref={(_ref) => {
										if (index === news.length - 1) {
											lastChild.current = _ref
										}
									}}
								>
									&bull;
								</Dot>
							</Fragment>
						))}
					</RunningNews>
					{!news?.length && <Loader />}
				</RunningNewsWrapper>
				<SeeAllButton small type='tertiary' variant='destructive' onClick={clickHandler}>
					{strings('all', 'news')}
				</SeeAllButton>
			</HeaderNewsWrapper>
		)
	}

	return (
		<DrawerBodyWrapper>
			<NewsWrapper>
				{news?.map((_item, index) => (
					<NewsLink href={_item.url} target='_blank' rel='noreferrer' key={`news-card-${index}`}>
						<NewsCard>
							<ThumbnailWrapper>
								{_item?.url_to_image && <Thumbnail src={_item?.url_to_image} alt='Image' />}
								{
									!_item?.url_to_image && (
										<Thumbnail src={dummyImages[Math.floor(Math.random() * 6)]} alt='Image' />
									) /*<span>No Image</span>*/
								}
							</ThumbnailWrapper>
							<InfoWrapper>
								<div>{_item.title}</div>
								<div>
									{getDate(new Date(_item?.published_at))}
									{'  '}
									&bull;
									{'  '}
									{getTime(new Date(_item?.published_at))}
								</div>
							</InfoWrapper>
						</NewsCard>
					</NewsLink>
				))}
			</NewsWrapper>
		</DrawerBodyWrapper>
	)
}

export default News
