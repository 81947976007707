const organisationPaths = {
	home: '/mb',
	dashboard: '/mb/dashboard',
	profile: '/mb/profile',
	my_profile: '/mb/profile/my-profile',
	my_organisation: '/mb/profile/my-organisation',
	my_peers: '/mb/profile/my-peers',
	my_peer: (userId = '') => {
		if (userId === '') {
			userId = ':userId'
		}
		return '/mb/profile/my-peer/' + userId
	},
	fullfill_enquiry: '/mb/fulfill-enquiry',
	fullfill_enquiry_open: '/mb/fulfill-enquiry/open-enquiries',
	submit_enquiry_quote: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/mb/fulfill-enquiry/open-enquiries/submit/' + enquiryId
	},
	edit_enquiry_quote: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/mb/fulfill-enquiry/open-enquiries/edit/' + enquiryId
	},
	fullfill_enquiry_my_quotes: '/mb/fulfill-enquiry/my-quotes',
	fullfill_enquiry_converted_order: '/mb/fulfill-enquiry/converted-to-order',

	manage_supply_orders: '/mb/manage-supply-orders',
	manage_supply_order_status: (status = '') => {
		if (status === '') {
			status = ':orderStatus'
		}
		return '/mb/manage-supply-orders/' + status
	},
	manage_supply_order: (orderId = '') => {
		if (orderId === '') {
			orderId = ':orderId'
		}
		return '/mb/manage-supply-orders/order/' + orderId
	},

	auction: '/mb/auction',
	create_auction: '/mb/auction/create-auction',
	my_auctions: '/mb/auction/my-auctions',
	convert_auction_to_order: '/mb/auction/convert-to-order',
	edit_auction: (auctionId = '') => {
		if (auctionId === '') {
			auctionId = ':auctionId'
		}
		return '/mb/auction/edit-auction/' + auctionId
	},
	view_auction: (auctionId = '') => {
		if (auctionId === '') {
			auctionId = ':auctionId'
		}
		return '/mb/auction/view-auction/' + auctionId
	},

	enquiry: '/mb/enquiry',
	create_enquiry: '/mb/enquiry/create-enquiry',
	my_enquiries: '/mb/enquiry/my-enquiries',
	convert_enquiry_to_order: '/mb/enquiry/convert-to-order',
	edit_enquiry: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/mb/enquiry/edit-enquiry/' + enquiryId
	},
	create_po: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/mb/enquiry/create-po/' + enquiryId
	},
	show_buyer_quotes: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/mb/enquiry/show-buyer-quotes/' + enquiryId
	},

	manage_purchase_orders: '/mb/manage-purchase-orders',
	manage_purchase_order_status: (status = '') => {
		if (status === '') {
			status = ':orderStatus'
		}
		return '/mb/manage-purchase-orders/' + status
	},
	manage_purchase_order: (orderId = '') => {
		if (orderId === '') {
			orderId = ':orderId'
		}
		return '/mb/manage-purchase-orders/order/' + orderId
	},

	bid_auction: '/mb/bid',
	bid_auction_active: '/mb/bid/auction/active',
	bid_auction_upcoming: '/mb/bid/auction/upcoming',
	bid_auction_your_bids: '/mb/bid/your-bids',
	bid_auction_converted_order: '/mb/bid/converted-to-order',
	bid_on_auction: (auctionId = '') => {
		if (auctionId === '') {
			auctionId = ':auctionId'
		}
		return '/mb/bid/bid-on-auction/' + auctionId
	},

	payment_summary: '/mb/payment-summary',
	ledger: '/mb/payment-summary/ledger',
	sale_invoices: '/mb/payment-summary/sale-invoices',
	purchase_invoices: '/mb/payment-summary/purchase-invoices',
	trader_list_inventory: '/mb/inventory',
	trader_add_inventory: '/mb/inventory/add',
	tools_weight_calculator: '/mb/tools/weight-calculator',

	supply_scrap_quote: '/mb/supply-scrap-quote',

	logistics_calculator: '/mb/logistics-calculator',
	logistics_requests: '/mb/logistics/requests',
	logistics_requests_all: '/mb/logistics/requests/all',
	logistics_my_quotes: '/mb/logistics/requests/my-quotes',
	logistics_converted_to_order: '/mb/logistics/requests/converted-to-order',
	logistics_quote: (logisticId = '') => {
		if (logisticId === '') {
			logisticId = ':logisticId'
		}
		return '/mb/logistics/requests/submit-quote/' + logisticId
	},
	manage_logistics_orders: '/mb/logistics/manage-logistics-orders',
	manage_logistics_order_status: (status = '') => {
		if (status === '') {
			status = ':orderStatus'
		}
		return '/mb/logistics/manage-logistics-orders/' + status
	},
	manage_logistics_order_details: (logisticsOrderId = '') => {
		if (logisticsOrderId === '') {
			logisticsOrderId = ':logisticsOrderId'
		}
		return '/mb/logistics/manage-logistics-orders/order/' + logisticsOrderId
	},
	logistics_convert_requests_to_order: '/mb/logistics/requests/convert-to-order',

	fabricator_factories: '/mb/cloud',
	fabricator_factories_all: '/mb/cloud/factories',
	fabricator_factories_add: '/mb/cloud/factories/add',
	fabricator_factories_edit: (factoryId = '') => {
		if (factoryId === '') {
			factoryId = ':factoryId'
		}
		return '/mb/cloud/factories/edit/' + factoryId
	},

	fabricator_orders: '/mb/cloud/orders',
	fabricator_orders_order_status: (status = '') => {
		if (status === '') {
			status = ':orderStatus'
		}
		return '/mb/cloud/orders/' + status
	},
	fabricator_manage_factory_order: (orderId = '') => {
		if (orderId === '') {
			orderId = ':orderId'
		}
		return '/mb/cloud/orders/manage/' + orderId
	},
	fabricator_view_factory_inventory: (factoryId = '') => {
		if (factoryId === '') {
			factoryId = ':factoryId'
		}
		return `/mb/cloud/factories/${factoryId}/inventory`
	},
}

export default organisationPaths
