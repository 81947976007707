import adminMenu from './SideMenu/admin'
import organisationMenu from './SideMenu/organisation'
import { isClient } from 'src/utils/adminFilter'
export { default as paths } from './paths'

export const getRoleBasedMenu = (role, orgRoles) => {
	if (!role) role = ''
	
	if (isClient(role)) {
		return organisationMenu(orgRoles)
	} else {
		return adminMenu(role.toUpperCase())
	}
}

export * from './Routes'
