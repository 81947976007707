import API from '../api'
import getUrl from './paths'

export const getAllPunchOrder = (page = 0, size = 10) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getAllPunchOrder, { page, size })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })

export const getPunchOrderById = (punchOrderId) =>
    new Promise((resolve, reject) => {
        API.get(getUrl.getPunchOrderById, { punchOrderId })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })