import API from './api'

const paths = {
	getInventoryOfOrganisation: 'inventory/getInventoryOfOrganisation',
	inventory: 'inventory',
	deleteInventoryDetail: 'inventory/deleteInventoryDetail',
	updateInventoryDetail: 'inventory/updateInventoryDetail'
}

export const getInventoryOfOrganisation = (organisationId) =>
	new Promise((resolve, reject) => {
		API.get(paths.getInventoryOfOrganisation, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addInventory = (inventory) =>
	new Promise((resolve, reject) => {
		API.post(paths.inventory, inventory)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateInventory = (inventoryDetails, organisationId) =>
	new Promise((resolve, reject) => {
		API.put(paths.inventory, inventoryDetails, organisationId)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const deleteInventoryDetail = (inventoryDetails, organisationId) =>
	new Promise((resolve, reject) => {
		API.put(paths.deleteInventoryDetail, inventoryDetails, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateInventoryDetail = (inventoryDetails, organisationId) =>
	new Promise((resolve, reject) => {
		API.put(paths.updateInventoryDetail, inventoryDetails,  organisationId)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})