import API from '../api'
import putUrl from './paths'

export const editBannerApi = (bannerId, acitve, priority) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editBanner, {}, { bannerId, acitve, priority })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
