import { strings } from '@constants'

class EnquiryBuyerQuote {
	constructor() {
		this.quote_type = strings('for') //x or for
		this.minimum_order_quantity = 0
		this.minimum_guaranteed_weight = 0
		this.price_valid_till = new Date().getTime()
		this.logistics_quote = 0
		this.quote_per_product = []
		this.lumpsum_logistics_quote = 0
		this.message = ''
		this.retail_supplier_quote_id = null
	}
}

export default EnquiryBuyerQuote
