import API from '../api'
import deleteUrl from './paths'

const deleteAdminAuction = (auctionId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteAuction, { auctionId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
    
 const deleteAdminAuctionOrder = (orderId) =>
		new Promise((resolve, reject) => {
			API.delete(deleteUrl.deleteAuctionOrder, { orderId })
				.then((response) => {
					resolve(response.data)
				})
				.catch((err) => reject(err))
		})   

export { deleteAdminAuction, deleteAdminAuctionOrder }
