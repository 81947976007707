import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { DrawerBodyWrapper, Select, TOAST_TYPE, TimeLine } from '@base'
import { COLLECTION_STATUS, DRAWER_TYPES, strings } from '@constants'
import { getPaymentReceivablesById } from '@data'
import { showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { AttachMoney, Message as MessageIcon } from '@material-ui/icons'
import { Devices, Spacings } from '@styles'
import { ActionButton } from './CreateCollection'
import { changeStatusPaymentReceivables } from '@data/conn/put/ledger'
import { displayAmount, showDateFormat } from '@utils'

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const RemarkDiv = styled.div`
	& :nth-child(1) {
		font-weight: bold;
	}
	& :nth-child(2) {
	}
`
const Footer = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: initial;
	}
`

const InputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	${({ isCall }) => {
		if (isCall) {
			return css`
				& > div:nth-child(1) {
					flex: 6;
					margin-right: 0px;
					@media ${Devices.tablet} {
						margin-right: 5px;
					}
				}
				& > div:nth-child(2) {
					flex: 6;
				}
			`
		} else {
			return css`
				& > div:nth-child(1) {
					flex: 3;
				}
				& > div:nth-child(2) {
					flex: 10;
				}
			`
		}
	}}

	@media ${Devices.tablet} {
		flex-direction: row;
	}
`

const StatusWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	font-weight: bold;
	color: ${({ theme }) => theme.palette.text.greydark};
	padding: ${Spacings.SPACING_2B};
	& > div {
		padding: ${Spacings.SPACING_2B};
	}
`

const CollectionJourney = ({ row, update }) => {
	const [journeyList, setJourneyList] = useState()
	const dispatch = useDispatch()
	const [paymentReceivables, setPaymentReceivables] = useState()
	const [collectionStatus, setCollectionStatus] = useState(row?.status)

	let collectionStatusType = Object.entries(COLLECTION_STATUS).map(([key, value]) => ({ key: key, label: value }))

	useEffect(() => {
		getPaymentReceivablesById(row.receivable_id)
			.then((response) => {
				setPaymentReceivables(response)
				setJourneyList(
					response?.remarks?.map((item) => {
						return { ...item, date: item.created_at, journeyState: CollectionJourneyState(item) }
					})
				)
			})
			.catch((error) => {
				console.log(error)
				dispatch(showToast(true, strings('journey_not_found'), { type: TOAST_TYPE.ERROR }))
			})
	}, [])

	const JoruneyText = (amount, message, date) => {
		return (
			<>
				<RemarkDiv>
					<span>{strings('amount', ': ')}</span>
					<span> {displayAmount(amount)}</span>
				</RemarkDiv>
				<RemarkDiv>
					<span>{strings('message', ': ')} </span>
					<span>{message}</span>
				</RemarkDiv>
				<RemarkDiv>
					<span>{strings(['payment', 'date', ': '])} </span>
					<span>{showDateFormat(new Date(date))}</span>
				</RemarkDiv>
			</>
		)
	}

	const CollectionJourneyState = (data) => {
		const message = data.message
		const amount = data.amount
		const date = data.expected_payment_date
		return {
			text: JoruneyText(amount, message, date),
			paymentType: data?.type === 'payment',
			icon: data?.type === 'payment' ? <AttachMoney /> : <MessageIcon />,
		}
	}
	const journeyClickHandler = (item, index, isEditRemark) => {
		const data = { ...item }
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.ADD_COLLECTION, {
				paymentReceivables: paymentReceivables,
				remarkIndex: index,
				data: data,
				isEdit: true,
				update: update,
				isEditRemark,
			})
		)
	}

	const addRemarkHandler = () => {
		dispatch(toggleDrawer(true, DRAWER_TYPES.ADD_COLLECTION, { paymentReceivables: row, isAddRemark: true, update }))
	}
	const collectionStatusChange = (value) => {
		setCollectionStatus(value.key)
		changeStatusPaymentReceivables(row?.receivable_id, value.key)
			.then((response) => {
				update()
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<InputWapper isCall>
					<StatusWrapper>
						<div>{strings('status')}</div>
					</StatusWrapper>
					<Select
						data={collectionStatusType}
						// id='reason'
						value={collectionStatusType.find((item) => item.key === collectionStatus) || {}}
						placeholder={strings(['status'])}
						displayKey={'label'}
						primaryKey={'key'}
						onChange={(val) => {
							collectionStatusChange(val)
						}}
						required={false}
					/>
				</InputWapper>
				<BodyWrapper>
					<TimeLine
						isCollection={true}
						data={journeyList}
						clickhandler={(item, index, isEditRemark) => journeyClickHandler(item, index, isEditRemark)}
						journeySourceId={row.receivable_id}
					/>
				</BodyWrapper>
			</DrawerBodyWrapper>
			<Footer>
				<ActionButton small type='primary' disabled={false} onClick={addRemarkHandler}>
					{strings('add', 'remarks')}
				</ActionButton>
			</Footer>
		</>
	)
}

export default CollectionJourney
