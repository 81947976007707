import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

export const getAllFactories = (page = 0, searchFilter = {}) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllFactories, { page, ...searchFilter })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getFactoriesOfUser = ({ page = 0, searchFilter = {}, userId }) =>
	new Promise((resolve, reject) => {
		const data = {
			page,
			...searchFilter,
		}
		if (userId) {
			data['userId'] = userId
		}
		API.get(getUrl.getFactoriesOfUser, data)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getFactoryById = (factoryId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getFactoryById, { factoryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getFactoriesMangeOrders = (order_status, employeeId, page = 0, auctionFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllFabricationOrdersOfUser, { order_status, page, employeeId, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllFabricationOrders = (page = 0, auctionFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllFabricationOrders, { page, ...auctionFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const searchOutputItemsById = (searchId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.searchOutputItemsById, { searchId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getFabricationOrderById = (fabricationOrderId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getFabricationOrderById, { fabricationOrderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadFabricationOrdersApi = (ordersFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadFabricationOrders, { ...ordersFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
