import styled, { css } from 'styled-components'
import { Devices, Spacings } from '@styles'
import { BaseWrapper, H6 } from '@base'

export const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const FormItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
export const InfoWrapper = styled.div`
	padding-top: ${Spacings.SPACING_2B};
`

export const Heading = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
	margin-top: ${Spacings.SPACING_1B};
	@media ${Devices.tablet} {
		margin-right: ${Spacings.SPACING_2B};
	}
`
export const CardWapper = styled.div`
	display: flex;
	margin-top: 10px;
`
export const CardInfo = styled.div`
	${({ isLeft, isRight }) => {
		if (isLeft) {
			return css`
				flex: 1;
				display: flex;
				justify-content: space-between;
			`
		}
		if (isRight) {
			return css`
				flex: 3;
				margin-left: 10px;
			`
		}
	}}
`
export const StepWrapper = styled(BaseWrapper)`
	flex-grow: 1;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	${({ isFlex }) => {
		if (isFlex) {
			return css`
				display: flex;
				flex-direction: column;
			`
		}
	}}
`
export const StepTopWrapper = styled(StepWrapper)`
	padding: 0;
	flex-grow: 0;
	margin-bottom: ${Spacings.SPACING_4B};
	display: flex;

	button {
		min-width: fit-content;
	}
`