import React, { useEffect, useState } from 'react'
import WebcamVideo from './WebcamVideo'
import styled, { css } from 'styled-components'
import { Banner, BaseWrapper, Button, H4, H5, H6, Loader } from '@base'
import { Spacings, Devices } from '@styles'
import WebcamImage from './WebcamImage'
import { Lock } from '@material-ui/icons'
import { PermissionStatusWrapper, StepsToAllow } from '../styles'

const AppWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const ContainerWrapper = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
	border-radius: ${Spacings.SPACING_2B};
	height: 65vh;
	max-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - .5rem)')};
	flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - .5rem)')};
`
export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 5px 0;
`

export const ButtonWrapper = styled(Button)`
	background-color: crimson;
	color: #fff;
	margin-top: 10px;
	padding: 10px 40px;
	border: none;
	border-radius: 25px;
	cursor: pointer;
	min-width: 0;
`

export const StepWrapper = styled(BaseWrapper)`
	flex-grow: 1;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	${({ isFlex }) => {
		if (isFlex) {
			return css`
				display: flex;
				flex-direction: column;
			`
		}
	}}
`
export const StepTopWrapper = styled(StepWrapper)`
	padding: 0;
	flex-grow: 0;
	display: flex;
	button {
		min-width: fit-content;
	}
`

export const BannerText = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`
export const BannerWrapper = styled(Banner)`
	height: 175px;
	overflow: auto;
	align-items: flex-start;
	@media ${Devices.mobile} {
		height: 100%;
		align-items: center;
	}
`
export const SubSectionHeading = styled(H6)`
	margin-top: ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_2B};
`
export const SuccessHeading = styled(H4)`
	margin-top: ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_2B};
`

const initialPermissions = {
	camera: false,
	microphone: false,
}

const Webcam = ({
	org,
	adminView,
	kycStatushandler,
	selectedStep,
	setActiveImageWebcam,
	requesType = 'video',
	capturePhotoHandler,
	preStep,
	update,
}) => {
	const [permissions, setPermissions] = useState(initialPermissions)
	const [loading, setLoading] = useState(!adminView)

	useEffect(() => {
		if (!adminView) {
			checkPermissions()
		}
	}, [])

	const checkPermissions = () => {
		setLoading(true)
		Promise.all([navigator.permissions.query({ name: 'microphone' }), navigator.permissions.query({ name: 'camera' })])
			.then(([microphonePermissionObj, cameraPermissionObj]) => {
				setPermissions({
					camera: cameraPermissionObj.state === 'granted',
					microphone: microphonePermissionObj.state === 'granted',
				})

				const requestAccess = (contraints) => navigator.mediaDevices.getUserMedia(contraints)

				microphonePermissionObj.onchange = () => {
					setPermissions((p) => ({
						...p,
						microphone: microphonePermissionObj.state === 'granted',
					}))
				}
				cameraPermissionObj.onchange = () => {
					setPermissions((p) => ({
						...p,
						camera: cameraPermissionObj.state === 'granted',
					}))
				}

				return requestAccess({ audio: microphonePermissionObj.state !== 'granted', video: cameraPermissionObj.state !== 'granted' })
			})
			.then(() => {})
			.catch((err) => {
				console.log(`Error in permission API: ${err}`)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const renderVideo = () => {
		if (loading) {
			return (
				<PermissionStatusWrapper>
					<H4>Checking microphone and camera permissions</H4>
					<br />
					<Loader size={2} />
				</PermissionStatusWrapper>
			)
		}

		if (!adminView && (!permissions.camera || !permissions.microphone)) {
			const sources = [!permissions.microphone && 'microphone', !permissions.camera && 'camera'].filter(Boolean)
			return (
				<PermissionStatusWrapper>
					<H4>
						Please allow {sources.join(' and ')} permission{sources.length > 1 && 's'} from browser settings
					</H4>
					<StepsToAllow>
						<H5>
							1. Click the <Lock /> icon in the browser's address bar
						</H5>
						<H5>2. Turn on {sources.join('  and ')}</H5>
					</StepsToAllow>
				</PermissionStatusWrapper>
			)
		}

		return (
			<WebcamVideo
				org={org}
				adminView={adminView}
				kycStatushandler={kycStatushandler}
				selectedStep={selectedStep}
				preStep={preStep}
				update={update}
			/>
		)
	}

	if (requesType === 'photos') {
		return <WebcamImage adminView={adminView} setActiveImageWebcam={setActiveImageWebcam} capturePhotoHandler={capturePhotoHandler} />
	}

	return <AppWrapper>{renderVideo()}</AppWrapper>
}

export default Webcam
