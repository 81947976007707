import API from '../api'
import deleteUrl from './paths'

const deleteAdminEnquiry = (enquiryId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteEnquiry, { enquiryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { deleteAdminEnquiry }
