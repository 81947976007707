import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, RadioButton, TOAST_TYPE } from '@base'
import { Devices, Spacings, Text } from '@styles'
import { strings } from '@constants'
import { useDispatch } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { editFinancials } from '@data'
import { toggleDrawer } from '@data/state/action/root'

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`
const RadioInputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@media ${Devices.tablet} {
		flex-direction: row;
	}
	margin-bottom: ${Spacings.SPACING_2B};
`
const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`
const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`
const SECURITY_TYPE = {
	TRUE: true,
	FALSE: false,
}

const SecurityForm = ({ data, financialData, update }) => {
	const dispatch = useDispatch()
	const [security, setSecurity] = useState(financialData?.security || false)

	const onValueChange = (value) => {
		setSecurity(value)
	}
	const onClickSave = () => {
		dispatch(showLoader(true))
		editFinancials({ ...financialData, security })
			.then(() => {
				update()
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, strings('msg_payment_update'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => console.log(err))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const disableButton = security === null

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<SectionItemWrapper>
						<RadioInputWapper>
							<RadioButtonWrapper>
								<RadioButton
									id='debit-radio'
									size='1.125'
									checked={security === SECURITY_TYPE.TRUE}
									value={SECURITY_TYPE.TRUE}
									onChange={(e) => onValueChange(SECURITY_TYPE.TRUE)}
								/>
								<Label htmlFor='debit-radio'>{strings('yes')}</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<RadioButton
									id='credit-radio'
									size='1.125'
									checked={security === SECURITY_TYPE.FALSE}
									value={SECURITY_TYPE.FALSE}
									onChange={(e) => onValueChange(SECURITY_TYPE.FALSE)}
								/>
								<Label htmlFor='credit-radio'>{strings('no')}</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<ActionButton small type='primary' disabled={disableButton} onClick={onClickSave}>
									{strings('update')}
								</ActionButton>
							</RadioButtonWrapper>
						</RadioInputWapper>
					</SectionItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
		</>
	)
}

export default SecurityForm
