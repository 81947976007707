import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, Checkbox, DrawerBodyWrapper, H5, Input, ItemCard, TOAST_TYPE } from '@base'
import { Devices, Spacings } from '@styles'
import { strings } from '@constants'
import { generateOTP, verifyOTP } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { ActionButtonWapper, BodySectionWrapper, ButtonIcon, ButtonIconWapper, FooterWrapper } from './styles'
import RejectReason from './RejectReason'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons'

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const SectionItemWrapper = styled.div`
	display: flex;
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
	flex-direction: ${({ direction }) => direction || 'column'};
	margin-top: ${({ marginTop }) => marginTop || `${Spacings.SPACING_2B}`};

	& > div {
		width: 100%;
	}
`

const ItemWrapper = styled.div`
	flex-grow: 1;
	margin-top: ${Spacings.SPACING_5B};
	display: flex;
	flex-direction: column;
	& > img {
		margin: 0px 0px ${Spacings.SPACING_2B} 0px;
		border-radius: ${Spacings.SPACING_3B};
	}
	@media ${Devices.mobile} {
		flex-direction: row;
		& > img {
			margin: 0px ${Spacings.SPACING_2B} 0px 0px;
		}
	}
`

const initialFormState = {
	aadhaar: {
		label: strings('aadhaar', 'number'),
		disabled: false,
		readOnly: false,
		placeholder: strings('enter', 'aadhaar'),
		value: '',
		dirty: false,
	},
	consent: {
		label: strings('consent_aadhaar'),
		disabled: false,
		value: true,
		dirty: false,
	},
	otp: {
		label: strings('otp'),
		disabled: false,
		readOnly: false,
		displayKey: 'otp',
		primaryKey: 'otp',
		placeholder: strings('enter', 'otp'),
		data: [],
		value: '',
		dirty: false,
	},
}

const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const Aadhaar = ({ nextStep, preStep, org, adminView, kycStatushandler, selectedStep }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(initialFormState)
	const [requestId, setRequestId] = useState(null)
	const [isActiveReason, setIsActiveReason] = useState(false)

	const updateState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
	}

	const getOtpHandler = () => {
		dispatch(showLoader(true))
		generateOTP(formState.aadhaar.value, 'Y')
			.then((res) => {
				setRequestId(res?.message)
				dispatch(showToast(true, strings('msg_otp_sent_mobile'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => {
				dispatch(showToast(true, strings('err_generate_aadhaar_otp_failed'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const submitOtpHandler = () => {
		dispatch(showLoader(true))
		verifyOTP(formState.aadhaar.value, 'Y', formState.otp.value, org?.user?.organisation_id, requestId)
			.then((res) => {
				if (res.response_message === 'Aadhaar data has been updated') {
					dispatch(
						showToast(true, strings('msg_otp_verified'), {
							type: TOAST_TYPE.SUCCESS,
						})
					)
					setTimeout(() => {
						nextStep()
					}, 2000)
				}
			})
			.catch((err) => dispatch(showToast(true, strings('msg_invalid_verification_code'), { type: TOAST_TYPE.ERROR })))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const footerHandler = () => {
		return (
			<BodySectionWrapper>
				<FooterWrapper>
					<SectionItemWrapper isStatement direction={'row'} marginTop={'0px'}>
						<ButtonIconWapper>
							<ButtonIcon
								type='secondary'
								small
								uppercase
								onClick={() => {
									preStep()
								}}
							>
								<ChevronLeftOutlined />
							</ButtonIcon>
							<ButtonIcon
								type='secondary'
								small
								margin={'0px 0px 0px 5px'}
								uppercase
								onClick={() => {
									nextStep()
								}}
							>
								<ChevronRightOutlined />
							</ButtonIcon>
						</ButtonIconWapper>
						<ActionButtonWapper>
							<Button
								xs
								type='secondary'
								variant='destructive'
								onClick={() => {
									setIsActiveReason(true)
								}}
							>
								{strings('reject')}
							</Button>
							<Button
								xs
								type='primary'
								margin='0px 0px 0px 3px'
								onClick={() => {
									kycStatushandler('', selectedStep, true, 'Aadhar Card request accept')
								}}
							>
								{strings('accept')}
							</Button>
						</ActionButtonWapper>
					</SectionItemWrapper>
					{isActiveReason && (
						<RejectReason
							kycStatushandler={kycStatushandler}
							setIsActiveReason={setIsActiveReason}
							setpNumber={selectedStep}
							stepName='Aadhar Card'
							rejectReasonMsg={org?.kyc_reject_reason?.[1] || ''}
						/>
					)}
				</FooterWrapper>
			</BodySectionWrapper>
		)
	}

	const itemData = [
		{
			label: strings('name'),
			value: org?.aadhaar_file_response?.result?.data_from_aadhaar?.name || '-',
		},
		{
			label: strings('dob'),
			value: org?.aadhaar_file_response?.result?.data_from_aadhaar?.dob || '-',
		},

		{
			label: strings('address'),
			wrap: true,
			value: org?.aadhaar_file_response?.result?.data_from_aadhaar?.address?.combined_address || '-',
		},
		{
			label: strings('gender'),
			value: org?.aadhaar_file_response?.result?.data_from_aadhaar?.gender || '-',
		},
	]

	return (
		<>
			<DrawerBodyWrapper>
				<SectionWrapper>
					<H5>{strings('provide_details_director')}</H5>
					<SectionItemWrapper>
						<Input
							type='text'
							disabled={requestId}
							label={formState.aadhaar?.label}
							value={formState.aadhaar.value || org?.aadhaar_file_response?.result?.data_from_aadhaar?.masked_aadhaar_number}
							onChange={onChange('aadhaar')}
							pattern='\d{12}|X{4}\s+X{4}\s+\d{4}'
							errorMap={{
								patternMismatch: strings('enter_digit_aadhaar'),
								valueMissing: strings('aadhar_required'),
							}}
						/>
					</SectionItemWrapper>
					<SectionItemWrapper direction='row' justifyContent='flex-start'>
						<Checkbox
							id='checkbox_consent'
							size={1.125}
							disabled={requestId && true}
							checked={formState.consent?.value}
							value={formState.consent?.value}
							onChange={(e) => onChange('consent')(e.target.checked)}
						/>
						<FormElementLabel disabled={adminView || (requestId && true)} htmlFor='checkbox_consent'>
							{formState.consent?.label}
						</FormElementLabel>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<Button
							small
							type='primary'
							onClick={getOtpHandler}
							disabled={!formState.consent?.value || (requestId && true) || formState.aadhaar.value.length !== 12}
						>
							{strings('generate', 'otp')}
						</Button>
					</SectionItemWrapper>

					<ItemWrapper>
						{org?.aadhaar_file_response?.result?.data_from_aadhaar?.image && (
							<img
								src={`data:image/png;base64,${org?.aadhaar_file_response?.result?.data_from_aadhaar?.image}`}
								alt={'aadhaar-photos'}
							/>
						)}
						{org?.aadhaar_file_response?.result?.data_from_aadhaar && (
							<ItemCard small data={itemData} key={`aadhaar-item-${Math.random()}`} />
						)}
					</ItemWrapper>

					{requestId && (
						<>
							<SectionItemWrapper>
								<Input
									type='text'
									disabled={formState.otp?.disabled}
									label={formState.otp?.label}
									value={formState.otp.value}
									onChange={onChange('otp')}
									pattern='\d{6}'
									maxLength='6'
									errorMap={{
										patternMismatch: strings('enter_digit_otp'),
										valueMissing: strings('otp_required'),
									}}
								/>
							</SectionItemWrapper>
							<SectionItemWrapper>
								<Button small type='primary' onClick={submitOtpHandler} disabled={formState.otp.value.length !== 6}>
									{strings('submit', 'otp')}
								</Button>
							</SectionItemWrapper>
						</>
					)}
				</SectionWrapper>
			</DrawerBodyWrapper>
			{adminView && footerHandler()}
		</>
	)
}

export default Aadhaar
