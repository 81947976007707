import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Input, Select } from '@base'
import { Spacings } from '@styles'
import { strings } from '@constants'
import { getAllUsers } from '@data'
import { User } from '@data/model'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	margin-top: ${Spacings.SPACING_2B};
	${({ isStatement }) => {
		if (isStatement) {
			return css`
				display: flex;
				justify-content: space-between;
			`
		}
	}}
	& > div {
		width: 100%;
	}
`

const NewReportForm = React.memo(({ state, updateState }) => {
	const { pan } = state
	const [users, setUsers] = useState([])
	const [selectedUser, setSelectedUser] = useState([])

	useEffect(() => {
		getAllUsers().then((data) => setUsers(data))
	}, [])

	const _users = useMemo(
		() =>
			users?.map((item) => {
				item['display_name'] = `${(item.organisation_name || item.full_name || '') + ' '}(${item.mobile_number})`
				return item
			}),
		[users]
	)

	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
	}

	const onChangeUserHandler = (item) => {
		onChange('userDetails')(new User(item).getUserDetails())
		onChange('pan')(item?.organisation?.pan_number || item?.organisation?.gst_number || '')
		setSelectedUser(item)
	}

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Select
					id='admin-buyer-select'
					placeholder={strings('select', 'user')}
					data={_users || []}
					displayKey='display_name'
					primaryKey='organisation_id'
					value={selectedUser}
					onChange={onChangeUserHandler}
					label={strings('select', 'user')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Input
					uppercase
					type='text'
					label={pan?.label}
					disabled={pan?.disabled}
					value={pan?.value}
					pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}|\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}|[A-Z]{5}[0-9]{4}[A-Z]{1}'
					onChange={onChange('pan')}
					errorMap={{
						patternMismatch: strings('enter_pan_gst'),
					}}
				/>
			</SectionItemWrapper>
		</FormWrapper>
	)
})

export default NewReportForm
