import API from '../api'
import postUrl from './paths'

export const addCommodityPriceListApi = (commodityItems) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addCommodityPriceList, commodityItems)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const createCommodityApi = (commodityData) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.commodity, commodityData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
