import API from '../api'
import putUrl from './paths'

const addMessageToEnquiryJourney = (enquiryId,message,userDetails) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.addMessageToEnquiryJourney,{userDetails}, { enquiryId, message} )
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export { addMessageToEnquiryJourney }