import React, { useMemo } from 'react'
import { Input } from '@base'
import { InputWapper, SectionItemWrapper } from './style'

const CommodityPriceForm = ({ item, index, onChangeHandle, commodities }) => {
	const { sku, grade, ex_delhi, ex_raipur, ex_mumbai, ex_bangalore, ex_hyderabad } = item
	
	const checkDisabled = useMemo(() => {
		const disabledSku = commodities.filter(item1 => item1.disabled && item1.disabled.length > 0).find(i => i._sku === item.sku?.value && i._grade === item.grade?.value)
		return {
			mumbai: disabledSku?.disabled?.includes('mumbai'),
			delhi: disabledSku?.disabled?.includes('delhi'),
			raipur: disabledSku?.disabled?.includes('raipur'),
			bangalore: disabledSku?.disabled?.includes('bangalore'),
			hyderabad: disabledSku?.disabled?.includes('hyderabad'),
		}
	}, [item])

	return (
		<SectionItemWrapper isFlex isLeftMargin index={index}>
			<InputWapper>
				<Input
					small
					type='text'
					value={sku?.value}
					name={sku?.name}
					disabled
					onChange={(val) => {
						onChangeHandle(index, sku?.name, val)
					}}
				/>
			</InputWapper>
			<InputWapper>
				<Input
					small
					name={grade?.name}
					type='text'
					value={grade?.value}
					onChange={(val) => {
						onChangeHandle(index, grade?.name, val)
					}}
				/>
			</InputWapper>
			<InputWapper>
				<Input
					small
					name={ex_delhi?.name}
					type='number'
					value={ex_delhi?.value}
					disabled={checkDisabled.delhi}
					onChange={(val) => {
						onChangeHandle(index, ex_delhi?.name, val)
					}}
				/>
			</InputWapper>
			<InputWapper>
				<Input
					small
					type='number'
					name={ex_raipur?.name}
					value={ex_raipur?.value}
					disabled={checkDisabled.raipur}
					onChange={(val) => {
						onChangeHandle(index, ex_raipur?.name, val)
					}}
				/>
			</InputWapper>
			<InputWapper>
				<Input
					small
					type='number'
					name={ex_mumbai?.name}
					value={ex_mumbai?.value}
					placeholder={ex_mumbai?.placeholder}
					disabled={checkDisabled.mumbai}
					onChange={(val) => {
						onChangeHandle(index, ex_mumbai?.name, val)
					}}
				/>
			</InputWapper>
			<InputWapper>
				<Input
					small
					type='number'
					name={ex_bangalore?.name}
					value={ex_bangalore?.value}
					placeholder={ex_bangalore?.placeholder}
					disabled={checkDisabled.bangalore}
					onChange={(val) => {
						onChangeHandle(index, ex_bangalore?.name, val)
					}}
				/>
			</InputWapper>
			<InputWapper>
				<Input
					small
					type='number'
					name={ex_hyderabad?.name}
					value={ex_hyderabad?.value}
					placeholder={ex_hyderabad?.placeholder}
					disabled={checkDisabled.hyderabad}
					onChange={(val) => {
						onChangeHandle(index, ex_hyderabad?.name, val)
					}}
				/>
			</InputWapper>
		</SectionItemWrapper>
	)
}

export default CommodityPriceForm
