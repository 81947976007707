import API from '../api'
import putUrl from './paths'

export const updateMachine = (machine, user) =>
	new Promise((resolve, reject) => {
		machine.updated_by = user.user_id
		machine.updated_at = new Date().getTime()
		API.put(putUrl.updateMachine, machine)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
