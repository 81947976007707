import { deleteUserWithOrg } from '@data'
import React, { useState } from 'react'

const DeleteProfile = () => {
	const [userId, setUserId] = useState('')
	const [msg, setmsg] = useState()

	const handleSubmit = (event) => {
		event.preventDefault()
		deleteUserWithOrg(userId)
			.then((response) => {
				setmsg(`User Deleted Successfully: ${userId}`)
			})
			.catch((err) => {
				setmsg()
				console.log(err)
			})
	}

	return (
		<form onSubmit={handleSubmit}>
			<div style={{ flexDirection: 'column', display: 'flex', width: '100%', height: '700px', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ margin: '10px 0px' }}>
					<h1>Delete User by Id</h1>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<input
						type='text'
						style={{ width: '250px', height: '30px' }}
						placeholder='Enter user Id'
						value={userId}
						onChange={(e) => setUserId(e.target.value)}
					/>
					<input type='submit' disabled={!userId} style={{ margin: '5px', width: '100px', height: '30px' }} />
				</div>
				<p>{msg}</p>
			</div>
		</form>
	)
}

export default DeleteProfile
