import { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { uploadDocument, uploadBuyerInvoiceDocumentsApi } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleFileViewer, updateDrawerMeta } from '@data/state/action/root'
import { Spacings, Text } from '@styles'
import {
	DrawerBodyWrapper,
	Input,
	SectionHeader,
	Button,
	DocumentDownload,
	H6,
	UploadButton,
	Calendar,
	TOAST_TYPE,
	ImageHandler,
	UploadMultipleFileButton,
} from '@base'
import { documentInvoiceType, strings, docTypeAll } from '@constants'

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ExportDocFormItemWrapper = styled.div`
	height: ${Spacings.SPACING_10B};
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
`
const ExportDocName = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin-bottom: ${Spacings.SPACING_1B};
	font-size: ${Text.SMALL};
`

const ExportDocItem = styled.div`
	justify-content: right;
	display: flex;
	flex-direction: row;
	flex: 1;
`

const ExportDownloadIconWrapper = styled.div`
	padding: ${Spacings.SPACING_1B};
	width: fit-content;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	align-items: center;
	& > svg {
		font-size: 1.2rem;
	}
	border-radius: ${Spacings.SPACING_2B};
`

const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	& > div {
		width: 100%;
	}

	${({ IsRejectWieght }) => {
		if (IsRejectWieght) {
			return css`
				& > div {
					flex: 4;
				}
				div:nth-child(2) {
					flex: 4;
					margin-left: 5px;
				}
			`
		}
	}};

	${({ isStock }) => {
		if (isStock) {
			return css`
				justify-content: space-between;
			`
		}
	}};
`

const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`

const DocUploadButton = styled(UploadButton)`
	margin-left: auto;
`

const DocumentsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const DownloadIconWrapper = styled.div`
	padding: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	align-items: center;
	justify-content: center;
	margin-right: ${Spacings.SPACING_4B};
	width: fit-content;
	border-radius: ${Spacings.SPACING_2B};
`

const Message = styled(H6)`
	flex-grow: 1;
	flex-basis: 100%;
	margin-top: ${Spacings.SPACING_1B};
	margin-bottom: ${Spacings.SPACING_3B};
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
`
const SubSectionHeading = styled(H6)`
	margin-top: ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_2B};
`
const UploadMultipleFile = styled(UploadMultipleFileButton)`
	margin-left: auto;
`
const initialFormState = {
	invoice_amount: {
		label: `${strings('invoice', 'amount')} (${strings(['including', 'gst'])})`,
		value: '',
		disabled: true,
		placeholder: strings('placeholder_enter_invoice_amount'),
	},
	invoice_number: {
		label: strings('invoice', 'number'),
		value: '',
		disabled: true,
		placeholder: strings('placeholder_enter_invoice_number'),
	},
	invoice_date: {
		label: strings('invoice', 'date'),
		value: '',
		disabled: true,
		placeholder: strings('placeholder_enter_invoice_date'),
	},
	delivery_status: {
		label: strings('delivery', 'status'),
		value: '',
		disabled: true,
		placeholder: strings(['enter', 'delivery', 'status']),
	},
}

const deriveStateFromData = (data) => {
	const _state = {
		...initialFormState,
	}

	_state.invoice_amount.value = data?.invoice_amount
	_state.invoice_number.value = data?.invoice_number
	_state.invoice_date.value = data?.invoice_date
	_state.delivery_status.value = data?.delivery_status

	return _state
}

const ViewInvoice = memo(({ order, isPurchaseOrder, update }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const invoiceData = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'invoiceData']))
	const [formState, setFormState] = useState(initialFormState)
	const isUploadInvoiceAllowed = user.role.toLowerCase() === strings('admin').toLowerCase() || !isPurchaseOrder
	const isDeliveryReceiptAllowed = user.role.toLowerCase() === strings('admin').toLowerCase() || isPurchaseOrder

	useEffect(() => {
		setFormState(deriveStateFromData(invoiceData))
	}, [invoiceData])

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onChange = (key) => (value) => updateState(key, { value })

	const uploadDoc = (files, type, key) => {
		const invoiceType = isPurchaseOrder ? 'buyer' : 'supplier'
		if (files.length < 1) return
		const getPath = Array.from(files).map((f) => f)
		dispatch(showLoader(true))
		const updates = {}
		uploadBuyerInvoiceDocumentsApi(
			invoiceData.invoice_number,
			getPath.length > 0 ? getPath : [files],
			order.order_id,
			documentInvoiceType[type],
			invoiceType
		)
			.then((response) => {
				dispatch(showToast(true, response.response_message, { type: TOAST_TYPE.SUCCESS }))
				updates[key] = ['tc_received', 'dkt_documents'].includes(key)
					? [...(invoiceData[key] ?? []), ...response.file_paths]
					: response.file_paths[0]
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...invoiceData,
							...updates,
						},
					})
				)
				update()
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const renderDocuments = (data, key) => {
		data = (data ?? []).filter(Boolean)
		if (Array.isArray(data) && data.length) {
			return (
				<DocumentsWrapper>
					<Message>{strings('click_icon_to_download')}</Message>
					{data.map((item, index) => (
						<DownloadIconWrapper key={`${key}_doc#_${index}`}>
							<DocumentDownload link={item} type={key} updateDocument={updateDocument} />
						</DownloadIconWrapper>
					))}
				</DocumentsWrapper>
			)
		}
		return (
			<DocumentsWrapper>
				<Message>{strings('no_documents')}</Message>
			</DocumentsWrapper>
		)
	}

	const renderExportDocuments = (data, key) => {
		data = (data ?? []).filter(Boolean)
		if (Array.isArray(data) && data.length) {
			return (
				<DocumentsWrapper>
					{data.map((item, index) => {
						return (
							<ExportDownloadIconWrapper key={`${key}_doc#_${index}`}>
								<DocumentDownload link={item} type={key} updateDocument={updateDocument} />
							</ExportDownloadIconWrapper>
						)
					})}
				</DocumentsWrapper>
			)
		}

		return (
			<DocumentsWrapper>
				<Message>{strings('no_documents')}</Message>
			</DocumentsWrapper>
		)
	}

	const updateDocument = (key, link) => {
		if (key === 'tc') {
			invoiceData.tc_received = invoiceData.tc_received.filter((item) => item !== link)
		} else if (key === 'bilti') {
			invoiceData.bilty_document = null
		} else if (key === 'ewaybill') {
			invoiceData.eway_bill_document = null
		} else if (key === 'delivery_receipt') {
			invoiceData.delivery_receipt = null
		} else if (key === 'dkt') {
			invoiceData.dkt_documents = invoiceData.dkt_documents.filter((item) => item !== link)
		}
	}

	const uploadPhotoHandler = (files) => {
		const getPath = Array.from(files).map((f) => f)
		dispatch(showLoader(true))
		const filed = getPath.map((item) => {
			return uploadDocument(item, invoiceData?.invoice_number, order.order_id, 'stockPhotos', 'supplier').then((response) => {
				if (response?.file_path) {
					return response?.file_path
				} else {
					return Promise.reject()
				}
			})
		})
		Promise.all(filed)
			.then((values) => {
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...invoiceData,
							product_photos: invoiceData?.product_photos ? [...values, ...invoiceData?.product_photos] : [...values],
						},
					})
				)
				dispatch(showToast(true, strings('stock_img_uploaded'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(true))
			})
	}

	const viewPhotoHandler = () => {
		if (invoiceData?.product_photos) {
			dispatch(toggleFileViewer(true, { files: invoiceData?.product_photos }))
		}
	}
	const viewInvoiceHandler = (file) => {
		dispatch(toggleFileViewer(true, { files: file }))
	}
	const renderOrderItems = () => {
		return invoiceData?.order_items?.map((item, index) => (
			<FormItemWrapper IsRejectWieght>
				<Input type='number' label={`${strings('serviced_weight_kg')} - ${item.product_name}`} value={item.serviced_weight} disabled />
				{order?.platform?.toLowerCase()?.includes('scrap') && (
					<Input type='number' label={`${strings('rejected', 'weight')}`} value={invoiceData?.rejected_weight} disabled />
				)}
			</FormItemWrapper>
		))
	}

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: (
					<>
						{strings('invoice', 'details')}
						<ActionButton
							small
							uppercase
							type='secondary'
							onClick={() => {
								if (invoiceData?.invoice_document) {
									viewInvoiceHandler(invoiceData?.invoice_document)
								}
							}}
						>
							{strings('view', 'invoice')}
						</ActionButton>
					</>
				),
			})
		)
	}, [dispatch, invoiceData])

	return (
		<DrawerBodyWrapper>
			<FormWrapper>
				<FormItemWrapper>
					<Input
						type='text'
						label={formState.invoice_number?.label}
						disabled={formState.invoice_number?.disabled}
						value={formState.invoice_number?.value}
						placeholder={formState.invoice_number?.placeholder}
						onChange={onChange('invoice_number')}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Calendar
						label={formState.invoice_date?.label}
						disabled={formState.invoice_date?.disabled}
						value={formState.invoice_date?.value}
						placeholder={formState.invoice_date?.placeholder}
						onChange={onChange('invoice_date')}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						type='number'
						label={formState.invoice_amount?.label}
						disabled={formState.invoice_amount?.disabled}
						value={formState.invoice_amount?.value}
						placeholder={formState.invoice_amount?.placeholder}
						onChange={onChange('invoice_amount')}
					/>
				</FormItemWrapper>
				{isPurchaseOrder && (
					<FormItemWrapper>
						<Input
							label={formState.delivery_status?.label}
							disabled={formState.delivery_status?.disabled}
							value={formState.delivery_status?.value}
							placeholder={formState.delivery_status?.placeholder}
							onChange={onChange('delivery_status')}
						/>
					</FormItemWrapper>
				)}
				<SubSectionHeading>{strings('serviced_weight_sku')}</SubSectionHeading>
				{renderOrderItems()}
				<FormItemWrapper>
					<ActionButton
						noAutoLeft
						small
						uppercase
						type='secondary'
						disabled={!invoiceData.gps_link}
						onClick={() => {
							window.open(invoiceData?.gps_link, '_blank')
						}}
					>
						{strings('track_order')}
					</ActionButton>
				</FormItemWrapper>
			</FormWrapper>
			<br />
			{isUploadInvoiceAllowed && (
				<>
					<SectionHeader>{strings('stock', 'photo')}</SectionHeader>
					<FormItemWrapper isStock>
						<ImageHandler images={invoiceData?.product_photos} handler={uploadPhotoHandler} />
					</FormItemWrapper>
				</>
			)}

			<br />
			{isDeliveryReceiptAllowed && (
				<>
					<SectionHeader>{strings('delivery_receipt')}</SectionHeader>
					<FormItemWrapper>{renderDocuments([invoiceData?.delivery_receipt], 'delivery_receipt')}</FormItemWrapper>

					<FormItemWrapper>
						<DocUploadButton
							label={strings('upload', 'delivery_receipt')}
							small
							uppercase
							onChange={(file) => uploadDoc(file, 'delivery_receipt', 'delivery_receipt')}
						/>
					</FormItemWrapper>
				</>
			)}

			<SectionHeader>{strings('test_certificate')} </SectionHeader>
			<FormItemWrapper>{renderDocuments(invoiceData?.tc_received, 'tc')}</FormItemWrapper>
			{isUploadInvoiceAllowed && (
				<FormItemWrapper>
					<UploadMultipleFile
						label={strings(['upload', 'test_certificate'])}
						small
						uppercase
						onChange={(file) => uploadDoc(file, 'tc', 'tc_received')}
					/>
				</FormItemWrapper>
			)}
			<br />
			<br />
			<SectionHeader>{strings('bilti')}</SectionHeader>
			<FormItemWrapper>{renderDocuments([invoiceData?.bilty_document], 'bilti')}</FormItemWrapper>
			{isUploadInvoiceAllowed && (
				<FormItemWrapper>
					<DocUploadButton
						label={strings('upload', 'bilti')}
						small
						uppercase
						onChange={(file) => uploadDoc(file, 'bilti', 'bilty_document')}
					/>
				</FormItemWrapper>
			)}
			<br />
			<br />
			<SectionHeader>{strings('ewaybill')}</SectionHeader>
			<FormItemWrapper>{renderDocuments([invoiceData?.eway_bill_document], 'ewaybill')}</FormItemWrapper>
			{isUploadInvoiceAllowed && (
				<FormItemWrapper>
					<DocUploadButton
						label={strings('upload', 'ewaybill')}
						small
						uppercase
						onChange={(file) => uploadDoc(file, 'ewaybill', 'eway_bill_document')}
					/>
				</FormItemWrapper>
			)}
			<br />
			<br />

			<SectionHeader>{strings('dharamkata_slip')}</SectionHeader>
			<FormItemWrapper>{renderDocuments(invoiceData?.dkt_documents, 'dkt')}</FormItemWrapper>
			{isUploadInvoiceAllowed && (
				<FormItemWrapper>
					<UploadMultipleFile
						label={strings('upload', 'dharamkata_slip')}
						small
						uppercase
						onChange={(file) => uploadDoc(file, 'dkt', 'dkt_documents')}
					/>
				</FormItemWrapper>
			)}
			<br />
			<br />
			{invoiceData.export_documents != null && <SectionHeader>{strings('export', 'documents')}</SectionHeader>}
			{invoiceData.export_documents
				?.filter((item) => item.only_admin == false)
				.map((item) => {
					return (
						<ExportDocFormItemWrapper>
							<ExportDocName>{docTypeAll.find((val) => item.document_type == val.name).aliasname}</ExportDocName>
							<ExportDocItem>{renderExportDocuments([item?.documents], item.document_type)}</ExportDocItem>
						</ExportDocFormItemWrapper>
					)
				})}
		</DrawerBodyWrapper>
	)
})

export default ViewInvoice
