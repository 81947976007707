import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { BaseWrapper, Button, H5, H6 } from '@base'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	flex-grow: 1;
`

export const CustomBaseWrapper = styled(BaseWrapper)`
	@media ${Devices.tablet} {
		margin-bottom: ${Spacings.SPACING_4B};
	}
`

export const OrderJourneyWrapper = styled(CustomBaseWrapper)`
	padding-top: ${Spacings.SPACING_4B};
	padding-bottom: ${Spacings.SPACING_4B};
	flex-grow: ${({ grow }) => (grow ? 1 : 0)};
	margin-bottom: ${Spacings.SPACING_3B};
`

export const ColumnBaseWrapper = styled(CustomBaseWrapper)`
	min-width: 1px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-top: ${Spacings.SPACING_3B};
	padding-bottom: ${Spacings.SPACING_3B};
`

export const PsuedoWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B};
	}
`

export const Footer = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
`

export const TwoPanelsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	& > div {
		flex: 1;
	}

	& > div {
		margin-bottom: ${Spacings.SPACING_4B};
	}

	@media ${Devices.laptop} {
		flex-direction: row;
		& > div:first-child {
			margin-right: ${Spacings.SPACING_4B};
		}
	}
`

export const FieldsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	@media ${Devices.tablet} {
		flex-direction: row;
	}
`

export const FieldWrapper = styled.div`
	flex-grow: 1;

	@media ${Devices.tablet} {
		max-width: ${({ width }) => width || '18rem'};
		margin-right: ${Spacings.SPACING_4B};
		&:last-of-type {
			margin-right: 0;
		}
	}
`

export const ItemsListWrapper = styled.div`
	min-height: 18rem;
	@media ${Devices.tablet} {
		min-height: 20rem;
	}
`

export const ExntededPanelItemsWrapper = styled.div`
	display: flex;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_4B} ${Spacings.SPACING_2B};
	flex-wrap: wrap;
`
export const ExntendedItemWrapper = styled.div`
	max-width: ${({ width }) => width + '%'};
	margin: ${({ margin }) => margin};
	flex-grow: 1;
`
export const WeightUnitExntendedItemWrapper = styled.div`
	display: flex;
	max-width: 70%;
	width: 70%;

	& > div:first-of-type {
		width: 60%;
		clip-path: polygon(0% 0%, calc(100% - ${Spacings.SPACING_6}) 0%, calc(100% - ${Spacings.SPACING_6}) 100%, 0% 100%);
		input {
			border-right: ${Spacings.SPACING_2B} solid ${({ theme }) => theme.palette.background.border};
		}
	}
	& > div:last-of-type {
		width: 40%;
		clip-path: polygon(${Spacings.SPACING_6} 0%, 100% 0%, 100% 100%, ${Spacings.SPACING_6} 100%);
		margin-left: -${Spacings.SPACING_3B};
	}
`

export const CardButtonsWrapper = styled.div`
	display: flex;
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
`

export const ListLabel = styled(H5)`
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
	margin-bottom: ${Spacings.SPACING_2B};
`

export const PillsWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_3B};
`

export const BasicOrderInfoWrapper = styled(CustomBaseWrapper)`
	margin-bottom: ${Spacings.SPACING_4B};
	padding-top: ${Spacings.SPACING_2B};
	padding-bottom: ${Spacings.SPACING_2B};
`
export const FlexRowWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: end;
	margin-top: ${Spacings.SPACING_1B};
`

export const OrderJourneysWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 50%);
	gap: ${Spacings.SPACING_2B};

	@media ${Devices.tablet} {
		grid-template-columns: repeat(3, 33.3%);
	}
`

export const PhotosWrapper = styled.div``

export const PhotosSectionHeading = styled(H5)`
	color: ${({ theme }) => theme.palette.text.dark};
	margin-bottom: ${Spacings.SPACING_3B};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.border};
	padding-bottom: ${Spacings.SPACING_1B};
`

export const OrderJourneyCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
`

export const ItemName = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${Spacings.SPACING_1B};
`

export const ItemInfo = styled(H5)`
	line-height: ${Text.LINE_HEIGHT_MIN};
	margin-bottom: ${Spacings.SPACING_1B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	font-size: ${Text.EXTRA_SMALL};
	min-height: ${Spacings.SPACING_4B};

	&:last-of-type {
		margin-bottom: ${Spacings.SPACING_5B};
	}

	& > span {
		margin-left: ${Spacings.SPACING_1B};
		display: inline-flex;
		align-items: center;
		font-size: ${Text.SMALL};
		& > svg {
			font-size: ${Text.EXTRA_SMALL};
		}
	}
`

export const InfoPill = styled.div`
	display: flex;
	width: fit-content;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ theme, ongoing }) => (ongoing ? theme.palette.text.dark : theme.palette.text.white)};
	background: ${({ theme, ongoing }) => (ongoing ? theme.palette.background.greenLight : theme.palette.background.redLight)};
	border-radius: ${Spacings.SPACING_10B};
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
	margin-bottom: ${Spacings.SPACING_3B};
`

export const ItemButtonWrapper = styled.div`
	display: flex;
	margin-top: auto;
`

export const FormSection = styled.div`
	display: flex;
	flex-direction: column;
`

export const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, calc(50% - ${Spacings.SPACING_6}));
	gap: ${Spacings.SPACING_3B};
`

export const FormFieldWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_1B};
`
export const MarkCompleteButton = styled(Button)`
	min-width: fit-content;
	margin-left: ${Spacings.SPACING_3B};
	pointer-events: ${({ noPointerEvents }) => (noPointerEvents ? 'none' : 'all')};
	@media ${Devices.tablet} {
		margin-left: auto;
	}
`

export const OrderLink = styled.div`
	color: ${({ theme }) => theme.palette.text.blue};
	cursor: pointer;
`
