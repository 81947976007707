class UserCommodity {
	constructor(data) {
		this.id = data?.id ?? ''
		this.access_type = 'web'
		this.active =   false
		this.category_name = data?.category_name ?? []
		this.commodity_id = data?.commodity_id ?? ''
		this.commodity_name = data?.commodity_name ?? ''
		this.commodity_type = data?.commodity_type ?? ''
		this.created_at = data?.created_at ?? null //date
		this.created_by = data?.created_by ?? ''
		this.updated_at = data?.updated_at ?? null //date
		this.updated_by = data?.updated_by ?? ''
	}

	hasCommodity() {
		return {
			active: this.active,
			commodity_id: this.commodity_id,
			commodity_name: this.commodity_name,
			commodity_type: this.commodity_type,
		}
	}
}

export default UserCommodity
