import React from 'react'
import { Checkbox, Input, Textarea, Select } from '@base'
import { strings } from '@constants'
import { capitalizeWord } from '@utils'
import {
	FormHeading,
	FormElementLabel,
	FormWrapper,
	SectionItemWrapper,
	SectionItemWrapperWithTwoItemsInRow,
	MBCheckboxContainer,
	MBCheckboxMain,
} from '../styles'

const CreateProductForm = ({
	availableBrands,
	categories,
	selectedCategory,
	setCategory,
	state,
	onProductChange,
	handleBrandsChange,
	handleAttributeChange,
	selectAllAttributes,
}) => {
	const { weight_per_meter, description, product_name, product_id, brands } = state

	const onChange = (key) => (value) => {
		onProductChange(key, value)
	}
	const onhandleAttributeChange = (e, attribute) => {
		handleAttributeChange(e, attribute)
	}

	const onhandleBrandsChange = (brand, selected) => {
		handleBrandsChange(brand, selected)
	}

	const onSetCategory = (value) => {
		setCategory(value)
	}

	const renderAttributeFields = () => {
		return (
			<SectionItemWrapperWithTwoItemsInRow>
				{Object.keys(selectedCategory.category_attributes).map((attribute) =>
					selectedCategory.category_attributes[attribute] && attribute !== 'length' ? (
						<Input
							type={attribute === 'custom_details' ? 'text' : 'number'}
							label={capitalizeWord(attribute) + ' (mm)*'}
							value={state.product_attributes[attribute]}
							onChange={(e) => onhandleAttributeChange(e, attribute)}
							required
						/>
					) : null
				)}

				<Input
					type='number'
					label={strings('length') + '(mm)'}
					value={state.product_attributes['length']}
					onChange={(e) => onhandleAttributeChange(e, 'length')}
				/>
			</SectionItemWrapperWithTwoItemsInRow>
		)
	}

	const renderBrandCheckBoxes = () => (
		<MBCheckboxContainer>
			{availableBrands.map((brand) => {
				const brandName = brand?.brand_name
				const checked = Boolean(brands?.find((_b) => _b.brand_name === brandName))
				return (
					<MBCheckboxMain key={brandName}>
						<Checkbox id={brandName} size={1.125} checked={checked} onChange={(e) => onhandleBrandsChange(brand, e.target.checked)} />
						<FormElementLabel htmlFor={brandName}>{brandName}</FormElementLabel>
					</MBCheckboxMain>
				)
			})}
		</MBCheckboxContainer>
	)
	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Select
					id='category-select'
					label={strings('category')}
					displayKey='category_name'
					primaryKey='category_id'
					placeholder={!categories ? 'Loading...' : 'Select a category'}
					data={categories}
					disabled={!categories}
					name={strings('category')}
					value={selectedCategory}
					onChange={onSetCategory}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Textarea label={strings('description')} value={description} rows={2} onChange={onChange('description')} />
			</SectionItemWrapper>
			<FormHeading>{strings('product', 'details')}</FormHeading>
			<SectionItemWrapper>
				<Input
					type='text'
					label={strings('product_name')}
					value={product_name}
					onChange={onChange('product_name')}
					placeholder={strings('msg_no_special_char')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Input type='text' label={strings('product_id')} disabled={true} value={product_id} onChange={onChange('product_id')} />
			</SectionItemWrapper>
			<FormHeading>{strings('product', 'attributes')}</FormHeading>
			{renderAttributeFields()}
			<SectionItemWrapper>
				<Input
					type='number'
					label={strings('weight_m')}
					value={weight_per_meter}
					onChange={onChange('weight_per_meter')}
					placeholder={strings('weight_per_meter')}
				/>
			</SectionItemWrapper>
			<FormHeading isAttribute>
				{strings('select', 'brands')}
				<div>
					<Checkbox
						id={'select-all'}
						size={1.125}
						checked={brands?.length === availableBrands?.length}
						onChange={(e) => selectAllAttributes(e.target.checked)}
					/>
					<FormElementLabel htmlFor={'select-all'}>{strings(['select', 'all', 'brands'])}</FormElementLabel>
				</div>
			</FormHeading>
			<SectionItemWrapper>{renderBrandCheckBoxes()}</SectionItemWrapper>
		</FormWrapper>
	)
}

export default CreateProductForm
