import API from '../api'
import postUrl from './paths'

export const createPunchOrder = (punchOrder) =>
    new Promise((resolve, reject) => {
        API.post(postUrl.createPunchOrder, { ...punchOrder })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })

export const uploadPunchOrderFile = (file) =>
    new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file', file)
        API.post(postUrl.uploadPunchOrderFile, formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })