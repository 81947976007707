class Ledger {
  constructor() {
    this.commodity = null; //string
    this.currency = null; //string
    this.mb_bank_details = null;
    // {
    //   account_number: null,
    //   bank_name: null,
    //   ifsc_code: null
    // },
    this.money_transaction_type = "DEBIT"; //string // credit/debit with respect to metalbook
    this.transaction_amount = 0;
    this.transaction_date = new Date().getTime();;
    this.transaction_id = null;//string
    this.transaction_remarks = null; //string
    this.transaction_source = "DEPOSIT"; //string // bill(invoice)/credit(dis)/deposit
    this.transaction_type = null; //string //sale/purchase
    this.user_bank_details = null;
    // {
    //   this.account_number = null; //string
    //   this.bank_name = null; //string
    //   this.ifsc_code = null; //string
    // };
    this.user_details = null;

    this.access_type = 'web'; //string
    this.updated_at = new Date().getTime(); //date
    this.updated_by = '';
    this.created_at = new Date().getTime(); //date
    this.created_by = '';
    this.platform = 'MARKETPLACE';
  }
}

export default Ledger;
