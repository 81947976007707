import React, { useEffect, useState } from 'react'
import { DrawerBodyWrapper, TOAST_TYPE } from '@base'
import { useDispatch } from 'react-redux'
import { strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { Brand, Commodity } from '@data/model'
import { createBrandApi, getAllCommodityApi } from '@data'
import { showLoader, showToast } from '@data/state/action'
import CreateBrandForm from './CreateBrandForm'
import { ActionButton, Footer } from '../../styles'

const typeData = [{ name: 'PRIMARY' }, { name: 'SECONDARY' }]

const initialFormState = {
	brand_name: {
		label: strings('name'),
		disabled: true,
		value: '',
		dirty: false,
		placeholder: strings(['enter', 'brand', 'name']),
	},
	type: {
		label: strings('type'),
		value: { name: null },
		disabled: false,
		placeholder: strings(['select', 'type']),
		data: typeData,
		displayKey: 'name',
		required: true,
		dirty: false,
	},
	commodity: {
		label: strings('commodity'),
		value: { commodity_name: null },
		disabled: false,
		placeholder: strings(['select', 'commodity', 'type']),
		displayKey: 'commodity_name',
		required: true,
		dirty: false,
	},
}
const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true) => {
	const derivedState = { ...oldState }
	derivedState.brand_name.value = data?.brand_name || ''
	derivedState.brand_name.disabled = !editable

	derivedState.type.value = { name: data?.type || 'SECONDARY' }
	derivedState.type.disabled = !editable

	derivedState.commodity.value = { commodity_name: data?.commodity || 'steel' }
	derivedState.commodity.disabled = !editable

	return derivedState
}

const CreateBrand = ({ brandDetails, editable = true, update }) => {
	const [formState, setFormState] = useState(initialFormState)
	const [commodities, setCommodities] = useState()
	const dispatch = useDispatch()

	useEffect(() => {
		getAllCommodityApi().then((res) => {
			setCommodities(res)
		})
	}, [])

	useEffect(() => {
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, brandDetails, !!editable))
	}, [editable])

	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}

	const addNewItemHandler = () => {
		const _brandItem = new Brand({
			type: formState.type.value?.name,
			commodity: formState.commodity.value,
			brand_name: formState.brand_name.value,
		})
		dispatch(showLoader(true))
		createBrandApi(_brandItem.toJS())
			.then((res) => {
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, strings('msg_brand_created'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const disableButton = formState.type.value?.name && formState.commodity.value?.commodity_name && formState.brand_name.value

	const renderButtons = () => {
		return (
			<ActionButton small type='primary' disabled={!disableButton} onClick={addNewItemHandler}>
				{strings('add')}
			</ActionButton>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<CreateBrandForm state={formState} updateState={updateFormState} commodities={commodities} />
			</DrawerBodyWrapper>
			<Footer>{renderButtons()}</Footer>
		</>
	)
}

export default CreateBrand
