import CategoryAttributes from './CategoryAttributes'
import Commodity from './Commodity'

class Category {
	constructor() {
		this.category_id = ''
		this.description = null //string
		this.category_name = null //string
		this.hsn_code = null
		this.thickness_first = false
		this.slug = ''
		this.thumbnail_url = null //string
		this.priority = 1
		this.top = false //boolean
		this.commodity = new Commodity()
		this.category_attributes = new CategoryAttributes()
		this.preferred_units = []
		this.popular_attributes = null

		this.access_type = 'web' //string
		this.updated_at = new Date().getTime() //date
		this.updated_by = ''
		this.created_at = new Date().getTime() //date
		this.created_by = ''
		this.gst = null
		this.igst = null
		this.type=null
	}
}

export default Category
