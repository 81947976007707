import API from '../api'
import postUrl from './paths'
import { User as UserModel } from '../../model'

let getUserByPhonePromise

export const getUserByPhone = (mobile) => {
	mobile = mobile.replace('+', '')

	if (!getUserByPhonePromise) {
		getUserByPhonePromise = new Promise((resolve, reject) => {
			API.post(postUrl.getUserByPhone, null, { mobile })
				.then((response) => {
					const user = new UserModel(response.data)
					resolve(user)
				})
				.catch((err) => reject(err))
				.finally(() => {
					getUserByPhonePromise = undefined
				})
		})
	}

	return getUserByPhonePromise
}

export const createUser = (query) =>
	new Promise((resolve, reject) =>
		API.post(postUrl.createUser, {}, query)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const uploadBulkUsers = (file, role) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadBulkUsers, formData, { role })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadUserBusinessPhotos = (file, userId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)
		API.post(postUrl.uploadUserBusinessPhotos, formData, { userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const requestCreditApi = (gstNumber, amountRequested, tenure, files, userId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		for (const file of files) {
			formData.append('files', file)
		}
		API.post(postUrl.requestCredit, formData, { gstNumber, amountRequested, tenure, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadFinancialDocsApi = (files, requestId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		for (const file of files) {
			formData.append('bspl', file)
		}
		API.post(postUrl.uploadFinancialDocs, formData, { requestId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const refreshZohoPastRecordsApi = (zohoUser, userId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.refreshZohoPastRecords, zohoUser, { userId, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const userNameApi = (userId, userName) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.setUserName, {}, { userId, userName })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const registeredGstApi = (gst, organisationId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.setRegisteredGst, null, { gst, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const panDetailsApi = (pan, userId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.enterPanDetails, {}, { pan, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateReportingManagerApi = (userId, managerId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.updateReportingManager, {}, { userId, managerId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
// export { createUser, uploadBulkUsers, uploadUserBusinessPhotos, requestCreditApi, uploadFinancialDocsApi, refreshZohoPastRecordsApi }


export const updateUserFCMToken = (token, userId) =>
	new Promise((resolve, reject) => {
		API.post(
			postUrl.updateUserFCM,
			{},
			{
				// mappingName: 'FCM',
				token,
				userId,
			}
		)
			.then((res) => resolve(res))
			.catch((err) => reject(err))
	})
