import React, { useEffect, useState } from 'react'
import { addUserCommodity, getAllCommodityApi } from '@data'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, MultiSelect, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { UserCommodity } from '@data/model'

const AddCommodity = ({ activeCommodity, updateCommodity, organisationId }) => {
	const dispatch = useDispatch()
	const [commodityList, setCommodityList] = useState([])
	const [activeCommodityList, setActiveCommodity] = useState([])

	useEffect(() => {
		getAllCommodityApi().then((res) => {
			setCommodityList(res?.filter((item) => item?.commodity_name !== activeCommodity?.commodity_name))
		})
	}, [])
	const addCommodityHandler = () => {
		const _userCommodityList = activeCommodityList.map((value) => {
			return new UserCommodity(value).hasCommodity()
		})

		dispatch(showLoader(true))
		addUserCommodity(_userCommodityList, organisationId)
			.then((res) => {
				dispatch(showToast(true, strings('add_commodity_success'), { type: TOAST_TYPE.SUCCESS }))
				// dispatch(toggleDrawer(false))
				updateCommodity()
			})
			.catch((err) => {
				dispatch(showToast(true, err, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	return (
		<>
			<DrawerBodyWrapper>
				<MultiSelect
					id='commodity_units'
					disabled={false}
					label={strings('select', 'commodity')}
					displayKey={'commodity_name'}
					primaryKey={'commodity_name'}
					placeholder={['select', 'commodity']}
					data={commodityList}
					value={activeCommodityList}
					onChange={(value) => setActiveCommodity(value)}
					multiple
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={false} onClick={addCommodityHandler}>
					{strings('add', 'commodity')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddCommodity
