import React, { useMemo } from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { Button, PageLevelTableWrapper, SectionHeader, Table, TableColumns } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { displayAmount } from '@utils'

const PdcList = ({ orgData, financialData, update }) => {
	//  pdc
	const dispatch = useDispatch()
	const buttonHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.ADD_PDC, {
				label: 'Add PDC',
				update: update,
				organisationId: orgData?.organisation_id,
			})
		)
	}

	const columns = useMemo(
		() => [
			{
				label: 'Cheque number',
				key: 'cheque_number',
				mobile: true,
			},
			TableColumns.dateColumn({ label: 'Cheque date', align: 'center', key: 'chq_date' }),
			{
				label: 'Amount',
				key: (item) => {
					return item?.amount ? displayAmount(item?.amount) : '-'
				},
				mobile: true,
			},
			{
				label: 'Bank',
				key: 'bank',
				mobile: true,
			},
			TableColumns.dateColumn({ label: 'Deposit date', align: 'center', key: 'deposit_date' }),
			{
				label: 'Remarks',
				key: 'remarks',
				mobile: true,
			},
			TableColumns.dateColumn({ label: 'Cheque bounce date', align: 'center', key: 'bounce_date' }),
		],
		[]
	)
	
	const rowClickHandler = (row) => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.EDIT_PDC, {
				label: 'Edit PDC',
				data: row,
				update: update,
				organisationId: orgData?.organisation_id,
			})
		)
	}

	return (
		<DetailsWrapper>
			<SectionHeader>
				{strings('pdc')}
				{
					<Button xs onClick={buttonHandler}>
						{strings('add')}
					</Button>
				}
			</SectionHeader>
			<GridListWrapper itemPerRow={[1, 1, 1]}>
				<PageLevelTableWrapper>
					<Table
						fullHeight
						data={financialData?.pdc}
						columns={columns}
						// rowsToDisplay={auction?.all_buyer_quotes?.length}
						// totalItemCount={auction?.all_buyer_quotes?.length}
						page={0}
						onRowClick={rowClickHandler}
						hideFooter
					/>
				</PageLevelTableWrapper>
			</GridListWrapper>
		</DetailsWrapper>
	)
}

export default PdcList
