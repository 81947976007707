import React from 'react'
import { FormItemWrapper, FormWrapper } from '../../styles'
import { Input, Select } from '@base'

const CreateBrandForm = ({ state, updateState, commodities }) => {
	const onChange = (key) => (value) => {
		if (key === 'brand_name') {
			updateState(key, { value: value?.toUpperCase() || '', dirty: true })
		} else {
			updateState(key, { value, dirty: true })
		}
	}

    return (
		<FormWrapper>
			<FormItemWrapper>
				<Select
					primaryKey={state?.commodity?.displayKey}
					displayKey={state?.commodity?.displayKey}
					data={commodities}
					label={state?.commodity?.label}
					disabled={state?.commodity?.disabled}
					value={state?.commodity?.value}
					placeholder={state?.commodity?.placeholder}
					onChange={onChange('commodity')}
					required={state?.commodity?.required}
				/>
			</FormItemWrapper>
			<FormItemWrapper>
				<Select
					primaryKey={state?.type?.displayKey}
					displayKey={state?.type?.displayKey}
					data={state?.type?.data}
					label={state?.type?.label}
					disabled={state?.type?.disabled}
					value={state?.type?.value}
					placeholder={state?.type?.placeholder}
					onChange={onChange('type')}
					required={state?.type?.required}
				/>
			</FormItemWrapper>
			<FormItemWrapper>
				<Input
					type='text'
					label={state?.brand_name?.label}
					disabled={state?.brand_name.disabled}
					value={state?.brand_name?.value}
					placeholder={state?.brand_name?.placeholder}
					onChange={onChange('brand_name')}
				/>
			</FormItemWrapper>
		</FormWrapper>
	)
}

export default CreateBrandForm
