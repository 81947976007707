import styled from 'styled-components'
import { Spacings, Text } from '@styles'
import { AddressCard, Button, Checkbox, DrawerBodyWrapper, DrawerFooterWrapper, H5, H6, Input, Calendar } from '@base'
import { displayAmount } from '@utils'
import { strings } from '@constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateDrawerMeta } from '@data/state/action/root'

export const QuoteBody = styled.div``
export const FormElementWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	max-width: 30rem;

	margin-top: ${Spacings.SPACING_1B};

	& > div {
		width: 100%;
	}
`

const CheckboxWrapper = styled(FormElementWrapper)`
	display: flex;
	align-items: center;
	margin-top: ${Spacings.SPACING_4B};
`
const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const BodyHeader = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	margin: ${Spacings.SPACING_2B} 0;
	font-weight: ${Text.BOLD_600};
`

const Amount = styled(H5)`
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-weight: ${Text.BOLD_600};
`

const QuoteSubmission = ({ view, edit, quote, acceptQuoteHandler, update }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: view ? strings('view_quote') : edit ? strings('edit_quote') : strings('submit_quote'),
			})
		)
	}, [dispatch, view, edit])

	const renderFooterButton = () => {
		if (view) {
			return (
				<Button small type='primary' margin='0 0 0 auto'>
					{strings('accept_quote')}
				</Button>
			)
		}
		if (edit) {
			return (
				<Button small type='primary' margin='0 0 0 auto'>
					{strings('update_quote')}
				</Button>
			)
		}

		return (
			<Button small type='primary' margin='0 0 0 auto'>
				{strings('submit_quote')}
			</Button>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<QuoteBody>
					{/* <BodyHeader>Quote details</BodyHeader> */}
					<FormElementWrapper>
						<Input
							disabled={view}
							id='weight-bid'
							type='number'
							label={strings('label_weight_mt')}
							value={quote?.order_quantity ? quote?.order_quantity / 1000 : null}
						/>
					</FormElementWrapper>
					<FormElementWrapper>
						<Input
							disabled={view}
							id='bid-per-mt'
							type='number'
							label={strings('label_your_bid_mt')}
							value={quote?.quote_per_mt ?? null}
						/>
					</FormElementWrapper>
					<FormElementWrapper>
						<Calendar disabled={view} label={strings('bid_valid_till')} value={quote?.quote_valid_till} />
					</FormElementWrapper>
					<CheckboxWrapper>
						<Checkbox
							disabled={view}
							id='logistics-required-checkbox'
							size={1.125}
							checked={quote?.logistics_requested}
							value={quote?.logistics_requested}
						/>
						<FormElementLabel disabled={view} htmlFor='logistics-required-checkbox'>
							{`${strings('logistics_requests')}?`}
						</FormElementLabel>
					</CheckboxWrapper>
					<br />
					<BodyHeader>{strings('total_quotation_amount')}</BodyHeader>
					<Amount>{displayAmount(quote?.order_quantity * quote?.quote_per_mt)}</Amount>
					<br />
					<BodyHeader>{strings('shipping_address')}</BodyHeader>
					<AddressCard data={quote?.shipping_address} />
				</QuoteBody>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderFooterButton()}</DrawerFooterWrapper>
		</>
	)
}

export default QuoteSubmission
